import {
  BoundaryRatios as ResponsiveBoundaryRatios,
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.LocationFullCorners01 class.
 *
 * @module squarespace-slide-rendering-layouts-location-full-corners-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-location-full-corners-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for location-full-corners-01.
   *
   * @class LocationFullCorners01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.LocationFullCorners01 = Y.Base.create('LocationFullCorners01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var responsiveWrapperNode = contentBox.querySelector('.responsive-wrapper.actions');

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          minFontSize: 10,
          widthRatio: 0.99,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
          },
          onMinFontSize: function(rootNode) {
            rootNode.classList.add('stacked');
          }
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          widthRatio: 1,
          minFontSize: 20,
          scale: 0.99
        }));

        // Actions and custom form font size
        var actionsFontSizeComponent = new ResponsiveFontSize({
          rootNode: responsiveWrapperNode,
          fontSizeNodes: [
            contentBox.querySelector(('[data-compound-type="action"] ul')),
            contentBox.querySelector('[data-slice-type="custom-form"]')
          ],
          minFontSize: 10,
          widthRatio: 0.8,
          enabled: false,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
          },
          onMinFontSize: function(rootNode) {
            rootNode.classList.add('stacked');
          }
        });

        this._registerResponsiveComponent(actionsFontSizeComponent);

        // Social icons wrapping
        var socialIconsWrappingComponent = new ResponsiveBoundaryRatios({
          rootNode: contentBox.querySelector('.responsive-wrapper.social'),
          widthRatio: 0.9,
          enabled: false,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
            rootNode.classList.remove('text-align-left');
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('stacked');
            rootNode.classList.add('text-align-left');
          }
        });

        this._registerResponsiveComponent(socialIconsWrappingComponent);

        // Overlap between copy and inline link group
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('.group-copy'),
          targetNode: contentBox.querySelector('.sqs-slice-group.group-links'),
          padding: 60,
          onReset: function(rootNode, targetNode) {
            rootNode.classList.remove('stacked');

            targetNode.classList.add('top-right');
            targetNode.classList.remove('restrict-width-500');

            actionsFontSizeComponent.disable();
            socialIconsWrappingComponent.disable();
          },
          onRespond: function(rootNode, targetNode) {
            rootNode.classList.add('stacked');

            targetNode.classList.remove('top-right');
            targetNode.classList.add('restrict-width-500');

            actionsFontSizeComponent.enable();
            socialIconsWrappingComponent.enable();
          }
        }));

        // Scroll on overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: contentBox.querySelector('.sqs-slide-layer.layer-front .sqs-slide-layer-content'),
          widthRatio: 0.95,
          padding: 0,
          onReset: function(rootNode) {
            contentBox.querySelector('.sqs-slide-layer.layer-front').classList.remove('scroll');
          },
          onRespond: function(rootNode) {
            contentBox.querySelector('.sqs-slide-layer.layer-front').classList.add('scroll');
          }
        }));
      }
    }
  );

}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
