"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SslSetting */

/* Java class defined in repository squarespace-v6 */
var SslSetting;

(function (SslSetting) {
  SslSetting["INSECURE"] = 1;
  SslSetting["PREFER_SECURE"] = 2;
  SslSetting["FORCE_SECURE"] = 3;
})(SslSetting || (SslSetting = {}));

var _default = SslSetting;
exports["default"] = _default;
module.exports = exports.default;