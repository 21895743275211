import SlideType from '@sqs/enums/SlideType';
import { SlideTypeNames, SlideTypeByName } from '@sqs/websites-constants';
import * as SelectorUtils from 'shared/utils/getSlideWrapperSelector';

/**
 * The module containing the Squarespace.SlideRendering.Slices.Base class.
 *
 * @module squarespace-slide-rendering-slices-base
 */
YUI.add('squarespace-slide-rendering-slices-base', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * The base widget for rendering individual slices on the frontend.
   *
   * @class Base
   * @namespace Squarespace.SlideRendering.Slices
   * @extends Widget
   */
  Y.Squarespace.SlideRendering.Slices.Base = Y.Base.create('Base', Y.Widget,
    [],
    {
      CONTENT_TEMPLATE: null
    },
    {
      CSS_PREFIX: 'sqs-slice',
      ATTRS: {
        /**
         * The id of the slice that this widget is rendering.
         *
         * @attribute sliceId
         * @type String
         * @default null
         * @required
         */
        sliceId: {
          value: null
        },

        /**
         * The type of slide that the slice is rendered into.
         *
         * @attribute slideType
         * @type String
         * @default 'cover-page'
         * @required
         */
        slideType: {
          value: SlideTypeNames[SlideType.COVER_PAGE]
        },

        /**
         * The selector for the specific type of slice container
         *
         * @attribute slideSelector
         * @type String
         * @required
         */
        slideSelector: {
          getter: function() {
            var slideType = SlideTypeByName[this.get('slideType')];
            return SelectorUtils.getWrapperSelector(slideType);
          }
        },

        /**
         * Whether or not this slice widget has the ability to refresh itself
         * with a given slice on the client alone.
         *
         * @attribute canClientRefresh
         * @type Boolean
         * @default false
         * @readOnly
         */
        canClientRefresh: {
          value: false
        }
      }
    }
  );
}, '1.0', {
  requires: [
    'base',
    'widget'
  ]
});
