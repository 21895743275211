"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.ProductLine */

/* Java class defined in repository squarespace-v6 */
var ProductLines;

(function (ProductLines) {
  ProductLines["COVER_PAGE"] = "cover_page";
  ProductLines["CLASSIC"] = "classic";
  ProductLines["COMMERCE"] = "commerce";
  ProductLines["ENTERPRISE"] = "enterprise";
  ProductLines["DOMAIN"] = "domain";
  ProductLines["PARKING_PAGE"] = "parking-page";
  ProductLines["GOOGLE_APPS"] = "google-apps";
  ProductLines["CAMPAIGNS"] = "campaigns";
  ProductLines["SCHEDULING"] = "scheduling";
  ProductLines["MEMBER_AREAS"] = "member-areas";
  ProductLines["SHIPPING_LABEL"] = "shipping-label";
})(ProductLines || (ProductLines = {}));

var _default = ProductLines;
exports["default"] = _default;
module.exports = exports.default;