import {
  FontSize as ResponsiveFontSize,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the Squarespace.SlideRendering.Layouts.LockFullCenter03
 * class.
 *
 * @module squarespace-slide-rendering-layouts-lock-full-center-03
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-lock-full-center-03', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for lock-full-center-03.
   *
   * @class LockFullCenter03
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.LockFullCenter03 = Y.Base.create('LockFullCenter03',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthRatio: 0.98,
          minFontSize: 14
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          widthRatio: 0.98,
          minFontSize: 18
        }));

        // Overlap between copy and password group
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('.sqs-slice-group.group-copy'),
          targetNode: contentBox.querySelector('.sqs-slice-group.group-password'),
          padding: 50,
          onReset: function(rootNode, targetNode) {
            targetNode.classList.add('align-center');
            targetNode.classList.remove('stacked');
            targetNode.style.top = '50%';
            targetNode.style.left = '50%';
          },
          onRespond: function(rootNode, targetNode) {
            targetNode.classList.remove('align-center');
            targetNode.classList.add('stacked');

            var copyRect = rootNode.getBoundingClientRect();

            targetNode.style.top = copyRect.bottom + 'px';
            targetNode.style.left = copyRect.left + 'px';

          }
        }));
      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
