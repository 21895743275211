/**
 * Track and register Facebook Pixel events.
 *
 * @param  {String}  eventName    Name of event
 * @param  {Object}  customData   Customized event data
 * @param  {Number}  [customData.value]  Value of a user performing this event to the business
 * @param  {String}  [customData.currency]  Currency for the value specified
 * @param  {String}  [customData.content_name]  Name of the page/product
 * @param  {String}  [customData.content_category]  Category of the page/product
 * @param  {String}  [customData.content_ids]  Product ids associated with the event
 * @param  {String}  [customData.contents]  A list of JSON object that contains the International Article Number (EAN)
 *                                          when applicable, or other product or content identifier(s) associated with
 *                                          the event as well as quantities and prices of the products
 * @param  {String}  [customData.content_type]  Either 'product' or 'product_group' based on the content_ids or
 *                                              contents being passed
 * @param  {Number}  [customData.num_items]  The number of items that checkout was initiated for
 * @param  {String}  [customData.search_string]  The string entered by the user for the search
 * @param  {String}  [customData.status]  Show the status of the registration
 */

interface FacebookPixelRequestParams {
  value?: string,
  currency?: string,
  content_name?: string,
  content_category?: string,
  content_ids?: string[]
  contents?: object[],
  content_type?: 'product',
  num_items?: number,
  search_string?: string,
  status?: string,
}

function trackFacebookPixel(eventName: string, customData: FacebookPixelRequestParams = {}) {
  // check topmost window for fbq definition for cases when function is called in a subframe
  const Top: any = window.top;
  if (Top.fbq) {
    Top.fbq('track', eventName, customData);
  }
}

export default trackFacebookPixel;
