"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.DomainPlanLevel */

/* Java class defined in repository squarespace-v6 */
var DomainPlanLevel;

(function (DomainPlanLevel) {
  DomainPlanLevel["FREE"] = "forever-free";
  DomainPlanLevel["BASIC"] = "basic";
  DomainPlanLevel["PREMIUM_I"] = "premium_i";
  DomainPlanLevel["PREMIUM_II"] = "premium_ii";
  DomainPlanLevel["PREMIUM_III"] = "premium_iii";
  DomainPlanLevel["PREMIUM_IV"] = "premium_iv";
  DomainPlanLevel["PREMIUM_V"] = "premium_v";
})(DomainPlanLevel || (DomainPlanLevel = {}));

var _default = DomainPlanLevel;
exports["default"] = _default;
module.exports = exports.default;