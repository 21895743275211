/**
 * The module containing the Squarespace.SlideRendering.Slices.SubHeading class.
 *
 * @module squarespace-slide-rendering-slices-sub-heading
 */
YUI.add('squarespace-slide-rendering-slices-sub-heading', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * The slice widget for slices of type SliceType.SUB_HEADING.
   *
   * @class SubHeading
   * @constructor
   * @namespace Squarespace.SlideRendering.Slices
   * @extends Squarespace.SlideRendering.Slices.Base
   * @uses Squarespace.SlideRendering.Slices.ClientRefresh
   */
  Y.Squarespace.SlideRendering.Slices.SubHeading = Y.Base.create('SubHeading',
    Y.Squarespace.SlideRendering.Slices.Base,
    [Y.Squarespace.SlideRendering.Slices.ClientRefresh],
    {
      // Override
      refreshFromSliceContent: function(sliceContent) {
        this.get('contentBox').one('h2').setHTML(sliceContent.get('text'));
      }
    },
    {
      SLICE_TYPE: Y.Squarespace.SliceType.SUB_HEADING,
      CSS_PREFIX: 'sqs-slice-sub-heading'
    }
  );
}, '1.0', {
  requires: [
    'base',
    'squarespace-enum',
    'squarespace-slide-rendering-slices-base',
    'squarespace-slide-rendering-slices-client-refresh'
  ]
});