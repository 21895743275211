import { CoverPageViewBinder } from '@sqs/site-rendering/AlbumPlayer';
/**
 * The module containing the Squarespace.SlideRendering.Slices.Album class.
 *
 * @module squarespace-slide-rendering-slices-album
 */
YUI.add('squarespace-slide-rendering-slices-album', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * The slice widget for slices of type ALBUM.
   *
   * @class Album
   * @constructor
   * @namespace Squarespace.SlideRendering.Slices
   * @extends Squarespace.SlideRendering.Slices.Base
   */
  var Album =
  Y.Squarespace.SlideRendering.Slices.Album = Y.Base.create('Album',
    Y.Squarespace.SlideRendering.Slices.Base,
    [],
    {
      initializer: function() {
        var contentBox = this.get('contentBox');
        var playlistNode = contentBox.one('.sqs-slice-album-playlist');

        if (!playlistNode.hasClass('demo-album')) {
          this._playlist = new CoverPageViewBinder();
        } else {
          this.get('contentBox').all('a').on('click', function(e) {
            e.halt();
          }, this);
        }
      },

      renderUI: function() {
        Album.superclass.renderUI.call(this);

        var contentBox = this.get('contentBox');
        var playlistNode = contentBox.one('.sqs-slice-album-playlist');

        if (this._playlist) {
          this._playlist.connectToNode(playlistNode.getDOMNode());
        }

      }
    },
    {
      SLICE_TYPE: Y.Squarespace.SliceType.ALBUM,
      CSS_PREFIX: 'sqs-slice-album'
    }
  );
}, '1.0', {
  requires: [
    'base',
    'squarespace-slide-rendering-slices-base',
    'squarespace-enum'
  ]
});
