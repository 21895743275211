"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.GoogleReCaptchaAlignmentType */

/* Java class defined in repository cms-legacy-data */
var GoogleReCaptchaAlignmentType;

(function (GoogleReCaptchaAlignmentType) {
  GoogleReCaptchaAlignmentType["LEFT"] = 1;
  GoogleReCaptchaAlignmentType["CENTER"] = 2;
  GoogleReCaptchaAlignmentType["RIGHT"] = 3;
})(GoogleReCaptchaAlignmentType || (GoogleReCaptchaAlignmentType = {}));

var _default = GoogleReCaptchaAlignmentType;
exports["default"] = _default;
module.exports = exports.default;