"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.AchievementType */

/* Java class defined in repository squarespace-v6 */
var AchievementType;

(function (AchievementType) {
  AchievementType["LIVE_STORE"] = "live_store";
  AchievementType["TEST_ACHIEVEMENT"] = "test1";
  AchievementType["ADDED_DONATION_BLOCK"] = "added_donation_block";
  AchievementType["ADDED_SHIPPING_OPTION"] = "added_shipping_option";
  AchievementType["PURCHASED_SITE"] = "purchased_site";
  AchievementType["PURCHASED_DOMAIN"] = "purchased_domain";
  AchievementType["PURCHASED_SCHEDULING"] = "purchased_scheduling";
  AchievementType["PURCHASED_CAMPAIGNS"] = "purchased_campaigns";
  AchievementType["PURCHASED_MEMBER_AREAS"] = "purchased_member_areas";
  AchievementType["ATTRIBUTION_SURVEY_SHOWN"] = "attribution_survey";
  AchievementType["FIRST_LOGIN"] = "first_login";
  AchievementType["GIFT_CARD_ISSUED"] = "gift_card_issued";
  AchievementType["POS_ORDER_PLACED"] = "pos_order_placed";
  AchievementType["COMMERCE_ONBOARDED"] = "commerce_onboarded";
  AchievementType["PRODUCT_CREATED"] = "product_created";
  AchievementType["THIRD_PARTY_ORDER_CREATED"] = "third_party_order_created";
  AchievementType["PRODUCT_CREATED_PRODUCT_COMPOSER"] = "product_created_product_composer";
  AchievementType["PRODUCT_SAVED"] = "product_saved";
  AchievementType["STORE_SETUP_COMPLETE"] = "store_setup_complete";
  AchievementType["ETSY_INTENT_EXPRESSED"] = "etsy_intent_expressed";
  AchievementType["COMMERCE_NPS_SURVEY_DAY_28_COMPLETED"] = "commerce_nps_survey_day28_completed";
  AchievementType["COMMERCE_NPS_SURVEY_DAY_88_COMPLETED"] = "commerce_nps_survey_day88_completed";
  AchievementType["COMMERCE_NPS_SURVEY_DAY_179_COMPLETED"] = "commerce_nps_survey_day179_completed";
  AchievementType["COMMERCE_NPS_SURVEY_DAY_340_COMPLETED"] = "commerce_nps_survey_day340_completed";
  AchievementType["COMMERCE_ORDERS_PANEL_M1_SURVEY_COMPLETED"] = "commerce_orders_panel_m1_survey_completed";
  AchievementType["COMMERCE_ORDERS_PANEL_M2_SURVEY_COMPLETED"] = "commerce_orders_panel_m2_survey_completed";
  AchievementType["MULTILINGUAL_EMAILS_SURVEY_COMPLETED"] = "multilingual_emails_survey_completed";
  AchievementType["MULTILINGUAL_EDIT_WARNING_SUPPRESSED"] = "multilingual_edit_warning_suppressed";
})(AchievementType || (AchievementType = {}));

var _default = AchievementType;
exports["default"] = _default;
module.exports = exports.default;