"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.enums.TermTypes */

/* Java class defined in repository squarespace-v6 */
var TermTypes;

(function (TermTypes) {
  TermTypes["WEBSITE_TERMS_OF_SERVICE"] = 1;
  TermTypes["PRIVACY_POLICY"] = 2;
  TermTypes["DOMAIN_FIRST_TERMS_OF_SERVICE"] = 3;
  TermTypes["IMAGE_TERMS_OF_SERVICE"] = 4;
  TermTypes["TEMPLATES_LICENSE"] = 5;
  TermTypes["LOGO_TERMS_OF_SERVICE"] = 7;
  TermTypes["TRADEMARK_USAGE"] = 8;
  TermTypes["COMBINED_TOS_AND_PRIVACY_POLICY"] = 9;
})(TermTypes || (TermTypes = {}));

var _default = TermTypes;
exports["default"] = _default;
module.exports = exports.default;