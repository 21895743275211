/**
 * The module containing the Squarespace.SlideRendering.Slices.SocialIcons class.
 *
 * @module squarespace-slide-rendering-slices-social-icons
 */
YUI.add('squarespace-slide-rendering-slices-social-icons', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * The slice widget for slices of type SliceType.SOCIAL_ICONS.
   *
   * @class SocialIcons
   * @constructor
   * @namespace Squarespace.SlideRendering.Slices
   * @extends Squarespace.SlideRendering.Slices.Base
   */
  Y.Squarespace.SlideRendering.Slices.SocialIcons = Y.Base.create('SocialIcons',
    Y.Squarespace.SlideRendering.Slices.Base,
    [],
    {},
    {
      SLICE_TYPE: Y.Squarespace.SliceType.SOCIAL_ICONS,
      CSS_PREFIX: 'sqs-slice-social-icons'
    }
  );
}, '1.0', {
  requires: [
    'base',
    'squarespace-enum',
    'squarespace-slide-rendering-slices-base'
  ]
});