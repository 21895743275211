"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.CensusEventType */

/* Java class defined in repository squarespace-v6 */
var CensusEventType;

(function (CensusEventType) {
  CensusEventType["PAGE_VIEW"] = 1;
  CensusEventType["SITE_SEARCH"] = 2;
  CensusEventType["HEARTBEAT"] = 10;
  CensusEventType["AUDIO"] = 20;
  CensusEventType["VIDEO"] = 21;
})(CensusEventType || (CensusEventType = {}));

var _default = CensusEventType;
exports["default"] = _default;
module.exports = exports.default;