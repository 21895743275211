"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.FormBackendConnectionType */

/* Java class defined in repository cms-legacy-data */
var FormBackendConnectionType;

(function (FormBackendConnectionType) {
  FormBackendConnectionType["GOOGLE"] = 1;
  FormBackendConnectionType["MAILCHIMP"] = 2;
  FormBackendConnectionType["EMAIL"] = 3;
  FormBackendConnectionType["SITE_USER"] = 4;
  FormBackendConnectionType["ZAPIER"] = 5;
  FormBackendConnectionType["CAMPAIGNS"] = 6;
})(FormBackendConnectionType || (FormBackendConnectionType = {}));

var _default = FormBackendConnectionType;
exports["default"] = _default;
module.exports = exports.default;