"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.AccountState */

/* Java class defined in repository cms-legacy-data */
var AccountStates;

(function (AccountStates) {
  AccountStates["ACTIVE"] = 1;
  AccountStates["UNAUTHORIZED"] = 2;
})(AccountStates || (AccountStates = {}));

var _default = AccountStates;
exports["default"] = _default;
module.exports = exports.default;