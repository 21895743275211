"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.WebsiteContentSubcategory */

/* Java class defined in repository squarespace-v6 */
var WebsiteContentSubcategory;

(function (WebsiteContentSubcategory) {
  WebsiteContentSubcategory["MUSICIAN"] = 1;
  WebsiteContentSubcategory["RECORD_LABEL"] = 2;
  WebsiteContentSubcategory["BAND"] = 3;
  WebsiteContentSubcategory["VENUE"] = 4;
  WebsiteContentSubcategory["EVENT"] = 5;
  WebsiteContentSubcategory["RESTAURANT"] = 6;
  WebsiteContentSubcategory["BAR"] = 7;
  WebsiteContentSubcategory["CAFE_BAKERY"] = 8;
  WebsiteContentSubcategory["GOODS"] = 9;
  WebsiteContentSubcategory["PHOTOGRAPHER"] = 10;
  WebsiteContentSubcategory["ARTIST"] = 11;
  WebsiteContentSubcategory["CREATIVE_AGENCY"] = 12;
  WebsiteContentSubcategory["ARCHITECT"] = 13;
  WebsiteContentSubcategory["INTERIOR_DESIGNER"] = 14;
  WebsiteContentSubcategory["PODCAST"] = 15;
  WebsiteContentSubcategory["MAGAZINE"] = 16;
  WebsiteContentSubcategory["BLOG"] = 17;
  WebsiteContentSubcategory["BOOK"] = 18;
  WebsiteContentSubcategory["PERSONAL_WEDDING"] = 19;
  WebsiteContentSubcategory["WEDDING_PROFESSIONAL"] = 20;
  WebsiteContentSubcategory["WEDDING_VENDOR"] = 21;
  WebsiteContentSubcategory["LAWYER"] = 22;
  WebsiteContentSubcategory["CONSULTANT"] = 23;
  WebsiteContentSubcategory["ARCHITECTURE"] = 24;
  WebsiteContentSubcategory["HEALTHCARE"] = 25;
  WebsiteContentSubcategory["MARKETING"] = 26;
  WebsiteContentSubcategory["TECHNOLOGY"] = 27;
  WebsiteContentSubcategory["DESIGN"] = 28;
  WebsiteContentSubcategory["REAL_ESTATE"] = 29;
  WebsiteContentSubcategory["CORPORATE"] = 30;
  WebsiteContentSubcategory["HOME_LIVING"] = 31;
  WebsiteContentSubcategory["FASHION"] = 32;
  WebsiteContentSubcategory["HEALTH_BEAUTY"] = 33;
  WebsiteContentSubcategory["ART_PHOTOGRAPHY"] = 34;
})(WebsiteContentSubcategory || (WebsiteContentSubcategory = {}));

var _default = WebsiteContentSubcategory;
exports["default"] = _default;
module.exports = exports.default;