"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.CommentStatus */

/* Java class defined in repository cms-legacy-data */
var CommentStatuses;

(function (CommentStatuses) {
  CommentStatuses["APPROVED"] = 1;
  CommentStatuses["AWAITING_MODERATION"] = 2;
  CommentStatuses["DELETED"] = 3;
  CommentStatuses["SPAM"] = 4;
  CommentStatuses["POSSIBLY_SPAM"] = 5;
})(CommentStatuses || (CommentStatuses = {}));

var _default = CommentStatuses;
exports["default"] = _default;
module.exports = exports.default;