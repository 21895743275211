"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.business.billing.WebsiteCompRecordType */

/* Java class defined in repository squarespace-v6 */
var WebsiteCompRecordType;

(function (WebsiteCompRecordType) {
  WebsiteCompRecordType["EMPLOYEE"] = 1;
  WebsiteCompRecordType["PRESS"] = 2;
  WebsiteCompRecordType["BRAND_PARTNER"] = 3;
  WebsiteCompRecordType["EVENT_PARTNER"] = 4;
  WebsiteCompRecordType["INFLUENCER"] = 5;
  WebsiteCompRecordType["FACULTY"] = 6;
  WebsiteCompRecordType["GIVEAWAY"] = 7;
  WebsiteCompRecordType["BULK_INVOICE"] = 8;
  WebsiteCompRecordType["RECRUITING_PARTNER"] = 9;
  WebsiteCompRecordType["BRAND_MARKETING"] = 10;
  WebsiteCompRecordType["ENTERPRISE_CONCIERGE"] = 11;
  WebsiteCompRecordType["EXTENSION_DEVELOPER"] = 12;
  WebsiteCompRecordType["EX_EMPLOYEE"] = 13;
  WebsiteCompRecordType["BUG_WORKAROUND"] = 14;
  WebsiteCompRecordType["MARKETPLACE"] = 15;
})(WebsiteCompRecordType || (WebsiteCompRecordType = {}));

var _default = WebsiteCompRecordType;
exports["default"] = _default;
module.exports = exports.default;