import {
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the Squarespace.SlideRendering.Layouts.LockFullCenter05
 * class.
 *
 * @module squarespace-slide-rendering-layouts-lock-full-center-05
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-lock-full-center-05', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for lock-full-center-05.
   *
   * @class LockFullCenter05
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.LockFullCenter05 = Y.Base.create('LockFullCenter05',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var contentGroupNode = contentBox.querySelector('.sqs-slice-group.group-content');
        var contentNode = contentBox.querySelector('.layer-front .sqs-slide-layer-content');
        var layerNode = contentBox.querySelector('.sqs-slide-layer');

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthRatio: 0.99,
          scale: 0.99,
          minFontSize: 14,
          heightBoundaryNode: layerNode,
          heightRatio: 0.16
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          heightRatio: 0.16,
          heightBoundaryNode: layerNode,
          widthRatio: 0.99,
          scale: 0.99,
          minFontSize: 18
        }));

        // Scroll on overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: contentGroupNode,
          onReset: function(rootNode) {
            rootNode.classList.add('align-center');
            rootNode.classList.add('align-center-absolute');
            contentNode.classList.remove('scroll');
          },
          onRespond: function(rootNode) {
            rootNode.classList.remove('align-center');
            rootNode.classList.remove('align-center-absolute');
            contentNode.classList.add('scroll');
          }
        }));

        // Overlap between content group and password group
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentGroupNode,
          targetNode: contentBox.querySelector('.sqs-slice-group.group-password'),
          padding: 0,
          onReset: function(rootNode, targetNode) {
            rootNode.classList.add('align-center');
            rootNode.classList.add('align-center-absolute');
            rootNode.classList.remove('stacked');

            // Make entire slide non-scrollable
            contentNode.classList.remove('scroll');

            targetNode.classList.add('bottom');
            targetNode.classList.add('align-center-horiz');
          },
          onRespond: function(rootNode, targetNode) {
            rootNode.classList.remove('align-center');
            rootNode.classList.remove('align-center-absolute');
            rootNode.classList.add('stacked');

            // Make entire slide scrollable
            contentNode.classList.add('scroll');

            targetNode.classList.remove('bottom');
            targetNode.classList.remove('align-center-horiz');
          }
        }));
      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
