import ButtonContext from '@sqs/enums/ButtonContext';
import * as CensusUtils from 'shared/utils/census/CensusUtils';

/**
 * The module containing the Squarespace.SlideRendering.Slices.Buttons class.
 *
 * @module squarespace-slide-rendering-slices-buttons
 */
YUI.add('squarespace-slide-rendering-slices-buttons', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * The slice widget for slices of type SliceType.BUTTONS.
   *
   * @class Buttons
   * @constructor
   * @namespace Squarespace.SlideRendering.Slices
   * @extends Squarespace.SlideRendering.Slices.Base
   * @uses Squarespace.SlideRendering.Slices.ClientRefresh
   */
  Y.Squarespace.SlideRendering.Slices.Buttons = Y.Base.create('Buttons',
    Y.Squarespace.SlideRendering.Slices.Base,
    [Y.Squarespace.SlideRendering.Slices.ClientRefresh],
    {
      renderUI: function() {
        this._trackButtonRender();
      },

      bindUI: function() {
        var contentBox = this.get('contentBox');
        contentBox.all('a').each(function(button) {
          button.on('click', this._trackButtonClick, this);
        }, this);
      },
      // Override
      refreshFromSliceContent: function(sliceContent) {
        var newHTML = '';
        var isEmpty = true;

        sliceContent.get('links').each(function(link) {
          var text = link.get('text');

          if (Y.Lang.isValue(text) && text !== '') {
            isEmpty = false;

            var clickthroughUrl = link.get('clickthroughUrl');

            newHTML += Y.Lang.sub('<li><a href="{href}"{target}>{text}</a></li>', {
              href: clickthroughUrl.url,
              targetNode: clickthroughUrl.newWindow ? ' targetNode="_blank"' : '',
              text: Y.Squarespace.Escaping.escapeForHtml(text)
            });
          }
        });

        this.get('contentBox').one('ul').setHTML(newHTML);
        this._updateContentEmptyAttribute(isEmpty);
      },

      _getTrackingData: function(el) {
        var href = el.getAttribute('href');
        var slideNode = el.ancestor('.sqs-slide');
        var layoutId = slideNode.getAttribute('data-slide-layout-id');
        var slideType = slideNode.getAttribute('data-slide-type');
        var slideButtonContext = slideType === 'popup-overlay' ?
          ButtonContext.POPUP_OVERLAY : ButtonContext.BUTTON_BLOCK;

        return {
          pagePath: Y.config.win.location.pathname,
          id: layoutId,
          buttonText: el.get('innerHTML'),
          clickthroughUrl: href,
          alignment: null,
          size: null,
          newWindow: el.hasAttribute('target'),
          context: slideButtonContext
        };
      },

      _trackButtonRender: function() {
        var contentBox = this.get('contentBox');
        contentBox.all('a').each(function(button) {

          var trackingData = this._getTrackingData(button);
          if (trackingData.context === ButtonContext.POPUP_OVERLAY) {
            return;
          }

          CensusUtils.trackButtonView(trackingData);
        }, this);

      },

      _trackButtonClick: function(e) {
        var anchorNode = e.currentTarget;

        if (anchorNode.hasAttribute('data-tracked')) {
          anchorNode.removeAttribute('data-tracked');
          return;
        }

        var isTargetNewWindow = anchorNode.hasAttribute('target');
        var href = e.target.getAttribute('href');

        // Safari disallows triggering mailto programatically
        var isMailto = href && href.split(':')[0].toLowerCase() === 'mailto';
        var shouldPreventDefault = (!isTargetNewWindow || e.metaKey || e.ctrlKey || e.shiftKey) && !isMailto;
        if (shouldPreventDefault) {
          e.preventDefault();
          e.stopImmediatePropagation();
        }

        var completeEvent = function() {
          if (shouldPreventDefault) {
            anchorNode.setAttribute('data-tracked', true);
            anchorNode.simulate('click');
          }
        };

        var trackingData = this._getTrackingData(e.target);

        CensusUtils.trackButtonClick(trackingData).then(function() {
          completeEvent();
        }).catch(function(err) {
          completeEvent();
        });
      }
    },
    {
      SLICE_TYPE: Y.Squarespace.SliceType.BUTTONS,
      CSS_PREFIX: 'sqs-slice-buttons'
    }
  );
}, '1.0', {
  requires: [
    'base',
    'squarespace-enum',
    'squarespace-escaping-utils',
    'squarespace-slide-rendering-slices-base',
    'squarespace-slide-rendering-slices-client-refresh'
  ]
});
