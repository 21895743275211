"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.getty.Composition */

/* Java class defined in repository squarespace-v6 */
var Compositions;

(function (Compositions) {
  Compositions["ABSTRACT"] = "abstract";
  Compositions["CANDID"] = "candid";
  Compositions["CLOSE_UP"] = "close_up";
  Compositions["COPY_SPACE"] = "copy_space";
  Compositions["CUT_OUT"] = "cut_out";
  Compositions["FULL_FRAME"] = "full_frame";
  Compositions["FULL_LENGTH"] = "full_length";
  Compositions["HEADSHOT"] = "headshot";
  Compositions["LOOKING_AT_CAMERA"] = "looking_at_camera";
  Compositions["MACRO"] = "macro";
  Compositions["PORTRAIT"] = "portrait";
  Compositions["SPARSE"] = "sparse";
  Compositions["STILL_LIFE"] = "still_life";
  Compositions["THREE_QUARTER_LENGTH"] = "three_quarter_length";
  Compositions["WAIST_UP"] = "waist_up";
})(Compositions || (Compositions = {}));

var _default = Compositions;
exports["default"] = _default;
module.exports = exports.default;