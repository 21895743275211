"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.business.billing.BillingStatus */

/* Java class defined in repository squarespace-v6 */
var BillingStatus;

(function (BillingStatus) {
  BillingStatus["FREE"] = 1;
  BillingStatus["LINKED"] = 2;
  BillingStatus["SUBSCRIBED"] = 3;
  BillingStatus["TRIAL"] = 4;
  BillingStatus["RESOLD"] = 5;
  BillingStatus["RESOLD_GRACE_PERIOD"] = 6;
})(BillingStatus || (BillingStatus = {}));

var _default = BillingStatus;
exports["default"] = _default;
module.exports = exports.default;