import { t } from 'shared/i18n';
import GoogleReCaptchaAlignmentType from '@sqs/enums/GoogleReCaptchaAlignmentType';
import GoogleReCaptchaThemeType from '@sqs/enums/GoogleReCaptchaThemeType';
import * as FormConnections from 'shared/utils/FormConnections';
import FormBackendConnectionState from '@sqs/enums/FormBackendConnectionState';

YUI.add('squarespace-models-form-base', function (Y) {

  var getBackendConnectionConfigsAsArray = function (value) {
    if (Y.Lang.isArray(value)) {
      return value;
    }

    var newArray = [];
    Y.Object.each(value, function (val, key) {
      if (val && (val.connectionState === FormBackendConnectionState.CONNECTED ||
      val.connectionState === FormBackendConnectionState.PENDING)) {
        newArray.push(Y.merge({ _type: key }, val));
      }
    });

    return newArray;
  };

  Y.namespace('Squarespace.Models');

  /**
   * Base class for a Form model. Desktop and MobileV7 have different
   * serialization implementations of this.
   *
   * @namespace Squarespace.Models
   * @class     FormBase
   * @extends   Model
   * @mixes     Squarespace.ModelSync.REST
   */
  Y.Squarespace.Models.FormBase = Y.Base.create(
  'formBase',
  Y.Model,
  [Y.Squarespace.ModelSync.REST],
  {

    // /api/rest/forms
    root: Y.Squarespace.REST_API_ROOT + 'forms',

    url: Y.Squarespace.REST_API_ROOT + 'forms/{id}',

    /**
     * Implements Squarespace.ModelSync.REST#serialize
     *
     * @override
     */
    serialize: function () {
      var formJSON = this.toJSON();

      // Fields are stored as an array of stringified objects.
      formJSON.fields = Y.Array.map(formJSON.fields, function (field) {
        return Y.JSON.stringify(field);
      });

      // Submission messages somehow started being stored as stringified
      // wysiwyg configs.
      formJSON.submissionMessage = Y.JSON.stringify(formJSON.submissionMessage);
      formJSON.disclaimerMessage = Y.JSON.stringify(formJSON.disclaimerMessage);

      // Get the backendConnectionConfigs object to be passed into the
      // serialized object sent to the server.
      var backendConnectionConfigsJSON = formJSON.backendConnectionConfigs;

      delete formJSON.backendConnectionConfigs;
      delete formJSON.connectedBackends;

      // Difference: on mobile do not stringify this, native will send
      // object instead of string to requestData
      return {
        form: formJSON,
        backendConnectionConfigs: backendConnectionConfigsJSON || [],
        removedBackends: this.get('removedConnectedBackends') };

    },

    /**
     * Iterate over the connected backend configs and return the backends that
     * matches the given type. If it does not exist, return empty array.
     *
     * @param {Number} backendType of enum FormBackendConnectionType - the
     *  type to filter by
     * @return {Array}
     */
    getConnBackend: function (backendType) {
      var connBackends = this.get('connectedBackends');
      if (!Y.Lang.isValue(connBackends)) {
        return [];
      }
      return Y.Array.filter(connBackends, function (connBackend) {
        return connBackend.backendType === backendType;
      });
    } },


  {

    ATTRS: {
      /**
       * The server-side model's id.
       *
       * @attribute id
       * @type String
       * @default null
       */
      id: {
        value: null },


      /**
       * The type of Form this is.
       *
       * @attribute type
       * @type Squarespace.FormType
       * @default Squarespace.FormType.FORM_BLOCK
       */
      type: {
        value: Y.Squarespace.FormType.FORM_BLOCK,
        validator: Y.Squarespace.AttrValidators.isValueIn(
        Y.Squarespace.FormType) },


      /**
       * The name of the Form.
       *
       * @attribute Name
       * @type String
       * @default 'New Form'
       */
      name: {
        value: t("New Form"),
        validator: Y.Squarespace.AttrValidators.isString },


      /**
       * The array of field config objects representing the fields that this
       * form renders. The config objects get stored as stringified objects
       * but should be parsed when coming into the client (see the setter).
       *
       * @attribute fields
       * @type Array.<Object>
       * @default []
       */
      fields: {
        value: [],
        setter: function (fields) {
          // Fields are stored as an array of stringified objects. We want
          // them to be parsed on the client.
          return Y.Array.map(fields, function (field) {
            if (Y.Lang.isString(field)) {
              return Y.JSON.parse(field);
            }
            return field;

          }, this);
        },
        validator: Y.Squarespace.AttrValidators.isArray },


      /**
       * The list of FormBackendConnection objects that represent external
       * storage services for this Form. This list should only contain one
       * FormBackendConnection for each FormBackendConnectionType. This field
       * is only read from the client. The code used to save these backends
       * on the server currently requires the incoming data to be in a
       * different structure (see backendConnectionConfigs field). There will
       * be efforts to reduce the need for the different structures and allow
       * this model to behave in a truly RESTful way when saving/updating.
       *
       * @attribute connectedBackends
       * @type Array
       * @default null
       */
      connectedBackends: {
        value: null,
        validator: Y.Squarespace.AttrValidators.isNullOrArray },


      /**
       * The text to display on the submit button for this Form
       *
       * @attribute submitButtonText
       * @type String
       * @default 'Submit'
       */
      submitButtonText: {
        value: t("Submit"),
        validator: Y.Squarespace.AttrValidators.isString },


      /**
       * The wysiwyg message to be displayed after the Form has been
       * submitted. These get stored as stringified objects on the server and
       * should be parsed when coming into the client (see the setter).
       *
       * @attribute submissionMessage
       * @type Object
       * @default { html: 'Thank you!' }
       */
      submissionMessage: {
        value: {
          html: t("Thank you!") },

        setter: function (submissionMessage) {
          // Submission messages somehow started being stored as stringified
          // wysiwyg configs and should be parsed when being set on the
          // client.
          return Y.Lang.isString(submissionMessage) ?
          Y.JSON.parse(submissionMessage) :
          submissionMessage;
        } },


      disclaimerMessage: {
        valueFn: function () {
          return (
            // Only a subset of custom form types have disclaimerMessage support
            // Forms on cover pages will have this conditionally set based
            // on the slice-demo-* type being rendered.
            this.get('type') === Y.Squarespace.FormType.NEWSLETTER_BLOCK ||
            this.get('type') === Y.Squarespace.FormType.SLIDE ?
            { html: t("We respect your privacy.") } :
            { html: '' });

        },
        setter: function (disclaimerMessage) {
          // Submission messages somehow started being stored as stringified
          // wysiwyg configs and should be parsed when being set on the
          // client.
          return Y.Lang.isString(disclaimerMessage) ?
          Y.JSON.parse(disclaimerMessage) :
          disclaimerMessage;
        } },


      /**
       * A String of HTML to be rendered following the submission of the Form.
       * This is in addition to the submissionMessage field and is a useful
       * way to load script tags after a Form has been submitted.
       *
       * @attribute submissionHTML
       * @type String
       * @default null
       */
      submissionHTML: {
        value: null,
        validator: Y.Squarespace.AttrValidators.isNullOrString },


      successRedirect: {
        value: '',
        setter: function (linkObject) {
          if (linkObject) {
            if (typeof linkObject === 'string') {
              return linkObject;
            }
            if (typeof linkObject === 'object') {
              return linkObject.url;
            }
          }
          return '';
        },
        validator: Y.Squarespace.AttrValidators.isNullOrString },


      /**
       * A client-created field used to restructure the connectedBackends
       * field to be sent to the server for saving purposes. The current
       * server code for saving the connectedBackends field requires the data
       * to be in a different structure than it is stored for the time being.
       * As a result, we need a separate field to pass into a compound object
       * that is sent to the server for create/update calls (see the
       * serialize function). Currently, classes that are responsible for
       * calling save on the Form model are also responsible for setting this
       * field beforehand (see the Form editor and form block editor).
       *
       * @attribute backendConnectionConfigs
       * @type Object
       * @default {}
       */
      backendConnectionConfigs: {
        value: [],
        /*
         * Converts Object to array and filter out non connected backends
         */
        setter: function (value) {
          var backends = getBackendConnectionConfigsAsArray(value);
          this.set('removedConnectedBackends', FormConnections.calculateRemoved(
          this.get('connectedBackends'),
          backends));

          return Y.Array.filter(backends, function (backend) {
            return backend.connectionState === FormBackendConnectionState.CONNECTED;
          });
        } },


      removedConnectedBackends: {
        value: [],
        validator: Y.Squarespace.AttrValidators.isArray },


      captchaEnabled: {
        value: false,
        validator: Y.Squarespace.AttrValidators.isBoolean },


      captchaTheme: {
        value: GoogleReCaptchaThemeType.LIGHT,
        validator: Y.Squarespace.AttrValidators.isValueIn(GoogleReCaptchaThemeType) },


      captchaAlignment: {
        value: GoogleReCaptchaAlignmentType.LEFT,
        validator: Y.Squarespace.AttrValidators.isValueIn(GoogleReCaptchaAlignmentType) } } });






}, '1.0', {
  requires: [
  'base',
  'json',
  'model',
  'model-list',
  'squarespace-attr-validators',
  'squarespace-enum',
  'squarespace-model-sync-rest',
  'squarespace-util'] });