import * as GlobalSentry from 'shared/utils/error-reporter/global-sentry';
import throttle from 'lodash/throttle';

/**
 * @module squarespace-slide-rendering-grid-gallery
 */
YUI.add('squarespace-slide-rendering-grid-gallery', function(Y) {

  Y.namespace('Squarespace.SlideRendering');

  var MAX_GRID_DUPLICATION_ITERATIONS = 100;
  var GALLERY_CONTAINER_CLASS = 'sqs-gallery-grid';
  var GRID_DENSITY_JSON = Y.Squarespace.SlideRendering.GridGalleryDensities;

  var DEBUG = false;

  /**
   * The widget used to render images in a gallery as a grid.
   *
   * @namespace Squarespace.SlideRendering
   * @class GridGallery
   * @extends Widget
   * @mixes Squarespace.Mixins.EventRegistrar
   */
  Y.Squarespace.SlideRendering.GridGallery = Y.Base.create('GridGallery',
    Y.Base,
    [ Y.Squarespace.Mixins.EventRegistrar ],
    {
      initializer: function() {
        this._initGallery();
        this._bindUI();
        this._timeouts = [];
        this._duplicationIterations = 0;

        this._throttledSyncUI = throttle(this.syncUI.bind(this), 100, {
          leading: false,
          trailing: true
        });
      },

      destructor: function () {
        this._throttledSyncUI.cancel();

        Y.Array.each(this._timeouts, function (timeout) {
          timeout.cancel();
        }, this);

        this._removeClonedItems();

        var container = this.get('container');
        if (container && container.inDoc()) {
          container.removeClass(GALLERY_CONTAINER_CLASS);
          this._reset();
        }
      },

      syncUI: function gridGallerySyncUI() {
        this._reset();
        this._renderGrid();
      },

      _bindUI: function() {
        var resizeEvent = window.orientation === undefined ? 'resize' : 'orientationchange';
        this._registerEvent(
          Y.one(Y.config.win).on(resizeEvent, this._throttledSyncUI, this)
        );
      },

      _initGallery: function() {
        this.get('container').addClass(GALLERY_CONTAINER_CLASS);

        // Images may not be loaded yet
        var items = this.get('items');
        var stack = new Y.Parallel();
        items.each(function(item) {
          var img = item.one('img');
          if (img.getAttribute('src') === '') {
            img.once('load', stack.add());
          }
        });
        stack.done(function() {
          this._throttledSyncUI();
        }.bind(this));
      },

      /**
       * Set/reset properties of this Gallery object.
       *
       * @method _reset
       * @private
       */
      _reset: function() {
        var container = this.get('container');
        this.densityValues = this._parseDensityJSON();
        this.imageOrdering = this._getTweakImageOrdering();
        this.dimension = this._getAxisDimension();
        this.axisGroups = [];
        this.imageRatios = [];
        container.setStyles({
          'marginTop': null,
          'marginLeft': null,
          'width': null,
          'height': null
        });

        this._setAxisDirection();

        this.currentAxisGroup = {
          dimension: this.dimension,
          items: [],
          imageRatios: []
        };
      },

      /**
       * The main jam: determine if each item fits into the axis. As each axis fills,
       * expand the axis dimension to remove extra space. If not enough images were supplied
       * to fill the container, duplicate supplied images and recheck. Once the
       * container is filled, center the gallery on the cross axis.
       *
       * @private
       */
      _renderGrid: function gridGalleryRenderGrid() {
        var items = this.get('items');
        items.each(function (element, index) {
          this._resetItemStyles(element);

          var ordering = this.imageOrdering;
          // In order to have the appearance of randomizing all of the images, including the
          // original (not cloned) images, use CSS to set their width and height to 0,
          // and do not use them as part of the grid rendering,
          // rather than changing their order within the DOM.
          if (ordering === 'repeat' || (ordering === 'random' && element.getAttribute('data-clone') !== '')) {
            var currentImage = element.one('img');
            var imageDimensions = this._getCalculatedImageDimensions(currentImage);
            this.currentAxisGroup.imageRatios.push(imageDimensions);

            var currentItem = element._node;
            var fitsInAxis = this._fitsInAxis(this.currentAxisGroup);
            if (fitsInAxis) {
              this.currentAxisGroup.items.push(currentItem);
            } else {
              this._growAxis(this.currentAxisGroup);
              this.axisGroups.push(this.currentAxisGroup);
              this.currentAxisGroup = {
                dimension: this.dimension,
                items: [currentItem],
                imageRatios: [this._getCalculatedImageDimensions(currentImage)]
              };
            }
          }
        }, this);

        // When might you hit the iteration limit?
        // Cover pages use a grid gallery to render the full screen
        // background image. If you use a single super tall image and thin
        // image as your cover page media, the logic in either _isScreenFilled
        // will never correctly detect screen fill and so will try to
        // duplicate the image over and over.
        // This is a rough fix.
        var isAtDuplicationIterationLimit =
          this._duplicationIterations >= MAX_GRID_DUPLICATION_ITERATIONS;
        if (isAtDuplicationIterationLimit) {
          GlobalSentry.captureException(new Error(
            'Could not duplicate enough rows to fill a grid gallery to fit container.'
          ));
          // Proceed to load whatever images we did manage to duplicate
        } else {
          var isScreenFilled = this._isScreenFilled();
          if (!isScreenFilled) {
            this._duplicateOriginalItems();
            // potential infinite loop since syncUI calls this function _renderGrid
            this._throttledSyncUI();
            return;
          }
        }

        this._positionItems();
        this._positionContainer();
        this._loadImages(items);
      },

      _loadImages: function (els) {
        els.each(function loadAndAnimateImage(el) {
          var currentImage = el.one('img');
          var shouldAnimate = this._getTweakLoadingAnimation();

          Y.one(currentImage).once('load', function animateIn(e) {
            var delayTime = 1;
            if (shouldAnimate) {
              delayTime = Math.floor(Math.random() * 2000);
            }
            this._timeouts.push(
              Y.later(delayTime, e.currentTarget, function () {
                this.get('parentNode').addClass('loaded');
              })
            );
          }, this);

          this._timeouts.push(
            Y.later(10, this, function loadImage() {
              window.ImageLoader.load(currentImage, {
                load: true,
                mode: 'fill'
              });
            })
          );
        }, this);
      },

      /**
       * Allow the style editor tweak "gallery-loading-animation" to show a preview
       * of the animation.
       *
       * @method previewImageLoading
       * @public
       */
      previewImageLoading: function(tweakValue) {
        var shouldAnimate = tweakValue;
        var delayTime = 100;
        var items = this.get('items');
        var gallery = this.get('container');
        gallery.addClass('no-transition');
        items.each(function(item) {
          item.removeClass('loaded');
          if (shouldAnimate === 'true') {
            delayTime = Math.floor(Math.random() * 2000) + 100;
          }
          this._timeouts.push(
            Y.later(delayTime, item, function() {
              this.addClass('loaded');
            })
          );
        });

        this._timeouts.push(
          Y.later(100, this, function() {
            gallery.removeClass('no-transition');
          })
        );
      },

      /**
       * Determine if the item can fit into the axis without overflowing.
       *
       * @method _fitsInAxis
       * @private
       * @returns boolean
       */
      _fitsInAxis: function(currentAxisGroup) {
        var container = this.get('container');
        var axisDimension = this._getRenderedAxisDimension(currentAxisGroup);
        var currentImageRatios = currentAxisGroup.imageRatios[currentAxisGroup.items.length];
        var targetAxisDimension = currentAxisGroup.dimension;
        var checkDimension = axisDimension + this._getProportionalDimension(currentImageRatios, targetAxisDimension);
        var clientDimension;
        if (this.get('axisDirection') === 'row') {
          clientDimension = container.get('clientWidth');
        } else {
          clientDimension = container.get('clientHeight');
        }
        return checkDimension <= clientDimension;
      },

      /**
       * Calculate the axis's total dimension based on items currently in that axis.
       *
       * @method _getRenderedAxisDimension
       * @private
       * @returns number
       */
      _getRenderedAxisDimension: function(currentAxisGroup) {
        var totalAxisDimension = 0;
        var targetAxisDimension = currentAxisGroup.dimension;
        var totalItems = currentAxisGroup.items.length;
        for (var ii = 0; ii < totalItems; ii++) {
          totalAxisDimension += this._getProportionalDimension(currentAxisGroup.imageRatios[ii], targetAxisDimension);
        }
        return totalAxisDimension;
      },

      /**
       * Calculate the item's dimension for rendering in the grid.
       *
       * @method _getProportionalDimension
       * @private
       * @returns number
       */
      _getProportionalDimension: function(imageRatios, targetDimension) {
        var dimension;
        if (this.get('axisDirection') === 'column') {
          dimension = imageRatios.height * targetDimension / imageRatios.width;
        } else if (this.get('axisDirection') === 'row') {
          dimension = imageRatios.width * targetDimension / imageRatios.height;
        }
        return dimension;
      },

      /**
       * Calculate the item's appropriate width and height
       *
       * @method _getCalculatedImageDimensions
       * @private
       */
      _getCalculatedImageDimensions: function(currentImage) {
        var dimensions = currentImage.getAttribute('data-image-dimensions').split('x');
        var naturalWidth = parseInt(dimensions[0], 10);
        var naturalHeight = parseInt(dimensions[1], 10);
        var calculatedWidth = naturalWidth;
        var calculatedHeight = naturalHeight;
        var tweakRatio = this._getTweakImageRatio();

        if (tweakRatio !== 'original') {
          if (naturalWidth > naturalHeight) {
            calculatedHeight = naturalWidth * tweakRatio.ratioHeight / tweakRatio.ratioWidth;
          } else {
            calculatedWidth = naturalHeight * tweakRatio.ratioWidth / tweakRatio.ratioHeight;
          }
        }
        return {
          width: calculatedWidth,
          height: calculatedHeight
        };
      },

      /**
       * Expand the appropriate axis dimension to fit elements into that axis without cropping.
       *
       * @method _growAxis
       * @private
       */
      _growAxis: function(currentAxisGroup) {
        // TODO: sometimes a extra sliver of background appears on the right
        var container = this.get('container');
        var renderedAxisDimension = this._getRenderedAxisDimension(currentAxisGroup);
        var axis = this.get('axisDirection');
        var clientDimension;
        if (axis === 'row') {
          clientDimension = container.get('clientWidth');
        } else {
          clientDimension = container.get('clientHeight');
        }
        var newAxisDimension = Math.floor(clientDimension * currentAxisGroup.dimension / renderedAxisDimension);
        var currentItem;
        var totalItems = currentAxisGroup.items.length;

        for (var ii = 0; ii < totalItems; ii++) {
          currentItem = currentAxisGroup.items[ii];
          if (axis === 'row') {
            currentItem.style.height = newAxisDimension + 'px';
            currentItem.style.width = Math.floor(this._getProportionalDimension(currentAxisGroup.imageRatios[ii],
              newAxisDimension)) + 'px';
          } else {
            currentItem.style.width = newAxisDimension + 'px';
            currentItem.style.height = Math.floor(this._getProportionalDimension(currentAxisGroup.imageRatios[ii],
              newAxisDimension)) + 'px';
          }
        }
        currentAxisGroup.dimension = newAxisDimension;
      },

      /**
       * Absolutely position items into vertical columns.
       *
       * @method _positionItems
       * @private
       */
      _positionItems: function() {
        this.axisGroups.forEach(function(group, groupIndex, groupArray) {
          var currentItem;
          var prevItem;
          var totalItems = group.items.length;
          var direction = this.get('axisDirection');

          var itemOffsetDimension = 'height';
          var itemOffsetAxis = 'top';
          var groupOffset = 'left';

          if (direction === 'row') {
            itemOffsetDimension = 'width';
            itemOffsetAxis = 'left';
            groupOffset = 'top';
          }

          for (var ii = 0; ii < totalItems; ii++) {
            currentItem = group.items[ii];
            prevItem = group.items[ii - 1];
            if (prevItem) {
              currentItem.style[itemOffsetAxis] = parseInt(prevItem.style[itemOffsetDimension], 10) +
                parseInt(prevItem.style[itemOffsetAxis], 10) + 'px';
            } else {
              currentItem.style[itemOffsetAxis] = '0';
            }

            var axisOffset = 0;
            for (var jj = 0; jj < groupIndex; jj++) {
              axisOffset += groupArray[jj].dimension;
            }
            currentItem.style[groupOffset] = axisOffset + 'px';
            currentItem.style.visibility = 'visible';
          }
        }.bind(this));
      },

      _positionContainer: function () {
        var container = this.get('container');
        var offset = this.axisGroups.overage / 2;
        var isColumnar = this.get('axisDirection') === 'column';
        var tweakImageRatio = this._getTweakImageRatio();
        var isOriginalRatio = tweakImageRatio === 'original';
        if (isColumnar && isOriginalRatio) {
          container.setStyles({
            'marginLeft': (0 - offset) + 'px',
            'width': 'calc(100% + ' + offset + 'px)'
          });
        } else {
          container.setStyles({
            'marginTop': (0 - offset) + 'px',
            'height': 'calc(100% + ' + offset + 'px)'
          });
        }
      },

      /**
       * Duplicate all supplied images, add them to the container.
       *
       * @private
       */
      _duplicateOriginalItems: function() {
        var htmlArray = [];
        var items = this.get('items');
        items.each(function (element, index) {
          if (element.hasAttribute('data-clone')) {
            return;
          }

          var markup = element.get('outerHTML');
          var newAttrib = ' data-clone="true" ';
          var position = markup.indexOf('class="sqs-slice-gallery-item');
          var newMarkup = [markup.slice(0, position - 1), newAttrib, markup.slice(position)].join('');
          htmlArray.push(newMarkup);
        }, this);

        if (this.imageOrdering === 'random') {
          this._randomSortArray(htmlArray);
        }

        var container = this.get('container');
        container.append(htmlArray.join(''));

        var itemsAndDuplicates = container.all('.sqs-slice-gallery-item');
        this.set('items', itemsAndDuplicates);
        this._duplicationIterations++;
      },

      /**
       * Remove all items with data-cloned attribute.
       *
       * @method _removeClonedItems
       * @private
       */
      _removeClonedItems: function() {
        var items = this.get('items');

        items.each(function(element, index) {
          if (element.hasAttribute('data-clone')) {
            element.remove();
          }
        }, this);
      },

      /**
       * Remove all styles set during previous rendering passes
       *
       * @method _resetItemStyles
       * @private
       */
      _resetItemStyles: function(item) {
        item.setStyles({
          'top': null,
          'left': null,
          'width': null,
          'height': null,
          'visibility': 'hidden'
        });
      },

      /**
       * Determine if there are enough supplied images to fill the container.
       * If so, also set the 'overage' amount to be used for the centering of
       * the gallery.
       *
       * @TODO add tests for various image sizes and axisDirection and test
       * that it is fulfilled with several images of that size. See note in
       * _renderGrid for why this function may be broken.
       * @FIXME
       * @private
       * @returns {boolean}
       */
      _isScreenFilled: function() {
        var container = this.get('container');
        var gridDimension = 0;
        var containerDimension;
        if (this.get('axisDirection') === 'row') {
          containerDimension = container.get('clientHeight');
        } else {
          containerDimension = container.get('clientWidth');
        }

        this.axisGroups.lastVisibleAxisGroupIndex = null;
        this.axisGroups.overage = null;
        this.axisGroups.forEach(function(element, index, array) {
          gridDimension += element.dimension;
          if (gridDimension > containerDimension) {
            if (!this.axisGroups.lastVisibleAxisGroupIndex) {
              this.axisGroups.lastVisibleAxisGroupIndex = index;
              this.axisGroups.overage = gridDimension - containerDimension;
            }
          } else {
            this.axisGroups.overage = gridDimension - containerDimension;
          }
        }.bind(this));

        if (gridDimension === 0 && containerDimension === 0) {
          return true;
        }

        return gridDimension > containerDimension;
      },

      /**
       * Determine the orientation of the Grid layout, and set the ATTR
       *
       * @method _setAxisDirection
       * @private
       */
      _setAxisDirection: function() {
        if (this.get('container').inDoc()) {
          var classes = Y.one(this.get('slideContainerSelector')).get('className');
          var ratioValue = classes.match(/(grid-gallery-direction-)\w+/g)[0];
          if (ratioValue.indexOf('horizontal') !== -1 || this._getTweakImageRatio() !== 'original') {
            this.set('axisDirection', 'row');
          } else {
            this.set('axisDirection', 'column');
          }
        }
      },

      /**
       * Determine the target number of rows or columns based on the tweak value
       *
       * @method getGridDensity
       * @private
       * @returns number
       */
      _getGridDensity: function() {
        var _densityArray = this.densityValues;
        var density = _densityArray[0];
        var tweakClassElement = Y.one(this.get('slideContainerSelector'));

        var tweakDensityArray = [
          'grid-gallery-density-very-low',
          'grid-gallery-density-low',
          'grid-gallery-density-medium',
          'grid-gallery-density-high',
          'grid-gallery-density-very-high'
        ];

        tweakDensityArray.forEach(function(element, index, array) {
          if (tweakClassElement.hasClass(element)) {
            density = _densityArray[index];
          }
        });

        return density;
      },

      _parseDensityJSON: function() {
        // todo: remove from Production code; for testing convenience only
        if (DEBUG === true) {
          if (this.get('testDensityValue')) {
            var n = this.get('testDensityValue');
            return [n, n, n, n, n];
          }
        }

        var densityObj = GRID_DENSITY_JSON;
        var container = this.get('container');
        var direction = this.get('axisDirection');
        var tweakRatio = this._getTweakImageRatio(true);
        var tweakOrientation = this._getTweakImageOrientation();
        var clientWidth = container.get('clientWidth');
        var clientHeight = container.get('clientHeight');

        var resolution;
        var ratio;
        var orientation;

        if (clientWidth <= 600 && clientHeight <= 600) {
          if (clientWidth < clientHeight) {
            resolution = 'maxWidth-600';
          } else {
            resolution = 'maxHeight-600';
          }
        } else if (clientWidth <= 600) {
          resolution = 'maxWidth-600';
        } else if (clientHeight <= 600) {
          resolution = 'maxHeight-600';
        } else if (clientWidth <= 800) {
          resolution = 'maxWidth-800';
        } else {
          resolution = 'desktop';
        }

        if (tweakRatio === 'original') {
          ratio = 'original';
        } else {
          ratio = 'r' + tweakRatio.ratioWidth.toString() + tweakRatio.ratioHeight.toString();
        }

        if (ratio === 'r11') {
          orientation = 'square';
        } else if (direction === 'column') {
          orientation = 'vertical';
        } else if (direction === 'row') {
          if (tweakOrientation === 'portrait') {
            orientation = 'vertical';
          } else {
            orientation = 'horizontal';
          }
        }

        return densityObj[resolution].orientation[orientation].ratio[ratio];
      },

      /**
       * Calculate the target width or height (depending on the axis direction) based on the density
       *
       * @method _getAxisDimension
       * @private
       * @returns number
       */
      _getAxisDimension: function() {
        var container = this.get('container');
        if (this.get('axisDirection') === 'row') {
          return Math.ceil(container.get('clientHeight') / this._getGridDensity());
        }
        return Math.ceil(container.get('clientWidth') / this._getGridDensity());

      },

      /**
       * Determine the ratio for items in the grid based on the tweak classname.
       *
       * @method _getTweakImageRatio
       * @private
       * @returns object or string 'original'
       */
      _getTweakImageRatio: function(ignoreOrientation) {
        var classes = Y.one(this.get('slideContainerSelector')).get('className');
        var classMatch = classes.match(/(grid-gallery-ratio-)\w+/g)[0];
        var ratioValue = classMatch.substring(classMatch.lastIndexOf('-') + 1);
        var rW;
        var rH;

        switch (ratioValue) {
        case '11':
          rW = 1;
          rH = 1;
          break;
        case '54':
          rW = 5;
          rH = 4;
          break;
        case '43':
          rW = 4;
          rH = 3;
          break;
        case '32':
          rW = 3;
          rH = 2;
          break;
        case '169':
          rW = 16;
          rH = 9;
          break;
        case '21':
          rW = 2;
          rH = 1;
          break;
        default:
          return 'original';
        }

        if (!ignoreOrientation) {
          if (this._getTweakImageOrientation() === 'portrait' && (rW !== rH)) {
            var pH = rW;
            rW = rH;
            rH = pH;
          }
        }

        return {
          ratioWidth: rW,
          ratioHeight: rH
        };
      },

      /**
       * Determine the orientation for items. Used only if the ratio is NOT
       * 'original' or '1x1'.
       *
       * @method _getTweakImageOrientation
       * @private
       * @returns string
       */
      _getTweakImageOrientation: function() {
        var classes = Y.one(this.get('slideContainerSelector')).get('className');
        var orientationValue = classes.match(/(grid-gallery-image-orientation-)\w+/g)[0];
        orientationValue = orientationValue.substring(orientationValue.lastIndexOf('-') + 1);
        return orientationValue;
      },

      /**
       * Should the images load with an animation.
       *
       * @method _getTweakLoadingAnimation
       * @private
       * @returns boolean
       */
      _getTweakLoadingAnimation: function() {
        var shouldAnimate = false;
        if (Y.one(this.get('slideContainerSelector')).hasClass('gallery-loading-animation')) {
          shouldAnimate = true;
        }
        return shouldAnimate;
      },

      /**
       * Determine the ordering for items.
       *
       * @method _getTweakImageOrdering
       * @private
       * @returns string
       */
      _getTweakImageOrdering: function() {
        var orderingValue = 'repeat';
        if (Y.one(this.get('slideContainerSelector')).hasClass('gallery-randomize-order')) {
          orderingValue = 'random';
        }
        this.set('imageOrdering', orderingValue);
        return this.get('imageOrdering');
      },

      /**
       *
       * Randomly sort an array.
       *
       * @method _randomSortArray
       * @private
       * @returns Array
       */
      _randomSortArray: function(array) {
        function generateNumber() {
          var x = Math.random();
          var sortNumber;
          if (x <= 0.36) {
            sortNumber = -1;
          } else if (x <= 0.64) {
            sortNumber = 0;
          } else if (x <= 1) {
            sortNumber = 1;
          }
          return sortNumber;
        }
        array.sort(generateNumber);
        // Do it again because array sorting is biased towards the original order.
        array.sort(generateNumber);
      }
    },
    {
      CSS_PREFIX: 'sqs-grid-gallery',
      ATTRS: {
        /**
         * The Slide node
         *
         * @attribute container
         * @type Node
         * @default null
         * @required
         */
        container: {
          value: null,
          validator: Y.Squarespace.AttrValidators.isNullOrNode
        },

        /**
         * The Slide container selector
         *
         * @attribute slideContainerSelector
         * @type String
         * @default '.sqs-slide-container'
         * @required
         */
        slideContainerSelector: {
          value: '.sqs-slide-container',
          validator: Y.Squarespace.AttrValidators.isNullOrString
        },

        /**
         * The list of nodes representing image wrappers
         *
         * @attribute items
         * @type NodeList
         * @default null
         * @required
         */
        items: {
          value: null,
          validator: Y.Squarespace.AttrValidators.isNullOrNodeList
        },

        /**
         * The direction of the axis for images, either "row" or "column"
         *
         * @attribute axisDirection
         * @type String
         * @default row
         * @required
         */
        axisDirection: {
          value: 'row',
          validator: function(val, attrName) {
            if (Y.Squarespace.AttrValidators.isString(val, attrName)) {
              if (val === 'row' || val === 'column') {
                return true;
              }
              if (__DEV__) {
                console.error(attrName + ' is not \'row\' or \'column\'');
              }
              return false;

            }
            return false;

          }
        },

        /**
         * Display the images in random order rather than repeating
         *
         * @attribute imageOrdering
         * @type string
         * @default 'repeat'
         * @required
         */
        imageOrdering: {
          value: 'repeat',
          validator: function(val, attrName) {
            if (Y.Squarespace.AttrValidators.isString(val, attrName)) {
              if (val === 'repeat' || val === 'random') {
                return true;
              }
              if (__DEV__) {
                console.error(attrName + ' is not \'repeat\' or \'random\'');
              }
              return false;

            }
            return false;

          }
        },

        // todo: remove from Production code; for testing convenience only
        testDensityValue: {
          value: null,
          validator: Y.Squarespace.AttrValidators.isNullOrNumber
        }
      }
    }
  );
}, '1.0', {
  requires: [
    'base',
    'parallel',
    'squarespace-attr-validators',
    'squarespace-slide-rendering-grid-gallery-densities',
    'squarespace-mixins-event-registrar'
  ]
});
