"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.ConfigurationMenuItemType */

/* Java class defined in repository squarespace-v6 */
var ConfigurationMenuItemType;

(function (ConfigurationMenuItemType) {
  ConfigurationMenuItemType["HOME"] = 1;
  ConfigurationMenuItemType["CONTENT"] = 2;
  ConfigurationMenuItemType["DASHBOARD"] = 3;
  ConfigurationMenuItemType["SETTINGS"] = 4;
  ConfigurationMenuItemType["COMMERCE"] = 5;
  ConfigurationMenuItemType["FOLDER"] = 6;
  ConfigurationMenuItemType["HELP"] = 7;
  ConfigurationMenuItemType["NEWSLETTERS"] = 8;
  ConfigurationMenuItemType["NOTIFICATIONS"] = 9;
})(ConfigurationMenuItemType || (ConfigurationMenuItemType = {}));

var _default = ConfigurationMenuItemType;
exports["default"] = _default;
module.exports = exports.default;