"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.ReferralState */

/* Java class defined in repository squarespace-v6 */
var ReferralState;

(function (ReferralState) {
  ReferralState["AWAITING_MATCH"] = "AWAITING_MATCH";
  ReferralState["APPLIED_TO_REFEREE"] = "APPLIED_TO_REFEREE";
  ReferralState["APPLIED_TO_BOTH"] = "APPLIED_TO_BOTH";
  ReferralState["REMOVED"] = "REMOVED";
})(ReferralState || (ReferralState = {}));

var _default = ReferralState;
exports["default"] = _default;
module.exports = exports.default;