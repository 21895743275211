/**
 * The module containing the Squarespace.SlideRendering.Slices.CustomForm class.
 *
 * @module squarespace-slide-rendering-slices-custon-form
 */

import { GoogleReCaptchaAPI } from '@sqs/universal-utils';

YUI.add('squarespace-slide-rendering-slices-custom-form', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * The slice widget for slices of type SliceType.CUSTOM_FORM.
   *
   * @namespace Squarespace.SlideRendering.Slices
   * @class     CustomForm
   * @extends   Squarespace.SlideRendering.Slices.Base
   * @mixes     Squarespace.SlideRendering.Slices.ClientRefresh
   */
  var CustomForm = Y.Squarespace.SlideRendering.Slices.CustomForm = Y.Base.create(
    'CustomForm',
    Y.Squarespace.SlideRendering.Slices.Base,
    [ Y.Squarespace.SlideRendering.Slices.ClientRefresh ],
    {
      initializer: function() {
        this._lightbox = new Y.Squarespace.Widgets.ModalLightbox({
          render: Y.one(this.get('slideSelector')),
          openClassNode: Y.one(this.get('slideSelector') + ' .sqs-slide-container')
        });
        this._formTemplatePromise = this._getFormTemplateSchema();
      },

      destructor: function() {
        this._lightbox.close();

        if (this._formWidget) {
          this._formWidget.destroy();
        }

        this._lightbox.destroy();
        this._formTemplatePromise = null;
      },

      renderUI: function() {
        CustomForm.superclass.renderUI.call(this);
        this._lightbox.render();
      },

      bindUI: function() {
        CustomForm.superclass.bindUI.call(this);
        var contentBox = this.get('contentBox');
        contentBox.delegate('click', this._openForm, '.open-form-button', this);
      },

      _openForm: function() {
        var contentBox = this.get('contentBox');
        var buttonContainer = contentBox.one('.open-form-button-container');

        var formData = buttonContainer.getData('form');
        var hasUserFormData = Y.Lang.isValue(formData) && formData !== '';

        var formJson;
        if (hasUserFormData) {
          formJson = Y.JSON.parse(formData.replace(/scr"\+"ipt/g, 'script')); // See CoreFormatters.java#JsonFormatter
        } else {
          formJson = (buttonContainer.hasClass('slice-newsletter-form') ?
            new Y.Squarespace.Models.SliceDemoNewsletterForm().toJSON() :
            new Y.Squarespace.Models.SliceDemoCustomForm().toJSON());
        }

        this._formTemplatePromise.then(Y.bind(function (formTemplate) {
          if (this._formWidget) {
            this._formWidget.destroy(true);
          }

          var formWidget = this._formWidget = new Y.Squarespace.Widgets.AsyncForm({
            form: formJson,
            formTemplate: formTemplate,
            formSubmitButtonText: formJson.submitButtonText,
            formName: formJson.name,
            showTitle: true,
            preventDefaultSubmit: !hasUserFormData,
            preventAllSubmits: !hasUserFormData
          });

          var lightbox = this._lightbox;
          lightbox.set('content', formWidget);

          var captchaContainer = GoogleReCaptchaAPI.getCaptchaContainer(
            Y.config.win,
            formWidget.get('contentBox').getDOMNode()
          );
          if (captchaContainer) {
            GoogleReCaptchaAPI.render(Y.config.win, captchaContainer);
          }

          if (Y.UA.ios > 0) {
            var metaViewport = Y.one('meta[name="viewport"]');
            metaViewport.setAttribute('content', metaViewport.getAttribute('content') + ', maximum-scale=1');
            lightbox.once('close', function() {
              var metaContent = this.getAttribute('content');
              var originalContent = metaContent.substring(0, metaContent.indexOf((', maximum-scale=1')));
              this.setAttribute('content', originalContent);
              setTimeout(function() {
                window.dispatchEvent(new Event('resize'));
              }, 100);
            }, metaViewport);
          }

          var galleryNode = Y.one(this.get('slideSelector') + ' [data-slice-type="gallery"] .scrim');
          var formBgColor = galleryNode ? galleryNode.getComputedStyle('backgroundColor') : null;
          lightbox.get('boundingBox').one('.lightbox-background').setStyle('backgroundColor', formBgColor);
          lightbox.open();
        }, this));
      },

      /**
       * Load the form rendering JSON template from the server for use with
       * rendering the form in the modal lightbox.
       *
       * @method _getFormTemplateSchema
       * @return {Promise} The promise used to load the template.
       * @private
       */
      _getFormTemplateSchema: function() {
        return Y.Squarespace.Promises.create(function(resolve) {
          Y.Data.get({
            url: '/api/template/GetTemplateSchema',
            data: {
              type: Y.Squarespace.BlockType.FORM
            },
            success: function(data) {
              resolve(data);
            }
          });
        });
      },

      /**
       * Called by slide-rendering/editor.js
       *
       * @override
       */
      refreshFromSliceContent: function (sliceContent) {
        var contentBox = this.get('contentBox');
        var buttonContainer = contentBox.one('.open-form-button-container');

        buttonContainer.addClass(
          (sliceContent.get('isNewsletterFormSelected') ?
            'slice-newsletter-form' :
            'slice-custom-form')
        );
        buttonContainer.removeClass(
          (sliceContent.get('isNewsletterFormSelected') ?
            'slice-custom-form' :
            'slice-newsletter-form')
        );

        var buttonLabel = sliceContent.get('buttonLabel');
        var isEmpty = !Y.Lang.isValue(buttonLabel) || buttonLabel === '';
        if (isEmpty) {
          buttonContainer.setHTML('');
        } else {
          buttonContainer.setHTML(
            '<span><a class="open-form-button">' + buttonLabel + '</a></span>'
          );
        }

        this._updateContentEmptyAttribute(isEmpty);
      }

    },
    {

      SLICE_TYPE: Y.Squarespace.SliceType.CUSTOM_FORM,

      CSS_PREFIX: 'sqs-slice-custom-form'

    }
  );

}, '1.0', {
  requires: [
    'base',
    'json',
    'squarespace-async-form',
    'squarespace-enum',
    'squarespace-modal-lightbox',
    'squarespace-models-slice-demo-custom-form',
    'squarespace-models-slice-demo-newsletter-form',
    'squarespace-promises',
    'squarespace-util',
    'squarespace-slide-rendering-slices-base',
    'squarespace-slide-rendering-slices-client-refresh'
  ]
});
