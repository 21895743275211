"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.LinkStatusType */

/* Java class defined in repository squarespace-v6 */
var LinkStatusType;

(function (LinkStatusType) {
  LinkStatusType["MAPPING"] = 1;
  LinkStatusType["VERIFICATION"] = 2;
})(LinkStatusType || (LinkStatusType = {}));

var _default = LinkStatusType;
exports["default"] = _default;
module.exports = exports.default;