"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.enums.WebsiteDisambiguationType */

/* Java class defined in repository squarespace-v6 */
var WebsiteDisambiguationType;

(function (WebsiteDisambiguationType) {
  WebsiteDisambiguationType["DEFAULT"] = 1;
  WebsiteDisambiguationType["DOMAIN_FIRST"] = 2;
  WebsiteDisambiguationType["GOOGLE_DOMAINS"] = 3;
  WebsiteDisambiguationType["DOMAIN_TRANSFER"] = 4;
})(WebsiteDisambiguationType || (WebsiteDisambiguationType = {}));

var _default = WebsiteDisambiguationType;
exports["default"] = _default;
module.exports = exports.default;