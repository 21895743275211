"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.model.roles.MemberAccountRole */

/* Java class defined in repository squarespace-v6 */
var MemberAccountRole;

(function (MemberAccountRole) {
  MemberAccountRole["SUPERADMIN"] = 1;
  MemberAccountRole["BILLING"] = 2;
  MemberAccountRole["SUPPORT"] = 3;
  MemberAccountRole["DEV"] = 4;
  MemberAccountRole["MARKETING"] = 5;
  MemberAccountRole["SUPPORT_SUPERVISOR"] = 6;
  MemberAccountRole["INTERNAL_REPORTING"] = 7;
  MemberAccountRole["EXTERNAL_BETA_ACCESS"] = 8;
  MemberAccountRole["ADVISOR_BILLING"] = 9;
  MemberAccountRole["TRUST_AND_SAFETY"] = 10;
  MemberAccountRole["SQSP_PUBLIC_IMPERSONATION"] = 11;
  MemberAccountRole["CAMPAIGNS_EMAIL_REVIEWER"] = 12;
  MemberAccountRole["ENTERPRISE_ADMIN"] = 13;
  MemberAccountRole["FRAUD_DETECTION_REVIEWER"] = 14;
  MemberAccountRole["INTERNAL_CAMPAIGNS_SENDER"] = 15;
  MemberAccountRole["SITEL_USER"] = 16;
  MemberAccountRole["LEGAL"] = 17;
  MemberAccountRole["PRODUCT_SUPPORT"] = 18;
  MemberAccountRole["EXTENSIONS_MANAGER"] = 19;
})(MemberAccountRole || (MemberAccountRole = {}));

var _default = MemberAccountRole;
exports["default"] = _default;
module.exports = exports.default;