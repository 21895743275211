"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.CommentTarget */

/* Java class defined in repository cms-legacy-data */
var CommentTargetTypes;

(function (CommentTargetTypes) {
  CommentTargetTypes["ITEM"] = 1;
  CommentTargetTypes["COLLECTION"] = 2;
})(CommentTargetTypes || (CommentTargetTypes = {}));

var _default = CommentTargetTypes;
exports["default"] = _default;
module.exports = exports.default;