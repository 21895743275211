"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.getty.Orientation */

/* Java class defined in repository squarespace-v6 */
var Orientations;

(function (Orientations) {
  Orientations["HORIZONTAL"] = "Horizontal";
  Orientations["VERTICAL"] = "Vertical";
  Orientations["SQUARE"] = "Square";
  Orientations["PANORAMIC_HORIZONTAL"] = "PanoramicHorizontal";
  Orientations["PANORAMIC_VERTICAL"] = "PanoramicVertical";
})(Orientations || (Orientations = {}));

var _default = Orientations;
exports["default"] = _default;
module.exports = exports.default;