/**
 * Form Connections utility functions
 * used by;
 * - scripts-v6/block/form-editor.js
 * - scripts-v6/models/form.js
 * And their MobileV7 counterparts
 */

import FormBackendConnectionType from '@sqs/enums/FormBackendConnectionType';
import FormBackendConnectionState from '@sqs/enums/FormBackendConnectionState';

/**
 * @param {Object[]} prev array of connection types
 * @param {Object[]} next array of connection types
 * @return {Number[]} enum value of backend types that were not retained
 * between prev and next
 */
export function calculateRemoved(prev, next) {
  if (!Array.isArray(prev) || !prev.length) {
    return [];
  }
  const nextBackends = next.map(connection => connection.backendType);
  return prev.reduce(
    (result, connection) => {
      if (!nextBackends.includes(connection.backendType)) {
        result.push(connection.backendType);
      }
      return result;
    },
    []
  );
}

/**
 * @return {Object} transform model data into unique object for each
 * connection type. Result to be used to render React ActionSlice for
 * each connection type.
 */
export function getFromModel({ formId, connections = [], legacyDefaults = {} } = {}) {
  const existing = connections.reduce((result, backend) => {
    const { backendId, backendType, config, connectionState, socialConfig } = backend;

    /**
     * a connectedBackend that was never fully saved has no id
     * treat these as though they were never connected
     */
    if (!backendId && backendType !== FormBackendConnectionType.ZAPIER) {
      if (__DEV__) {
        console.warn('[form] backend was not connected properly', backend);
      }
      return result;
    }

    switch (backendType) {
    case FormBackendConnectionType.CAMPAIGNS:
      result.campaignsConnect = {
        connectionState: FormBackendConnectionState.CONNECTED,
        useSingleOptIn: !!(config && config.useSingleOptIn === 'true'),
        listId: backendId
      };
      break;

    case FormBackendConnectionType.EMAIL:
      result.emailConnect = {
        connectionState,
        email: backendId
      };
      break;

    case FormBackendConnectionType.ZAPIER:
      result.zapierConnect = {
        connectionState,
        url: backendId
      };
      break;

    case FormBackendConnectionType.GOOGLE:
      result.googleDocsConnect = {
        connectionState,
        username: socialConfig.profileUserName,
        spreadsheetName: config.spreadsheetName || legacyDefaults.googleSpreadsheetName,
        socialConfig
      };
      break;

    case FormBackendConnectionType.MAILCHIMP:
      result.mailchimpConnect = {
        connectionState,
        list: backendId,
        username: socialConfig.profileUserName || legacyDefaults.mailchimpUsername,
        useSingleOptIn: !!(config && config.useSingleOptIn === 'true'),
        socialConfig
      };
      break;

    default:
      result.errors = result.errors || [];
      result.errors.push(backend);
      break;
    }

    return result;
  }, {});

  // If block is new, make sure email is connected as the website owner's
  // email address.
  if (!formId) {
    // CMS-21410
    // An authenticatedAccount.email is not guaranteed to exist. Some users
    // created their Squarespace account via social site connection (e.g.
    // register via twitter) and we may not have their e-mail address.
    const { email } = window.Static.SQUARESPACE_CONTEXT.authenticatedAccount;
    if (email && !existing.emailConnect) {
      existing.emailConnect = {
        connectionState: FormBackendConnectionState.CONNECTED,
        email
      };
    }
  }

  return existing;
}
