"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.StoreState */

/* Java class defined in repository squarespace-v6 */
var StoreStates;

(function (StoreStates) {
  StoreStates["LIVE"] = 1;
  StoreStates["TEST_MODE"] = 2;
  StoreStates["NOT_CONNECTED"] = 3;
})(StoreStates || (StoreStates = {}));

var _default = StoreStates;
exports["default"] = _default;
module.exports = exports.default;