/**
 * @module squarespace-models-form
 */
YUI.add('squarespace-models-form', function (Y) {

  Y.namespace('Squarespace.Models');

  var FormModel = Y.Squarespace.Models.Form = Y.Base.create(
    'form',
    Y.Squarespace.Models.FormBase,
    [],
    {
      /**
       * Desktop-specific override, expect stringified object instead of plain
       * object
       * @override
       */
      serialize: function () {
        var serializedObject = FormModel.superclass.serialize.call(this);
        return Y.JSON.stringify(serializedObject);
      }
    }
  );

  Y.Squarespace.Models.FormList = Y.Base.create(
    'formList',
    Y.ModelList,
    [ Y.Squarespace.ModelSync.REST ],
    {

      model: Y.Squarespace.Models.Form,

      filterByType: function(type) {
        return this.filter(function(form) {
          return form.get('type') === type;
        });
      }

    }
  );

  Y.Squarespace.FormCache = new Y.Squarespace.Models.FormList();

}, '1.0', {
  requires: [
    'base',
    'json',
    'model',
    'model-list',
    'squarespace-model-sync-rest',
    'squarespace-models-form-base'
  ]
});
