"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.LicensedAssetSource */

/* Java class defined in repository cms-legacy-data */
var LicensedAssetSource;

(function (LicensedAssetSource) {
  LicensedAssetSource["GETTY"] = 1;
  LicensedAssetSource["UNSPLASH"] = 2;
})(LicensedAssetSource || (LicensedAssetSource = {}));

var _default = LicensedAssetSource;
exports["default"] = _default;
module.exports = exports.default;