"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.PageAction */

/* Java class defined in repository cms-legacy-data */
var PageAction;

(function (PageAction) {
  PageAction["PREVIOUS"] = 1;
  PageAction["NEXT"] = 2;
})(PageAction || (PageAction = {}));

var _default = PageAction;
exports["default"] = _default;
module.exports = exports.default;