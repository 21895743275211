/**
 * The module containing the Squarespace.SlideRendering.Slices.Image class.
 *
 * @module squarespace-slide-rendering-slices-image
 */
YUI.add('squarespace-slide-rendering-slices-image', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * The slice widget for slices of type SliceType.IMAGE
   *
   * @class Image
   * @namespace Squarespace.SlideRendering
   * @extends Y.Squarespace.SlideRendering.Slices.Base
   */
  var Image =
  Y.Squarespace.SlideRendering.Slices.Image = Y.Base.create('Base',
    Y.Squarespace.SlideRendering.Slices.Base,
    [],
    {
      syncUI: function() {
        Image.superclass.syncUI.call(this);

        var image = this.get('contentBox').one('img');

        if (image) {
          image.plug(Y.Squarespace.Loader2, {
            load: true,
            mode: 'fit'
          });
        }
      }
    },
    {
      SLICE_TYPE: Y.Squarespace.SliceType.IMAGE,
      CSS_PREFIX: 'sqs-slice-image',
      ATTRS: {}
    }
  );
}, '1.0', {
  requires: [
    'base',
    'squarespace-enum',
    'squarespace-image-loader',
    'squarespace-slide-rendering-slices-base'
  ]
});