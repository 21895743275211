import CommerceEvent from 'shared/constants/commerce/CommerceEvent';
import PublicApiEvent from 'shared/constants/PublicApiEvent';
import trackFacebookPixel from 'shared/utils/analytics/trackFacebookPixel';
import trackInternal from 'shared/utils/analytics/trackInternal';

/**
 *  Commerce Event Tracking Util 💸
 *
 *  This provides a layer of indirection that enables us to add type
 *  information to event payloads. It also decouples us from the
 *  `trackInternal` util and provides a centralized location for all
 *  front-end commerce events.
 *
 *  Prefer this over calling `trackInternal` directly.
 */

/**
 * Tracks when the cart page product form is opened.
 */
export const trackCartPageProductFormOpen = () => trackInternal(CommerceEvent.CART_PAGE_PRODUCT_FORM_OPEN);

/**
 * Tracks when the cart page product form is explicitly closed, e.g.
 * The 'cancel' button is clicked.
 */
export const trackCartPageProductFormClose = () => trackInternal(CommerceEvent.CART_PAGE_PRODUCT_FORM_CLOSE);

/**
 * Tracks when the cart page product form is submitted.
 * Does not record successes or failures.
 */
export const trackCartPageProductFormSubmit = () => trackInternal(CommerceEvent.CART_PAGE_PRODUCT_FORM_SUBMIT);


/**
 * Tracks when a customer creates an account.
 */
export const trackCreateCustomerAccount = (customData = {}) =>
  trackFacebookPixel(CommerceEvent.CREATE_CUSTOMER_ACCOUNT, customData);

/**
 * Tracks when a product is added to the cart.
 */
export const trackAddToCart = (customData = {}) => trackFacebookPixel(CommerceEvent.ADD_TO_CART, customData);

/**
 * Tracks when a search is made and a search result is clicked.
 */
export const trackSearch = (customData = {}) => trackFacebookPixel(CommerceEvent.SEARCH, customData);

/**
 * Tracks when the user subscribes to a newsletter.
 */
export const trackSubscribeNewsletter = (customData = {}) =>
  trackFacebookPixel(CommerceEvent.SUBSCRIBE_NEWSLETTER, customData);

/**
 * Tracks when the user views a product page.
 */
export const trackViewProductPage = (customData = {}) =>
  trackFacebookPixel(CommerceEvent.VIEW_PRODUCT_PAGE, customData);

/**
 * Public API Events
 */
export const trackApiKeysPanelLearnMore = () =>
  trackInternal('interface_click', { identifier: PublicApiEvent.API_KEYS_PANEL_LEARN_MORE });

export const trackApiKeysPanelTermsOfUse = () =>
  trackInternal('interface_click', { identifier: PublicApiEvent.API_KEYS_PANEL_TERMS_OF_USE });

export const trackApiKeysPanelCreateKey = () =>
  trackInternal('interface_click', { identifier: PublicApiEvent.API_KEYS_PANEL_CREATE_KEY });

export const trackApiKeysPanelDeleteKey = (data: any) =>
  trackInternal('interface_click', { identifier: PublicApiEvent.API_KEYS_PANEL_DELETE_KEY, ...data });

export const trackApiKeysPanelDeleteKeyConfirm = (data: any) =>
  trackInternal('interface_click', { identifier: PublicApiEvent.API_KEYS_PANEL_DELETE_KEY_CONFIRM, ...data });

export const trackApiKeyCreationModalCopy = (data: any) =>
  trackInternal('interface_click', { identifier: PublicApiEvent.API_KEY_CREATION_MODAL_COPY, ...data });

export const trackApiKeyCreationModalNext = (data: any) =>
  trackInternal('interface_click', { identifier: PublicApiEvent.API_KEY_CREATION_MODAL_NEXT, ...data });

export const trackApiKeyCreationModalCancel = (data: any) =>
  trackInternal('interface_click', { identifier: PublicApiEvent.API_KEY_CREATION_MODAL_CANCEL, ...data });

export const trackApiKeyCreationModalComplete = (data: any) =>
  trackInternal('interface_click', { identifier: PublicApiEvent.API_KEY_CREATION_MODAL_COMPLETE, ...data });

/**
 * Products on Instagram event
 */
export const trackInstagramProductsFeedUrlCopy = () => trackInternal(CommerceEvent.INSTAGRAM_PRODUCTS_FEED_URL_COPY);

/**
 * Facebook Dynamic Ads event
 */
export const trackDynamicAdsProductsFeedUrlCopy = () =>
  trackInternal(CommerceEvent.FACEBOOK_DYNAMIC_ADS_PRODUCTS_FEED_URL_COPY);

/**
 * Facebook Pixel event
 */
export const trackFacebookPixelSaveId = () => trackInternal(CommerceEvent.FACEBOOK_PIXEL_SAVE_ID);

/**
 * Inventory Panel Events
 */
export const trackInventoryPanelSearchProducts = () => trackInternal(CommerceEvent.INVENTORY_PANEL_SEARCH_PRODUCTS);

export const trackInventoryPanelSortOnName = () => trackInternal(CommerceEvent.INVENTORY_PANEL_SORT_ON_NAME);

export const trackInventoryPanelSortOnStock = () => trackInternal(CommerceEvent.INVENTORY_PANEL_SORT_ON_STOCK);

export const trackInventoryPanelSortOnPrice = () => trackInternal(CommerceEvent.INVENTORY_PANEL_SORT_ON_PRICE);

export const trackInventoryPanelClickRowExpander = () =>
  trackInternal(CommerceEvent.INVENTORY_PANEL_CLICK_ROW_EXPANDER);

export const trackInventoryPanelClickRow = () => trackInternal(CommerceEvent.INVENTORY_PANEL_CLICK_ROW);

export const trackInventoryPanelClickNextPage = () => trackInternal(CommerceEvent.INVENTORY_PANEL_CLICK_NEXT_PAGE);
