import { Compiler, Formatters, Formatter, Predicates } from '@squarespace/template-engine';
import humanizeDate from 'shared/utils/humanizeDate';
import legacyCapitalize from 'shared/utils/legacyCapitalize';
import isNumber from 'lodash/isNumber';

class TimeSinceFormatter extends Formatter {
  apply(args, vars, ctx) {
    const first = vars[0];
    const value = first.node.asNumber();
    if (!isNumber(value)) {
      return 'Invalid date.';
    }

    const humanizedDate = legacyCapitalize(humanizeDate(value));
    first.set(`<span class="timesince" data-date="${value}">${humanizedDate}</span>`);
  }
}

/**
 * Function to help you evaluate a JSONT template and
 * context and get a string back
 *
 * @param {String} code The template string
 * @param {Object} json The JSONT render context
 */
export default function evaluateJsonTemplate(code, json) {
  const compiler = new Compiler({
    predicates: Predicates,
    formatters: Object.assign({}, Formatters, {
      timesince: new TimeSinceFormatter()
    })
  });

  const { ctx, errors } = compiler.execute({ code, json });

  if (errors.length > 0) {
    console.log('JSONT compilation error messages:', errors);
  }

  return ctx.render();
}