import AccessPermissions from '@sqs/enums/AccessPermissions';
import AccountStates from '@sqs/enums/AccountStates';
import AchievementType from '@sqs/enums/AchievementType';
import AdvertisingProviderType from '@sqs/enums/AdvertisingProviderType';
import AgeTypes from '@sqs/enums/AgeTypes';
import AggregationGroupSortByTypes from '@sqs/enums/AggregationGroupSortByTypes';
import AggregationTypes from '@sqs/enums/AggregationTypes';
import AnnouncementBarStyle from '@sqs/enums/AnnouncementBarStyle';
import ApplicationSettingType from '@sqs/enums/ApplicationSettingType';
import AuthenticationFrameMessageType from '@sqs/enums/AuthenticationFrameMessageType';
import BackgroundSource from '@sqs/enums/BackgroundSource';
import BillingError from '@sqs/enums/BillingError';
import BillingProductTypes from '@sqs/enums/BillingProductTypes';
import BillingStatus from '@sqs/enums/BillingStatus';
import BillingSubscriptionStatus from '@sqs/enums/BillingSubscriptionStatus';
import BillingTaxType from '@sqs/enums/BillingTaxType';
import BlockForeignKeyFieldNames from '@sqs/enums/BlockForeignKeyFieldNames';
import BlockType from '@sqs/enums/BlockType';
import CanadianProvinces from '@sqs/enums/CanadianProvinces';
import CardBrands from '@sqs/enums/CardBrands';
import CensusDataMetric from '@sqs/enums/CensusDataMetric';
import CensusDataType from '@sqs/enums/CensusDataType';
import CensusEventType from '@sqs/enums/CensusEventType';
import CensusGranularity from '@sqs/enums/CensusGranularity';
import CertificateStatus from '@sqs/enums/CertificateStatus';
import ChannelMappingState from '@sqs/enums/ChannelMappingState';
import CollectionOrdering from '@sqs/enums/CollectionOrdering';
import CollectionTypes from '@sqs/enums/CollectionTypes';
import CommentSortTypes from '@sqs/enums/CommentSortTypes';
import CommentStates from '@sqs/enums/CommentStates';
import CommentStatuses from '@sqs/enums/CommentStatuses';
import CommentTargetTypes from '@sqs/enums/CommentTargetTypes';
import CommerceCouponType from '@sqs/enums/CommerceCouponType';
import CommerceDiscountType from '@sqs/enums/CommerceDiscountType';
import CommerceEmailTypes from '@sqs/enums/CommerceEmailTypes';
import Compositions from '@sqs/enums/Compositions';
import ConfigurationMenuItemType from '@sqs/enums/ConfigurationMenuItemType';
import CurrencySymbol from '@sqs/enums/CurrencySymbol';
import CustomerField from '@sqs/enums/CustomerField';
import CustomerSortDirection from '@sqs/enums/CustomerSortDirection';
import DeviceCategory from '@sqs/enums/DeviceCategory';
import DiscountCriteriaType from '@sqs/enums/DiscountCriteriaType';
import DiscountStatus from '@sqs/enums/DiscountStatus';
import DiscountTemplateType from '@sqs/enums/DiscountTemplateType';
import DiscountTriggerType from '@sqs/enums/DiscountTriggerType';
import DiscountValidationErrorType from '@sqs/enums/DiscountValidationErrorType';
import DNSProviderIdentifier from '@sqs/enums/DNSProviderIdentifier';
import DomainAvailability from '@sqs/enums/DomainAvailability';
import DomainNameStatus from '@sqs/enums/DomainNameStatus';
import DomainPlanLevel from '@sqs/enums/DomainPlanLevel';
import DomainPresetTemplateId from '@sqs/enums/DomainPresetTemplateId';
import DomainProviderId from '@sqs/enums/DomainProviderId';
import DomainSubscriptionState from '@sqs/enums/DomainSubscriptionState';
import DomainTransferStatus from '@sqs/enums/DomainTransferStatus';
import DomainTypes from '@sqs/enums/DomainTypes';
import DomainVerificationStatus from '@sqs/enums/DomainVerificationStatus';
import EmailTypes from '@sqs/enums/EmailTypes';
import EventView from '@sqs/enums/EventView';
import ExternalServiceProviders from '@sqs/enums/ExternalServiceProviders';
import ExternalServiceProvidersOnlyOneAllowed from '@sqs/enums/ExternalServiceProvidersOnlyOneAllowed';
import FeatureLevels from '@sqs/enums/FeatureLevels';
import Features from '@sqs/enums/Features';
import FeatureSetIdentifiers from '@sqs/enums/FeatureSetIdentifiers';
import Flag from '@sqs/enums/Flag';
import FocalPointSources from '@sqs/enums/FocalPointSources';
import FormType from '@sqs/enums/FormType';
import GettyError from '@sqs/enums/GettyError';
import GoogleAppsPlanType from '@sqs/enums/GoogleAppsPlanType';
import GoogleAppsSubscriptionState from '@sqs/enums/GoogleAppsSubscriptionState';
import GoogleAppsSubscriptionStatus from '@sqs/enums/GoogleAppsSubscriptionStatus';
import GraphicalStyles from '@sqs/enums/GraphicalStyles';
import GroupSizes from '@sqs/enums/GroupSizes';
import ImageFamilies from '@sqs/enums/ImageFamilies';
import ImportingSources from '@sqs/enums/ImportingSources';
import IndexType from '@sqs/enums/IndexType';
import InvoiceProvider from '@sqs/enums/InvoiceProvider';
import InvoiceStatuses from '@sqs/enums/InvoiceStatuses';
import JobStatuses from '@sqs/enums/JobStatuses';
import JobTypes from '@sqs/enums/JobTypes';
import LicensedAssetSource from '@sqs/enums/LicensedAssetSource';
import LinkStatusType from '@sqs/enums/LinkStatusType';
import MarketingCouponExpirationTypes from '@sqs/enums/MarketingCouponExpirationTypes';
import MeasurementStandard from '@sqs/enums/MeasurementStandard';
import MediaProcessingState from '@sqs/enums/MediaProcessingState';
import MemberAccountGuild from '@sqs/enums/MemberAccountGuild';
import MemberAccountStatus from '@sqs/enums/MemberAccountStatus';
import MemberAccountRole from '@sqs/enums/MemberAccountRole';
import MobileInfoBarStyle from '@sqs/enums/MobileInfoBarStyle';
import OrderDirection from '@sqs/enums/OrderDirection';
import OrderFulfillmentStates from '@sqs/enums/OrderFulfillmentStates';
import OrderPaymentStates from '@sqs/enums/OrderPaymentStates';
import OrderStates from '@sqs/enums/OrderStates';
import Orientations from '@sqs/enums/Orientations';
import PageAction from '@sqs/enums/PageAction';
import PageFeatures from '@sqs/enums/PageFeatures';
import PageTypes from '@sqs/enums/PageTypes';
import PaymentMethodType from '@sqs/enums/PaymentMethodType';
import PaymentProviderType from '@sqs/enums/PaymentProviderType';
import PaymentSettingsErrorType from '@sqs/enums/PaymentSettingsErrorType';
import PayPalCheckoutErrorTypes from '@sqs/enums/PayPalCheckoutErrorTypes';
import PinterestDomainVerificationState from '@sqs/enums/PinterestDomainVerificationState';
import PlanGenus from '@sqs/enums/PlanGenus';
import PlanViolationErrors from '@sqs/enums/PlanViolationErrors';
import PriceTaxInterpretations from '@sqs/enums/PriceTaxInterpretations';
import ProductLines from '@sqs/enums/ProductLines';
import ProductTypes from '@sqs/enums/ProductTypes';
import RecordType from '@sqs/enums/RecordType';
import ReferralMatchType from '@sqs/enums/ReferralMatchType';
import ReferralSearchResultStatus from '@sqs/enums/ReferralSearchResultStatus';
import ReferralSource from '@sqs/enums/ReferralSource';
import ReferralState from '@sqs/enums/ReferralState';
import ShippingRateTypes from '@sqs/enums/ShippingRateTypes';
import SliceType from '@sqs/enums/SliceType';
import SlideLayoutCategory from '@sqs/enums/SlideLayoutCategory';
import SlideLayoutStatus from '@sqs/enums/SlideLayoutStatus';
import SlideType from '@sqs/enums/SlideType';
import SocialIconAlignments from '@sqs/enums/SocialIconAlignments';
import SocialIconColors from '@sqs/enums/SocialIconColors';
import SocialIconSizes from '@sqs/enums/SocialIconSizes';
import SocialIconStyles from '@sqs/enums/SocialIconStyles';
import SocialSearchReferenceType from '@sqs/enums/SocialSearchReferenceType';
import SocialSearchStatus from '@sqs/enums/SocialSearchStatus';
import SocialServices from '@sqs/enums/SocialServices';
import SortOrders from '@sqs/enums/SortOrders';
import SSBadgeDevices from '@sqs/enums/SSBadgeDevices';
import SSBadgePosition from '@sqs/enums/SSBadgePosition';
import SSBadgeType from '@sqs/enums/SSBadgeType';
import SSBadgeVisibility from '@sqs/enums/SSBadgeVisibility';
import SslSetting from '@sqs/enums/SslSetting';
import StatusConstants from '@sqs/enums/StatusConstants';
import StoreStates from '@sqs/enums/StoreStates';
import SubscriptionEventRecordTypes from '@sqs/enums/SubscriptionEventRecordTypes';
import SubscriptionLineItemType from '@sqs/enums/SubscriptionLineItemType';
import SuspensionState from '@sqs/enums/SuspensionState';
import SystemBlogCollectionPostDisplay from '@sqs/enums/SystemBlogCollectionPostDisplay';
import SystemWebsiteSubjects from '@sqs/enums/SystemWebsiteSubjects';
import TaxCategoryTypes from '@sqs/enums/TaxCategoryTypes';
import TaxJurisdictionType from '@sqs/enums/TaxJurisdictionType';
import TaxScopes from '@sqs/enums/TaxScopes';
import TemplateErrorType from '@sqs/enums/TemplateErrorType';
import TermsAcceptanceRequirement from '@sqs/enums/TermsAcceptanceRequirement';
import TermTypes from '@sqs/enums/TermTypes';
import TransferAvailability from '@sqs/enums/TransferAvailability';
import TweakTypes from '@sqs/enums/TweakTypes';
import VideoFilterOption from '@sqs/enums/VideoFilterOption';
import WebsiteCompRecordType from '@sqs/enums/WebsiteCompRecordType';
import WebsiteContentCategory from '@sqs/enums/WebsiteContentCategory';
import WebsiteContentSubcategory from '@sqs/enums/WebsiteContentSubcategory';
import WebsiteDisambiguationType from '@sqs/enums/WebsiteDisambiguationType';
import WebsiteGoal from '@sqs/enums/WebsiteGoal';
import WebsiteRole from '@sqs/enums/WebsiteRole';
import WebsiteType from '@sqs/enums/WebsiteType';
import WorkflowStates from '@sqs/enums/WorkflowStates';

/**
 * Deprecated enums module, use @sqs/enums instead.
 */
YUI.add('squarespace-enum', function(Y) {

  var ns = Y.namespace('Squarespace');

  ns.AccessPermissions = AccessPermissions;
  ns.AccountStates = AccountStates;
  ns.AchievementType = AchievementType;
  ns.AdvertisingProviderType = AdvertisingProviderType;
  ns.AgeTypes = AgeTypes;
  ns.AggregationGroupSortByTypes = AggregationGroupSortByTypes;
  ns.AggregationTypes = AggregationTypes;
  ns.AnnouncementBarStyle = AnnouncementBarStyle;
  ns.ApplicationSettingType = ApplicationSettingType;
  ns.AuthenticationFrameMessageType = AuthenticationFrameMessageType;
  ns.BackgroundSource = BackgroundSource;
  ns.BillingError = BillingError;
  ns.BillingProductTypes = BillingProductTypes;
  ns.BillingStatus = BillingStatus;
  ns.BillingSubscriptionStatus = BillingSubscriptionStatus;
  ns.BillingTaxType = BillingTaxType;
  ns.BlockForeignKeyFieldNames = BlockForeignKeyFieldNames;
  ns.BlockType = BlockType;
  ns.CanadianProvinces = CanadianProvinces;
  ns.CardBrands = CardBrands;
  ns.CensusDataMetric = CensusDataMetric;
  ns.CensusDataType = CensusDataType;
  ns.CensusEventType = CensusEventType;
  ns.CensusGranularity = CensusGranularity;
  ns.CertificateStatus = CertificateStatus;
  ns.ChannelMappingState = ChannelMappingState;
  ns.CollectionOrdering = CollectionOrdering;
  ns.CollectionTypes = CollectionTypes;
  ns.CommentSortTypes = CommentSortTypes;
  ns.CommentStates = CommentStates;
  ns.CommentStatuses = CommentStatuses;
  ns.CommentTargetTypes = CommentTargetTypes;
  ns.CommerceCouponType = CommerceCouponType;
  ns.CommerceDiscountType = CommerceDiscountType;
  ns.CommerceEmailTypes = CommerceEmailTypes;
  ns.Compositions = Compositions;
  ns.ConfigurationMenuItemType = ConfigurationMenuItemType;
  ns.CurrencySymbol = CurrencySymbol;
  ns.CustomerField = CustomerField;
  ns.CustomerSortDirection = CustomerSortDirection;
  ns.DeviceCategory = DeviceCategory;
  ns.DiscountCriteriaType = DiscountCriteriaType;
  ns.DiscountStatus = DiscountStatus;
  ns.DiscountTemplateType = DiscountTemplateType;
  ns.DiscountTriggerType = DiscountTriggerType;
  ns.DiscountValidationErrorType = DiscountValidationErrorType;
  ns.DNSProviderIdentifier = DNSProviderIdentifier;
  ns.DomainAvailability = DomainAvailability;
  ns.DomainNameStatus = DomainNameStatus;
  ns.DomainPlanLevel = DomainPlanLevel;
  ns.DomainPresetTemplateId = DomainPresetTemplateId;
  ns.DomainProviderId = DomainProviderId;
  ns.DomainSubscriptionState = DomainSubscriptionState;
  ns.DomainTransferStatus = DomainTransferStatus;
  ns.DomainTypes = DomainTypes;
  ns.DomainVerificationStatus = DomainVerificationStatus;
  ns.EmailTypes = EmailTypes;
  ns.EventView = EventView;
  ns.ExternalServiceProviders = ExternalServiceProviders;
  ns.ExternalServiceProvidersOnlyOneAllowed = ExternalServiceProvidersOnlyOneAllowed;
  ns.FeatureLevels = FeatureLevels;
  ns.Features = Features;
  ns.FeatureSetIdentifiers = FeatureSetIdentifiers;
  ns.Flag = Flag;
  ns.FocalPointSources = FocalPointSources;
  ns.FormType = FormType;
  ns.GettyError = GettyError;
  ns.GoogleAppsPlanType = GoogleAppsPlanType;
  ns.GoogleAppsSubscriptionState = GoogleAppsSubscriptionState;
  ns.GoogleAppsSubscriptionStatus = GoogleAppsSubscriptionStatus;
  ns.GraphicalStyles = GraphicalStyles;
  ns.GroupSizes = GroupSizes;
  ns.ImageFamilies = ImageFamilies;
  ns.ImportingSources = ImportingSources;
  ns.IndexType = IndexType;
  ns.InvoiceProvider = InvoiceProvider;
  ns.InvoiceStatuses = InvoiceStatuses;
  ns.JobStatuses = JobStatuses;
  ns.JobTypes = JobTypes;
  ns.LicensedAssetSource = LicensedAssetSource;
  ns.LinkStatusType = LinkStatusType;
  ns.MarketingCouponExpirationTypes = MarketingCouponExpirationTypes;
  ns.MeasurementStandard = MeasurementStandard;
  ns.MediaProcessingState = MediaProcessingState;
  ns.MemberAccountGuild = MemberAccountGuild;
  ns.MemberAccountStatus = MemberAccountStatus;
  ns.MemberAccountRole = MemberAccountRole;
  ns.MobileInfoBarStyle = MobileInfoBarStyle;
  ns.OrderDirection = OrderDirection;
  ns.OrderFulfillmentStates = OrderFulfillmentStates;
  ns.OrderPaymentStates = OrderPaymentStates;
  ns.OrderStates = OrderStates;
  ns.Orientations = Orientations;
  ns.PageAction = PageAction;
  ns.PageFeatures = PageFeatures;
  ns.PageTypes = PageTypes;
  ns.PaymentMethodType = PaymentMethodType;
  ns.PaymentProviderType = PaymentProviderType;
  ns.PaymentSettingsErrorType = PaymentSettingsErrorType;
  ns.PayPalCheckoutErrorTypes = PayPalCheckoutErrorTypes;
  ns.PinterestDomainVerificationState = PinterestDomainVerificationState;
  ns.PlanGenus = PlanGenus;
  ns.PlanViolationErrors = PlanViolationErrors;
  ns.PriceTaxInterpretations = PriceTaxInterpretations;
  ns.ProductLines = ProductLines;
  ns.ProductTypes = ProductTypes;
  ns.RecordType = RecordType;
  ns.ReferralMatchType = ReferralMatchType;
  ns.ReferralSearchResultStatus = ReferralSearchResultStatus;
  ns.ReferralSource = ReferralSource;
  ns.ReferralState = ReferralState;
  ns.ShippingRateTypes = ShippingRateTypes;
  ns.SliceType = SliceType;
  ns.SlideLayoutCategory = SlideLayoutCategory;
  ns.SlideLayoutStatus = SlideLayoutStatus;
  ns.SlideType = SlideType;
  ns.SocialIconAlignments = SocialIconAlignments;
  ns.SocialIconColors = SocialIconColors;
  ns.SocialIconSizes = SocialIconSizes;
  ns.SocialIconStyles = SocialIconStyles;
  ns.SocialSearchReferenceType = SocialSearchReferenceType;
  ns.SocialSearchStatus = SocialSearchStatus;
  ns.SocialServices = SocialServices;
  ns.SortOrders = SortOrders;
  ns.SSBadgeDevices = SSBadgeDevices;
  ns.SSBadgePosition = SSBadgePosition;
  ns.SSBadgeType = SSBadgeType;
  ns.SSBadgeVisibility = SSBadgeVisibility;
  ns.SslSetting = SslSetting;
  ns.StatusConstants = StatusConstants;
  ns.StoreStates = StoreStates;
  ns.SubscriptionEventRecordTypes = SubscriptionEventRecordTypes;
  ns.SubscriptionLineItemType = SubscriptionLineItemType;
  ns.SuspensionState = SuspensionState;
  ns.SystemBlogCollectionPostDisplay = SystemBlogCollectionPostDisplay;
  ns.SystemWebsiteSubjects = SystemWebsiteSubjects;
  ns.TaxCategoryTypes = TaxCategoryTypes;
  ns.TaxJurisdictionType = TaxJurisdictionType;
  ns.TaxScopes = TaxScopes;
  ns.TemplateErrorType = TemplateErrorType;
  ns.TermsAcceptanceRequirement = TermsAcceptanceRequirement;
  ns.TermTypes = TermTypes;
  ns.TransferAvailability = TransferAvailability;
  ns.TweakTypes = TweakTypes;
  ns.VideoFilterOption = VideoFilterOption;
  ns.WebsiteCompRecordType = WebsiteCompRecordType;
  ns.WebsiteContentCategory = WebsiteContentCategory;
  ns.WebsiteContentSubcategory = WebsiteContentSubcategory;
  ns.WebsiteDisambiguationType = WebsiteDisambiguationType;
  ns.WebsiteGoal = WebsiteGoal;
  ns.WebsiteRole = WebsiteRole;
  ns.WebsiteType = WebsiteType;
  ns.WorkflowStates = WorkflowStates;
}, '1.0', { requires: [] });
