"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.WebsiteContentCategory */

/* Java class defined in repository squarespace-v6 */
var WebsiteContentCategory;

(function (WebsiteContentCategory) {
  WebsiteContentCategory["MUSIC"] = 1;
  WebsiteContentCategory["FOOD_DRINK"] = 2;
  WebsiteContentCategory["PORTFOLIO"] = 3;
  WebsiteContentCategory["PUBLISHING_CONTENT"] = 4;
  WebsiteContentCategory["WEDDING"] = 5;
  WebsiteContentCategory["PROFESSIONAL_SERVICES"] = 6;
  WebsiteContentCategory["BUSINESS"] = 7;
  WebsiteContentCategory["OTHER"] = 8;
  WebsiteContentCategory["CLASSES_SERVICES"] = 9;
  WebsiteContentCategory["JEWELRY_ACCESSORIES"] = 10;
  WebsiteContentCategory["FASHION_APPAREL"] = 11;
  WebsiteContentCategory["HOME_DECOR"] = 12;
  WebsiteContentCategory["HEALTH_BEAUTY"] = 13;
  WebsiteContentCategory["BOOKS"] = 14;
  WebsiteContentCategory["ART_PHOTOGRAPHY"] = 15;
  WebsiteContentCategory["TICKETS"] = 16;
  WebsiteContentCategory["ELECTRONICS"] = 17;
  WebsiteContentCategory["TOYS_GAMES"] = 18;
  WebsiteContentCategory["MUSIC_VIDEOS_CONTENT"] = 19;
  WebsiteContentCategory["SPORTS_OUTDOORS"] = 20;
  WebsiteContentCategory["PAPER_GOODS"] = 21;
  WebsiteContentCategory["AUTOMOTIVE"] = 22;
})(WebsiteContentCategory || (WebsiteContentCategory = {}));

var _default = WebsiteContentCategory;
exports["default"] = _default;
module.exports = exports.default;