import debounce from 'lodash/debounce';
import SlideType from '@sqs/enums/SlideType';
import FontLoadChecker from '@sqs/site-rendering/FontLoadChecker';
import * as GlobalSentry from 'shared/utils/error-reporter/global-sentry';
import { SliceTypeByName, SlideTypeNames } from '@sqs/websites-constants';
import SvgCompatibility from 'shared/utils/SvgCompatibility';

/**
 * The module containing the Squarespace.SlideRendering.Layouts.Base class.
 *
 * @module squarespace-slide-rendering-layouts-base
 */
YUI.add('squarespace-slide-rendering-layouts-base', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The widget responsible for progressively enhancing slides being rendered on
   * the frontend. It is mainly responsible for widgetizing the contained slices
   * that require it. Because this is simply a frontend widget that is meant to
   * be very lightweight, we extend base Widget rather than SSWidget.
   *
   * @class Base
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Widget
   */
  var Base =
  Y.Squarespace.SlideRendering.Layouts.Base = Y.Base.create('LayoutBase',
    Y.Widget,
    [],
    {
      initializer: function() {
        // Initialize an empty mapping of slice widgets and an empty array of
        // responsive components.
        this._sliceWidgetMap = {};
        this._responsiveComponents = [];
        // this._isMobileSafari = (Y.UA.mobile === 'Apple' && Y.UA.safari > 0);

        // Sync the registered responsive components when the window resizes.
        this._debouncedSyncResponsiveComponents = debounce(
          this._syncResponsiveComponents,
          200
        );
        this._windowResizeHandler = Y.one(Y.config.win).on(
          'resize',
          this._debouncedSyncResponsiveComponents,
          this
        );

        this._watchTweaks();

        Y.Global.once('slideReady', function() {
          var slide = this.get('contentBox');
          if (slide.getAttribute('data-slide-type') === SlideTypeNames[SlideType.POPUP_OVERLAY]) {
            // Without the timeout the transition isn't rendered when the class is added
            setTimeout(function() {
              slide.addClass('sqs-slide-animation-ready');
            }, 500);
          }
        }, this);
      },

      destructor: function() {
        this._debouncedSyncResponsiveComponents.cancel();
        this._windowResizeHandler.detach();

        Y.Object.each(
          this._sliceWidgetMap,
          function destroyEachSliceWidget(sliceWidget) {
            sliceWidget.destroy(true);
          }
        );

        this.get('contentBox').removeClass('sqs-slide-ready');
        this._destroyResponsiveComponents();
      },


      renderUI: function() {

        var isMobileApp = typeof squarespaceStartApp !== 'undefined';

        var fontLoadChecker;

        if (!isMobileApp) {
          // The font-checking is a fairly expensive operation, and it doesn't make sense
          // in the mobile app (e.g. the Start app), where it adds a considerable delay
          // to the rendering of each cover page.
          fontLoadChecker = new FontLoadChecker([
            '[data-compound-type="logo"][data-slice-type="heading"] h1',
            '[data-slice-type="heading"]:not([data-compound-type]) h1',
            '[data-slice-type="body"]'
          ], Y.config.win.document.body);
        }

        this._checkSupportVH();

        if (Y.UA.safari > 0 && !Y.UA.mobile) {
          Y.one('html').addClass('browser-safari');
        }

        Base.superclass.renderUI.call(this);

        var contentBox = this.get('contentBox');

        // Widgetize slice nodes.
        contentBox.all('[data-slice-type]').each(function(sliceNode) {

          // Do not create widgets for empty placeholder nodes.
          if (sliceNode.getAttribute('data-content-empty') === 'true') {
            return;
          }

          this._widgetizeSliceNode(sliceNode);
        }, this);

        this._initResponsiveComponents();

        SvgCompatibility.parse();

        // SIX-14235 - interim fix
        // Issue: Image which rely on ImageLoader cause _shouldRespond to evaluated 'false' while the image width is 0.
        // Fix: Use Y.Parallel to call _syncResponsiveComponents after fontLoadChecker Promise resolves,
        //    if we're not in the mobile app.

        var imagesToLoad = contentBox.all('[data-slice-type="image"] img[data-image-dimensions*="x"]');
        var stack = new Y.Parallel();
        var syncAndShowSlide = function() {
          this._debouncedSyncResponsiveComponents();
          this.get('contentBox').addClass('sqs-slide-ready');
          Y.Global.fire('slideReady');
        }.bind(this);
        var contentReady = function() {
          if (!isMobileApp) {
            var fontPromise = fontLoadChecker.check();
            Y.Squarespace.Promises.all(fontPromise).then(function() {
              syncAndShowSlide();
            });
          } else {
            syncAndShowSlide();
          }
        };
        var syncTimeout = setTimeout(contentReady, 2000);
        imagesToLoad.each(function(img) {
          img.once('load', stack.add());
        }, this);
        stack.done(function() {
          clearTimeout(syncTimeout);
          contentReady();
        });
      },

      /**
       * Bootstrap the given slice node with its corresponding slice widget.
       *
       * @method _widgetizeSliceNode
       * @param sliceNode {Node} The slice node to progressively enhance.
       * @private
       */
      _widgetizeSliceNode: function(sliceNode) {
        var typeName = sliceNode.getAttribute('data-slice-type');
        var compoundTypeName = sliceNode.getAttribute('data-compound-type');
        var sliceType = SliceTypeByName[typeName];

        var sliceWidget = Y.Squarespace.SlideRendering.Slices.Factory.create(sliceType, {
          sliceId: sliceNode.getAttribute('data-slice-id') || null,
          slideType: this.get('slideType'),
          boundingBox: sliceNode,
          render: true
        });

        var key = compoundTypeName ? SliceTypeByName[compoundTypeName] : SliceTypeByName[typeName];
        this._sliceWidgetMap[key] = sliceWidget;
      },

      /**
       * Call syncUI on each of the slice widgets.
       *
       * @method _syncSliceWidgets
       * @private
       */
      _syncSliceWidgets: function() {
        Y.Object.each(this._sliceWidgetMap, function(sliceWidget) {
          sliceWidget.syncUI();
        });
      },

      /**
       * Reset the _responsiveComponents array and let them be gc'd.
       *
       * @method _destroyResponsiveComponents
       * @private
       */
      _destroyResponsiveComponents: function() {

        this._responsiveComponents = [];
      },

      /**
       * Initialize responsive components for the layout and register them in
       * the _responsiveComponents array. This method may be left blank if the
       * layout has no responsive components.
       *
       * @method _initResponsiveComponents
       * @protected
       */
      _initResponsiveComponents: function() {},

      _watchTweaks: function() {
        var slideType = this.get('slideType');
        if (window.Static.SQUARESPACE_CONTEXT.authenticatedAccount && slideType === 'popup-overlay') {
          var contentBox = this.get('contentBox').getDOMNode();
          this.tweakPreview = function() {
            contentBox.querySelector('.sqs-slide-layer-content').style.transition = 'none';
            contentBox.classList.remove('sqs-slide-animation-ready');
            setTimeout(function() {
              this.querySelector('.sqs-slide-layer-content').style.transition = '';
              this.classList.add('sqs-slide-animation-ready');
            }.bind(contentBox), 500);
          };

          var tweakNamesToWatch = [
            'button-layout-style',
            'newsletter-layout-style'
          ];

          Y.Global.after('tweak:change', function(event) {
            var tweakCategory = event.config.category;
            var tweakName = event.getName();

            if (tweakCategory === 'Overlay Animation') {
              this.tweakPreview();
            } else if (tweakCategory === 'Overlay Design') {
              this._debouncedSyncResponsiveComponents();
            }

            if (tweakNamesToWatch.indexOf(tweakName) >= 0) {
              this._debouncedSyncResponsiveComponents();
            }
          }, this);
        }
      },

      /**
       * Call reset on each registered responsive component and then do the same
       * calling respond.
       *
       * Called on window resize and other events where content changes.
       *
       * NEVER CALL THIS FUNCTION DIRECTLY, use
       * _debouncedSyncResponsiveComponents instead to avoid render updates
       * after a slide has been destroyed.
       */
      _syncResponsiveComponents: function () {
        // JIRA 15545: mobile Safari has a bug wherein the calculation for an
        // element's dimensions occurs prior to the font being applied to the
        // element, causing _shouldRespond to incorrectly return false.
        // In 2015, @djohnson attempted to fix it with a mobile delay. See the
        // revision history of this file if it needs to be re-introduced.
        this._responsiveComponents.forEach(function eachComponentRespond(responsiveComponent) {
          try {
            responsiveComponent.reset();
            responsiveComponent.respond();
          } catch (error) {
            GlobalSentry.captureException(error);
          }
        });
        Y.Global.fire('slideComponentSync');
      },

      /**
       * Add the given responsive component to the list of registered
       * components. This should always be used when initializing a new
       * responsive component.
       *
       * @method _registerResponsiveComponent
       * @param {Squarespace.Responsive.Base} component The responsive
       *     component to register.
       */
      _registerResponsiveComponent: function(component) {
        this._responsiveComponents.push(component);
      },

      /**
       * Mobile Safari on iOS 7 does not properly interpret viewport height
       * measurement units. VH are used primarily in 50/50 layouts on mobile
       * (window width < 1020px) to maintain a proper min-height for the
       * sqs-slide-layers. LESS rules can be scoped to the 'no-vh' class.
       */
      _checkSupportVH: function() {
        if (parseInt(Y.UA.ios, 10) === 7) {
          Y.one('html').addClass('no-vh');
        } else {
          Y.one('html').addClass('vh');
        }
      },

      CONTENT_TEMPLATE: null
    },
    {
      CSS_PREFIX: 'sqs-slide',
      ATTRS: {
        /**
         * The id of the slide that this widget is rendering.
         *
         * @attribute slideId
         * @type String
         * @default Gathered from the boundingBox.
         */
        slideId: {
          valueFn: function() {
            return this.get('contentBox').getAttribute('data-slide-id');
          }
        },

        /**
         * The type of the slide that this widget is rendering.
         *
         * @attribute slideType
         * @type String
         * @default Gathered from the boundingBox.
         */
        slideType: {
          valueFn: function() {
            return this.get('contentBox').getAttribute('data-slide-type');
          }
        },

        /**
         * The identifier of the slide layout selected for this slide.
         *
         * @attribute slideLayoutIdentifier
         * @type String
         * @default null
         * @required
         */
        slideLayoutIdentifier: {
          valueFn: function() {
            return this.get('contentBox').getAttribute('data-slide-layout-id');
          }
        }
      }
    }
  );
}, '1.0', {
  requires: [
    'base',
    'squarespace-attr-validators',
    'parallel',
    'squarespace-promises',
    'squarespace-slide-rendering-slices-factory',
    'widget'
  ]
});
