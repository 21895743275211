"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.DomainPresetTemplateId */

/* Java class defined in repository squarespace-v6 */
var DomainPresetTemplateId;

(function (DomainPresetTemplateId) {
  DomainPresetTemplateId["GOOGLE_APPS_MAIL_OLD"] = 1;
  DomainPresetTemplateId["GOOGLE_APPS_MAIL"] = 2;
  DomainPresetTemplateId["ZOHO_MAIL"] = 3;
  DomainPresetTemplateId["SQUARESPACE_APEX_FORWARDING"] = 4;
  DomainPresetTemplateId["SQUARESPACE_SUBDOMAIN_FORWARDING"] = 5;
  DomainPresetTemplateId["SQUARESPACE_WEBSITE"] = 6;
  DomainPresetTemplateId["SQUARESPACE_WEBSITE_LEGACY_WILDCARD"] = 7;
  DomainPresetTemplateId["SQUARESPACE_SUBDOMAIN_WEBSITE"] = 8;
  DomainPresetTemplateId["GOOGLE_APPS_VERIFICATION"] = 9;
  DomainPresetTemplateId["NEWSLETTERS_EMAIL"] = 10;
})(DomainPresetTemplateId || (DomainPresetTemplateId = {}));

var _default = DomainPresetTemplateId;
exports["default"] = _default;
module.exports = exports.default;