/**
 * The module containing the Squarespace.SlideRendering.Slices.ClientRefresh class.
 *
 * @module squarespace-slide-rendering-slices-client-refresh
 */
YUI.add('squarespace-slide-rendering-slices-client-refresh', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * A mixin for slice widgets that have the ability to refresh their rendering
   * on the client alone given an updated slice model.
   *
   * @class ClientRefresh
   * @namespace Squarespace.SlideRendering.Slices
   * @extends Base
   * @extensionfor Squarespace.SlideRendering.Slices.Base
   */
  Y.Squarespace.SlideRendering.Slices.ClientRefresh = Y.Base.create('Heading', Y.Base,
    [],
    {
      /**
       * Take the given slice content to refresh the rendering of the slice
       * widget that this class has been mixed into. This method must be
       * overridden by those who mix in this class.
       *
       * @method _refreshFromSliceContent
       * @param sliceContent {Squarespace.Models.Slice} The slice to use when
       *     refreshing the rendering.
       * @protected
       */
      refreshFromSliceContent: function(sliceContent) {
        if (__DEV__) {
          console.error('ClientRefresh: Method _refreshFromSliceContent must ' +
                      'be implemented.');
        }
      },

      /**
       * Update the data-content-empty attribute on the content box depending
       * on the value of isEmpty.
       *
       * @method _updateContentEmptyAttribute
       * @param isEmpty {Boolean} Whether or not the content is empty.
       * @private
       */
      _updateContentEmptyAttribute: function(isEmpty) {
        var contentBox = this.get('contentBox');

        if (isEmpty) {
          contentBox.setAttribute('data-content-empty', true);
        } else {
          contentBox.removeAttribute('data-content-empty');
        }
      }
    },
    {
      ATTRS: {
        // Override
        canClientRefresh: {
          value: true
        }
      }
    }
  );
}, '1.0', {
  requires: [
    'base'
  ]
});
