"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.model.advertisingcoupons.AdvertisingProviderType */

/* Java class defined in repository squarespace-v6 */
var AdvertisingProviderType;

(function (AdvertisingProviderType) {
  AdvertisingProviderType["GOOGLE_ADS"] = 1;
})(AdvertisingProviderType || (AdvertisingProviderType = {}));

var _default = AdvertisingProviderType;
exports["default"] = _default;
module.exports = exports.default;