"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SocialIconColor */

/* Java class defined in repository cms-legacy-data */
var SocialIconColors;

(function (SocialIconColors) {
  SocialIconColors["BLACK"] = "black";
  SocialIconColors["WHITE"] = "white";
  SocialIconColors["STANDARD"] = "standard";
})(SocialIconColors || (SocialIconColors = {}));

var _default = SocialIconColors;
exports["default"] = _default;
module.exports = exports.default;