"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.business.commerce.discount.criteria.DiscountCriteriaType */

/* Java class defined in repository squarespace-v6 */
var DiscountCriteriaType;

(function (DiscountCriteriaType) {
  DiscountCriteriaType["ANY_ORDER"] = "ANY_ORDER";
  DiscountCriteriaType["PRODUCT"] = "PRODUCT";
  DiscountCriteriaType["CART_TOTAL"] = "CART_TOTAL";
  DiscountCriteriaType["CATEGORY"] = "CATEGORY";
  DiscountCriteriaType["CATEGORY_ID"] = "CATEGORY_ID";
  DiscountCriteriaType["SINGLE_PAYWALL_PRODUCT"] = "SINGLE_PAYWALL_PRODUCT";
})(DiscountCriteriaType || (DiscountCriteriaType = {}));

var _default = DiscountCriteriaType;
exports["default"] = _default;
module.exports = exports.default;