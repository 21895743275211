import {
  FontSize as ResponsiveFontSize,
  BoundaryRatios as ResponsiveBoundaryRatios,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.LandingFullCenter01 class.
 *
 * @module squarespace-slide-rendering-layouts-landing-full-center-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-landing-full-center-01', function(Y) {

  var toDOMNode = function(yuiNode) {
    if (Y.instanceOf(yuiNode, Y.Node)) {
      return yuiNode.getDOMNode();
    }

    return null;
  };

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for landing-full-center-01.
   *
   * @class LandingFullCenter01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.LandingFullCenter01 = Y.Base.create('LandingFullCenter01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox');

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: toDOMNode(contentBox.one('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthRatio: 0.5,
          minFontSize: 13
        }));

        var headingHeightResponsiveComponent;

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: toDOMNode(contentBox.one('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          widthRatio: 0.99,
          scale: 0.99,
          minFontSize: 54,
          onReset: function(rootNode) {
            headingHeightResponsiveComponent.disable();
          },
          onRespond: function(rootNode) {
          },
          onMinFontSize: function(rootNode) {
            headingHeightResponsiveComponent.enable();
          }
        }));


        headingHeightResponsiveComponent = new ResponsiveFontSize({
          rootNode: toDOMNode(contentBox.one('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          widthRatio: 1,
          heightRatio: 0.2,
          scale: 0.99,
          minFontSize: 18,
          enabled: false,
          heightBoundaryNode: toDOMNode(contentBox.one('.sqs-slide-layer')),
          onReset: function(rootNode) {
          },
          onRespond: function(rootNode) {
          },
          onMinFontSize: function(rootNode) {
          }
        });
        this._registerResponsiveComponent(headingHeightResponsiveComponent);


        // Actions and custom form font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: toDOMNode(contentBox.one('.responsive-wrapper.actions')),
          fontSizeNodes: [
            toDOMNode(contentBox.one('[data-compound-type="action"] ul')),
            toDOMNode(contentBox.one('[data-slice-type="custom-form"]'))
          ],
          minFontSize: 10,
          widthRatio: 0.8,
          onReset: function(rootNode) {
            rootNode = new Y.Node(rootNode);
            rootNode.removeClass('stacked');
          },
          onMinFontSize: function(rootNode) {
            rootNode = new Y.Node(rootNode);
            rootNode.addClass('stacked');
          }
        }));

        // Social icons wrapping
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: toDOMNode(contentBox.one('.responsive-wrapper.social')),
          widthRatio: 0.9,
          onReset: function(rootNode) {
            rootNode = new Y.Node(rootNode);
            rootNode.removeClass('stacked');
          },
          onRespond: function(rootNode) {
            rootNode = new Y.Node(rootNode);
            rootNode.addClass('stacked');
          }
        }));

        // Overlap between copy and social icons wrapper
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: toDOMNode(contentBox.one('.group-copy')),
          targetNode: toDOMNode(contentBox.one('.group-social')),
          padding: 20,
          onReset: function(rootNode, targetNode) {
            rootNode = new Y.Node(rootNode);
            targetNode = new Y.Node(targetNode);

            rootNode.addClass('align-center-vert');
            rootNode.addClass('full-width');
            rootNode.removeClass('stacked');

            // Make entire slide non-scrollable
            contentBox.one('.sqs-slide-layer.layer-front').removeClass('scroll');

            targetNode.addClass('bottom-full-width');
          },
          onRespond: function(rootNode, targetNode) {
            rootNode = new Y.Node(rootNode);
            targetNode = new Y.Node(targetNode);

            rootNode.removeClass('align-center-vert');
            rootNode.addClass('full-width');
            rootNode.addClass('stacked');

            // Make entire slide scrollable
            contentBox.one('.sqs-slide-layer.layer-front').addClass('scroll');

            targetNode.removeClass('bottom-full-width');
          }
        }));

        // Overlap between copy and logo
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: toDOMNode(contentBox.one('.group-copy')),
          targetNode: toDOMNode(contentBox.one('[data-compound-type="logo"]:not([data-content-empty="true"])')),
          padding: 20,
          onReset: function(rootNode, targetNode) {
            rootNode = new Y.Node(rootNode);
            targetNode = new Y.Node(targetNode);

            rootNode.addClass('align-center-vert');
            rootNode.addClass('full-width');
            rootNode.removeClass('stacked');

            // Make entire slide non-scrollable
            contentBox.one('.sqs-slide-layer.layer-front').removeClass('scroll');
            targetNode.addClass('align-center-horiz');

            contentBox.one('.group-social').addClass('bottom-full-width');
          },
          onRespond: function(rootNode, targetNode) {
            rootNode = new Y.Node(rootNode);
            targetNode = new Y.Node(targetNode);

            rootNode.removeClass('align-center-vert');
            rootNode.removeClass('full-width');
            rootNode.addClass('stacked');

            // Make entire slide scrollable
            contentBox.one('.sqs-slide-layer.layer-front').addClass('scroll');

            targetNode.removeClass('align-center-horiz');

            contentBox.one('.group-social').removeClass('bottom-full-width');
          }
        }));
      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
