"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.TaxJurisdictionType */

/* Java class defined in repository squarespace-v6 */
var TaxJurisdictionType;

(function (TaxJurisdictionType) {
  TaxJurisdictionType["COUNTRY"] = "country";
  TaxJurisdictionType["STATE"] = "state";
  TaxJurisdictionType["COUNTY"] = "county";
  TaxJurisdictionType["DISTRICT"] = "district";
  TaxJurisdictionType["CITY"] = "city";
})(TaxJurisdictionType || (TaxJurisdictionType = {}));

var _default = TaxJurisdictionType;
exports["default"] = _default;
module.exports = exports.default;