"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.model.roles.WebsiteRole */

/* Java class defined in repository squarespace-v6 */
var WebsiteRole;

(function (WebsiteRole) {
  WebsiteRole["ADMIN"] = 1;
  WebsiteRole["BILLING"] = 2;
  WebsiteRole["REPORTING"] = 3;
  WebsiteRole["EDITOR"] = 4;
  WebsiteRole["MODERATOR"] = 6;
  WebsiteRole["COMMENTER"] = 7;
  WebsiteRole["STORE_MANAGER"] = 8;
  WebsiteRole["SCHEDULING"] = 9;
  WebsiteRole["VIEWER"] = 10;
  WebsiteRole["CAMPAIGNS_EDITOR"] = 11;
  WebsiteRole["SCHEDULING_VIEWER"] = 12;
  WebsiteRole["SCHEDULING_EDITOR"] = 13;
})(WebsiteRole || (WebsiteRole = {}));

var _default = WebsiteRole;
exports["default"] = _default;
module.exports = exports.default;