"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.CommerceEmailType */

/* Java class defined in repository squarespace-v6 */
var CommerceEmailTypes;

(function (CommerceEmailTypes) {
  CommerceEmailTypes["ORDER_CONFIRMED"] = 1;
  CommerceEmailTypes["ORDER_SHIPPED"] = 2;
  CommerceEmailTypes["ORDER_REFUNDED"] = 3;
  CommerceEmailTypes["DIGITAL_GOODS_DELIVERY"] = 4;
  CommerceEmailTypes["CONTRIBUTION_CONFIRMED"] = 5;
  CommerceEmailTypes["ABANDONED_CART"] = 6;
  CommerceEmailTypes["RESET_PASSWORD"] = 7;
  CommerceEmailTypes["CUSTOMER_WELCOME"] = 8;
  CommerceEmailTypes["PASSWORD_UPDATED"] = 10;
  CommerceEmailTypes["GIFT_CARD_DELIVERY"] = 11;
  CommerceEmailTypes["MEMBER_WELCOME"] = 13;
  CommerceEmailTypes["RESTOCK_NOTIFICATION"] = 14;
  CommerceEmailTypes["CUSTOM_MESSAGE"] = 50;
  CommerceEmailTypes["BASE_TEMPLATE"] = 51;
})(CommerceEmailTypes || (CommerceEmailTypes = {}));

var _default = CommerceEmailTypes;
exports["default"] = _default;
module.exports = exports.default;