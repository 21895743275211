"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SocialSearchReferenceType */

/* Java class defined in repository squarespace-v6 */
var SocialSearchReferenceType;

(function (SocialSearchReferenceType) {
  SocialSearchReferenceType["PAGE"] = 1;
  SocialSearchReferenceType["BLOG_POST"] = 2;
  SocialSearchReferenceType["TEMPLATE_INSTALLATION"] = 3;
  SocialSearchReferenceType["SLIDE"] = 4;
})(SocialSearchReferenceType || (SocialSearchReferenceType = {}));

var _default = SocialSearchReferenceType;
exports["default"] = _default;
module.exports = exports.default;