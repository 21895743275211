"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.util.search.aggregations.AggregationTypes */

/* Java class defined in repository cms-legacy-data */
var AggregationTypes;

(function (AggregationTypes) {
  AggregationTypes["AUTHOR"] = "author";
  AggregationTypes["YEAR"] = "year";
  AggregationTypes["MONTH"] = "month";
  AggregationTypes["DAY"] = "day";
  AggregationTypes["CATEGORY"] = "category";
  AggregationTypes["TAG"] = "tag";
})(AggregationTypes || (AggregationTypes = {}));

var _default = AggregationTypes;
exports["default"] = _default;
module.exports = exports.default;