import { t } from 'shared/i18n';
/**
 * The module containing the Squarespace.SlideRendering.Slices.Password class.
 *
 * @module squarespace-slide-rendering-slices-password
 */
YUI.add('squarespace-slide-rendering-slices-password', function (Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * The slice widget for slices of type SliceType.PASSWORD.
   *
   * @class Password
   * @constructor
   * @namespace Squarespace.SlideRendering.Slices
   * @extends Squarespace.SlideRendering.Slices.Base
   */
  var Password =
  Y.Squarespace.SlideRendering.Slices.Password = Y.Base.create('Password',
  Y.Squarespace.SlideRendering.Slices.Base,
  [],
  {
    renderUI: function () {
      // Focus the password input on initial render unless ios safari
      if (!(Y.UA.ios && Y.UA.safari)) {
        this.get('contentBox').one('input[type="password"]').focus();
      }
    },

    bindUI: function () {
      Password.superclass.bindUI.call(this);

      var contentBox = this.get('contentBox');
      var input = contentBox.one('input[type="password"]');
      var formNode = contentBox.one('.password-form');

      input.after('valuechange', function () {
        this._hideErrorMessage();
      }, this);

      formNode.on('submit', function (e) {
        e.preventDefault();
        this._hideErrorMessage();
        this._authenticate();
      }, this);
    },

    _authenticate: function () {
      // If the user is logged in and editing their lock screen, we don't
      // want the input to ever try and authenticate.
      if (Y.Squarespace.Utils.isInDamaskFrame()) {
        this._showErrorMessage(t("Log out to test."));


        return;
      }

      var input = this.get('contentBox').one('input[type="password"]');
      var bodyId = Y.one('body').get('id');
      var url = Y.Squarespace.API_ROOT + 'auth/visitor';
      var data = { password: input.get('value') };

      if (bodyId.indexOf('collection-') !== -1) {
        url += '/collection';
        data.collectionId = bodyId.split('collection-')[1];
      } else {
        url += '/site';
      }

      Y.Data.post({
        headers: { 'Content-Type': 'application/json' },
        url: url,
        data: Y.JSON.stringify(data),
        success: function () {
          window.location.reload(true);
        },
        failure: function () {
          this._showErrorMessage(t("Please try again."));


          this.get('contentBox').one('input[type="password"]').focus();
        } },
      this);
    },

    _showErrorMessage: function (message) {
      var contentBox = this.get('contentBox');

      contentBox.one('.error-message').setHTML(message);
      contentBox.addClass('show-error');
    },

    _hideErrorMessage: function () {
      this.get('contentBox').removeClass('show-error');
    } },

  {
    SLICE_TYPE: Y.Squarespace.SliceType.PASSWORD,
    CSS_PREFIX: 'sqs-slice-password' });


}, '1.0', {
  requires: [
  'base',
  'squarespace-enum',
  'squarespace-slide-rendering-slices-base',
  'squarespace-util'] });