import MapView from '@sqs/site-rendering/Maps/MapView';
import Styles from '@sqs/site-rendering/Maps/constants/styles';

/**
 * The module containing the Squarespace.SlideRendering.Slices.Map class.
 *
 * @module squarespace-slide-rendering-slices-map
 */
YUI.add('squarespace-slide-rendering-slices-map', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * The slice widget for slices of type MAP.
   *
   * @class Map
   * @constructor
   * @namespace Squarespace.SlideRendering.Slices
   * @extends Squarespace.SlideRendering.Slices.Base
   */
  var Map =
  Y.Squarespace.SlideRendering.Slices.Map = Y.Base.create('Map',
    Y.Squarespace.SlideRendering.Slices.Base,
    [Y.Squarespace.SlideRendering.Slices.ClientRefresh],
    {
      initializer: function() {
        this._events = [];
      },

      destructor: function() {
        this._events.forEach(function(event) {
          event.detach();
        });

        this._events = null;
      },

      renderUI: function() {
        Map.superclass.renderUI.call(this);
        this._createMap();
      },

      bindUI: function() {
        Map.superclass.bindUI.call(this);

        this._events.push(
          Y.Global.on('tweak:change', this._onTweakChange, this));

        this._events.push(
          Y.Global.on('tweak:reset', this._syncMapStyle, this));

        this._events.push(
          Y.one(Y.config.win).on('resize', this._centerMarker, this));
      },

      /**
       * Callback to all tweak changes. Updates the map's style
       * only if the map style tweak changes.
       *
       * @method _onTweakChange
       * @private
       * @param {Evemt} e
       */
      _onTweakChange: function(e) {
        if (e.getName() === 'map-style') {
          this._syncMapStyle();
        }
      },

      /**
       * Updates the map's style and marker.
       *
       * @method _syncMapStyle
       * @private
       */
      _syncMapStyle: function() {

        var map = this.get('map');

        map.updateUIOptions({
          style: this._getMapStyle()
        });
        this._centerMarker();

      },

      /**
       * Creates the map and renders it.
       *
       * @method _createMap
       * @private
       */
      _createMap: function() {
        var map = this.get('mapContainer');
        var raw = map.getAttribute('data-location');
        var location = Y.JSON.parse(raw);

        this._renderLocation(location);
      },

      _getMapStyle: function() {
        var selector = this.get('slideSelector') + ' .sqs-slide-container';
        var classList = Y.one(selector).get('className');
        var style;

        if (classList.indexOf('minimal-dark') !== -1) {
          style = Styles.MinimalDark;
        } else if (classList.indexOf('minimal-blue') !== -1) {
          style = Styles.MinimalBlue;
        } else {
          style = Styles.MinimalLight;
        }

        return style;
      },

      /**
       * Renders a location within the map.
       *
       * @method _renderLocation
       * @param {Node} node
       * @param {Object} location
       */
      _renderLocation: function(location) {
        var node = this.get('mapContainer');

        var filtered = {
          mapZoom: location.mapZoom,
          mapLat: location.mapLat,
          mapLng: location.mapLng,
          markerLat: location.markerLat,
          markerLng: location.markerLng
        };

        var ui = {
          draggable: false,
          scrollwheel: false,
          showLabels: false,
          showControls: false,
          style: this._getMapStyle()
        };

        MapView.createView(ui, filtered, node.getDOMNode())
          .then(Y.bind(function(map) {
            this.set('map', map);
            map.centerMarker();
          }, this), function() {
            if (__DEV__) {
              console.log('Could not load Maps');
            }
          });
      },

      _centerMarker: function() {
        this.get('map').centerMarker();
      },

      /**
       * Empties the widget's contentBox and rebuilds the inner map node.
       *
       * @method _rebuildContent
       * @private
       * @returns {Node} The constructed inner node.
       */
      _rebuildContent: function() {
        this.get('mapContainer').remove(true);

        var newMap = Y.Node.create('<div class="sqs-slice-content-map"></div>');

        this.get('contentBox').appendChild(newMap);
        this.set('mapContainer', newMap);
      },

      // Override
      refreshFromSliceContent: function(sliceContent) {
        var location = sliceContent.get('location').toJSON();

        this._rebuildContent();
        this._renderLocation(location);
      }
    },
    {
      SLICE_TYPE: Y.Squarespace.SliceType.MAP,
      CSS_PREFIX: 'sqs-slice-map',
      ATTRS: {
        /**
         * The node in which the map will be rendered.
         *
         * @attribute map
         * @type Node
         * @default Generated by the HTML_PARSER.
         * @writeOnce
         */
        map: {
          value: null,
          validator: Y.Squarespace.AttrValidators.isNullOrInstanceOf(MapView)
        },

        mapContainer: {
          value: null,
          validator: Y.Squarespace.AttrValidators.isNullOrNode
        }
      },
      HTML_PARSER: {
        mapContainer: '.sqs-slice-content-map'
      }
    }
  );

}, '1.0', {
  requires: [
    'base',
    'squarespace-attr-validators',
    'squarespace-enum',
    'squarespace-slide-rendering-slices-base',
    'squarespace-slide-rendering-slices-client-refresh'
  ]
});
