import {
  BoundaryRatios as ResponsiveBoundaryRatios,
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.ProfileLeftRight01 class.
 *
 * @module squarespace-slide-rendering-layouts-profile-left-right-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-profile-left-right-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for profile-left-right-01.
   *
   * @class ProfileLeftRight01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.ProfileLeftRight01 = Y.Base.create('ProfileLeftRight01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var copyNode = contentBox.querySelector('.group-copy');
        var responsiveWrapperNode = contentBox.querySelector('.responsive-wrapper');

        var flexboxSupported = !!Y.one('html.flexbox');

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          minFontSize: 26,
          widthRatio: 0.99
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          widthBoundaryNode: copyNode,
          widthRatio: 1,
          scale: 0.99,
          minFontSize: 16
        }));

        // Actions and Form font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: responsiveWrapperNode,
          fontSizeNodes: [
            contentBox.querySelector(('[data-compound-type="action"] ul')),
            contentBox.querySelector('[data-slice-type="custom-form"]')
          ],
          minFontSize: 10,
          widthRatio: 0.9,
          onReset: function() {
            responsiveWrapperNode.classList.remove('stacked');
          },
          onMinFontSize: function() {
            responsiveWrapperNode.classList.add('stacked');
          }
        }));

        // Social Icons boundary detection
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: contentBox.querySelector('[data-slice-type="social-icons"]:not([data-content-empty="true"])'),
          widthBoundaryNode: copyNode,
          widthRatio: 0.7,
          onReset: function(rootNode) {
            rootNode.classList.remove('wrapped');
            if (rootNode.querySelector('.break')) {
              rootNode.querySelector('.break').remove();
            }
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('wrapped');
            // Social icons wrap onto two lines
            var icons = rootNode.childNodes;
            if (!rootNode.querySelector('.break')) {
              var item = new Y.Node(icons.item(Math.floor(icons.length / 2)));
              item.insert('<br class="break">', 'before');
            }
          }
        }));

        // Social icons wrapping
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: responsiveWrapperNode,
          widthRatio: 0.9,
          onReset: function(rootNode, SUPPORTS_FLEXBOX) {
            rootNode.classList.remove('stacked');
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('stacked');
          }
        }));

        // Overlap between copy and logo first child
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: copyNode,
          targetNode: contentBox.querySelector('[data-compound-type="logo"] > *'),
          padding: 40,
          onReset: function(rootNode, targetNode) {
            if (!flexboxSupported) {
              copyNode.classList.add('align-center-vert');
            }
          },
          onRespond: function(rootNode, targetNode) {
            if (!Y.one('html.flexbox')) {
              copyNode.classList.remove('align-center-vert');
            }
          }
        }));

        // Overlap between copy node and gallery (in vertical 50/50 stacked)
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: copyNode,
          targetNode: contentBox.querySelector('.content-gallery'),
          padding: parseInt(copyNode.ownerDocument.defaultView.getComputedStyle(copyNode.parentNode).paddingTop, 10),
          onReset: function(rootNode) {
            if (!flexboxSupported) {
              rootNode.classList.add('align-center');
              rootNode.classList.remove('align-center-horiz');
            }
          },
          onRespond: function(rootNode) {
            // This should only happen when the 50/50 split stacks at 1020 width. Rather than test window.innerWidth,
            // check the floats to ensure we're inside the breakpoint in case that width changes later.
            if (copyNode.ownerDocument.defaultView.getComputedStyle(copyNode.parentNode).float !== 'left') {
              if (!flexboxSupported) {
                rootNode.classList.remove('align-center');
                rootNode.classList.add('align-center-horiz');
              }
            }
          }
        }));

        // Remove vertical centering on overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: copyNode,
          padding: Math.max(25, parseInt(getComputedStyle(contentBox)['border-top-width'], 10) * 2),
          onReset: function(rootNode) {
            rootNode.parentNode.classList.remove('scroll');
            if (!flexboxSupported) {
              copyNode.classList.add('align-center');
              copyNode.classList.remove('align-center-horiz');
            }
          },
          onRespond: function(rootNode) {
            rootNode.parentNode.classList.add('scroll');
            if (!flexboxSupported) {
              copyNode.classList.remove('align-center');
              copyNode.classList.add('align-center-horiz');
            }
          }
        }));
      }
    }
  );

}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
