"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.getty.AgeType */

/* Java class defined in repository squarespace-v6 */
var AgeTypes;

(function (AgeTypes) {
  AgeTypes["NEWBORN"] = "newborn";
  AgeTypes["BABY"] = "baby";
  AgeTypes["CHILD"] = "child";
  AgeTypes["TEENAGER"] = "teenager";
  AgeTypes["YOUNG_ADULT"] = "young_adult";
  AgeTypes["ADULT"] = "adult";
  AgeTypes["ADULTS_ONLY"] = "adults_only";
  AgeTypes["MATURE_ADULT"] = "mature_adult";
  AgeTypes["SENIOR_ADULT"] = "senior_adult";
})(AgeTypes || (AgeTypes = {}));

var _default = AgeTypes;
exports["default"] = _default;
module.exports = exports.default;