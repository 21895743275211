"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.model.roles.Permission */

/* Java class defined in repository squarespace-v6 */
var AccessPermissions;

(function (AccessPermissions) {
  AccessPermissions["AUTHENTICATED_NO_SITE_PERMISSIONS"] = 0;
  AccessPermissions["CONFIG"] = 1;
  AccessPermissions["ADMIN"] = 2;
  AccessPermissions["CONFIG_CONTENT_MANAGER"] = 11;
  AccessPermissions["CONFIG_SETTINGS"] = 12;
  AccessPermissions["CONFIG_ACTIVITY"] = 13;
  AccessPermissions["CONFIG_COMMERCE"] = 14;
  AccessPermissions["CONFIG_NEWSLETTER"] = 15;
  AccessPermissions["CONFIG_DAMASK"] = 17;
  AccessPermissions["CONFIG_CRM"] = 18;
  AccessPermissions["CONFIG_CHANGE_STRUCTURE"] = 1110;
  AccessPermissions["CONFIG_TRUSTED_COMMENTER"] = 1111;
  AccessPermissions["CONFIG_JOBS_DELETE"] = 1112;
  AccessPermissions["CONFIG_SETTINGS_GENERAL"] = 1210;
  AccessPermissions["CONFIG_SETTINGS_LOCALE"] = 1211;
  AccessPermissions["CONFIG_SETTINGS_SOCIAL_ACCOUNT"] = 1212;
  AccessPermissions["CONFIG_SETTINGS_FACEBOOK"] = 1213;
  AccessPermissions["CONFIG_SETTINGS_SOCIAL_SETTINGS"] = 1214;
  AccessPermissions["CONFIG_SETTINGS_MEMBER_ACCOUNTS"] = 1215;
  AccessPermissions["CONFIG_SETTINGS_DOMAIN"] = 1216;
  AccessPermissions["CONFIG_SETTINGS_TEMPLATE"] = 1217;
  AccessPermissions["CONFIG_SETTINGS_IMPORT"] = 1218;
  AccessPermissions["CONFIG_SETTINGS_CODEINJECT"] = 1219;
  AccessPermissions["CONFIG_SETTINGS_ADVANCED"] = 1220;
  AccessPermissions["CONFIG_SETTINGS_DEVELOPER"] = 1221;
  AccessPermissions["CONFIG_SETTINGS_SESSIONS"] = 1222;
  AccessPermissions["CONFIG_SETTINGS_MOBILE"] = 1223;
  AccessPermissions["CONFIG_SETTINGS_BILLING"] = 1224;
  AccessPermissions["CONFIG_SETTINGS_SUPPORT"] = 1225;
  AccessPermissions["CONFIG_SETTINGS_SITE"] = 1226;
  AccessPermissions["CONFIG_SETTINGS_LABS"] = 1227;
  AccessPermissions["CONFIG_SETTINGS_ADVERTISING"] = 1228;
  AccessPermissions["CONFIG_SETTINGS_USER_ACCOUNTS"] = 1229;
  AccessPermissions["CONFIG_SETTINGS_REMOVE_WEBSITE"] = 1230;
  AccessPermissions["CONFIG_SETTINGS_CIRCLE_LABS"] = 1231;
  AccessPermissions["CONFIG_SETTINGS_INTERNAL_LABS"] = 1232;
  AccessPermissions["CONFIG_SETTINGS_PROJECT_TITLE"] = 1233;
  AccessPermissions["CONFIG_ACTIVITY_STATS"] = 1310;
  AccessPermissions["CONFIG_ACTIVITY_COMMENTS"] = 1311;
  AccessPermissions["CONFIG_COMMERCE_ORDERS"] = 1410;
  AccessPermissions["CONFIG_COMMERCE_STORE_SETTINGS"] = 1411;
  AccessPermissions["CONFIG_COMMERCE_SHIPPING"] = 1412;
  AccessPermissions["CONFIG_COMMERCE_COUPONS"] = 1413;
  AccessPermissions["CONFIG_COMMERCE_TAXES"] = 1414;
  AccessPermissions["CONFIG_COMMERCE_EMAIL_NOTIFICATIONS"] = 1415;
  AccessPermissions["CONFIG_COMMERCE_INVENTORY"] = 1416;
  AccessPermissions["CONFIG_COMMERCE_DONATIONS"] = 1417;
  AccessPermissions["CONFIG_COMMERCE_ACCOUNTING"] = 1418;
  AccessPermissions["CONFIG_COMMERCE_DISCOUNTS"] = 1419;
  AccessPermissions["CONFIG_COMMERCE_PAYMENTS"] = 1420;
  AccessPermissions["CONFIG_COMMERCE_POS"] = 1421;
  AccessPermissions["CONFIG_COMMERCE_PRODUCT_REVIEWS"] = 1422;
  AccessPermissions["CONFIG_NEWSLETTER_READ_SUBSCRIBER"] = 1510;
  AccessPermissions["CONFIG_NEWSLETTER_WRITE_SUBSCRIBER"] = 1511;
  AccessPermissions["CONFIG_NEWSLETTER_READ_GROUP"] = 1512;
  AccessPermissions["CONFIG_NEWSLETTER_WRITE_GROUP"] = 1513;
  AccessPermissions["CONFIG_NEWSLETTER_READ_MESSAGE"] = 1514;
  AccessPermissions["CONFIG_NEWSLETTER_WRITE_MESSAGE"] = 1515;
  AccessPermissions["CONFIG_NEWSLETTER_READ_SETTINGS"] = 1516;
  AccessPermissions["CONFIG_NEWSLETTER_WRITE_SETTINGS"] = 1517;
  AccessPermissions["CONFIG_ACCEPT_TOS"] = 1610;
  AccessPermissions["CONFIG_DOMAINS_REMOVE"] = 1611;
  AccessPermissions["CONFIG_DOMAINS_ADD"] = 1612;
  AccessPermissions["CONFIG_CRM_COMMERCE_CUSTOMERS"] = 1810;
  AccessPermissions["CONFIG_MARKETING"] = 1910;
  AccessPermissions["CONFIG_MARKETING_CAMPAIGNS"] = 1911;
  AccessPermissions["CONFIG_MARKETING_LOCAL_LISTINGS"] = 1912;
  AccessPermissions["CONFIG_SCHEDULING"] = 1920;
  AccessPermissions["ADMIN_GRANT_ACCESS"] = 2010;
  AccessPermissions["ADMIN_BILLING_READ"] = 2011;
  AccessPermissions["ADMIN_BILLING_WRITE"] = 2012;
  AccessPermissions["ADMIN_READ_CUSTOMER_DATA"] = 2013;
  AccessPermissions["ADMIN_WRITE_CUSTOMER_DATA"] = 2014;
  AccessPermissions["ADMIN_DEV_SETTINGS"] = 2015;
  AccessPermissions["ADMIN_MARKETING_READ"] = 2016;
  AccessPermissions["ADMIN_MARKETING_WRITE"] = 2017;
  AccessPermissions["ADMIN_LOGINAS"] = 2018;
  AccessPermissions["ADMIN_TEMPLATE_SETTINGS"] = 2019;
  AccessPermissions["ADMIN_SYSTEM_JSON_VIEW"] = 2020;
  AccessPermissions["ADMIN_TAXONOMY"] = 2021;
  AccessPermissions["ADMIN_CHANGE_USER_EMAIL"] = 2022;
  AccessPermissions["ADMIN_ADD_NOTE"] = 2023;
  AccessPermissions["ADMIN_EDIT_OTHER_NOTES"] = 2024;
  AccessPermissions["ADMIN_EXTEND_TRIAL"] = 2025;
  AccessPermissions["ADMIN_BIZPIPE_STATS_MOBILE_ACCESS"] = 2026;
  AccessPermissions["ADMIN_REFERRALS_WRITE"] = 2027;
  AccessPermissions["ADMIN_BILLING_REFUND"] = 2028;
  AccessPermissions["ADMIN_BILLING_APPLY_EDU_DISCOUNT"] = 2029;
  AccessPermissions["ADMIN_DEACTIVATE_SQUARESPACE_ACCOUNT"] = 2030;
  AccessPermissions["ADMIN_BILLING_EXPIRE"] = 2031;
  AccessPermissions["ADMIN_CHANGE_MAX_COMPS"] = 2032;
  AccessPermissions["ADMIN_CHANGE_GUILDS"] = 2033;
  AccessPermissions["ADMIN_DOMAIN_WRITE"] = 2034;
  AccessPermissions["ADMIN_GOOGLE_APPS_WRITE"] = 2035;
  AccessPermissions["ADMIN_ADD_CONTRIBUTER"] = 2036;
  AccessPermissions["ADMIN_CHANGE_WEBSITE_OWNER"] = 2037;
  AccessPermissions["ADMIN_REMOVE_CONTRIBUTER"] = 2038;
  AccessPermissions["ADMIN_TAXATION_READ"] = 2039;
  AccessPermissions["ADMIN_SUSPEND"] = 2040;
  AccessPermissions["ADMIN_TAXATION_WRITE"] = 2041;
  AccessPermissions["ADMIN_LOGINAS_PUBLIC"] = 2042;
  AccessPermissions["ADMIN_MASS_REMOVAL"] = 2043;
  AccessPermissions["ADMIN_REMOVE_SITE"] = 2044;
  AccessPermissions["ADMIN_TERMINATE_MEMBER_ACCOUNT"] = 2045;
  AccessPermissions["ADMIN_DOMAIN_REMOVE"] = 2046;
  AccessPermissions["ADMIN_GOOGLE_APPS_LINK"] = 2047;
  AccessPermissions["ADMIN_REFERRALS_READ"] = 2048;
  AccessPermissions["ADMIN_SURVEYS"] = 2049;
  AccessPermissions["ADMIN_REPORTING_CREDIT_TILL"] = 2050;
  AccessPermissions["ADMIN_TWO_FACTOR"] = 2051;
  AccessPermissions["ADMIN_READ_IDENTIFIABLE_CUSTOMER_DATA"] = 2052;
  AccessPermissions["ADMIN_BILLING_CHANGE_PLAN"] = 2053;
  AccessPermissions["ADMIN_CAMPAIGNS_REVIEW_EMAIL"] = 2054;
  AccessPermissions["ADMIN_PRESERVE_MEMBER_ACCOUNT"] = 2055;
  AccessPermissions["ADMIN_LOCK_MEMBER_ACCOUNT"] = 2056;
  AccessPermissions["ADMIN_REDACT_SITE_USERS"] = 2057;
  AccessPermissions["ADMIN_BILLING_MIGRATE_RIPLEY"] = 2058;
  AccessPermissions["ADMIN_ENTERPRISE"] = 2059;
  AccessPermissions["ADMIN_BILLING_ADD_CREDIT"] = 2060;
  AccessPermissions["ADMIN_FRAUD_DETECTION_READ"] = 2061;
  AccessPermissions["ADMIN_FRAUD_DETECTION_WRITE"] = 2062;
  AccessPermissions["ADMIN_FRAUD_DETECTION_SEND_EMAIL"] = 2063;
  AccessPermissions["ADMIN_INTERNAL_CAMPAIGNS_SENDER"] = 2064;
  AccessPermissions["SITEL_DENY_TEST_TOGGLE"] = 2065;
  AccessPermissions["ADMIN_BILLING_DELETE_PAYMENT_METHOD"] = 2066;
  AccessPermissions["ADMIN_EXTENSIONS_CONSOLE"] = 2067;
  AccessPermissions["ADMIN_EXTENSIONS_CONSOLE_INTERNAL_CLIENTS"] = 2068;
})(AccessPermissions || (AccessPermissions = {}));

var _default = AccessPermissions;
exports["default"] = _default;
module.exports = exports.default;