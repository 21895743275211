"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.business.commerce.discount.DiscountStatus */

/* Java class defined in repository squarespace-v6 */
var DiscountStatus;

(function (DiscountStatus) {
  DiscountStatus["ALL"] = "ALL";
  DiscountStatus["ACTIVE"] = "ACTIVE";
  DiscountStatus["SCHEDULED"] = "SCHEDULED";
  DiscountStatus["EXPIRED"] = "EXPIRED";
})(DiscountStatus || (DiscountStatus = {}));

var _default = DiscountStatus;
exports["default"] = _default;
module.exports = exports.default;