"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.GettyError */

/* Java class defined in repository squarespace-v6 */
var GettyError;

(function (GettyError) {
  GettyError["IMAGE_NOT_AVAILABLE"] = "This image is no longer available to license. Your card was not charged.";
})(GettyError || (GettyError = {}));

var _default = GettyError;
exports["default"] = _default;
module.exports = exports.default;