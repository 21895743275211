"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.DomainSubscriptionState */

/* Java class defined in repository squarespace-v6 */
var DomainSubscriptionState;

(function (DomainSubscriptionState) {
  DomainSubscriptionState["ACTIVE"] = 1;
  DomainSubscriptionState["EXPIRED"] = 2;
  DomainSubscriptionState["PASTDUE"] = 3;
  DomainSubscriptionState["PENDING"] = 4;
})(DomainSubscriptionState || (DomainSubscriptionState = {}));

var _default = DomainSubscriptionState;
exports["default"] = _default;
module.exports = exports.default;