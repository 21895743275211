"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.business.pinterest.PinterestDomainVerificationState */

/* Java class defined in repository cms-legacy-data */
var PinterestDomainVerificationState;

(function (PinterestDomainVerificationState) {
  PinterestDomainVerificationState["NONE"] = 1;
  PinterestDomainVerificationState["PENDING"] = 2;
  PinterestDomainVerificationState["PROCESSED"] = 3;
  PinterestDomainVerificationState["SUCCEEDED"] = 4;
  PinterestDomainVerificationState["FAILED"] = 5;
  PinterestDomainVerificationState["USER_PROFILE_CONFIG_PROBLEM"] = 6;
})(PinterestDomainVerificationState || (PinterestDomainVerificationState = {}));

var _default = PinterestDomainVerificationState;
exports["default"] = _default;
module.exports = exports.default;