"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.TemplateErrorType */

/* Java class defined in repository cms-legacy-data */
var TemplateErrorType;

(function (TemplateErrorType) {
  TemplateErrorType["JSON_DESERIALIZE"] = 1;
  TemplateErrorType["JSON_PROPERTY_MAP"] = 2;
  TemplateErrorType["CSS_COMPILE"] = 3;
  TemplateErrorType["JSON_TEMPLATE"] = 4;
  TemplateErrorType["CSS_TWEAK_PARSE"] = 5;
  TemplateErrorType["LOCALIZE_STRING_PARSE"] = 6;
  TemplateErrorType["SECTION_DEFINITION"] = 7;
})(TemplateErrorType || (TemplateErrorType = {}));

var _default = TemplateErrorType;
exports["default"] = _default;
module.exports = exports.default;