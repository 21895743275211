"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.WebsiteType */

/* Java class defined in repository squarespace-v6 */
var WebsiteType;

(function (WebsiteType) {
  WebsiteType["CLASSIC"] = 1;
  WebsiteType["COVER_PAGE"] = 2;
  WebsiteType["PARKING_PAGE"] = 3;
  WebsiteType["COMMERCE"] = 4;
  WebsiteType["ENTERPRISE"] = 5;
})(WebsiteType || (WebsiteType = {}));

var _default = WebsiteType;
exports["default"] = _default;
module.exports = exports.default;