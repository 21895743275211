/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.LandingRightLeft01 class.
 *
 * @module squarespace-slide-rendering-layouts-landing-right-left-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-landing-right-left-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for landing-right-left-01.
   *
   * @class LandingRightLeft01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.LandingRightLeft01 = Y.Base.create('LandingRightLeft01',
    Y.Squarespace.SlideRendering.Layouts.LandingLeftRight01,
    [],
    {}
  );

}, '1.0', {
  requires: [
    'base',
    'squarespace-slide-rendering-layouts-landing-left-right-01'
  ]
});