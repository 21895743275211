"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.BillingProductType */

/* Java class defined in repository squarespace-v6 */
var BillingProductTypes;

(function (BillingProductTypes) {
  BillingProductTypes["WEBSITE"] = "website";
  BillingProductTypes["DOMAIN"] = "domain";
  BillingProductTypes["GOOGLE_APPS"] = "google-apps";
  BillingProductTypes["GETTY_IMAGE"] = "getty-image";
  BillingProductTypes["CAMPAIGNS"] = "campaigns";
  BillingProductTypes["SCHEDULING"] = "scheduling";
  BillingProductTypes["MEMBER_AREAS"] = "member-areas";
  BillingProductTypes["SHIPPING_LABEL"] = "shipping-label";
})(BillingProductTypes || (BillingProductTypes = {}));

var _default = BillingProductTypes;
exports["default"] = _default;
module.exports = exports.default;