"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.BillingErrorType */

/* Java class defined in repository squarespace-v6 */
var BillingError;

(function (BillingError) {
  BillingError["CARD_DECLINED"] = 1;
  BillingError["FULFILLMENT_FAILED"] = 2;
  BillingError["CHARGE_FAILED"] = 3;
  BillingError["STRONG_AUTHENTICATION_FAILED"] = 4;
})(BillingError || (BillingError = {}));

var _default = BillingError;
exports["default"] = _default;
module.exports = exports.default;