"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.business.components.domains.transfer.availability.TransferAvailability */

/* Java class defined in repository squarespace-v6 */
var TransferAvailability;

(function (TransferAvailability) {
  TransferAvailability["AVAILABLE"] = "AVAILABLE";
  TransferAvailability["UNSUPPORTED_TLD"] = "UNSUPPORTED_TLD";
  TransferAvailability["DOMAIN_EXISTS"] = "DOMAIN_EXISTS";
  TransferAvailability["SQUARESPACE_MANAGED"] = "SQUARESPACE_MANAGED";
  TransferAvailability["LINKED_ON_ANOTHER_SITE"] = "LINKED_ON_ANOTHER_SITE";
  TransferAvailability["TRANSFER_IN_PROGRESS"] = "TRANSFER_IN_PROGRESS";
  TransferAvailability["NOT_REGISTERED"] = "NOT_REGISTERED";
  TransferAvailability["NEW_REGISTRATION"] = "NEW_REGISTRATION";
  TransferAvailability["LOCKED"] = "LOCKED";
  TransferAvailability["TRADE_LOCK"] = "TRADE_LOCK";
  TransferAvailability["EXCEEDS_MAX_PERIOD"] = "EXCEEDS_MAX_PERIOD";
  TransferAvailability["PREMIUM"] = "PREMIUM";
  TransferAvailability["UNKNOWN"] = "UNKNOWN";
  TransferAvailability["PROTECTED_KEYWORD"] = "PROTECTED_KEYWORD";
  TransferAvailability["WITHIN_EXPIRATION_PERIOD"] = "WITHIN_EXPIRATION_PERIOD";
})(TransferAvailability || (TransferAvailability = {}));

var _default = TransferAvailability;
exports["default"] = _default;
module.exports = exports.default;