import { t } from 'shared/i18n';
/**
 * The module containing the Squarespace.Models.SliceDemoNewsletterForm class.
 *
 * @module squarespace-models-slice-demo-newsletter-form
 * @author dbarber
 */
YUI.add('squarespace-models-slice-demo-newsletter-form', function (Y) {

  Y.namespace('Squarespace.Models');

  /**
   * The model used for the demo newsletter form for slices.
   *
   * @namespace Squarespace.Models
   * @class     SliceDemoNewsletterForm
   * @extends   Squarespace.Models.Form
   */
  Y.Squarespace.Models.SliceDemoNewsletterForm = Y.Base.create('SliceDemoNewsletterForm',
  Y.Squarespace.Models.Form,
  [],
  {},
  {

    ATTRS: {
      /* @override */
      type: {
        value: Y.Squarespace.FormType.SLIDE },


      /* @override */
      name: {
        value: t("Newsletter Form") },


      /* @override */
      fields: {
        value: [
        {
          type: 'email',
          id: 'overlayNewsletterEmail',
          locked: true,
          title: t("Email Address"),
          description: '',
          placeholder: t("Email Address"),
          required: true,
          email: true }] },




      /* @override */
      disclaimerMessage: {
        value: {
          html: t("We respect your privacy.") },

        setter: function (disclaimerMessage) {
          // Submission messages somehow started being stored as stringified
          // wysiwyg configs and should be parsed when being set on the
          // client.
          if (Y.Lang.isString(disclaimerMessage)) {
            return Y.JSON.parse(disclaimerMessage);
          }

          return disclaimerMessage;
        } } } });





}, '1.0', {
  requires: [
  'base',
  'squarespace-enum',
  'squarespace-models-form'] });