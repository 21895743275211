import {
  BoundaryRatios as ResponsiveBoundaryRatios,
  FontSize as ResponsiveFontSize,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.LandingFullLeft02 class.
 *
 * @module squarespace-slide-rendering-layouts-landing-full-left-02
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-landing-full-left-02', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for landing-full-left-02.
   *
   * @class LandingFullLeft02
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.LandingFullLeft02 = Y.Base.create('LandingFullLeft02',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var contentNode = contentBox.querySelector('.layer-front .sqs-slide-layer-content');
        var groupCopyNode = contentBox.querySelector('.group-copy');
        var responsiveWrapperNode = contentBox.querySelector('.responsive-wrapper');

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthRatio: 1,
          scale: 0.99,
          minFontSize: 18
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          widthRatio: 1,
          heightRatio: 0.15,
          scale: 0.99,
          minFontSize: 14,
          heightBoundaryNode: contentBox.querySelector('.group-copy')
        }));

        // Actions and custom form font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: responsiveWrapperNode,
          fontSizeNodes: [
            contentBox.querySelector(('[data-compound-type="action"] ul')),
            contentBox.querySelector('[data-slice-type="custom-form"]')
          ],
          widthBoundaryNode: contentNode,
          minFontSize: 12
        }));


        // Social Icons boundary detection
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: contentBox.querySelector('[data-slice-type="social-icons"]:not([data-content-empty="true"])'),
          widthBoundaryNode: contentNode,
          widthRatio: 0.8,
          onReset: function(rootNode) {
            rootNode.classList.remove('wrapped');
            if (rootNode.querySelector('.break')) {
              rootNode.querySelector('.break').remove();
            }
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('wrapped');
            // Social icons wrap onto two lines
            var icons = rootNode.childNodes;
            if (!rootNode.querySelector('.break')) {
              var item = new Y.Node(icons.item(Math.floor(icons.length / 2)));
              item.insert('<br class="break">', 'before');
            }
          }
        }));

        // Responsive wrapper stacking
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: responsiveWrapperNode,
          widthRatio: 0.99,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('stacked');
          }
        }));

        // Overlap between copy and social icons responsive wrapper
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: groupCopyNode,
          targetNode: responsiveWrapperNode,
          padding: 40,
          onReset: function(rootNode, targetNode) {
            rootNode.classList.add('align-center-vert');
            rootNode.classList.remove('stacked');

            // Make entire slide non-scrollable
            contentNode.parentNode.classList.remove('scroll');

            targetNode.parentNode.classList.add('bottom-full-width');
          },
          onRespond: function(rootNode, targetNode) {
            rootNode.classList.remove('align-center-vert');
            rootNode.classList.add('stacked');

            // Make entire slide scrollable
            contentNode.parentNode.classList.add('scroll');

            targetNode.parentNode.classList.remove('bottom-full-width');
          }
        }));
      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
