"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.OverlayTrigger */

/* Java class defined in repository squarespace-v6 */
var OverlayTrigger;

(function (OverlayTrigger) {
  OverlayTrigger["TIME"] = 1;
  OverlayTrigger["SCROLL"] = 2;
})(OverlayTrigger || (OverlayTrigger = {}));

var _default = OverlayTrigger;
exports["default"] = _default;
module.exports = exports.default;