"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.googleapps.SubscriptionStatus */

/* Java class defined in repository squarespace-v6 */
var GoogleAppsSubscriptionStatus;

(function (GoogleAppsSubscriptionStatus) {
  GoogleAppsSubscriptionStatus["ACTIVE"] = 1;
  GoogleAppsSubscriptionStatus["PENDING"] = 2;
  GoogleAppsSubscriptionStatus["CANCELLED"] = 3;
  GoogleAppsSubscriptionStatus["SUSPENDED"] = 4;
})(GoogleAppsSubscriptionStatus || (GoogleAppsSubscriptionStatus = {}));

var _default = GoogleAppsSubscriptionStatus;
exports["default"] = _default;
module.exports = exports.default;