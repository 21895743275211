"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.IndexType */

/* Java class defined in repository cms-legacy-data */
var IndexType;

(function (IndexType) {
  IndexType["STACKED"] = "stacked";
  IndexType["GRID"] = "grid";
  IndexType["FULL_URL"] = "full-url";
})(IndexType || (IndexType = {}));

var _default = IndexType;
exports["default"] = _default;
module.exports = exports.default;