import {
  BoundaryRatios as ResponsiveBoundaryRatios,
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the Squarespace.SlideRendering.Layouts.AudioFullRight01
 * class.
 *
 * @module squarespace-slide-rendering-layouts-audio-full-right-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-audio-full-right-01', function(Y) {

  var getSlideLayer = function(rootNode) {
    return (new Y.Node(rootNode)).ancestor('.sqs-slide-layer').getDOMNode();
  };

  var enableScroll = function(rootNode) {
    getSlideLayer(rootNode).classList.add('scroll');
  };

  var disableScroll = function(rootNode) {
    getSlideLayer(rootNode).classList.remove('scroll');
  };

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for audio-full-right-01.
   *
   * @class AudioFullRight01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.AudioFullRight01 = Y.Base.create('AudioFullRight01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var copyGroupNode = contentBox.querySelector('.group-copy');
        var actionsGroupNode = copyGroupNode.querySelector('.responsive-wrapper');

        // Jira SIX-17483: slices no longer pass parameters so DOM manipulation
        // necessary to maintain the expected layout.
        var tracks = Array.prototype.slice.call(contentBox.querySelectorAll('.track'));
        tracks.forEach(function(item, index, array) {
          item.insertBefore(
            item.querySelector('.track-title'),
            item.querySelector('.track-progress-bar')
          );
        });

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthRatio: 0.98,
          minFontSize: 12
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          widthRatio: 0.98,
          minFontSize: 15
        }));

        // Actions and custom form font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: actionsGroupNode,
          widthBoundaryNode: copyGroupNode,
          fontSizeNodes: [
            contentBox.querySelector(('[data-compound-type="action"] ul')),
            contentBox.querySelector('[data-slice-type="custom-form"]')
          ],
          minFontSize: 10,
          widthRatio: 0.9,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
          },
          onMinFontSize: function(rootNode) {
            rootNode.classList.add('stacked');
          }
        }));

        // Social icons wrapping
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: contentBox.querySelector('[data-slice-type="social-icons"]:not([data-content-empty="true"])'),
          widthRatio: 0.9,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
            if (rootNode.querySelector('.break')) {
              rootNode.querySelector('.break').remove();
            }
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('stacked');
            // Social icons wrap onto two lines
            var icons = rootNode.childNodes;
            if (!rootNode.querySelector('.break')) {
              var item = new Y.Node(icons.item(Math.floor(icons.length / 2)));
              item.insert('<br class="break">', 'before');
            }
          }
        }));

        // Logo and copy Overlap Detection
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('.group-logo'),
          targetNode: copyGroupNode,
          padding: 20,
          onReset: function(rootNode, targetNode) {
            disableScroll(targetNode);
            rootNode.classList.add('top-full-width');
            targetNode.classList.add('align-center-vert');
          },
          onRespond: function(rootNode, targetNode) {
            enableScroll(targetNode);
            rootNode.classList.remove('top-full-width');
            targetNode.classList.remove('align-center-vert');
          }
        }));

        // Social and copy Overlap Detection
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('[data-slice-type="social-icons"]:not([data-content-empty="true"])'),
          targetNode: copyGroupNode,
          padding: 50,
          onReset: function(rootNode, targetNode) {
            rootNode.parentNode.classList.add('bottom-full-width');
            targetNode.classList.add('align-center-vert');
          },
          onRespond: function(rootNode, targetNode) {
            rootNode.parentNode.classList.remove('bottom-full-width');
            targetNode.classList.remove('align-center-vert');
          }
        }));

        // Social and actions Overlap Detection
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('[data-slice-type="social-icons"]:not([data-content-empty="true"])'),
          targetNode: actionsGroupNode,
          padding: 50,
          onReset: function(rootNode, targetNode) {
            rootNode.parentNode.classList.add('bottom-full-width');
            targetNode.parentNode.classList.add('align-center-vert');
            disableScroll(rootNode);
            contentBox.querySelector('.group-logo').classList.add('top-full-width');

          },
          onRespond: function(rootNode, targetNode) {
            rootNode.parentNode.classList.remove('bottom-full-width');
            targetNode.parentNode.classList.remove('align-center-vert');
            enableScroll(rootNode);
            contentBox.querySelector('.group-logo').classList.remove('top-full-width');
          }
        }));

        // Copy overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: copyGroupNode,
          onReset: function(rootNode) {
            disableScroll(rootNode);
            contentBox.querySelector('.group-logo').classList.add('top-full-width');
          },
          onRespond: function(rootNode) {
            enableScroll(rootNode);
            contentBox.querySelector('.group-logo').classList.remove('top-full-width');
          }
        }));

        // Social overflow
        var socialResponsiveComponent = new ResponsiveOverflowDetector({
          rootNode: contentBox.querySelector('[data-slice-type="social-icons"]:not([data-content-empty="true"])'),
          onReset: function(rootNode) {
            disableScroll(rootNode);
            contentBox.querySelector('.group-logo').classList.add('top-full-width');
          },
          onRespond: function(rootNode) {
            enableScroll(rootNode);
            contentBox.querySelector('.group-logo').classList.remove('top-full-width');
          }
        });
        this._registerResponsiveComponent(socialResponsiveComponent);
      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
