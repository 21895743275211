"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.business.commerce.order.OrderFulfillmentState */

/* Java class defined in repository squarespace-v6 */
var OrderFulfillmentStates;

(function (OrderFulfillmentStates) {
  OrderFulfillmentStates["PENDING"] = "PENDING";
  OrderFulfillmentStates["COMPLETED"] = "COMPLETED";
  OrderFulfillmentStates["CANCELLED"] = "CANCELLED";
})(OrderFulfillmentStates || (OrderFulfillmentStates = {}));

var _default = OrderFulfillmentStates;
exports["default"] = _default;
module.exports = exports.default;