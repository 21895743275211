import {
  BoundaryRatios as ResponsiveBoundaryRatios,
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
} from '@sqs/responsive';
/**
 * The module containing the Squarespace.SlideRendering.Layouts.OverlayFullCenter02
 * class.
 *
 * @module squarespace-slide-rendering-layouts-overlay-full-center-02
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-overlay-full-center-02', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for -02.
   *
   * @class OverlayFullCenter02
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.OverlayFullCenter02 = Y.Base.create('OverlayFullCenter02',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var contentNode = contentBox.querySelector('.layer-front .sqs-slide-layer-content');
        var actionsGroup = contentBox.querySelector('.actions');

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector('[data-slice-type="heading"]:not([data-compound-type]) h1'),
          heightBoundaryNode: contentBox.querySelector('.layer-front'),
          widthRatio: 1,
          heightRatio: 0.15,
          scale: 0.99,
          minFontSize: 18
        }));

        // Responsive input stacking
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: actionsGroup.querySelector('.sqs-slice[data-slice-type="newsletter"] button[type="submit"]'),
          widthBoundaryNode: actionsGroup,
          widthRatio: 0.67,
          onReset: function(rootNode) {
            actionsGroup.classList.remove('stacked');
          },
          onRespond: function(rootNode) {
            actionsGroup.classList.add('stacked');
          }
        }));

        // Scroll on overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: contentBox.querySelector('.sqs-slice-group.group-content'),
          onReset: function(rootNode) {
            rootNode.classList.add('align-center');
            rootNode.classList.add('align-center-absolute');
            contentNode.classList.remove('scroll');
          },
          onRespond: function(rootNode) {
            rootNode.classList.remove('align-center');
            rootNode.classList.remove('align-center-absolute');
            contentNode.classList.add('scroll');
          }
        }));
      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
