"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.GoogleAppsSubscriptionState */

/* Java class defined in repository squarespace-v6 */
var GoogleAppsSubscriptionState;

(function (GoogleAppsSubscriptionState) {
  GoogleAppsSubscriptionState["PENDING"] = 1;
  GoogleAppsSubscriptionState["ACTIVE"] = 2;
  GoogleAppsSubscriptionState["PASTDUE"] = 3;
  GoogleAppsSubscriptionState["SUSPENDED"] = 4;
  GoogleAppsSubscriptionState["PROCESSING"] = 5;
  GoogleAppsSubscriptionState["REACTIVATION_ASYNC_PAYMENT_PROCESSING"] = 6;
  GoogleAppsSubscriptionState["REACTIVATION_ASYNC_PAYMENT_SUCCEEDED"] = 7;
})(GoogleAppsSubscriptionState || (GoogleAppsSubscriptionState = {}));

var _default = GoogleAppsSubscriptionState;
exports["default"] = _default;
module.exports = exports.default;