import { t } from 'shared/i18n';
import { containFocus } from '@sqs/utils/focus';

/**
 * @module squarespace-modal-lightbox
 */
YUI.add('squarespace-modal-lightbox', function (Y) {
  var rootCssClass = 'sqs-modal-lightbox';

  Y.namespace('Squarespace.Widgets');

  var ModalLightbox =
  /**
   * The lightbox used to pop up forms. Used by several components, including
   * product workflows and form blocks.
   *
   * @class       ModalLightbox
   * @extends     Squarespace.Widgets.SSWidget
   * @namespace   Squarespace.Widgets
   * @constructor
   */
  Y.Squarespace.Widgets.ModalLightbox = Y.Base.create('modalLightbox', Y.Squarespace.Widgets.SSWidget,
  [],
  {
    initializer: function () {
      var contentBox = this.get('contentBox');
      contentBox.set('role', 'dialog');
      contentBox.set('aria-label', this.get('title'));

      /**
      * Fires when the modal is opened
      * @event open
      */
      this.publish('open');
      /**
      * Fires when the modal is closed
      * @event close
      */
      this.publish('close');

      this._resizeEmitter = new Y.Squarespace.ResizeEmitter({ timeout: 100 });
    },

    destructor: function () {
      this._resizeEmitter.destroy();
      Y.Squarespace.EscManager.removeTarget(this);
    },

    renderUI: function () {
      ModalLightbox.superclass.renderUI.call(this);

      this.get('boundingBox').hide();

      var innerEl =
      this._innerEl = Y.Node.create('<div class="lightbox-inner"></div>');

      innerEl.append('<div class="lightbox-content"></div>');

      this.get('contentBox').append('<div class="lightbox-background"></div>').
      append(innerEl);

      this._renderContent();

      if (this.get('openOnRender')) {
        this.open();
      }
    },

    bindUI: function () {
      ModalLightbox.superclass.bindUI.call(this);

      this.after('contentChange', function () {
        this._renderContent();
        this.syncUI();
      }, this);

      this._resizeEmitter.on('resize', this.syncUI, this);

      this.get('contentBox').delegate('click', function () {
        this.close();
      }, '.lightbox-close', this);

      this.get('contentBox').delegate('keydown', function (e) {
        // Standard button behavior is to react to Enter (keyCode == 13)
        // and Space (keyCode == 32) presses.
        if (e.keyCode === 13 || e.keyCode === 32) {
          e.preventDefault();
          this.close();
        }
      }, '.lightbox-close', this);

      // bind in scrollLock to the innerEl (it scrolls.)
      this._innerEl.plug(Y.Squarespace.Plugin.ScrollLock);
    },

    syncUI: function () {
      ModalLightbox.superclass.syncUI.call(this);
      this._setContentMarginTop();
    },

    open: function () {
      Y.one('html').addClass('sqs-modal-lightbox-open');
      if (this.get('openClassNode')) {
        this.get('openClassNode').addClass('sqs-modal-lightbox-open');
      }

      this.get('boundingBox').show();

      this._setContentBoxTop();

      this.syncUI();

      Y.Squarespace.EscManager.addTarget(this);

      this.fire('open');

      this.revertFocusContainment = containFocus({
        container: this.get('boundingBox')._node });


      // https://jira.squarespace.net/browse/CMS-37918
      this.documentScrollSubscription = Y.one('document').on('scroll', this._setContentBoxTop, this);
    },

    close: function () {
      Y.one('html').removeClass('sqs-modal-lightbox-open');
      if (this.get('openClassNode')) {
        this.get('openClassNode').removeClass('sqs-modal-lightbox-open');
      }

      this.get('boundingBox').hide();

      this.fire('close');

      Y.Squarespace.EscManager.removeTarget(this);

      if (this.revertFocusContainment) {
        this.revertFocusContainment();
      }

      if (this.documentScrollSubscription) {
        this.documentScrollSubscription.detach();
        this.documentScrollSubscription = null;
      }
    },

    _renderContent: function () {
      var contentEl = this._innerEl.one('.lightbox-content');
      var content = this.get('content');

      contentEl.empty();

      if (Y.instanceOf(content, Y.Widget)) {
        content.render(contentEl);
      } else {
        contentEl.setContent(content);
      }

      var closeElLabel = t("Close");


      contentEl.prepend(
      '<div role="button" tabindex="0" class="lightbox-close" aria-label="' +
      closeElLabel +
      '">&times;</div>');

    },

    _setContentBoxTop: function () {
      this.get('contentBox').setStyle('top', Y.one(Y.config.doc).get('scrollTop'));
    },

    _setContentMarginTop: function () {
      var contentEl = this._innerEl.one('.lightbox-content');

      var winHeight = Y.one(Y.config.win).get('winHeight');
      var contentElHeight = contentEl.get('offsetHeight');
      var contentTallerThanWindow = contentElHeight > winHeight;
      var marginTop;

      if (contentTallerThanWindow) {
        marginTop = 50;
      } else {
        marginTop = winHeight / 2 - contentElHeight / 2;
      }

      var marginBottom = this.get('hasExtraBottomMargin') ? winHeight / 2 : marginTop;

      contentEl.setStyles({
        marginTop: marginTop,
        marginBottom: marginBottom });

    } },

  {
    CSS_PREFIX: rootCssClass,
    ATTRS: {

      /**
       * @attribute content
       * @type      [type]
       */
      content: {
        value: null },


      /**
       * @attribute animDuration
       * @type      Number
       * @default 0.3
       */
      animDuration: {
        value: 0.3 },


      /**
       * @attribute openOnRender
       * @type      Boolean
       * @default false
       */
      openOnRender: {
        value: false },


      /**
       * @attribute openClassNode
       * @type      Node
       */
      openClassNode: {
        value: null },


      /**
       * @attribute title
       * @type      String
       * Title is currently only available to screen readers.
       */
      title: {
        value: '' },


      /**
       * @attribute hasExtraBottomMargin
       * @type      boolean
       * This is used to render extra margin at the bottom of the lightbox.
       * We need to add this margin when rendering a lightbox with a form on
       * iOS, or else the form is unusable. See: COM-10881
       */
      hasExtraBottomMargin: {
        value: false } } });




}, '1.0', {
  requires: [
  'base',
  'node',
  'squarespace-dom-emitters-resize',
  'squarespace-escmanager',
  'squarespace-plugin-scroll-lock',
  'squarespace-ss-widget',
  'squarespace-util'] });