"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.getty.GroupSize */

/* Java class defined in repository squarespace-v6 */
var GroupSizes;

(function (GroupSizes) {
  GroupSizes["NONE"] = "none";
  GroupSizes["ONE"] = "one";
  GroupSizes["TWO"] = "two";
  GroupSizes["GROUP"] = "group";
})(GroupSizes || (GroupSizes = {}));

var _default = GroupSizes;
exports["default"] = _default;
module.exports = exports.default;