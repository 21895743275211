import {
  BoundaryRatios as ResponsiveBoundaryRatios,
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.LandingFullTopLeft01 class.
 *
 * @module squarespace-slide-rendering-layouts-landing-full-topleft-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-landing-full-topleft-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for landing-full-topleft-01.
   *
   * @class LandingFullTopLeft01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.LandingFullTopLeft01 = Y.Base.create('LandingFullTopLeft01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var layerFront = contentBox.querySelector('.layer-front');
        var contentNode = contentBox.querySelector('.layer-front .sqs-slide-layer-content');
        var copyNode = contentBox.querySelector('.group-copy');
        var logoGroupNode = contentBox.querySelector('.group-logo');
        var logoNode = logoGroupNode.querySelector('[data-compound-type="logo"] > *');
        var formButton = contentBox.querySelector('[data-slice-type="custom-form"]');

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthRatio: 0.5,
          minFontSize: 15
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          widthRatio: 0.99,
          scale: 0.99,
          minFontSize: 18
        }));

        // Actions font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="action"] ul')),
          widthBoundaryNode: copyNode,
          minFontSize: 11,
          widthRatio: 0.8,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
            if (formButton) {
              formButton.style.fontSize = null;
            }
          },
          onRespond: function(rootNode) {
            if (formButton) {
              formButton.style.fontSize = rootNode.ownerDocument.defaultView.getComputedStyle(rootNode).fontSize;
            }
          },
          onMinFontSize: function(rootNode) {
            rootNode.classList.add('stacked');
            if (formButton) {
              formButton.classList.add('stacked');
            }
          }
        }));

        // Action/Social link group boundary detection
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: contentBox.querySelector('.sqs-slice-group.link-group-inline'),
          widthBoundaryNode: contentNode,
          widthRatio: 0.99,
          onReset: function(rootNode, widthBoundaryNode) {
            rootNode.classList.remove('stacked');
          },
          onRespond: function(rootNode, widthBoundaryNode) {
            rootNode.classList.add('stacked');
          }
        }));

        // Social Icons boundary detection
        var socialIconBoundaryComponent = new ResponsiveBoundaryRatios({
          rootNode: contentBox.querySelector('[data-slice-type="social-icons"]:not([data-content-empty="true"])'),
          widthBoundaryNode: contentNode,
          widthRatio: 0.99,
          onReset: function(rootNode, widthBoundaryNode) {
            rootNode.classList.remove('stacked');
            if (rootNode.querySelector('.break')) {
              rootNode.querySelector('.break').remove();
            }
          },
          onRespond: function(rootNode, widthBoundaryNode) {
            rootNode.classList.add('stacked');
            // Social icons wrap onto two lines
            var icons = rootNode.childNodes;
            if (!rootNode.querySelector('.break')) {
              var item = new Y.Node(icons.item(Math.floor(icons.length / 2)));
              item.insert('<br class="break">', 'before');
            }
          }
        });
        this._registerResponsiveComponent(socialIconBoundaryComponent);

        // Logo and copy Overlap Detection
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: logoNode,
          targetNode: copyNode,
          onReset: function(rootNode, targetNode) {
            rootNode = new Y.Node(rootNode);
            targetNode = new Y.Node(targetNode);

            layerFront.classList.remove('scroll');
            logoGroupNode.classList.add('bottom-full-width');
            logoGroupNode.classList.remove('stacked');
          },
          onRespond: function(rootNode, targetNode) {
            rootNode = new Y.Node(rootNode);
            targetNode = new Y.Node(targetNode);

            layerFront.classList.add('scroll');
            logoGroupNode.classList.remove('bottom-full-width');
            logoGroupNode.classList.add('stacked');
          }
        }));

        // Copy Overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: copyNode,
          padding: 20,
          onReset: function(rootNode) {
            layerFront.classList.remove('scroll');
            logoGroupNode.classList.add('bottom-full-width');
            logoGroupNode.classList.remove('stacked');
          },
          onRespond: function(rootNode) {
            layerFront.classList.add('scroll');
            logoGroupNode.classList.remove('bottom-full-width');
            logoGroupNode.classList.add('stacked');
          }
        }));

        // Logo Overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: logoNode,
          padding: 0,
          onReset: function(rootNode) {
            layerFront.classList.remove('scroll');
            logoGroupNode.classList.add('bottom-full-width');
            logoGroupNode.classList.remove('stacked');
          },
          onRespond: function(rootNode) {
            layerFront.classList.add('scroll');
            logoGroupNode.classList.remove('bottom-full-width');
            logoGroupNode.classList.add('stacked');
          }
        }));

      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
