"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.slides.SlideLayoutStatus */

/* Java class defined in repository cms-legacy-data */
var SlideLayoutStatus;

(function (SlideLayoutStatus) {
  SlideLayoutStatus["ALPHA"] = 1;
  SlideLayoutStatus["BETA"] = 2;
  SlideLayoutStatus["RELEASED"] = 3;
})(SlideLayoutStatus || (SlideLayoutStatus = {}));

var _default = SlideLayoutStatus;
exports["default"] = _default;
module.exports = exports.default;