"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.BillingSubscriptionStatus */

/* Java class defined in repository squarespace-v6 */
var BillingSubscriptionStatus;

(function (BillingSubscriptionStatus) {
  BillingSubscriptionStatus["ACTIVE"] = "active";
  BillingSubscriptionStatus["CANCELED"] = "canceled";
  BillingSubscriptionStatus["PAST_DUE"] = "pastDue";
  BillingSubscriptionStatus["TRIAL"] = "trial";
  BillingSubscriptionStatus["UNPAID"] = "unpaid";
  BillingSubscriptionStatus["EXPIRED"] = "expired";
  BillingSubscriptionStatus["PENDING"] = "pending";
  BillingSubscriptionStatus["PENDING_CANCELED"] = "pending_canceled";
  BillingSubscriptionStatus["TRANSFERRING"] = "transferring";
})(BillingSubscriptionStatus || (BillingSubscriptionStatus = {}));

var _default = BillingSubscriptionStatus;
exports["default"] = _default;
module.exports = exports.default;