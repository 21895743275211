"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.business.commerce.discount.DiscountValidationErrorType */

/* Java class defined in repository squarespace-v6 */
var DiscountValidationErrorType;

(function (DiscountValidationErrorType) {
  DiscountValidationErrorType["MINIMUM_CART_TOTAL_GREATER_THAN_ZERO"] = "MINIMUM_CART_TOTAL_GREATER_THAN_ZERO";
  DiscountValidationErrorType["AT_LEAST_ONE_CATEGORY"] = "AT_LEAST_ONE_CATEGORY";
  DiscountValidationErrorType["NON_EXISTING_CATEGORY"] = "NON_EXISTING_CATEGORY";
  DiscountValidationErrorType["PRODUCT_ID_REQUIRED"] = "PRODUCT_ID_REQUIRED";
  DiscountValidationErrorType["DISCOUNT_DISABLED"] = "DISCOUNT_DISABLED";
  DiscountValidationErrorType["NON_EXISTING_PRODUCT"] = "NON_EXISTING_PRODUCT";
  DiscountValidationErrorType["NON_EXISTING_PRICING_OPTION"] = "NON_EXISTING_PRICING_OPTION";
  DiscountValidationErrorType["PRODUCT_QUANTITY_REQUIRED"] = "PRODUCT_QUANTITY_REQUIRED";
  DiscountValidationErrorType["FIXED_AMOUNT_GREATER_THAN_ZERO"] = "FIXED_AMOUNT_GREATER_THAN_ZERO";
  DiscountValidationErrorType["PERCENTAGE_INVALID"] = "PERCENTAGE_INVALID";
  DiscountValidationErrorType["TRIGGER_REQUIRED"] = "TRIGGER_REQUIRED";
  DiscountValidationErrorType["PROMO_CODE_REQUIRED"] = "PROMO_CODE_REQUIRED";
  DiscountValidationErrorType["PROMO_CODE_ALREADY_EXISTS"] = "PROMO_CODE_ALREADY_EXISTS";
  DiscountValidationErrorType["PROMO_CODE_RESERVED"] = "PROMO_CODE_RESERVED";
  DiscountValidationErrorType["START_DATE_REQUIRED"] = "START_DATE_REQUIRED";
  DiscountValidationErrorType["TEMPLATE_REQUIRED"] = "TEMPLATE_REQUIRED";
  DiscountValidationErrorType["NON_EXISTING_SHIPPING_OPTION"] = "NON_EXISTING_SHIPPING_OPTION";
  DiscountValidationErrorType["CRITERIA_REQUIRED"] = "CRITERIA_REQUIRED";
  DiscountValidationErrorType["MAX_ALLOWED_AUTO_TRIGGER_REACHED"] = "MAX_ALLOWED_AUTO_TRIGGER_REACHED";
  DiscountValidationErrorType["AUTO_TRIGGER_NOT_SUPPORTED"] = "AUTO_TRIGGER_NOT_SUPPORTED";
  DiscountValidationErrorType["NAME_REQUIRED"] = "NAME_REQUIRED";
  DiscountValidationErrorType["DISCOUNT_LIMIT_GREATER_THAN_ZERO"] = "DISCOUNT_LIMIT_GREATER_THAN_ZERO";
  DiscountValidationErrorType["LIMITED_USE_NOT_SUPPPORTED"] = "LIMITED_USE_NOT_SUPPPORTED";
  DiscountValidationErrorType["INVALID_SUBSCRIPTION_OPTIONS"] = "INVALID_SUBSCRIPTION_OPTIONS";
  DiscountValidationErrorType["UNKNOWN"] = "UNKNOWN";
})(DiscountValidationErrorType || (DiscountValidationErrorType = {}));

var _default = DiscountValidationErrorType;
exports["default"] = _default;
module.exports = exports.default;