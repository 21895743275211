/**
 * System-Wide Commerce Events
 */
enum CommerceEvent {
  CART_PAGE_PRODUCT_FORM_OPEN = 'commerce_cart_page_product_form_open',
  CART_PAGE_PRODUCT_FORM_CLOSE = 'commerce_cart_page_product_form_close',
  CART_PAGE_PRODUCT_FORM_SUBMIT = 'commerce_cart_page_product_form_submit',
  CART_PAGE_LOADED = 'commerce_cart_page_loaded',

  // Facebook Pixel Events
  CREATE_CUSTOMER_ACCOUNT = 'CompleteRegistration',
  ADD_TO_CART = 'AddToCart',
  SEARCH = 'Search',
  SUBSCRIBE_NEWSLETTER = 'Lead',
  VIEW_PRODUCT_PAGE = 'ViewContent',
  PURCHASE = 'Purchase',

  // Inventory Panel Events
  INVENTORY_PANEL_SEARCH_PRODUCTS = 'commerce_inventory_panel_search_products',
  INVENTORY_PANEL_SORT_ON_NAME = 'commerce_inventory_panel_sort_on_name',
  INVENTORY_PANEL_SORT_ON_STOCK = 'commerce_inventory_panel_sort_on_stock',
  INVENTORY_PANEL_SORT_ON_PRICE = 'commerce_inventory_panel_sort_on_price',
  INVENTORY_PANEL_CLICK_ROW_EXPANDER = 'commerce_inventory_panel_click_row_expander',
  INVENTORY_PANEL_CLICK_ROW = 'commerce_inventory_panel_click_row',
  INVENTORY_PANEL_CLICK_NEXT_PAGE = 'commerce_inventory_panel_click_next_page',
  INVENTORY_PANEL_SETTINGS_OPEN = 'commerce_inventory_panel_settings_open',
  INVENTORY_PANEL_SETTINGS_CLOSE = 'commerce_inventory_panel_settings_close',
  INVENTORY_PANEL_SETTINGS_SAVE = 'commerce_inventory_panel_settings_save',
  INVENTORY_PANEL_SETTINGS_TOGGLE_SCARCITY_PDP = 'commerce_inventory_panel_settings_toggle_scarcity_pdp',
  INVENTORY_PANEL_SETTINGS_TOGGLE_SCARCITY_BLOCKS = 'commerce_inventory_panel_settings_toggle_scarcity_blocks',
  INVENTORY_PANEL_SETTINGS_CHANGE_SCARCITY_THRESHOLD = 'commerce_inventory_panel_settings_change_scarcity_threshold',

  INSTAGRAM_PRODUCTS_FEED_URL_COPY = 'commerce_instagram_products_feed_url_copy',
  FACEBOOK_DYNAMIC_ADS_PRODUCTS_FEED_URL_COPY = 'commerce_facebook_ads_feed_url_copy',
  FACEBOOK_PIXEL_SAVE_ID = 'commerce_facebook_pixel_save_id'
}

export default CommerceEvent;
