"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.enums.MemberAccountStatus */

/* Java class defined in repository squarespace-v6 */
var MemberAccountStatus;

(function (MemberAccountStatus) {
  MemberAccountStatus["ACTIVE"] = "ACTIVE";
  MemberAccountStatus["SUSPENDED"] = "SUSPENDED";
  MemberAccountStatus["TERMINATED"] = "TERMINATED";
})(MemberAccountStatus || (MemberAccountStatus = {}));

var _default = MemberAccountStatus;
exports["default"] = _default;
module.exports = exports.default;