/*
 * This file was generated by the `create-manifests` gulp task.
 * Run `npm run gulp create-manifests` to regenerate this file.
 */
import 'src/main/webapp/universal/scripts-v6/popup-overlay.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/factory.js';
import 'src/main/webapp/universal/scripts-v6/enum.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/audio-full-center-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/parallel/parallel.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/factory.js';
import 'src/main/webapp/universal/scripts-v6/factory-utils.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/album.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/base.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/body.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/client-refresh.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/buttons.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/custom-form.js';
import 'src/main/webapp/universal/scripts-v6/async-form.js';
import 'src/main/webapp/universal/scripts-v6/ss-widget.js';
import 'src/main/webapp/universal/scripts-v6/debugger.js';
import 'src/main/webapp/universal/scripts-v6/template-helpers.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/handlebars-base/handlebars-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/substitute/substitute.js';
import 'src/main/webapp/universal/scripts-v6/ui-templates.js';
import 'src/main/webapp/universal/scripts-v6/modal-lightbox.js';
import 'src/main/webapp/universal/scripts-v6/models/slice-demo-custom-form.js';
import 'src/main/webapp/universal/scripts-v6/models/form.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/model/model.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/model-list/model-list.js';
import 'src/main/webapp/universal/scripts-v6/model-sync-rest.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/model-sync-rest/model-sync-rest.js';
import 'src/main/webapp/universal/scripts-v6/models/form-base.js';
import 'src/main/webapp/universal/scripts-v6/models/slice-demo-newsletter-form.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/gallery.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/grid-gallery.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/grid-gallery-densities.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/has-scrim.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/color-processor.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/rgb.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/heading.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/image.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/lock.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/map.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/newsletter.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/navigation.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/password.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/social-icons.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/sub-heading.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/twitter.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/slices/video.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/audio-full-left-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/audio-full-right-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/audio-left-right-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/landing-full-center-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/landing-full-center-02.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/landing-full-centercard-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/landing-full-left-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/landing-full-left-02.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/landing-full-right-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/landing-full-topleft-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/landing-left-right-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/landing-right-left-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/location-full-corners-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/location-right-left-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/lock-full-bottomleft-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/lock-full-center-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/lock-full-center-02.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/lock-full-center-03.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/lock-full-center-04.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/lock-full-center-05.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/overlay-center-card-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/overlay-center-card-02.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/overlay-center-card-03.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/overlay-center-card-04.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/overlay-center-card-05.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/overlay-corner-card-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/overlay-full-bottom-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/overlay-full-center-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/overlay-full-center-02.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/profile-center-center-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/profile-center-center-02.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/profile-full-bottom-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/profile-full-center-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/profile-full-left-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/profile-full-middleband-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/profile-full-right-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/profile-full-top-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/profile-left-left-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/profile-left-right-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/twitter-full-center-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/twitter-full-left-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/video-full-bottom-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/video-full-center-01.js';
import 'src/main/webapp/universal/scripts-v6/slide-rendering/layouts/video-full-corners-01.js';