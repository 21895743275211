// @ts-ignore
import TrackEvent from '@sqs/track-events/v0';
import buildTrackInternalPayload from './trackInternalUtil';

const trackEvent = new TrackEvent();

export default function trackInternal(
  eventName: string,
  data: any = {},
  optionalTopLevelArgs = {}
) {
  return trackEvent.track(
    eventName,
    {
      ...data,
      ...buildTrackInternalPayload()
    },
    optionalTopLevelArgs
  ).catch((err: Error) => {
    // Network errors from Record Event should be ignored
    if (__DEV__) {
      console.error(err);
    }
  });
}