"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.CensusGranularity */

/* Java class defined in repository squarespace-v6 */
var CensusGranularity;

(function (CensusGranularity) {
  CensusGranularity["HOURLY"] = 1;
  CensusGranularity["DAILY"] = 2;
  CensusGranularity["WEEKLY"] = 3;
  CensusGranularity["MONTHLY"] = 4;
  CensusGranularity["YEARLY"] = 5;
})(CensusGranularity || (CensusGranularity = {}));

var _default = CensusGranularity;
exports["default"] = _default;
module.exports = exports.default;