"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.business.commerce.payments.gateway.PaymentProviderType */

/* Java class defined in repository squarespace-v6 */
var PaymentProviderType;

(function (PaymentProviderType) {
  PaymentProviderType["STRIPE"] = 1;
  PaymentProviderType["PAYPAL"] = 2;
  PaymentProviderType["GIFT_CARD"] = 3;
  PaymentProviderType["COMPOSITE"] = 4;
  PaymentProviderType["NO_OP"] = 5;
  PaymentProviderType["CASH"] = 6;
  PaymentProviderType["SQUARE"] = 7;
  PaymentProviderType["THIRD_PARTY_CHANNEL"] = 8;
})(PaymentProviderType || (PaymentProviderType = {}));

var _default = PaymentProviderType;
exports["default"] = _default;
module.exports = exports.default;