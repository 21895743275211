"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.business.commerce.taxes.enums.PriceTaxInterpretation */

/* Java class defined in repository squarespace-v6 */
var PriceTaxInterpretations;

(function (PriceTaxInterpretations) {
  PriceTaxInterpretations["TAX_INCLUSIVE"] = "TAX_INCLUSIVE";
  PriceTaxInterpretations["TAX_EXCLUSIVE"] = "TAX_EXCLUSIVE";
})(PriceTaxInterpretations || (PriceTaxInterpretations = {}));

var _default = PriceTaxInterpretations;
exports["default"] = _default;
module.exports = exports.default;