import {
  BoundaryRatios as ResponsiveBoundaryRatios,
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.VideoFullCorners01 class.
 *
 * @module squarespace-slide-rendering-layouts-video-full-corners-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-video-full-corners-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for video-full-corners-01.
   *
   * @class VideoFullCorners01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.VideoFullCorners01 = Y.Base.create('VideoFullCorners01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var copyNode = contentBox.querySelector('.group-copy');
        var contentNode = contentBox.querySelector('.group-content');
        var linksNode = contentBox.querySelector('.group-links');
        var responsiveNodeSocial = contentBox.querySelector('.responsive-wrapper.social');

        // Branding heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthBoundaryNode: contentNode,
          minFontSize: 20,
          widthRatio: 0.99,
          onReset: function(rootNode) {
            rootNode.style.whiteSpace = 'nowrap';
          },
          onMinFontSize: function(rootNode) {
            rootNode.style.whiteSpace = 'normal';
          }
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          minFontSize: 15,
          widthBoundaryNode: contentNode,
          widthRatio: 0.99,
          onReset: function(rootNode) {
            rootNode.style.whiteSpace = 'nowrap';
          },
          onMinFontSize: function(rootNode) {
            rootNode.style.whiteSpace = 'normal';
          }
        }));

        // Actions and custom form font size
        var actionsFontSizeComponent = new ResponsiveFontSize({
          rootNode: responsiveNodeSocial,
          fontSizeNodes: [
            contentBox.querySelector(('[data-compound-type="action"] ul')),
            contentBox.querySelector('[data-slice-type="custom-form"]')
          ],
          widthBoundaryNode: contentBox.querySelector('.group-content > .sqs-slice-group:not(.group-branding)'),
          minFontSize: 10,
          widthRatio: 0.9,
          enabled: false,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
            rootNode.classList.remove('text-align-left');
          },
          onMinFontSize: function(rootNode) {
            rootNode.classList.add('stacked');
            rootNode.classList.add('text-align-left');
          }
        });
        this._registerResponsiveComponent(actionsFontSizeComponent);

        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: copyNode,
          widthBoundaryNode: contentNode,
          widthRatio: 0.99,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('stacked');
          }
        }));

        // Overlap between copy and inline link group
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: copyNode,
          targetNode: linksNode,
          padding: 60,
          onReset: function(rootNode, targetNode) {
            copyNode.classList.remove('stacked');
            var pNode = rootNode.parentNode;
            pNode.classList.remove('stacked');
            pNode.classList.remove('left-right-floats-off');
            responsiveNodeSocial.classList.remove('wrapped');
            actionsFontSizeComponent.disable();
          },
          onRespond: function(rootNode, targetNode) {
            copyNode.classList.add('stacked');
            var pNode = rootNode.parentNode;
            pNode.classList.add('stacked');
            pNode.classList.add('left-right-floats-off');
            responsiveNodeSocial.classList.remove('wrapped');
            actionsFontSizeComponent.enable();
          }
        }));

        // Social Icons boundary detection
        var socialIconBoundaryComponent = new ResponsiveBoundaryRatios({
          rootNode: contentBox.querySelector('[data-slice-type="social-icons"]:not([data-content-empty="true"])'),
          widthBoundaryNode: contentNode,
          widthRatio: 0.7,
          enabled: false,
          onReset: function(rootNode) {
            rootNode.classList.remove('wrapped');
            if (rootNode.querySelector('.break')) {
              rootNode.querySelector('.break').remove();
            }
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('wrapped');
            // Social icons wrap onto two lines
            var icons = rootNode.childNodes;
            if (!rootNode.querySelector('.break')) {
              var item = new Y.Node(icons.item(Math.floor(icons.length / 2)));
              item.insert('<br class="break">', 'before');
            }
          }
        });
        this._registerResponsiveComponent(socialIconBoundaryComponent);

        // Social Icons boundary detection
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: responsiveNodeSocial,
          widthBoundaryNode: contentNode,
          widthRatio: 0.99,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
            socialIconBoundaryComponent.disable();
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('stacked');
            socialIconBoundaryComponent.enable();
          }
        }));

        // Overlap between main content and video
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('.group-video'),
          targetNode: contentBox.querySelector('.layer-front .sqs-slide-layer-content'),
          padding: 0,
          onReset: function(rootNode, targetNode) {
            rootNode.classList.add('absolute');
            targetNode.parentNode.classList.remove('scroll');
          },
          onRespond: function(rootNode, targetNode) {
            rootNode.classList.remove('absolute');
            targetNode.parentNode.classList.add('scroll');
          }
        }));

        // Scroll on overflow
        // `padding: -1;`: An element set to `bottom: 0` could inccorectly be seen
        // as below the viewport in some browsers. This ensures it is acutally overflowing.
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: contentBox.querySelector('.sqs-slide-layer.layer-front .sqs-slide-layer-content'),
          widthRatio: 0.95,
          padding: -1,
          onReset: function(rootNode) {
            contentBox.querySelector('.sqs-slide-layer.layer-front').classList.remove('scroll');
          },
          onRespond: function(rootNode) {
            contentBox.querySelector('.sqs-slide-layer.layer-front').classList.add('scroll');
          }
        }));
      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
