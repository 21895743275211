import { VideoBackground } from '@squarespace/video-background-rendering';

/**
 * The module containing the Squarespace.SlideRendering.Slices.Gallery class.
 *
 * @module squarespace-slide-rendering-slices-gallery
 */
YUI.add('squarespace-slide-rendering-slices-gallery', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  var DEBUG = false;

  /**
   * The slice widget for slices of type GALLERY.
   *
   * @class Gallery
   * @constructor
   * @namespace Squarespace.SlideRendering.Slices
   * @extends Squarespace.SlideRendering.Slices.Base
   */
  var Gallery =
  Y.Squarespace.SlideRendering.Slices.Gallery = Y.Base.create('Gallery',
    Y.Squarespace.SlideRendering.Slices.Base,
    [Y.Squarespace.SlideRendering.Slices.HasScrim],
    {
      initializer: function() {
        this._events = [];
        this._initGallery();
      },

      destructor: function() {
        this._events.forEach(function(event) {
          event.detach();
        });
        this._events = null;

        if (this._gallery) {
          this._gallery.destroy();
        }
      },

      bindUI: function() {
        Gallery.superclass.bindUI.call(this);

        this._events.push(
          Y.Global.after('tweak:change', this._onTweakChange, this));

        this._events.push(
          Y.Global.after('slideUpdateRendered', function() {
            var galleryItems = this.get('items');
            var stack = new Y.Parallel();
            galleryItems.each(function(item) {
              var img = item.one('img');
              if (img && window.ImageLoader) {
                window.ImageLoader.load(img, true);
                img.once('load', stack.add());
              }
            }, this);
            stack.done(function() {
              this.syncUI();
            }.bind(this));
          }, this));

        if (this._gallery) {
          this._gallery.after('currentIndexChange', this._syncScrim, this);
        }

        var videoSliceSelector = this.get('slideSelector') + ' .sqs-slice[data-slice-type="video"]';
        if (this._isVideoBg() && Y.one(videoSliceSelector)) {
          this._events.push(
            Y.one(this.get('slideSelector') + ' .sqs-slice-play-button').on('click', function(e) {
              try {
                this.videoBg.player.pauseVideo();
              } catch (err) {
                if (DEBUG) {
                  console.warn(err);
                }
              }
            }, this),

            Y.Global.on('lightbox-hidden', function() {
              try {
                this.videoBg.player.playVideo();
              } catch (err) {
                if (DEBUG) {
                  console.warn(err);
                }
              }
            }, this)
          );
        }

        this._events.push(
          Y.Global.after('tweak:reset', function() {
            this.get('items').each(function(item) {
              item.setStyles({
                'background-color': null,
                'height': null,
                'width': null,
                'overflow-y': null,
                'overflow-x': null
              });
            });
            this._gallery.destroy();
            this._initGallery();
          }, this));
      },

      syncUI: function() {
        Gallery.superclass.syncUI.call(this);

        if (!this._isGrid() && !this._isVideoBg()) {
          this._gallery.refresh();
          this._syncScrim();
        } else {
          if (!this.get('boundingBox').one('.scrim')) {
            this.get('boundingBox').append('<div class="scrim"></div>');
          }
          if (this._gallery) {
            this._gallery.syncUI();
          }
        }
      },

      _initGallery: function() {
        this._removeInvalidImages();

        var items = this.get('items');
        var container = this.get('boundingBox');

        if (this._isVideoBg()) {
          var item = items.item(0).getDOMNode();
          var properties = this._getVideoPropertiesFromElement(item);
          properties.container = item;
          this.videoBg = new VideoBackground(properties, Y.config.win);
        } else if (this._isGrid()) {
          if (this.get('contentBox').ancestor('.sqs-slide').hasClass('sqs-slide-ready')) {
            this._createGrid(container, items);
          } else {
            Y.Global.on('slideReady', this._createGrid(container, items));
          }
          // todo: remove from Production code; for testing convenience only
          if (DEBUG === true) {
            window.grid = this._gallery;
          }
        } else {
          this._gallery = new Y.SQS.Gallery.Gallery2({
            container: container,
            slides: items,
            autoplay: true,
            autoplayOptions: {
              timeout: 5000
            },
            loaderOptions: {
              mode: 'fill'
            },
            refreshOnResize: true,
            loop: true,
            design: 'stacked',
            designOptions: {
              autoHeight: false,
              speed: 1,
              transition: 'fade'
            }
          });
        }
      },

      _removeInvalidImages: function() {
        var items = this.get('items');

        if (items) {
          items.each(function(el) {
            if (el.one('img') &&
                !el.hasClass('gallery-video-background') &&
                !el.hasClass('demo-image') &&
                !el.one('img').getAttribute('data-image-dimensions')
            ) {
              el.remove();
            }
          });
        }

        this.set('items', this.get('boundingBox').all('.sqs-slice-gallery-item'));
      },

      _syncScrim: function() {
        var index = this._gallery.get('currentIndex');
        var slide = this._gallery.get('slides').item(index);

        if (slide) {
          this.updateScrim(slide);
        }
      },

      _createGrid: function(container, items) {
        var selector = this.get('slideSelector') + ' .sqs-slide-container';

        this._gallery = new Y.Squarespace.SlideRendering.GridGallery({
          container: container,
          items: items,
          slideContainerSelector: selector
        });
      },

      /**
       * Is the gallery tweak set to 'grid'
       *
       * @method _isGrid
       * @type boolean
       * @default false
       */
      _isGrid: function() {
        var grid = false;
        if (Y.one(this.get('slideSelector') + ' .gallery-style-grid')) {
          grid = true;
        }
        return grid;
      },

      /**
       * Is this a video background gallery
       *
       * @method _isVideoBg
       * @type boolean
       * @default false
       */
      _isVideoBg: function() {
        var vid = false;
        if (Y.one(this.get('slideSelector') + ' [data-slice-type="gallery"] .gallery-video-background')) {
          vid = true;
        }
        return vid;
      },

      /**
       * Retrieve configuration properties from gallery item's attributes
       *
       * @method _getVideoPropertiesFromElement
       * @private
       * @param DOMNode
       */
      _getVideoPropertiesFromElement: function(el) {
        var props = {};
        if (el.getAttribute('data-config-url')) {
          props.url = el.getAttribute('data-config-url');
        }
        if (el.getAttribute('data-config-playback-speed')) {
          props.playbackSpeed = el.getAttribute('data-config-playback-speed');
        }
        if (el.getAttribute('data-config-filter')) {
          props.filter = el.getAttribute('data-config-filter');
        }
        if (el.getAttribute('data-config-filter-strength')) {
          props.filterStrength = el.getAttribute('data-config-filter-strength');
        }
        if (el.getAttribute('data-config-custom-fallback-image')) {
          props.useCustomFallbackImage = true;
        }
        return props;
      },

      /**
       * Callback to all tweak changes. Updates the gallery's style
       * only if the grid gallery style tweak changes.
       *
       * @method _onTweakChange
       * @private
       * @param {Evemt} e
       */
      _onTweakChange: function(e) {
        var tweakName = e.getName();
        var tweakValue = e.getValue();

        if (tweakName === 'gallery-style') {
          this.get('items').each(function() {
            this.getDOMNode().style.cssText = '';
            var img = this.one('img');
            img.setAttribute('src', '');
            img.setAttribute('data-image-resolution', '');
            img.setStyle('display', 'initial');
          });
          this._gallery.destroy();
          this._initGallery();
        } else if (this._isGrid()) {
          switch (tweakName) {
          case 'gallery-style':
            this.get('items').each(function() {
              this._node.style.cssText = '';
            });
            this._gallery.destroy();
            this._initGallery();
            break;
          case 'grid-gallery-density':
            this._gallery._removeClonedItems();
            this._gallery.destroy();
            this._initGallery();
            break;
          case 'grid-gallery-ratio':
            if (tweakValue !== 'Original') {
              this._gallery.set('axisDirection', 'row');
              this._gallery._removeClonedItems();
              this._gallery.destroy();
              this._initGallery();
            } else {
              this._gallery._setAxisDirection();
            }
            break;
          case 'grid-gallery-image-orientation':
            this._gallery.set('axisDirection', 'row');
            this._gallery._removeClonedItems();
            this._gallery.destroy();
            this._initGallery();
            break;
          case 'grid-gallery-direction':
            if (tweakValue === 'Horizontal') {
              this._gallery.set('axisDirection', 'row');
            } else {
              this._gallery.set('axisDirection', 'column');
            }
            break;
          case 'gallery-randomize-order':
            if (tweakValue === 'true') {
              this._gallery.set('imageOrdering', 'random');
            } else {
              this._gallery.set('imageOrdering', 'repeat');
            }
            this._gallery._removeClonedItems();
            this._gallery.destroy();
            this._initGallery();
            break;
          case 'gallery-loading-animation':
            this._gallery.previewImageLoading(tweakValue);
            break;
          }
        }

        if (this._isVideoBg() && this.videoBg) {
          this.videoBg.scaleVideo();
        }

        this.syncUI();
      }
    },
    {
      SLICE_TYPE: Y.Squarespace.SliceType.GALLERY,
      CSS_PREFIX: 'sqs-slice-gallery',
      ATTRS: {
        items: {
          value: null,
          validator: Y.Squarespace.AttrValidators.isNullOrNodeList
        }
      },
      HTML_PARSER: {
        items: ['.sqs-slice-gallery-item']
      }
    }
  );

}, '1.0', {
  requires: [
    'base',
    'squarespace-attr-validators',
    'squarespace-enum',
    'squarespace-gallery-ng',
    'squarespace-slide-rendering-grid-gallery',
    'squarespace-slide-rendering-slices-has-scrim',
    'squarespace-slide-rendering-slices-base'
  ]
});
