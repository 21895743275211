"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.api.billing.models.PaymentMethodType */

/* Java class defined in repository squarespace-v6 */
var PaymentMethodType;

(function (PaymentMethodType) {
  PaymentMethodType["CARD"] = "card";
  PaymentMethodType["POST_TAX_CREDIT"] = "post_tax_credit";
  PaymentMethodType["CUSTOMER_PAYMENT_TRANSFER"] = "customer_payment_transfer";
  PaymentMethodType["SEPA_DEBIT"] = "sepa_debit";
})(PaymentMethodType || (PaymentMethodType = {}));

var _default = PaymentMethodType;
exports["default"] = _default;
module.exports = exports.default;