"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.JobType */

/* Java class defined in repository cms-legacy-data */
var JobTypes;

(function (JobTypes) {
  JobTypes["IMAGE_PROCESSING"] = 2;
  JobTypes["IMPORTING"] = 3;
  JobTypes["PUSH"] = 4;
  JobTypes["S3"] = 6;
  JobTypes["SMTP"] = 7;
  JobTypes["DESK_TICKET"] = 8;
  JobTypes["INSTAGRAM"] = 10;
  JobTypes["SOCIAL_PULL"] = 11;
  JobTypes["FOURSQUARE"] = 12;
  JobTypes["DYNECT"] = 13;
  JobTypes["BETAEND"] = 14;
  JobTypes["EXPORTING_WORDPRESS"] = 16;
  JobTypes["EXPORTING_INTERNAL"] = 17;
  JobTypes["COLLECTION_SYNC"] = 18;
  JobTypes["CONTENT_STATS_REGENERATION"] = 19;
  JobTypes["SEARCH_REALTIME"] = 20;
  JobTypes["GENERATE_SITEMAP"] = 21;
  JobTypes["IMAGE_FOCAL_POINT"] = 22;
  JobTypes["MIGRATE_CONTENT_STATS"] = 23;
  JobTypes["DELETE_WEBSITE"] = 24;
  JobTypes["RESTORE_DELETED_WEBSITE"] = 25;
  JobTypes["STATS_MIGRATION"] = 26;
  JobTypes["STATS_COPY"] = 27;
  JobTypes["AUDIO_PROCESSING"] = 28;
  JobTypes["SEND_NEWSLETTER_MESSAGE"] = 29;
  JobTypes["PUBLISH_SCHEDULED_POST"] = 30;
  JobTypes["DISABLE_COMMENTS"] = 31;
  JobTypes["XERO_MANUAL_EXPORT"] = 32;
  JobTypes["SIGNUP"] = 33;
  JobTypes["GOOGLE_APPS_REGISTRATION"] = 34;
  JobTypes["DELINQUENT_BILLING_MAIL"] = 35;
  JobTypes["ONBOARDING_TRIAL_EMAIL"] = 36;
  JobTypes["DOMAIN_BATCH_REGISTRATION"] = 37;
  JobTypes["DOMAIN_BATCH_CONFIRMATION_EMAIL"] = 38;
  JobTypes["PINTEREST_DOMAIN_VERIFICATION_POLL"] = 39;
  JobTypes["SEND_FREE_INBOX_REMINDER"] = 40;
  JobTypes["DROPBOX2_TEMPLATE_PUSH"] = 41;
  JobTypes["CREATIVE_WELCOME_EMAIL"] = 42;
  JobTypes["MASS_REMOVAL_PARENT"] = 43;
  JobTypes["MASS_REMOVAL_SUBSCRIPTION"] = 44;
  JobTypes["IMAGE_SCALING"] = 45;
  JobTypes["GLOBAL_MIGRATION"] = 46;
  JobTypes["DOMAIN_TRANSFER_REQUEST"] = 47;
  JobTypes["PARKING_PAGE_SIGNUP"] = 48;
  JobTypes["RETRY_PAST_DUE_PARENT"] = 49;
  JobTypes["RETRY_PAST_DUE_SUBSCRIPTION"] = 50;
  JobTypes["CALLBACKS_EMAIL"] = 51;
  JobTypes["EXPORTING_INVENTORY"] = 52;
  JobTypes["CLONE_AND_TRANSLATE_SITE"] = 53;
  JobTypes["SCHEDULE_FORM_ORPHAN_DETECTION"] = 54;
  JobTypes["FULFILL_RENEWAL"] = 55;
  JobTypes["DOMAIN_TRANSFER_UPDATER"] = 56;
  JobTypes["RIPLEY_SYNC_GOOGLE_APPS_SUB"] = 57;
  JobTypes["RIPLEY_DOMAIN_BATCH_CONFIRMATION_EMAIL"] = 58;
  JobTypes["RIPLEY_DOMAIN_TRANSFER_REQUEST"] = 59;
  JobTypes["RIPLEY_GOOGLE_APPS_SUBSCRIPTION"] = 60;
  JobTypes["GOOGLE_IMPORT_CONTACTS"] = 61;
  JobTypes["RIPLEY_SIGNUP"] = 62;
  JobTypes["RIPLEY_MASS_REMOVAL_PARENT"] = 63;
  JobTypes["RIPLEY_MASS_REMOVAL_SUBSCRIPTION"] = 64;
  JobTypes["CONTENT_INJECTION_ARCHETYPE_CREATION_PARENT"] = 65;
  JobTypes["CLEANUP_SOCIAL_LINKS"] = 66;
  JobTypes["TRIAL_DELETION_EMAIL"] = 67;
  JobTypes["BULK_MANAGE_PRODUCTS"] = 68;
  JobTypes["WEBSITE_CLONE"] = 69;
  JobTypes["RIPLEY_WEBSITE_CLONE"] = 70;
  JobTypes["EXTRACT_TRANSLATABLE_STRINGS"] = 71;
  JobTypes["IMAGE_ITEM_REHOST"] = 72;
  JobTypes["NESTED_CATEGORIES_MIGRATION"] = 73;
})(JobTypes || (JobTypes = {}));

var _default = JobTypes;
exports["default"] = _default;
module.exports = exports.default;