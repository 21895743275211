import isString from 'lodash/isString';

// Keep this regex the same as the one in ValidationUtils.java.
const EMAIL_PATTERN = '^([a-zA-Z0-9_.!#$%&\'*+-/=?^_`{|}~])+\\@(([a-zA-Z0-9\\-])+\\.)+([a-zA-Z0-9]{2,4})+$';
const VALID_EMAIL_REGEX = new RegExp(EMAIL_PATTERN);

/**
 * Front-end validation for email formats. Should stay in sync with
 * ValidationUtils.java.
 *
 * @param  {String} email
 * @return {Boolean}
 */
const isValidEmail = (email) => {
  if (!isString(email)) {
    return false;
  }

  if (email.length < 3 || email.length > 256) {
    return false;
  }

  return VALID_EMAIL_REGEX.test(email);
};

export { EMAIL_PATTERN, VALID_EMAIL_REGEX, isValidEmail };
