"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.getty.GraphicalStyle */

/* Java class defined in repository squarespace-v6 */
var GraphicalStyles;

(function (GraphicalStyles) {
  GraphicalStyles["FINE_ART"] = "fine_art";
  GraphicalStyles["ILLUSTRATION"] = "illustration";
  GraphicalStyles["PHOTOGRAPHY"] = "photography";
})(GraphicalStyles || (GraphicalStyles = {}));

var _default = GraphicalStyles;
exports["default"] = _default;
module.exports = exports.default;