"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.CommerceDiscountType */

/* Java class defined in repository squarespace-v6 */
var CommerceDiscountType;

(function (CommerceDiscountType) {
  CommerceDiscountType["FLAT"] = 1;
  CommerceDiscountType["PERCENTAGE"] = 2;
  CommerceDiscountType["FREE_SHIPPING"] = 3;
})(CommerceDiscountType || (CommerceDiscountType = {}));

var _default = CommerceDiscountType;
exports["default"] = _default;
module.exports = exports.default;