"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.DNSProviderIdentifier */

/* Java class defined in repository squarespace-v6 */
var DNSProviderIdentifier;

(function (DNSProviderIdentifier) {
  DNSProviderIdentifier["OPEN_SRS"] = 1;
  DNSProviderIdentifier["NS1"] = 2;
  DNSProviderIdentifier["NS1_COLLISION_SYSTEM"] = 3;
})(DNSProviderIdentifier || (DNSProviderIdentifier = {}));

var _default = DNSProviderIdentifier;
exports["default"] = _default;
module.exports = exports.default;