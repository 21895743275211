/**
 * The module containing the Squarespace.SlideRendering.GridGalleryDensities class.
 * The grid tweak options include a "Density" setting with a range of values in
 * a dropdown: "Very Low", "Low", "Medium", "High" and "Very High". The intention
 * is to allow the user some control over how many images compose the grid without
 * giving them an explict number to select. Rather than describing the number of
 * images, though, the density refers to the target number of axis groups
 * (rows or columns) to display, based on other factors including screen size and
 * other tweak values. The grid logic then divides the available gallery size by
 * that resulting density number and uses that as the target axis height (for rows)
 * or width (for columns) and fills that axis group with an optimal number of images.

 * Screen size is the top level of the JSON with desktop as the main grouping,
 * and different maxheight and maxwidth variations for screen size. Within those
 * are the orientation of the images (horizontal for landscape, vertical for portrait
 * images, square for a 1x1 orientation), followed by the selected ratio tweak value.
 * These values refer to the resulting class names: "original" for no cropping,
 * "r21" for 2x1 aspect ratio, "r169" for 16x9, and so on. The array at that key
 * maps to the "Very Low" to "Very High" density values. In some cases, particularly
 * at the non-desktop sizes, vlaues will repeat to provide an optimal effect, resulting
 * in "Very Low" and "Low" behaving the same visually, and "High" and "Very High" the same.
 *
 * @module squarespace-slide-rendering-grid-gallery-densities
 */
YUI.add('squarespace-slide-rendering-grid-gallery-densities', function(Y) {

  Y.namespace('Squarespace.SlideRendering');

  Y.Squarespace.SlideRendering.GridGalleryDensities = {
    'desktop': {
      'orientation': {
        'horizontal': {
          'ratio': {
            'original': [3, 4, 5, 6, 7],
            'r21': [3, 4, 5, 6, 7],
            'r169': [3, 4, 5, 6, 7],
            'r32': [3, 4, 5, 6, 7],
            'r43': [3, 4, 5, 6, 7],
            'r54': [3, 4, 5, 6, 7]
          }
        },
        'vertical': {
          'ratio': {
            'original': [5, 6, 7, 8, 9],
            'r21': [2, 3, 4, 5, 6],
            'r169': [2, 3, 4, 5, 6],
            'r32': [2, 3, 4, 5, 6],
            'r43': [2, 3, 4, 5, 6],
            'r54': [2, 3, 4, 5, 6]
          }
        },
        'square': {
          'ratio': {
            'r11': [3, 4, 5, 6, 7]
          }
        }
      }
    },
    'maxWidth-800': {
      'orientation': {
        'horizontal': {
          'ratio': {
            'original': [5, 6, 7, 8, 9],
            'r21': [3, 4, 5, 6, 7],
            'r169': [3, 4, 5, 6, 7],
            'r32': [3, 4, 5, 6, 7],
            'r43': [3, 4, 5, 6, 7],
            'r54': [3, 4, 5, 6, 7]
          }
        },
        'vertical': {
          'ratio': {
            'original': [3, 4, 5, 6, 7],
            'r21': [2, 3, 4, 5, 6],
            'r169': [2, 3, 4, 5, 6],
            'r32': [2, 3, 4, 5, 6],
            'r43': [2, 3, 4, 5, 6],
            'r54': [2, 3, 4, 5, 6]
          }
        },
        'square': {
          'ratio': {
            'r11': [3, 4, 5, 6, 7]
          }
        }
      }
    },
    'maxWidth-600': {
      'orientation': {
        'horizontal': {
          'ratio': {
            'original': [6, 7, 8, 9, 10],
            'r21': [7, 7, 11, 14, 14],
            'r169': [7, 7, 10, 13, 13],
            'r32': [6, 6, 8, 11, 11],
            'r43': [5, 5, 7, 10, 10],
            'r54': [5, 5, 7, 9, 9]
          }
        },
        'vertical': {
          'ratio': {
            'original': [3, 3, 4, 5, 5],
            'r21': [3, 3, 4, 5, 5],
            'r169': [3, 3, 4, 5, 5],
            'r32': [4, 4, 5, 6, 6],
            'r43': [5, 5, 6, 7, 7],
            'r54': [5, 5, 7, 9, 9]
          }
        },
        'square': {
          'ratio': {
            'r11': [6, 6, 7, 9, 9]
          }
        }
      }
    },
    'maxHeight-600': {
      'orientation': {
        'horizontal': {
          'ratio': {
            'original': [3, 3, 4, 5, 5],
            'r21': [3, 3, 4, 5, 5],
            'r169': [3, 3, 4, 5, 5],
            'r32': [3, 3, 4, 5, 5],
            'r43': [3, 3, 4, 5, 5],
            'r54': [3, 3, 4, 5, 5]
          }
        },
        'vertical': {
          'ratio': {
            'original': [4, 4, 5, 6, 6],
            'r21': [2, 2, 3, 4, 4],
            'r169': [2, 2, 3, 4, 4],
            'r32': [2, 2, 3, 4, 4],
            'r43': [2, 2, 3, 4, 4],
            'r54': [2, 2, 3, 4, 4]
          }
        },
        'square': {
          'ratio': {
            'r11': [3, 3, 4, 5, 5]
          }
        }
      }
    }
  };

}, '1.0', {
  requires: []
});