"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.ImportType */

/* Java class defined in repository squarespace-v6 */
var ImportingSources;

(function (ImportingSources) {
  ImportingSources["V5"] = 1;
  ImportingSources["WORDPRESS"] = 2;
  ImportingSources["TUMBLR"] = 3;
  ImportingSources["BLOGGER"] = 5;
  ImportingSources["ETSY"] = 7;
  ImportingSources["SHOPIFY"] = 8;
  ImportingSources["BIG_CARTEL"] = 9;
})(ImportingSources || (ImportingSources = {}));

var _default = ImportingSources;
exports["default"] = _default;
module.exports = exports.default;