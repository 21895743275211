"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.TaxScope */

/* Java class defined in repository squarespace-v6 */
var TaxScopes;

(function (TaxScopes) {
  TaxScopes["COUNTRY"] = 1;
  TaxScopes["STATE"] = 2;
  TaxScopes["LOCAL"] = 3;
})(TaxScopes || (TaxScopes = {}));

var _default = TaxScopes;
exports["default"] = _default;
module.exports = exports.default;