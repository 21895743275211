"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.business.commerce.discount.trigger.DiscountTriggerType */

/* Java class defined in repository squarespace-v6 */
var DiscountTriggerType;

(function (DiscountTriggerType) {
  DiscountTriggerType["CODE"] = "CODE";
  DiscountTriggerType["AUTO"] = "AUTO";
  DiscountTriggerType["EPHEMERAL"] = "EPHEMERAL";
})(DiscountTriggerType || (DiscountTriggerType = {}));

var _default = DiscountTriggerType;
exports["default"] = _default;
module.exports = exports.default;