"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.business.customers.search.CustomerSortDirection */

/* Java class defined in repository squarespace-v6 */
var CustomerSortDirection;

(function (CustomerSortDirection) {
  CustomerSortDirection["ASCENDING"] = "asc";
  CustomerSortDirection["DESCENDING"] = "desc";
})(CustomerSortDirection || (CustomerSortDirection = {}));

var _default = CustomerSortDirection;
exports["default"] = _default;
module.exports = exports.default;