"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.GoogleReCaptchaThemeType */

/* Java class defined in repository cms-legacy-data */
var GoogleReCaptchaThemeType;

(function (GoogleReCaptchaThemeType) {
  GoogleReCaptchaThemeType["LIGHT"] = 1;
  GoogleReCaptchaThemeType["DARK"] = 2;
})(GoogleReCaptchaThemeType || (GoogleReCaptchaThemeType = {}));

var _default = GoogleReCaptchaThemeType;
exports["default"] = _default;
module.exports = exports.default;