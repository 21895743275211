import { t } from 'shared/i18n';

import { invoke, isObject, isString } from 'lodash';
import qs from 'qs';
import { scrollIntoView } from '@sqs/utils/scroll';

import { FacebookPixelConstants as PixelConstants } from '@sqs/websites-constants';
import { trackSubscribeNewsletter } from 'shared/utils/commerce/trackEvent';
import { GoogleReCaptchaAPI } from '@sqs/universal-utils';
import { isValidEmail } from 'shared/utils/EmailUtils';
import network from '@sqs/network';
import { addIntraPageEventFields } from 'shared/utils/census/CensusUtils';

/**
 * Construct a new instance to use.
 * This is used in various templates that render forms in templates-v6
 * (directly instantiated and called in the form's onsubmit) and by
 * async-forms.js.
 * It provides the field validation rules, form submission processing,
 * overridable error messaging, sanitization functions for forms constructed
 * by the form builder module.
 */
export default class FormHandler {
  static initialQuery = qs.parse(window.location.search.replace(/^\?/, ''));

  static endpoints = {
    SAVE: '/api/form/SaveFormSubmission',
    FORM_KEY: '/api/form/FormSubmissionKey' };


  static NETWORK_OPTIONS = {
    withCredentials: true };


  static strings = {
    SUBMIT_TEXT_IN_PROGRESS: t("Submitting\u2026"),




    SUBMIT_ERROR: t("Error processing form submission. Please reload and try again."),




    UNKNOWN_ERROR: t("Unknown error, please try again later."),




    CAPTCHA_INCOMPLETE_ERROR: t("Complete CAPTCHA before submitting."),




    FORM_KEY_ERROR: t("Could not get a form submission key, please try again later."),




    FIELD_SANITIZATION_ERROR: t("Could not parse the form fields, please check your entries."),




    FIELD_VALIDATION_ERROR: t("One or more fields is invalid, please check your entries."),




    ERROR_ABOVE: t("Your form has encountered a problem. Please scroll down to review."),




    ERROR_BELOW: t("Your form has encountered a problem. Please scroll up to review."),




    FAIL_MAX_LENGTH: t("You have exceeded the character limit."),




    FAIL_EMAIL: t("Email is not valid. Email addresses should follow the format user@domain.com.") };






  static errorCodes = {
    401: t("Unable to submit form. Please try again later."),




    404: t("This form has not been configured yet."),




    429: t("Unable to submit form. Submission rate limit exceeded. Please try again later."),




    500: t("Unable to send form. Please try again later.") };






  static getFormKey = () =>
  network.
  post(FormHandler.endpoints.FORM_KEY, {}, FormHandler.NETWORK_OPTIONS).
  then(({ data: { key } }) => key);

  /**
   * @param {*} err
   * @return {boolean}
   */
  static isFieldErrors = ({ response }) =>
  isObject(response) &&
  response &&
  response.status === 400 &&
  response.data && response.data.errors &&
  Object.keys(response.data.errors).length > 0;

  /**
   * @param {*} [err='']
   * @return {string}
   */
  static getErrorMessage = (err = '') => {
    if (isString(err)) {
      return err;
    }
    if (!isObject(err)) {
      return '';
    }
    if (err.status && FormHandler.errorCodes[err.status]) {
      return FormHandler.errorCodes[err.status];
    }
    if (err.data && err.data.error) {
      return err.data.error;
    }
    return '';
  };

  static sanitizeValue = (el) => {
    const fieldEl = el.querySelector('.field-element');
    return fieldEl ? fieldEl.value : null;
  };

  static sanitizeChildValuesToArray = (el) => el ?
  [...el.querySelectorAll('.field-element')].map(({ value }) => value) :
  [];

  static sanitizePhone = (el) => {
    const result = FormHandler.sanitizeChildValuesToArray(el);
    return result.length === 3 ? ['', ...result] : result;
  };

  static sanitizeCheckbox = (el) =>
  [...el.querySelectorAll('input')].reduce(
  (result, inputEl) => {
    if (inputEl.checked) {
      result.push(inputEl.value);
    }
    return result;
  },
  []);


  static sanitizeRadio = (el) => FormHandler.sanitizeCheckbox(el)[0];

  static sanitizeSelect = (el) => {
    const selectEl = el.querySelector('select');
    return selectEl ? selectEl.value : null;
  };

  static sanitizeLike = (el) => [...el.querySelectorAll('.item')].reduce(
  (result, itemEl) => {
    const answers = FormHandler.sanitizeCheckbox(itemEl);
    const question = itemEl.dataset.question;
    if (question && answers.length > 0) {
      result[question] = answers;
    }
    return result;
  },
  {});


  static sanitizeHidden = (el) => {
    const query = FormHandler.initialQuery ||
    qs.parse(window.location.search.replace(/^\?/, ''));
    return (
    el.name && query[el.name] ?
    query[el.name] :
    el.value).
    toString();
  };

  static sanitizersHash = {
    address: FormHandler.sanitizeChildValuesToArray,
    checkbox: FormHandler.sanitizeCheckbox,
    date: FormHandler.sanitizeChildValuesToArray,
    hidden: FormHandler.sanitizeHidden,
    likert: FormHandler.sanitizeLike,
    name: FormHandler.sanitizeChildValuesToArray,
    phone: FormHandler.sanitizePhone,
    radio: FormHandler.sanitizeRadio,
    select: FormHandler.sanitizeSelect,
    time: FormHandler.sanitizeChildValuesToArray,
    default: FormHandler.sanitizeValue };


  static getSanitizedFieldValue = (el) => {
    if (el.classList.contains('section')) {
      return;
    }
    const sanitizerKey = [...el.classList].find(
    (fieldClass) => FormHandler.sanitizersHash[fieldClass]) ||
    'default';
    const sanitizer = FormHandler.sanitizersHash[sanitizerKey];
    return sanitizer(el);
  };

  static validateField = (el) => {
    if (el.classList.contains('section')) {
      return { isValid: true };
    }

    const isRequired = el.classList.contains('required');

    if (el.dataset.maxLength) {
      const input = el.querySelector('textarea');
      const value = input.value.trim();
      const wasOptional = !isRequired && value.length === 0;
      const maxLength = parseInt(el.dataset.maxLength, 10);
      // @TODO UTF-8 support for maxLength
      const hasMaxLength = Boolean(maxLength && !isNaN(maxLength));
      const isValid = wasOptional ||
      !hasMaxLength || input.value.length <= maxLength;
      return {
        isValid,
        message: FormHandler.strings.FAIL_MAX_LENGTH };

    }

    if (el.classList.contains('email')) {
      const input = el.querySelector('input');
      const value = input.value.trim();
      const wasOptional = !isRequired && value.length === 0;
      const isValid = wasOptional || isValidEmail(value);
      return {
        isValid,
        message: FormHandler.strings.FAIL_EMAIL };

    }

    return { isValid: true };
  };

  /**
   * @param {Window} win
   * @param {Element} formEl
   */
  constructor(win, formEl) {
    if (!win || !formEl) {
      throw new Error('Invalid FormHandler construction.');
    }

    this.win = win;
    if (formEl instanceof Element) {
      this.formEl = formEl;
    } else if (formEl.formNode) {// passed Y.Node to constructor
      this.formEl = formEl.formNode.getDOMNode();
    }
    if (!this.formEl) {
      throw new Error('FormHandler needs a valid <form> for formEl.');
    }
    this.submitEl = this.formEl.querySelector('[type="submit"]') ||
    document.createElement('div');
    this.submitText = this.submitEl.value;
  }

  /**
   * Do not rename unless you want to update slices/newsletter.js too
   */
  clearErrors() {
    [...this.formEl.querySelectorAll('.field-error')].forEach(
    (el) => el.remove());

    [...this.formEl.querySelectorAll('.form-item.error')].forEach(
    (el) => el.classList.remove('error'));

  }

  isLocked() {
    this.formEl.classList.contains('submitting');
  }

  lock() {
    this.submitEl.classList.add('submitting');
    this.submitEl.value = FormHandler.strings.SUBMIT_TEXT_IN_PROGRESS;
  }

  unlock() {
    this.submitEl.classList.remove('submitting');
    this.submitEl.value = this.submitText;
  }

  /**
   * @return {object}
   *  isValid: bool,
   *  errors: [ obj ]
   */
  validate() {
    return [...this.formEl.querySelectorAll('.form-item')].reduce(
    (result, itemEl) => {
      const { isValid, message } = FormHandler.validateField(itemEl);
      if (!isValid) {
        result.isValid = false;
        result.errors.push({ fieldId: itemEl.id, message });
      }
      return result;
    },
    { isValid: true, errors: [] });

  }

  /**
   * @param {object} data
   * @return {?object} data extended with captchaKey if valid
   */
  withCaptcha(data) {
    const captchaContainer =
    GoogleReCaptchaAPI.getCaptchaContainer(this.win, this.formEl);
    if (!captchaContainer) {
      return data;
    }
    const captchaKey = GoogleReCaptchaAPI.validate(this.win, captchaContainer);
    return captchaKey ?
    {
      ...data,
      captchaKey: captchaKey } :

    null;
  }

  /**
   * Called from the template (possibly via Y.Squarespace.FormSubmit)
   * Do not rename unless you want to update slices/newsletter.js too
   *
   * @param {object} formData
   * @param {?string} formData.collectionId null for newsletter popup
   * @param {boolean} [formData.disableValidation] true to disable client validation
   * @param {string} formData.formId
   * @param {boolean} formData.isOverylayForm true if this form is part of
   * @param {?string} formData.objectName page or null for newsletter popup
   * @param {string} formData.submitButtonText
   * @return {false} always return false to prevent default form submit
   */
  submit(formData) {
    if (this.isLocked()) {
      return false;
    }
    this.lock();

    if (!formData.disableValidation) {
      this.clearErrors();

      const { isValid, errors } = this.validate();
      if (!isValid) {
        this.fail(FormHandler.strings.FIELD_VALIDATION_ERROR);
        this.renderFieldClientErrors(errors);
        return false;
      }
    }

    let data;
    try {
      data = this.withCaptcha(formData);
    } catch (err) {
      this.fail(FormHandler.strings.CAPTCHA_INCOMPLETE_ERROR);
      return false;
    }

    FormHandler.getFormKey().
    catch((err) => {
      this.fail(FormHandler.strings.FORM_KEY_ERROR);
    }).
    then((key) => {
      data.key = key;

      try {
        const sanitizedFields = this.getSanitizedFieldValues();
        data.form = JSON.stringify(sanitizedFields);
      } catch (err) {
        throw new Error(FormHandler.strings.FIELD_SANITIZATION_ERROR);
      }

      try {
        this.save(data);
      } catch (err) {
        throw new Error(FormHandler.strings.SUBMIT_ERROR);
      }
    }).
    catch((err) => {
      this.fail(err.message);
    });

    return false;
  }

  /**
   * @return {object} data to send to API
   */
  getSanitizedFieldValues() {
    return [...this.formEl.querySelectorAll('.form-item')].reduce(
    (result, itemEl) => {
      result[itemEl.id] = FormHandler.getSanitizedFieldValue(itemEl);
      return result;
    },
    {});

  }

  /**
   * @param {object} data for form saving
   * @param {string} [data.captchaKey]
   * @param {string} data.collectionId
   * @param {string} data.form stringified form values
   * @param {string} data.formId
   * @param {string} data.isOverlayForm
   * @param {string} data.key form key from API request
   * @param {string} data.objectName
   * @param {string} data.submitButtonText
   * @return {Promise}
   */
  save(data) {
    const submissionData = { ...data };

    // Mutates the submissionData object
    addIntraPageEventFields(submissionData);

    return network.
    post(
    FormHandler.endpoints.SAVE,
    submissionData,
    FormHandler.NETWORK_OPTIONS).

    then(this.success).
    catch(this.fail);
  }

  /**
   * newsletter block and promo pop up will have class 'newsletter-form' cover
   * page newsletter link wil have id 'overlayNewsletterEmail'
   *
   * @return {Boolean}
   */
  isNewsletterForm() {
    return Boolean(this.formEl.classList.contains('newsletter-form') ||
    this.formEl.querySelector(PixelConstants.NEWSLETTER_OVERLAY_NODE_ID));
  }

  success = (result) => {
    if (this.isNewsletterForm()) {
      trackSubscribeNewsletter();
    }

    // Mark popup overlay form submit success
    invoke(this.win, 'Y.Global.fire', 'form:submitSuccess');

    // Redirect or allow another submission and success display message
    if (!this.redirectToSuccessRedirect()) {
      this.unlock();
      this.renderSuccess();
    }
  };

  /**
   * Do not rename unless you want to update slices/newsletter.js too
   */
  createErrorNode = (message) => {
    const errorNode = this.win.document.createElement('div');
    errorNode.className = 'field-error';
    errorNode.innerHTML = message;
    return errorNode;
  };

  renderFieldError(fieldId, message) {
    const fieldEl = this.formEl.querySelector(`#${fieldId}`);
    if (!fieldEl) {
      return;
    }

    fieldEl.classList.add('error');
    const titleEl = fieldEl.querySelector('.title');
    if (message && titleEl && titleEl.parentNode) {
      titleEl.parentNode.insertBefore(
      this.createErrorNode(message),
      titleEl);

    }
  }

  /**
   * @param {Object[]} errors
   */
  renderFieldClientErrors(errors) {
    errors.forEach(({ fieldId, message }) =>
    this.renderFieldError(fieldId, message));
  }

  /**
   * @param {Object} errors from (backend) field validation where the server
   * returns only one error per field
   */
  renderFieldServerErrors(errors) {
    Object.entries(errors).forEach(([fieldId, message]) => {
      this.renderFieldError(fieldId, message);
    });
  }

  renderErrorMessage(message) {
    this.formEl.insertBefore(
    this.createErrorNode(message),
    this.isNewsletterForm() ?
    this.formEl.querySelector('.newsletter-form-body') :
    this.formEl.firstChild);

  }

  renderSurroundingErrors() {
    const parent = this.formEl;
    parent.insertBefore(
    this.createErrorNode(FormHandler.strings.ERROR_ABOVE),
    parent.firstChild);

    this.formEl.appendChild(
    this.createErrorNode(FormHandler.strings.ERROR_BELOW));

  }

  fail = (err) => {
    this.renderErrors(err);
    if (!this.isNewsletterForm()) {
      this.renderSurroundingErrors();
    }

    const captchaContainer = GoogleReCaptchaAPI.getCaptchaContainer(this.win, this.formEl);
    if (captchaContainer) {
      // Disable the submit button if the captcha can enable it on completion.
      // @see common.js#renderForm()
      if (this.submitEl && this.submitEl.getAttribute('data-captcha-bound')) {
        this.submitEl.disabled = true;
      }
      GoogleReCaptchaAPI.reset(this.win, captchaContainer);
    }

    this.unlock();
  };

  renderErrors(err) {
    if (FormHandler.isFieldErrors(err)) {// checks for 400 && err.data.errors
      this.renderFieldServerErrors(err.data.errors);
    } else {
      const errorMessage = FormHandler.getErrorMessage(err) ||
      FormHandler.strings.UNKNOWN_ERROR;
      this.renderErrorMessage(errorMessage);
    }
  }

  /**
   * E.g. thank you message
   */
  showSubmissionText() {
    const el = this.formEl.querySelector('.form-submission-text');
    if (!el) {
      return;
    }
    el.classList.remove('hidden');
    [...el.querySelectorAll('*')].forEach(
    (child) => child.classList.remove('hidden'));

  }

  hideForm() {
    [...this.formEl.querySelectorAll('*')].forEach(
    (child) => child.classList.add('hidden'));

  }

  redirectToSuccessRedirect() {
    // This setting is not available on popup overlay forms
    const redirect = this.formEl.dataset.successRedirect;
    if (redirect) {
      // Ensure back button does not resubmit form
      if (window.history.replaceState) {
        window.history.replaceState({}, document.title, window.location.href);
      }
      window.location.href = redirect;
      return true;
    }
    return false;
  }

  /**
   * This will be added to an innerHTML and any script tags will be
   * extracted and eval'd
   *
   * @return {string}
   */
  getPostSubmitHtml() {
    const htmlEl = this.formEl.querySelector('.form-submission-html');
    return htmlEl ? htmlEl.dataset.submissionHtml : '';
  }

  appendAndRunPostSubmitHtml(html = '') {
    const htmlEl = this.formEl.querySelector('.form-submission-html');
    htmlEl.innerHTML = this.getPostSubmitHtml();
    htmlEl.classList.remove('hidden');
    [...htmlEl.querySelectorAll('script')].forEach((scriptEl) => {
      try {
        if (scriptEl.src) {
          // Force re-execution of external script tags
          const duplicateScriptEl = document.createElement('script');
          [...scriptEl.attributes].forEach((a) => {
            duplicateScriptEl[a.name] = a.value;
          });
          scriptEl.parentNode.insertBefore(duplicateScriptEl, scriptEl);
        } else {
          // Don't change this to just eval() or eslint will barf
          this.win.eval(scriptEl.innerText);
        }
      } catch (err) {
        if (__DEV__) {
          console.warn(
          '[FormHandler] postSubmitHtml had a bad script tag',
          html);

        }
      }
    });
  }

  renderSuccess() {
    this.hideForm();
    this.showSubmissionText();
    scrollIntoView(this.formEl, true);
    this.appendAndRunPostSubmitHtml(this.getPostSubmitHtml());
  }}