"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.FeatureLevel */

/* Java class defined in repository squarespace-v6 */
var FeatureLevels;

(function (FeatureLevels) {
  FeatureLevels["WEBSITE_COVER_PAGE"] = "cover_page";
  FeatureLevels["WEBSITE_LEGACY_PERSONAL"] = "personal";
  FeatureLevels["WEBSITE_LEGACY_PROFESSIONAL"] = "professional";
  FeatureLevels["WEBSITE_LEGACY_BUSINESS"] = "commerce";
  FeatureLevels["WEBSITE_CLASSIC_PERSONAL"] = "classic_personal";
  FeatureLevels["WEBSITE_CLASSIC_PERSONAL_GATED_TEST"] = "classic_personal_gated";
  FeatureLevels["WEBSITE_CLASSIC_PERSONAL_RESOLD"] = "classic_personal_resold";
  FeatureLevels["WEBSITE_CLASSIC_BUSINESS"] = "classic_business";
  FeatureLevels["WEBSITE_CLASSIC_BUSINESS_RESOLD"] = "classic_business_resold";
  FeatureLevels["WEBSITE_COMMERCE_BASIC"] = "commerce_basic";
  FeatureLevels["WEBSITE_COMMERCE_BASIC_RESOLD"] = "commerce_basic_resold";
  FeatureLevels["WEBSITE_COMMERCE_ADVANCED"] = "commerce_advanced";
  FeatureLevels["WEBSITE_COMMERCE_ADVANCED_CALLBACKS"] = "commerce_advanced_callbacks";
  FeatureLevels["WEBSITE_COMMERCE_ADVANCED_RESOLD"] = "commerce_advanced_resold";
  FeatureLevels["WEBSITE_ENTERPRISE_SELECT"] = "enterprise_select";
  FeatureLevels["WEBSITE_PARKING_PAGE"] = "parking_page";
  FeatureLevels["WEBSITE_BUSINESS_PRICE_TEST_AUG_2017"] = "website_business_price_test_aug_2017";
  FeatureLevels["DOMAIN_FREE"] = "forever-free";
  FeatureLevels["DOMAIN_BASIC"] = "basic";
  FeatureLevels["DOMAIN_PREMIUM_I"] = "premium_i";
  FeatureLevels["DOMAIN_PREMIUM_II"] = "premium_ii";
  FeatureLevels["DOMAIN_PREMIUM_III"] = "premium_iii";
  FeatureLevels["DOMAIN_PREMIUM_IV"] = "premium_iv";
  FeatureLevels["DOMAIN_PREMIUM_V"] = "premium_v";
  FeatureLevels["GOOGLE_APPS_DEFAULT"] = "google-apps-default";
  FeatureLevels["GOOGLE_WORKSPACE_STARTER"] = "google_workspace_starter";
  FeatureLevels["GOOGLE_WORKSPACE_STANDARD"] = "google_workspace_standard";
  FeatureLevels["CAMPAIGNS_STARTER"] = "campaigns_starter";
  FeatureLevels["CAMPAIGNS_PLUS"] = "campaigns_plus";
  FeatureLevels["CAMPAIGNS_PRO"] = "campaigns_pro";
  FeatureLevels["CAMPAIGNS_CORE"] = "campaigns_core";
  FeatureLevels["CAMPAIGNS_MAX"] = "campaigns_max";
  FeatureLevels["SCHEDULING_EMERGING"] = "scheduling_emerging";
  FeatureLevels["SCHEDULING_GROWING"] = "scheduling_growing";
  FeatureLevels["SCHEDULING_POWERHOUSE"] = "scheduling_powerhouse";
  FeatureLevels["MEMBER_AREAS_STARTER"] = "member-areas_starter";
  FeatureLevels["MEMBER_AREAS_CORE"] = "member-areas_core";
  FeatureLevels["MEMBER_AREAS_PRO"] = "member-areas_pro";
  FeatureLevels["SHIPPING_LABEL_DEFAULT"] = "shipping-label-default";
})(FeatureLevels || (FeatureLevels = {}));

var _default = FeatureLevels;
exports["default"] = _default;
module.exports = exports.default;