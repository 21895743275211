/**
 * The module containing the Squarespace.SlideRendering.Slices.Video class.
 *
 * @module squarespace-slide-rendering-slices-video
 */
YUI.add('squarespace-slide-rendering-slices-video', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * The slice widget for slices of type Video.
   *
   * @class Video
   * @constructor
   * @namespace Squarespace.SlideRendering.Slices
   * @extends Squarespace.SlideRendering.Slices.Base
   * @uses Squarespace.SlideRendering.Slices.ClientRefresh
   */
  var Video =
  Y.Squarespace.SlideRendering.Slices.Video = Y.Base.create('Video',
    Y.Squarespace.SlideRendering.Slices.Base,
    [Y.Squarespace.SlideRendering.Slices.ClientRefresh],
    {
      destructor: function() {
        if (this._lightbox) {
          this._lightbox.destroy();
          delete this._lightbox;
        }
      },

      bindUI: function() {
        Video.superclass.bindUI.call(this);

        this.get('contentBox').one('.sqs-slice-play-button').on('click', function() {
          this._openVideo();
          this._lightbox._overlay.once('hide', function(e) {
            Y.Global.fire('lightbox-hidden');
          }, this);
        }, this);

      },

      // Override
      refreshFromSliceContent: function(sliceContent) {
        var contentItem = sliceContent.get('contentItem');
        var structuredContent = contentItem.get('structuredContent');
        var oembed = structuredContent.get('oembed');
        var videoWrapper = this.get('contentBox').one('.sqs-video-wrapper');

        if (Y.Lang.isValue(videoWrapper)) {
          videoWrapper.remove(true);
        }

        if (Y.Lang.isValue(oembed) && Y.Lang.isValue(oembed.html) && oembed.html !== '') {
          var html = oembed.html;
          var providerName = oembed.providerName || '';
          var contentBox = this.get('contentBox');
          var playButton = contentBox.one('.sqs-slice-play-button');
          var escapedHtml = Y.Squarespace.Escaping.escapeForHtmlTag(html);

          contentBox.insertBefore(
            '<div class="sqs-video-wrapper" data-load="false" data-html="' +
                  escapedHtml + '" data-providerName="' +
                  providerName + '" ></div>', playButton);
        }
      },

      /**
       * Open the video, if one exists.
       *
       * @method _openVideo
       * @private
       */
      _openVideo: function() {
        var videoWrapper = this.get('contentBox').one('.sqs-video-wrapper');

        if (!Y.Lang.isValue(videoWrapper)) {
          return;
        }

        if (this._lightbox) {
          this._lightbox.destroy();
          delete this._lightbox;
        }

        this._lightbox = new Y.Squarespace.Lightbox2({
          controls: {
            previous: false,
            next: false
          },
          set: [{ content: videoWrapper }],
          currentSetIndex: 0,
          videoLoaderOptions: {
            forceAutoplay: true
          }
        });

        this._lightbox.render();
      }
    },
    {
      SLICE_TYPE: Y.Squarespace.SliceType.VIDEO,
      CSS_PREFIX: 'sqs-slice-video'
    }
  );
}, '1.0', {
  requires: [
    'base',
    'squarespace-attr-validators',
    'squarespace-escaping-utils',
    'squarespace-modal-lightbox',
    'squarespace-slide-rendering-slices-base',
    'squarespace-slide-rendering-slices-client-refresh',
    'squarespace-video-loader'
  ]
});
