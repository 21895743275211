/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.ProfileCenterCenter02 class.
 *
 * @module squarespace-slide-rendering-layouts-profile-center-center-02
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-profile-center-center-02', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for profile-center-center-02.
   *
   * @class ProfileCenterCenter02
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.ProfileCenterCenter02 = Y.Base.create('ProfileCenterCenter02',
    Y.Squarespace.SlideRendering.Layouts.ProfileCenterCenter01,
    [],
    {}
  );
}, '1.0', {
  requires: [
    'base',
    'squarespace-slide-rendering-layouts-profile-center-center-01'
  ]
});
