import {
  BoundaryRatios as ResponsiveBoundaryRatios,
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.ProfileFullBottom01 class.
 *
 * @module squarespace-slide-rendering-layouts-profile-full-bottom-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-profile-full-bottom-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for profile-full-bottom-01.
   *
   * @class ProfileFullBottom01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.ProfileFullBottom01 = Y.Base.create('ProfileFullBottom01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var copyNode = contentBox.querySelector('.group-copy');
        var contentNode = contentBox.querySelector('.group-content');
        var linksNode = contentBox.querySelector('.group-links');
        var responsiveNodeSocial = contentBox.querySelector('.responsive-wrapper.social');

        // Branding heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthBoundaryNode: contentNode,
          minFontSize: 20,
          widthRatio: 0.99,
          onReset: function(rootNode) {
            rootNode.style.whiteSpace = 'nowrap';
          },
          onMinFontSize: function(rootNode) {
            rootNode.style.whiteSpace = 'normal';
          }
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          minFontSize: 15,
          widthBoundaryNode: contentNode,
          widthRatio: 0.99,
          onReset: function(rootNode) {
            rootNode.style.whiteSpace = 'nowrap';
          },
          onMinFontSize: function(rootNode) {
            rootNode.style.whiteSpace = 'normal';
          }
        }));

        // Actions and custom form font size
        var actionsFontSizeComponent = new ResponsiveFontSize({
          rootNode: responsiveNodeSocial,
          fontSizeNodes: [
            contentBox.querySelector(('[data-compound-type="action"] ul')),
            contentBox.querySelector('[data-slice-type="custom-form"]')
          ],
          widthBoundaryNode: contentBox.querySelector('.group-links'),
          minFontSize: 12,
          widthRatio: 0.99,
          enabled: false,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
            rootNode.classList.remove('text-align-left');
          },
          onMinFontSize: function(rootNode) {
            rootNode.classList.add('stacked');
            rootNode.classList.add('text-align-left');
          }
        });
        this._registerResponsiveComponent(actionsFontSizeComponent);

        // Overlap between body and inline link group, even when body is empty
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('[data-slice-type="body"]'),
          targetNode: linksNode,
          padding: 120,
          onReset: function() {
            copyNode.classList.remove('stacked');
          },
          onRespond: function() {
            copyNode.classList.add('stacked');
          }
        }));

        // Overlap between copy and inline link group
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: copyNode,
          targetNode: linksNode,
          padding: 120,
          onReset: function(rootNode, targetNode) {
            var pNode = rootNode.parentNode;
            pNode.classList.remove('stacked');
            pNode.classList.remove('left-right-floats-off');
            responsiveNodeSocial.classList.remove('wrapped');
            actionsFontSizeComponent.disable();
          },
          onRespond: function(rootNode, targetNode) {
            var pNode = rootNode.parentNode;
            pNode.classList.add('stacked');
            pNode.classList.add('left-right-floats-off');
            responsiveNodeSocial.classList.remove('wrapped');
            actionsFontSizeComponent.enable();
          }
        }));

        // Social Icons boundary detection
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: responsiveNodeSocial,
          widthBoundaryNode: contentNode,
          widthRatio: 0.99,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
            socialIconBoundaryComponent.disable();
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('stacked');
            socialIconBoundaryComponent.enable();
          }
        }));

        // Social Icons boundary detection
        var socialIconBoundaryComponent = new ResponsiveBoundaryRatios({
          rootNode: contentBox.querySelector('[data-slice-type="social-icons"]:not([data-content-empty="true"])'),
          widthBoundaryNode: contentNode,
          widthRatio: 0.7,
          enabled: false,
          onReset: function(rootNode) {
            rootNode.classList.remove('wrapped');
            if (rootNode.querySelector('.break')) {
              rootNode.querySelector('.break').remove();
            }
          },
          onRespond: function(rootNode) {
            rootNode = new Y.Node(rootNode);
            rootNode.addClass('wrapped');
            // Social icons wrap onto two lines
            var icons = rootNode.get('childNodes');
            if (!rootNode.one('.break')) {
              icons.item(Math.floor(icons.size() / 2)).insert('<br class="break">', 'before');
            }
          }
        });
        this._registerResponsiveComponent(socialIconBoundaryComponent);

        // Scroll on overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: contentBox.querySelector('.sqs-slide-layer.layer-front .sqs-slide-layer-content .group-content'),
          onReset: function(rootNode) {
            contentBox.querySelector('.sqs-slide-layer.layer-front').classList.remove('scroll');
          },
          onRespond: function(rootNode) {
            contentBox.querySelector('.sqs-slide-layer.layer-front').classList.add('scroll');
          }
        }));
      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
