import { GoogleReCaptchaAPI } from '@sqs/universal-utils';
import GoogleReCaptchaAlignmentType from '@sqs/enums/GoogleReCaptchaAlignmentType';
import GoogleReCaptchaThemeType from '@sqs/enums/GoogleReCaptchaThemeType';
import { t } from 'shared/i18n';
import { isValidEmail } from 'shared/utils/EmailUtils';

/**
 * @module squarespace-slide-rendering-slices-newsletter
 */
YUI.add('squarespace-slide-rendering-slices-newsletter', function (Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * The slice widget for slices of type SliceType.NEWSLETTER.
   *
   * @namespace Squarespace.SlideRendering.Slices
   * @class Newsletter
   * @extends Squarespace.SlideRendering.Slices.Base
   */
  var Newsletter = Y.Squarespace.SlideRendering.Slices.Newsletter = Y.Base.create(
  'Newsletter',
  Y.Squarespace.SlideRendering.Slices.Base,
  [Y.Squarespace.SlideRendering.Slices.ClientRefresh],
  {
    initializer: function () {
      Newsletter.superclass.initializer.call(this);

      this._bindGlobalEvents();
    },

    renderUI: function () {
      Newsletter.superclass.renderUI.call(this);

      this.renderCaptcha();
    },

    bindUI: function () {
      Newsletter.superclass.bindUI.call(this);

      var cb = this.get('contentBox');
      var formNode = cb.one('form.newsletter-form');
      var formSubmit = Y.Squarespace.FormSubmit(formNode.getDOMNode());
      var emailField = this._getEmailField(formNode);

      emailField.on('focus', function () {
        formSubmit.clearErrors();
      }, this);

      formNode.on('submit', function () {
        formSubmit.clearErrors();

        function displayError(errorMessage) {
          var errorNode = formSubmit.createErrorNode(errorMessage);
          formNode.prepend(errorNode);
          // The error node is not programmatically focusable
          // by default, so a negative tabindex needs to be set on it
          // (so it's focusable but not tabbable).
          errorNode.setAttribute('tabindex', -1);
          errorNode.focus();
        }

        // @TODO just use FormHandler's built-in validation, which also uses
        // isValidEmail
        if (!isValidEmail(emailField.get('value'))) {
          displayError(t("Please enter a valid email address."));






          return false;
        }

        var formId = formNode.getAttribute('data-form-id');
        if (!formId) {
          displayError(t("Please connect your newsletter to a storage option."));






          return false;
        }

        formSubmit.submit({
          collectionId: null,
          disableValidation: true, // implemented custom validation above
          formId: formId,
          isOverlayForm: true,
          objectName: null,
          submitButtonText: this._getSubmitButtonText(formNode) });

        return false;
      }, this);
    },

    /**
     * @param {YUI.Node} formNode
     * @return {YUI.Node}
     */
    _getEmailField: function (formNode) {
      return formNode.one('.field-element[type="text"]');
    },

    /**
     * @param {YUI.Node} formNode
     * @return {string}
     */
    _getEmailPlaceholderText: function (formNode) {
      return this._getEmailField(formNode).
      getDOMNode().
      getAttribute('placeholder');
    },

    /**
     * @param {YUI.Node} formNode
     * @return {YUI.Node}
     */
    _getSubmitButton: function (formNode) {
      return formNode.one('button');
    },

    /**
     * @param {YUI.Node} formNode
     * @return {string}
     */
    _getSubmitButtonText: function (formNode) {
      return this._getSubmitButton(formNode).get('value');
    },

    _bindGlobalEvents: function () {
      Y.Global.detach('refreshCaptchaFromState');

      this._handleCaptchRefresh = Y.Global.after('refreshCaptchaFromState', function (e) {
        var captchaConfig = e.captchaConfig;
        var cb = this.get('contentBox');
        var captchaContainerWrapper = cb.one('div.captcha-container-wrapper');

        if (captchaContainerWrapper) {
          var captchaContainer = captchaContainerWrapper.one('.captcha-container');

          if (captchaContainer) {
            GoogleReCaptchaAPI.destroy(captchaContainer.getDOMNode());
            captchaContainer.remove();
          }

          if (captchaConfig.captchaEnabled) {
            captchaContainerWrapper.append(this._createCaptchaContainer(captchaConfig));
            this.renderCaptcha();
          }
        }
      }, this);
    },

    _createCaptchaContainer: function (captchaConfig, isCompact) {
      var captchaContainer = Y.Node.create('<div class="captcha-container"/>');

      captchaContainer.toggleClass(
      'align-center',
      captchaConfig.captchaAlignment === GoogleReCaptchaAlignmentType.CENTER);

      captchaContainer.toggleClass(
      'align-right',
      captchaConfig.captchaAlignment === GoogleReCaptchaAlignmentType.RIGHT);

      captchaContainer.setAttribute(
      'data-theme',
      captchaConfig.captchaTheme === GoogleReCaptchaThemeType.LIGHT ? 'light' : 'dark');


      return captchaContainer;
    },

    renderCaptcha: function () {
      var contentBox = this.get('contentBox');
      if (!contentBox) {
        return;
      }
      var captchaContainer = contentBox.one('.captcha-container');
      var captchaContainerEl = captchaContainer && captchaContainer.getDOMNode();
      if (!captchaContainerEl) {
        return;
      }

      GoogleReCaptchaAPI.render(Y.config.win, captchaContainerEl);
    },

    destructor: function () {
      // Event cleanup.
      if (this._handleCaptchRefresh) {
        this._handleCaptchRefresh.detach();
      }
    },

    /**
     * @param {YUI.Model} sliceContent
     * @override
     */
    refreshFromSliceContent: function (sliceContent) {
      var cb = this.get('contentBox');
      var formNode = cb.one('form.newsletter-form');

      // Default to "Email Address" in the current site locale if field is
      // not populated.
      var emailPlaceholder = sliceContent.get('emailPlaceholder') || t("Email Address");

      this._getEmailField(formNode).getDOMNode().
      setAttribute('placeholder', emailPlaceholder);

      var submitButtonText = sliceContent.get('submitButtonText') || '';
      this._getSubmitButton(formNode).set('innerHTML', submitButtonText);

      var postSubmitMessageEl = cb.one('.form-submission-text');
      var submissionMessage = sliceContent.get('submissionMessage') ||
      { html: '' };
      postSubmitMessageEl.set('innerHTML', submissionMessage.html);

      var postSubmitHtmlEl = cb.one('.form-submission-html');
      var submissionHTML = sliceContent.get('submissionHTML');
      postSubmitHtmlEl.set('innerHTML', submissionHTML);

      var disclaimerMessageEl = cb.one('.form-disclaimer-text');
      var disclaimerMessage = sliceContent.get('disclaimerMessage');
      disclaimerMessageEl.set('innerHTML', disclaimerMessage.html);
    } },

  {
    SLICE_TYPE: Y.Squarespace.SliceType.NEWSLETTER,
    CSS_PREFIX: 'sqs-slice-newsletter' });



}, '1.0', {
  requires: [
  'base',
  'squarespace-enum',
  'squarespace-form-submit',
  'squarespace-slide-rendering-slices-base',
  'squarespace-slide-rendering-slices-client-refresh'] });