"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.business.commerce.payments.paypal.PayPalCheckoutErrorType */

/* Java class defined in repository squarespace-v6 */
var PayPalCheckoutErrorTypes;

(function (PayPalCheckoutErrorTypes) {
  PayPalCheckoutErrorTypes["CREATE_CHECKOUT_ERROR_GENERIC"] = "create-checkout-error-generic";
  PayPalCheckoutErrorTypes["CREATE_CHECKOUT_ERROR_ADDRESS_MISSING_STATE"] = "create-checkout-shipping-address-missing-state";
  PayPalCheckoutErrorTypes["CREATE_CHECKOUT_ERROR_ADDRESS_MISSING_POSTAL_CODE"] = "create-checkout-shipping-address-missing-postal-code";
  PayPalCheckoutErrorTypes["CREATE_CHECKOUT_ERROR_ADDRESS_MISSING_CITY"] = "create-checkout-shipping-address-missing-city";
  PayPalCheckoutErrorTypes["CREATE_CHECKOUT_ERROR_ADDRESS_MISSING_COUNTRY"] = "create-checkout-shipping-address-missing-country";
  PayPalCheckoutErrorTypes["CREATE_CHECKOUT_ERROR_ADDRESS_INVALID"] = "create-checkout-shipping-address-invalid";
  PayPalCheckoutErrorTypes["GET_CHECKOUT_ERROR"] = "get-checkout-error";
  PayPalCheckoutErrorTypes["BUILD_REDIRECT_URL_ERROR"] = "build-redirect-url-error";
  PayPalCheckoutErrorTypes["PAYPAL_NOT_AVAILABLE_ERROR"] = "paypal-not-available-error";
  PayPalCheckoutErrorTypes["PAYPAL_ACCOUNT_ERROR"] = "paypal-account-error";
  PayPalCheckoutErrorTypes["DISCONNECTED_ACCOUNT"] = "disconnect-account";
  PayPalCheckoutErrorTypes["CHECKOUT_AMOUNT_ZERO"] = "checkout-amount-zero";
})(PayPalCheckoutErrorTypes || (PayPalCheckoutErrorTypes = {}));

var _default = PayPalCheckoutErrorTypes;
exports["default"] = _default;
module.exports = exports.default;