"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.business.customers.search.CustomerField */

/* Java class defined in repository squarespace-v6 */
var CustomerField;

(function (CustomerField) {
  CustomerField["NAME"] = "name";
  CustomerField["EMAIL"] = "email";
  CustomerField["ACCOUNT"] = "account";
  CustomerField["ORDER_NUM"] = "orderNum";
  CustomerField["LAST_ORDER_ON"] = "lastOrderOn";
  CustomerField["TOTAL_SPENT"] = "totalSpent";
})(CustomerField || (CustomerField = {}));

var _default = CustomerField;
exports["default"] = _default;
module.exports = exports.default;