"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SuspensionState */

/* Java class defined in repository squarespace-v6 */
var SuspensionState;

(function (SuspensionState) {
  SuspensionState["HIDDEN"] = 1;
  SuspensionState["QUARANTINED"] = 2;
})(SuspensionState || (SuspensionState = {}));

var _default = SuspensionState;
exports["default"] = _default;
module.exports = exports.default;