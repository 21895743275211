"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.CommentSortOrder */

/* Java class defined in repository cms-legacy-data */
var CommentSortTypes;

(function (CommentSortTypes) {
  CommentSortTypes["OLDEST_FIRST"] = 1;
  CommentSortTypes["NEWEST_FIRST"] = 2;
  CommentSortTypes["MOST_LIKED"] = 3;
  CommentSortTypes["LEAST_LIKED"] = 4;
  CommentSortTypes["MOST_FLAGGED"] = 5;
})(CommentSortTypes || (CommentSortTypes = {}));

var _default = CommentSortTypes;
exports["default"] = _default;
module.exports = exports.default;