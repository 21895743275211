"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.slides.SlideLayoutCategory */

/* Java class defined in repository cms-legacy-data */
var SlideLayoutCategory;

(function (SlideLayoutCategory) {
  SlideLayoutCategory["LANDING"] = 1;
  SlideLayoutCategory["PROFILE"] = 2;
  SlideLayoutCategory["AUDIO"] = 3;
  SlideLayoutCategory["VIDEO"] = 4;
  SlideLayoutCategory["LOCATION"] = 5;
  SlideLayoutCategory["LOCK"] = 6;
  SlideLayoutCategory["TWITTER"] = 7;
  SlideLayoutCategory["OVERLAY_NEWSLETTER"] = 8;
  SlideLayoutCategory["OVERLAY_PROMOTION"] = 9;
})(SlideLayoutCategory || (SlideLayoutCategory = {}));

var _default = SlideLayoutCategory;
exports["default"] = _default;
module.exports = exports.default;