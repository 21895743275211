"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.FocalPointSource */

/* Java class defined in repository cms-legacy-data */
var FocalPointSources;

(function (FocalPointSources) {
  FocalPointSources["HUMAN"] = 1;
  FocalPointSources["MACHINE"] = 2;
  FocalPointSources["DEFAULT"] = 3;
})(FocalPointSources || (FocalPointSources = {}));

var _default = FocalPointSources;
exports["default"] = _default;
module.exports = exports.default;