"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.InvoiceStatus */

/* Java class defined in repository squarespace-v6 */
var InvoiceStatuses;

(function (InvoiceStatuses) {
  InvoiceStatuses["UNPAID"] = 1;
  InvoiceStatuses["PAID"] = 2;
  InvoiceStatuses["FORGIVEN"] = 3;
})(InvoiceStatuses || (InvoiceStatuses = {}));

var _default = InvoiceStatuses;
exports["default"] = _default;
module.exports = exports.default;