"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.WebsiteGoal */

/* Java class defined in repository squarespace-v6 */
var WebsiteGoal;

(function (WebsiteGoal) {
  WebsiteGoal["PORTFOLIO"] = 1;
  WebsiteGoal["EMPLOYMENT"] = 2;
  WebsiteGoal["ONLINE_SALES"] = 3;
  WebsiteGoal["BUSINESS_PROMOTION"] = 4;
  WebsiteGoal["ANNOUNCEMENT"] = 5;
  WebsiteGoal["COLLECT_INFO"] = 6;
  WebsiteGoal["CAPTURE_IDEAS"] = 7;
  WebsiteGoal["OTHER"] = 8;
})(WebsiteGoal || (WebsiteGoal = {}));

var _default = WebsiteGoal;
exports["default"] = _default;
module.exports = exports.default;