"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.EventView */

/* Java class defined in repository cms-legacy-data */
var EventView;

(function (EventView) {
  EventView["CALENDAR"] = 0;
  EventView["LIST"] = 1;
})(EventView || (EventView = {}));

var _default = EventView;
exports["default"] = _default;
module.exports = exports.default;