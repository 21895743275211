import { Hub as Sentry } from '@sentry/types';

/**
 * exports capture methods from the sentry/browser global constant which is defined in 'error-reporter/index.js'
 * https://www.npmjs.com/package/@sentry/react
 *
 * DISCLAIMER:
 * if the HTML page does not import {rollup-error-reporter}, SQUARESPACE_SENTRY will be undefined
 * and the 'capture' methods will log to the console, regardless of the environment
 * See 'src/main/webapp/templates-v6/config/config-cover-page.html' as an example of adding the rollup
 */

let captureMessage: Sentry['captureMessage'],
  captureException: Sentry['captureException'],
  captureEvent: Sentry['captureEvent'],
  configureScope: Sentry['configureScope'],
  withScope: Sentry['withScope'];

const logToConsole = (message: any, ...args: any[]) => {
  console.log(`[Sentry] ${message}`, ...args);
  return 'some-error-identifier';
};

let isSentryConnected = false;

if (window.SQUARESPACE_SENTRY) {
  isSentryConnected = true;
  captureMessage = window.SQUARESPACE_SENTRY.captureMessage;
  captureException = window.SQUARESPACE_SENTRY.captureException;
  captureEvent = window.SQUARESPACE_SENTRY.captureEvent;
  configureScope = window.SQUARESPACE_SENTRY.configureScope;
  withScope = window.SQUARESPACE_SENTRY.withScope;
} else {
  console.warn('SENTRY WAS __NOT__ INITIALIZED. Logs will be forwarded to console instead.');
  captureMessage = logToConsole;
  captureException = logToConsole;
  captureEvent = logToConsole;
  configureScope = () => {};
  withScope = () => {};
}

export {
  captureMessage,
  captureException,
  captureEvent,
  configureScope,
  withScope,
  isSentryConnected,
};
