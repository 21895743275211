import {
  BoundaryRatios as ResponsiveBoundaryRatios,
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.AudioFullCenter01 class.
 *
 * @module squarespace-slide-rendering-layouts-audio-full-center-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-audio-full-center-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for audio-full-center-01.
   *
   * @class AudioFullCenter01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.AudioFullCenter01 = Y.Base.create('AudioFullCenter01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var copyNode = contentBox.querySelector('.group-copy');
        var logoNode = contentBox.querySelector('[data-compound-type="logo"] > *');
        var responsiveWrapperNode = contentBox.querySelector('.responsive-wrapper');
        var contentNode = contentBox.querySelector('.layer-front .sqs-slide-layer-content');
        var albumNode = contentBox.querySelector('[data-slice-type="album"]');

        // Jira SIX-17483: slices no longer pass parameters so DOM manipulation
        // necessary to maintain the expected layout.
        var tracks = Array.prototype.slice.call(contentBox.querySelectorAll('.track'));
        tracks.forEach(function(item, index, array) {
          item.insertBefore(
            item.querySelector('.track-title'),
            item.querySelector('.track-progress-bar')
          );
        });

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthRatio: 0.49,
          minFontSize: 13,
          onReset: function(rootNode, widthBoundaryNode) {
            widthBoundaryNode.classList.remove('text-align-center');
          },
          onMinFontSize: function(rootNode, widthBoundaryNode) {
            widthBoundaryNode.classList.add('text-align-center');
          }
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          heightBoundaryNode: contentBox.querySelector('.sqs-slide-layer'),
          widthBoundaryNode: copyNode,
          widthRatio: 0.9,
          heightRatio: 0.16,
          scale: 0.99,
          minFontSize: 18
        }));

        // Actions and custom form font size
        var actionsFontSizeComponent = new ResponsiveFontSize({
          rootNode: responsiveWrapperNode,
          fontSizeNodes: [
            contentBox.querySelector(('[data-compound-type="action"] ul')),
            contentBox.querySelector('[data-slice-type="custom-form"]')
          ],
          widthBoundaryNode: copyNode,
          minFontSize: 10,
          widthRatio: 0.9,
          enabled: true,
          onReset: function(rootNode) {
            rootNode = new Y.Node(rootNode);

            rootNode.removeClass('stacked');
            if (contentNode.clientWidth > 900) {
              rootNode.addClass('top-right');
              if (rootNode.one('[data-slice-type]:not([data-content-empty="true"])')) {
                rootNode.ancestor('.sqs-slide').one('[data-compound-type="logo"]').addClass('text-align-left');
              }
            } else {
              rootNode.removeClass('top-right');
              if (rootNode.one('[data-slice-type]:not([data-content-empty="true"])')) {
                rootNode.ancestor('.sqs-slide').one('[data-compound-type="logo"]').removeClass('text-align-left');
              }
            }
          },
          onMinFontSize: function(rootNode) {
            rootNode = new Y.Node(rootNode);

            rootNode.addClass('stacked');
            rootNode.removeClass('top-right');
            rootNode.ancestor('.sqs-slide').one('[data-compound-type="logo"]').removeClass('text-align-left');
          }
        });

        this._registerResponsiveComponent(actionsFontSizeComponent);

        // Overlap between logo first child and responsive wrapper
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: logoNode,
          targetNode: responsiveWrapperNode,
          padding: 60,
          onReset: function(rootNode, targetNode) {
            if (contentNode.clientWidth > 900) {
              targetNode.classList.add('top-right');
              if (targetNode.querySelector('[data-slice-type]:not([data-content-empty="true"])')) {
                rootNode.parentNode.classList.add('text-align-left');
              }
            } else {
              targetNode.classList.remove('top-right');
              if (targetNode.querySelector('[data-slice-type]:not([data-content-empty="true"])')) {
                rootNode.parentNode.classList.remove('text-align-left');
              }
            }
          },
          onRespond: function(rootNode, targetNode) {
            targetNode.classList.remove('top-right');
            rootNode.parentNode.classList.remove('text-align-left');
          }
        }));

        // Overlap between copy and album player
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: copyNode,
          targetNode: albumNode,
          padding: 100,
          onReset: function(rootNode, targetNode) {
            rootNode.classList.add('align-center-js');
            rootNode.classList.remove('stacked');

            contentNode.classList.remove('scroll');
            targetNode.parentNode.classList.add('bottom');

            Y.later(10, rootNode, function() {
              this.style.marginTop = -Math.floor(this.clientHeight / 2) + 'px';
              this.style.marginLeft = -Math.floor(this.clientWidth / 2) + 'px';
            });
          },
          onRespond: function(rootNode, targetNode) {
            rootNode.classList.remove('align-center-js');
            rootNode.classList.add('stacked');

            contentNode.classList.add('scroll');
            targetNode.parentNode.classList.remove('bottom');
          }
        }));

        // Responsive Node boundary detection
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: responsiveWrapperNode,
          widthBoundaryNode: copyNode,
          widthRatio: 0.8,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
            rootNode.classList.add('top-right');
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('stacked');
            rootNode.classList.remove('top-right');
          }
        }));

        // Overlap between copy and responsive wrapper
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: copyNode,
          targetNode: responsiveWrapperNode,
          padding: 60,
          onReset: function(rootNode, targetNode) {
            rootNode.classList.add('align-center-js');
            rootNode.classList.remove('stacked');
            targetNode.classList.add('top-right');

            contentNode.classList.remove('scroll');

            Y.later(10, rootNode, function() {
              this.style.marginTop = -Math.floor(this.clientHeight / 2) + 'px';
              this.style.marginLeft = -Math.floor(this.clientWidth / 2) + 'px';
            });
          },
          onRespond: function(rootNode, targetNode) {
            rootNode.classList.remove('align-center-js');
            rootNode.classList.add('stacked');
            targetNode.classList.remove('top-right');

            contentNode.classList.add('scroll');
          }
        }));

        // Scroll on overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: copyNode,
          widthRatio: 0.9,
          onReset: function(rootNode) {
            rootNode.classList.add('align-center-js');
            rootNode.classList.remove('stacked');

            contentNode.classList.remove('scroll');
            albumNode.parentNode.classList.add('bottom');

            rootNode.style.marginTop = -Math.floor(rootNode.clientHeight / 2) + 'px';
            rootNode.style.marginLeft = -Math.floor(rootNode.clientWidth / 2) + 'px';
          },
          onRespond: function(rootNode) {
            rootNode.classList.remove('align-center-js');
            rootNode.classList.add('stacked');

            contentNode.classList.add('scroll');
            albumNode.parentNode.classList.remove('bottom');
          }
        }));
      }
    }
  );

}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
