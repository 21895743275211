import {
  BoundaryRatios as ResponsiveBoundaryRatios,
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
  OverlapDetector as ResponsiveOverlapDetector
} from '@sqs/responsive';


/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.VideoFullBottom01 class.
 *
 * @module squarespace-slide-rendering-layouts-video-full-bottom-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-video-full-bottom-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for video-full-bottom-01.
   *
   * @class VideoFullBottom01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.VideoFullBottom01 = Y.Base.create('VideoFullBottom01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var copyNode = contentBox.querySelector('.group-copy');
        var contentNode = contentBox.querySelector('.group-content');
        var linksNode = contentBox.querySelector('.group-links');
        var overlapPadding = 60;

        // Branding heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthBoundaryNode: contentNode,
          minFontSize: 20,
          widthRatio: 0.99,
          onReset: function(rootNode) {
            rootNode.style.whiteSpace = 'nowrap';
          },
          onMinFontSize: function(rootNode) {
            rootNode.style.whiteSpace = 'normal';
          }
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          minFontSize: 15,
          widthBoundaryNode: contentNode,
          widthRatio: 0.99,
          onReset: function(rootNode) {
            rootNode.style.whiteSpace = 'nowrap';
          },
          onMinFontSize: function(rootNode) {
            rootNode.style.whiteSpace = 'normal';
          }
        }));

        // Actions and custom form font size
        var actionsFontSizeComponent = new ResponsiveFontSize({
          rootNode: contentBox.querySelector('.responsive-wrapper'),
          fontSizeNodes: [
            contentBox.querySelector(('[data-compound-type="action"] ul')),
            contentBox.querySelector('[data-slice-type="custom-form"]')
          ],
          widthBoundaryNode: contentBox.querySelector('.group-content'),
          minFontSize: 10,
          widthRatio: 0.45,
          enabled: true,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
            rootNode.classList.remove('text-align-left');
          },
          onMinFontSize: function(rootNode) {
            rootNode.classList.add('stacked');
            rootNode.classList.add('text-align-left');
          }
        });
        this._registerResponsiveComponent(actionsFontSizeComponent);

        // Overlap between body and inline link group, even when body is empty
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('[data-slice-type="body"]'),
          targetNode: linksNode,
          padding: 60,
          onReset: function() {
            copyNode.classList.remove('stacked');
          },
          onRespond: function() {
            copyNode.classList.add('stacked');
          }
        }));

        // Overlap between copy and inline link group
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: copyNode,
          targetNode: linksNode,
          padding: 60,
          onReset: function(rootNode, targetNode) {
            var pNode = rootNode.parentNode;
            pNode.classList.remove('stacked');
            pNode.classList.remove('left-right-floats-off');
            targetNode.querySelector('.responsive-wrapper').classList.remove('wrapped');
            actionsFontSizeComponent.disable();
          },
          onRespond: function(rootNode, targetNode) {
            var pNode = rootNode.parentNode;
            pNode.classList.add('stacked');
            pNode.classList.add('left-right-floats-off');
            targetNode.querySelector('.responsive-wrapper').classList.remove('wrapped');
            actionsFontSizeComponent.enable();
          }
        }));

        // Social Icons boundary detection
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: contentBox.querySelector('.group-links .responsive-wrapper'),
          widthBoundaryNode: contentNode,
          widthRatio: 0.99,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
            socialIconBoundaryComponent.disable();
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('stacked');
            socialIconBoundaryComponent.enable();
          }
        }));

        // Social Icons boundary detection
        var socialIconBoundaryComponent = new ResponsiveBoundaryRatios({
          rootNode: contentBox.querySelector('[data-slice-type="social-icons"]:not([data-content-empty="true"])'),
          widthBoundaryNode: contentNode,
          widthRatio: 0.7,
          enabled: false,
          onReset: function(rootNode) {
            rootNode.classList.remove('wrapped');
            if (rootNode.querySelector('.break')) {
              rootNode.querySelector('.break').remove();
            }
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('wrapped');
            // Social icons wrap onto two lines
            var icons = rootNode.childNodes;
            if (!rootNode.querySelector('.break')) {
              var item = new Y.Node(icons.item(Math.floor(icons.length / 2)));
              item.insert('<br class="break">', 'before');
            }
          }
        });
        this._registerResponsiveComponent(socialIconBoundaryComponent);

        // Overlap between content group and video button: step 1
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentNode,
          targetNode: contentBox.querySelector('.group-video'),
          padding: 120,
          onReset: function(rootNode, targetNode) {
            videoOverlapComponent.disable();
            targetNode.classList.add('text-align-center');
            targetNode.classList.add('align-center');
            targetNode.classList.remove('bottom-full-width');
            rootNode.classList.add('full-width');
          },
          onRespond: function(rootNode, targetNode) {
            targetNode.classList.remove('text-align-center');
            targetNode.classList.remove('align-center');
            if (contentBox.querySelector('.vertical-positioning-top')) {
              rootNode.classList.remove('full-width');
              targetNode.classList.add('bottom-full-width');
            }
            videoOverlapComponent.enable();
          }
        }));

        // Overlap between content group and video button: step 2
        var videoOverlapComponent = new ResponsiveOverlapDetector({
          rootNode: contentNode,
          targetNode: contentBox.querySelector('.group-video'),
          padding: 60,
          enabled: false,
          onReset: function(rootNode, targetNode) {
            //rootNode.classList.add('full-width');
            rootNode.parentNode.classList.remove('scroll');
            rootNode.style.top = null;
            rootNode.style.bottom = null;
            targetNode.style.top = null;
            targetNode.style.bottom = null;
            //targetNode.classList.remove('stacked');
          },
          onRespond: function(rootNode, targetNode) {
            //rootNode.classList.remove('full-width');
            setTimeout(function() {
              this.classList.add('scroll');
            }.bind(rootNode.parentNode), 50);

            if (contentBox.querySelector('.vertical-positioning-top')) {
              targetNode.style.top = rootNode.getBoundingClientRect().bottom + overlapPadding + 'px';
              targetNode.style.bottom = 'auto';
            } else {
              rootNode.style.top = targetNode.getBoundingClientRect().bottom + overlapPadding + 'px';
              rootNode.style.bottom = 'auto';
            }
            //targetNode.classList.add('stacked');
          }
        });
        this._registerResponsiveComponent(videoOverlapComponent);

        // Scroll on contentNode overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: contentNode,
          onReset: function(rootNode) {
            //contentNode.classList.add('full-width');
            rootNode.parentNode.classList.remove('scroll');
          },
          onRespond: function(rootNode) {
            //contentNode.classList.remove('full-width');
            setTimeout(function() {
              this.classList.add('scroll');
            }.bind(rootNode.parentNode), 50);
          }
        }));

        // Scroll on video group overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: contentBox.querySelector('.group-video'),
          onReset: function(rootNode) {
            //contentNode.classList.add('full-width');
            rootNode.parentNode.classList.remove('scroll');
          },
          onRespond: function(rootNode) {
            //contentNode.classList.remove('full-width');
            setTimeout(function() {
              this.classList.add('scroll');
            }.bind(rootNode.parentNode), 50);
          }
        }));
      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
