"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.DomainTransferStatus */

/* Java class defined in repository squarespace-v6 */
var DomainTransferStatus;

(function (DomainTransferStatus) {
  DomainTransferStatus["UNINITIATED"] = 1;
  DomainTransferStatus["PENDING_CUSTOMER_APPROVAL"] = 2;
  DomainTransferStatus["PENDING_WINNER_APPROVAL"] = 3;
  DomainTransferStatus["PENDING_LOSER_APPROVAL"] = 4;
  DomainTransferStatus["CANCELED"] = 5;
  DomainTransferStatus["COMPLETED"] = 6;
  DomainTransferStatus["UNKNOWN_STATUS"] = 7;
  DomainTransferStatus["USER_CANCELED"] = 8;
  DomainTransferStatus["PENDING_IPS_TAG_UPDATE"] = 9;
  DomainTransferStatus["FAILED"] = 10;
  DomainTransferStatus["AWAITING_PROCESSING"] = 11;
  DomainTransferStatus["PROCESSING"] = 12;
  DomainTransferStatus["DISMISSED"] = 13;
  DomainTransferStatus["AWAITING_UNLOCKING"] = 14;
  DomainTransferStatus["AWAITING_AUTH_CODE"] = 15;
})(DomainTransferStatus || (DomainTransferStatus = {}));

var _default = DomainTransferStatus;
exports["default"] = _default;
module.exports = exports.default;