"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SystemWebsiteSubject */

/* Java class defined in repository squarespace-v6 */
var SystemWebsiteSubjects;

(function (SystemWebsiteSubjects) {
  SystemWebsiteSubjects["FAITH"] = "faith";
  SystemWebsiteSubjects["FASHION"] = "fashion";
  SystemWebsiteSubjects["FINE_ART"] = "fine_art";
  SystemWebsiteSubjects["FITNESS"] = "fitness";
  SystemWebsiteSubjects["FOOD"] = "food";
  SystemWebsiteSubjects["GRAPHIC_DESIGN"] = "design";
  SystemWebsiteSubjects["HEALTH_CARE"] = "health";
  SystemWebsiteSubjects["MUSIC"] = "music";
  SystemWebsiteSubjects["PHOTOGRAPHY"] = "photo";
  SystemWebsiteSubjects["TECHNOLOGY"] = "tech";
  SystemWebsiteSubjects["VIDEOGRAPHY"] = "video";
  SystemWebsiteSubjects["WEDDING_COUPLE"] = "wedding_couple";
  SystemWebsiteSubjects["WEDDING_SERVICES"] = "wedding_services";
  SystemWebsiteSubjects["OTHER"] = "other";
})(SystemWebsiteSubjects || (SystemWebsiteSubjects = {}));

var _default = SystemWebsiteSubjects;
exports["default"] = _default;
module.exports = exports.default;