"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.BackgroundSource */

/* Java class defined in repository cms-legacy-data */
var BackgroundSource;

(function (BackgroundSource) {
  BackgroundSource["UPLOAD"] = 1;
  BackgroundSource["INSTAGRAM"] = 2;
  BackgroundSource["VIDEO"] = 3;
  BackgroundSource["NONE"] = 4;
})(BackgroundSource || (BackgroundSource = {}));

var _default = BackgroundSource;
exports["default"] = _default;
module.exports = exports.default;