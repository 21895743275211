"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.MeasurementStandard */

/* Java class defined in repository squarespace-v6 */
var MeasurementStandard;

(function (MeasurementStandard) {
  MeasurementStandard["IMPERIAL"] = 1;
  MeasurementStandard["METRIC"] = 2;
})(MeasurementStandard || (MeasurementStandard = {}));

var _default = MeasurementStandard;
exports["default"] = _default;
module.exports = exports.default;