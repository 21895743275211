/**
 * The module containing the Squarespace.SlideRendering.Layouts.Factory class.
 * @module squarespace-slide-rendering-layouts-factory
 */
YUI.add('squarespace-slide-rendering-layouts-factory', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  var LAYOUTS = {
    'audio-full-center-01': Y.Squarespace.SlideRendering.Layouts.AudioFullCenter01,
    'audio-full-left-01': Y.Squarespace.SlideRendering.Layouts.AudioFullLeft01,
    'audio-full-right-01': Y.Squarespace.SlideRendering.Layouts.AudioFullRight01,
    'audio-left-right-01': Y.Squarespace.SlideRendering.Layouts.AudioLeftRight01,
    'landing-full-center-01': Y.Squarespace.SlideRendering.Layouts.LandingFullCenter01,
    'landing-full-center-02': Y.Squarespace.SlideRendering.Layouts.LandingFullCenter02,
    'landing-full-centercard-01': Y.Squarespace.SlideRendering.Layouts.LandingFullCenterCard01,
    'landing-full-left-01': Y.Squarespace.SlideRendering.Layouts.LandingFullLeft01,
    'landing-full-left-02': Y.Squarespace.SlideRendering.Layouts.LandingFullLeft02,
    'landing-full-right-01': Y.Squarespace.SlideRendering.Layouts.LandingFullRight01,
    'landing-full-topleft-01': Y.Squarespace.SlideRendering.Layouts.LandingFullTopLeft01,
    'landing-left-right-01': Y.Squarespace.SlideRendering.Layouts.LandingLeftRight01,
    'landing-right-left-01': Y.Squarespace.SlideRendering.Layouts.LandingRightLeft01,
    'location-full-corners-01': Y.Squarespace.SlideRendering.Layouts.LocationFullCorners01,
    'location-right-left-01': Y.Squarespace.SlideRendering.Layouts.LocationRightLeft01,
    'lock-full-center-01': Y.Squarespace.SlideRendering.Layouts.LockFullCenter01,
    'lock-full-center-02': Y.Squarespace.SlideRendering.Layouts.LockFullCenter02,
    'lock-full-center-03': Y.Squarespace.SlideRendering.Layouts.LockFullCenter03,
    'lock-full-center-04': Y.Squarespace.SlideRendering.Layouts.LockFullCenter04,
    'lock-full-center-05': Y.Squarespace.SlideRendering.Layouts.LockFullCenter05,
    'lock-full-bottomleft-01': Y.Squarespace.SlideRendering.Layouts.LockFullBottomleft01,
    'overlay-center-card-01': Y.Squarespace.SlideRendering.Layouts.OverlayCenterCard01,
    'overlay-center-card-02': Y.Squarespace.SlideRendering.Layouts.OverlayCenterCard02,
    'overlay-center-card-03': Y.Squarespace.SlideRendering.Layouts.OverlayCenterCard03,
    'overlay-center-card-04': Y.Squarespace.SlideRendering.Layouts.OverlayCenterCard04,
    'overlay-center-card-05': Y.Squarespace.SlideRendering.Layouts.OverlayCenterCard05,
    'overlay-corner-card-01': Y.Squarespace.SlideRendering.Layouts.OverlayCornerCard01,
    'overlay-full-bottom-01': Y.Squarespace.SlideRendering.Layouts.OverlayFullBottom01,
    'overlay-full-center-01': Y.Squarespace.SlideRendering.Layouts.OverlayFullCenter01,
    'overlay-full-center-02': Y.Squarespace.SlideRendering.Layouts.OverlayFullCenter02,
    'profile-center-center-01': Y.Squarespace.SlideRendering.Layouts.ProfileCenterCenter01,
    'profile-center-center-02': Y.Squarespace.SlideRendering.Layouts.ProfileCenterCenter02,
    'profile-full-bottom-01': Y.Squarespace.SlideRendering.Layouts.ProfileFullBottom01,
    'profile-full-center-01': Y.Squarespace.SlideRendering.Layouts.ProfileFullCenter01,
    'profile-full-left-01': Y.Squarespace.SlideRendering.Layouts.ProfileFullLeft01,
    'profile-full-middleband-01': Y.Squarespace.SlideRendering.Layouts.ProfileFullMiddleband01,
    'profile-full-right-01': Y.Squarespace.SlideRendering.Layouts.ProfileFullRight01,
    'profile-full-top-01': Y.Squarespace.SlideRendering.Layouts.ProfileFullTop01,
    'profile-left-left-01': Y.Squarespace.SlideRendering.Layouts.ProfileLeftLeft01,
    'profile-left-right-01': Y.Squarespace.SlideRendering.Layouts.ProfileLeftRight01,
    'twitter-full-center-01': Y.Squarespace.SlideRendering.Layouts.TwitterFullCenter01,
    'twitter-full-left-01': Y.Squarespace.SlideRendering.Layouts.TwitterFullLeft01,
    'video-full-bottom-01': Y.Squarespace.SlideRendering.Layouts.VideoFullBottom01,
    'video-full-center-01': Y.Squarespace.SlideRendering.Layouts.VideoFullCenter01,
    'video-full-corners-01': Y.Squarespace.SlideRendering.Layouts.VideoFullCorners01
  };

  /**
   * A factory class for creating Squarespace.SlideRendering.Layouts
   * subclasses for a given Layout ID. Defaults to base if a subclass
   * is not available.
   *
   * @class Factory
   * @static
   * @namespace Squarespace.SlideRendering.Layouts
   */
  Y.Squarespace.SlideRendering.Layouts.Factory = {
    create: function(layoutId, config) {
      var Ctor = (LAYOUTS[layoutId] || Y.Squarespace.SlideRendering.Layouts.Base);
      return new Ctor(config);
    }
  };

}, '1.0', {
  requires: [
    'squarespace-enum',
    'squarespace-slide-rendering-layouts-audio-full-center-01',
    'squarespace-slide-rendering-layouts-audio-full-left-01',
    'squarespace-slide-rendering-layouts-audio-full-right-01',
    'squarespace-slide-rendering-layouts-audio-left-right-01',
    'squarespace-slide-rendering-layouts-base',
    'squarespace-slide-rendering-layouts-landing-full-center-01',
    'squarespace-slide-rendering-layouts-landing-full-center-02',
    'squarespace-slide-rendering-layouts-landing-full-centercard-01',
    'squarespace-slide-rendering-layouts-landing-full-left-01',
    'squarespace-slide-rendering-layouts-landing-full-left-02',
    'squarespace-slide-rendering-layouts-landing-full-right-01',
    'squarespace-slide-rendering-layouts-landing-full-topleft-01',
    'squarespace-slide-rendering-layouts-landing-left-right-01',
    'squarespace-slide-rendering-layouts-landing-right-left-01',
    'squarespace-slide-rendering-layouts-location-full-corners-01',
    'squarespace-slide-rendering-layouts-location-right-left-01',
    'squarespace-slide-rendering-layouts-lock-full-bottomleft-01',
    'squarespace-slide-rendering-layouts-lock-full-center-01',
    'squarespace-slide-rendering-layouts-lock-full-center-02',
    'squarespace-slide-rendering-layouts-lock-full-center-03',
    'squarespace-slide-rendering-layouts-lock-full-center-04',
    'squarespace-slide-rendering-layouts-lock-full-center-05',
    'squarespace-slide-rendering-layouts-overlay-center-card-01',
    'squarespace-slide-rendering-layouts-overlay-center-card-02',
    'squarespace-slide-rendering-layouts-overlay-center-card-03',
    'squarespace-slide-rendering-layouts-overlay-center-card-04',
    'squarespace-slide-rendering-layouts-overlay-center-card-05',
    'squarespace-slide-rendering-layouts-overlay-corner-card-01',
    'squarespace-slide-rendering-layouts-overlay-full-bottom-01',
    'squarespace-slide-rendering-layouts-overlay-full-center-01',
    'squarespace-slide-rendering-layouts-overlay-full-center-02',
    'squarespace-slide-rendering-layouts-profile-center-center-01',
    'squarespace-slide-rendering-layouts-profile-center-center-02',
    'squarespace-slide-rendering-layouts-profile-full-bottom-01',
    'squarespace-slide-rendering-layouts-profile-full-center-01',
    'squarespace-slide-rendering-layouts-profile-full-left-01',
    'squarespace-slide-rendering-layouts-profile-full-middleband-01',
    'squarespace-slide-rendering-layouts-profile-full-right-01',
    'squarespace-slide-rendering-layouts-profile-full-top-01',
    'squarespace-slide-rendering-layouts-profile-left-left-01',
    'squarespace-slide-rendering-layouts-profile-left-right-01',
    'squarespace-slide-rendering-layouts-twitter-full-center-01',
    'squarespace-slide-rendering-layouts-twitter-full-left-01',
    'squarespace-slide-rendering-layouts-video-full-bottom-01',
    'squarespace-slide-rendering-layouts-video-full-center-01',
    'squarespace-slide-rendering-layouts-video-full-corners-01'
  ]
});
