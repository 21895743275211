import { t } from 'shared/i18n';
import GoogleReCaptchaAlignmentType from '@sqs/enums/GoogleReCaptchaAlignmentType';
import GoogleReCaptchaThemeType from '@sqs/enums/GoogleReCaptchaThemeType';
/**
 * The module containing the Squarespace.Models.SliceDemoCustomForm class.
 *
 * @module squarespace-models-slice-demo-custom-form
 * @author dbarber
 */
YUI.add('squarespace-models-slice-demo-custom-form', function (Y) {

  Y.namespace('Squarespace.Models');

  /**
   * The model used for the demo custom form for slices.
   *
   * @class SliceDemoCustomForm
   * @constructor
   * @namespace Squarespace.Models
   * @extends Squarespace.Models.Form
   */
  Y.Squarespace.Models.SliceDemoCustomForm = Y.Base.create('SliceDemoCustomForm',
  Y.Squarespace.Models.Form,
  [],
  {},
  {
    ATTRS: {
      // Override
      type: {
        value: Y.Squarespace.FormType.SLIDE },


      // Override
      name: {
        value: t("Demo Form") },




      // Override
      fields: {
        value: [
        {
          type: 'name',
          id: null,
          locked: false,
          title: t("Name"),


          description: '',
          required: true,
          name: true },

        {
          type: 'email',
          id: null,
          locked: false,
          title: t("Email Address"),


          description: '',
          placeholder: '',
          required: true,
          email: true },

        {
          type: 'text',
          id: null,
          locked: false,
          title: t("Subject"),


          description: '',
          placeholder: '',
          required: true,
          text: true },

        {
          type: 'textarea',
          id: null,
          locked: false,
          title: t("Message"),


          description: '',
          placeholder: '',
          required: true,
          textarea: true }] },




      // Override
      submitButtonText: {
        value: t("Submit") },




      // Override
      disclaimerMessage: {
        value: {
          html: t("We respect your privacy.") } },



      // Override
      submissionMessage: {
        value: {
          html: t("Thank you!") } },



      captchaEnabled: {
        value: false },


      captchaTheme: {
        value: GoogleReCaptchaThemeType.LIGHT },


      captchaAlignment: {
        value: GoogleReCaptchaAlignmentType.LEFT } } });




}, '1.0', {
  requires: [
  'base',
  'squarespace-enum',
  'squarespace-models-form'] });