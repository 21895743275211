import SlideType from '@sqs/enums/SlideType';
import { SlideTypeNames, TweakPageTypes } from '@sqs/websites-constants';

const tweakPageToSelectorMap = {
  [TweakPageTypes.POPUP_OVERLAY]: `.sqs-slide-wrapper[data-slide-type="${SlideTypeNames[SlideType.POPUP_OVERLAY]}"]`,
  [TweakPageTypes.SPLASH_PAGE]: `.sqs-slide-wrapper[data-slide-type="${SlideTypeNames[SlideType.COVER_PAGE]}"]`,
  [TweakPageTypes.LOCK_SCREEN]: `.sqs-slide-wrapper[data-slide-type="${SlideTypeNames[SlideType.LOCK_SCREEN]}"]`
};

const getSiteCssSelector = () => 'link[href*="site.css"]';

const getSlideStylesId = () => 'slide-styles';

const getCheckoutStylesId = () => 'checkout-page-styles';

const getWrapperSelector = (slideType) =>
  `.sqs-slide-wrapper[data-slide-type="${SlideTypeNames[slideType]}"]`;

/**
 * @param {string} tweakPageType a pageType like 'CHECKOUT' or 'STYLE'
 * @return {string} css selector
 */
const getTweakWrapperSelector = (tweakPageType) => {
  const result = tweakPageType ? tweakPageToSelectorMap[tweakPageType] : '';
  return result || '';
};

const getTweakStyleTagId = (id) => `style_tweak_tag_${id}`;

export {
  getSiteCssSelector,
  getSlideStylesId,
  getCheckoutStylesId,
  getWrapperSelector,
  getTweakWrapperSelector,
  getTweakStyleTagId
};
