"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.JobStatus */

/* Java class defined in repository cms-legacy-data */
var JobStatuses;

(function (JobStatuses) {
  JobStatuses["WAITING"] = 1;
  JobStatuses["PROCESSING"] = 2;
  JobStatuses["SUCCESS"] = 3;
  JobStatuses["FAILURE"] = 4;
  JobStatuses["CANCELLED"] = 5;
  JobStatuses["SCHEDULED"] = 6;
})(JobStatuses || (JobStatuses = {}));

var _default = JobStatuses;
exports["default"] = _default;
module.exports = exports.default;