"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.DomainType */

/* Java class defined in repository squarespace-v6 */
var DomainTypes;

(function (DomainTypes) {
  DomainTypes["APPLICATION"] = 1;
  DomainTypes["LINKED"] = 2;
  DomainTypes["SQUARESPACE"] = 3;
  DomainTypes["GOOGLE"] = 4;
})(DomainTypes || (DomainTypes = {}));

var _default = DomainTypes;
exports["default"] = _default;
module.exports = exports.default;