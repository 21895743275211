"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.ReferralSearchResultStatus */

/* Java class defined in repository squarespace-v6 */
var ReferralSearchResultStatus;

(function (ReferralSearchResultStatus) {
  ReferralSearchResultStatus["NO_MATCH"] = 0;
  ReferralSearchResultStatus["SINGLE_MATCH"] = 1;
  ReferralSearchResultStatus["MULTI_MATCH"] = 2;
})(ReferralSearchResultStatus || (ReferralSearchResultStatus = {}));

var _default = ReferralSearchResultStatus;
exports["default"] = _default;
module.exports = exports.default;