"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.FeatureSetIdentifier */

/* Java class defined in repository squarespace-v6 */
var FeatureSetIdentifiers;

(function (FeatureSetIdentifiers) {
  FeatureSetIdentifiers["WEBSITE_CLASSIC_TRIAL"] = "website-classic-trial";
  FeatureSetIdentifiers["WEBSITE_CLASSIC_PERSONAL"] = "website-classic-personal";
  FeatureSetIdentifiers["WEBSITE_CLASSIC_PERSONAL_GATED_TEST"] = "website-classic-personal-gated";
  FeatureSetIdentifiers["WEBSITE_CLASSIC_PERSONAL_RESOLD"] = "website-classic-personal-resold";
  FeatureSetIdentifiers["WEBSITE_CLASSIC_PROFESSIONAL"] = "website-classic-professional";
  FeatureSetIdentifiers["WEBSITE_BUSINESS_RESOLD"] = "website-classic-professional-resold";
  FeatureSetIdentifiers["WEBSITE_CLASSIC_COMPED"] = "website-classic-comped";
  FeatureSetIdentifiers["WEBSITE_LEGACY_COMPED"] = "website-legacy-comped";
  FeatureSetIdentifiers["WEBSITE_LEGACY_PROFESSIONAL"] = "website-legacy-professional";
  FeatureSetIdentifiers["WEBSITE_LEGACY_PERSONAL"] = "website-legacy-personal";
  FeatureSetIdentifiers["WEBSITE_LEGACY_COMMERCE"] = "website-legacy-commerce";
  FeatureSetIdentifiers["WEBSITE_BUSINESS_PRICE_TEST_AUG_2017"] = "website-business-price-test-aug-2017";
  FeatureSetIdentifiers["WEBSITE_COVER_PAGE"] = "website-starter";
  FeatureSetIdentifiers["WEBSITE_COVER_PAGE_TRIAL"] = "website-starter-trial";
  FeatureSetIdentifiers["WEBSITE_COMMERCE_TRIAL"] = "website-commerce-trial";
  FeatureSetIdentifiers["WEBSITE_COMMERCE_BASIC"] = "website-commerce-basic";
  FeatureSetIdentifiers["WEBSITE_COMMERCE_BASIC_RESOLD"] = "website-commerce-basic-resold";
  FeatureSetIdentifiers["WEBSITE_COMMERCE_ADVANCED"] = "website-commerce-advanced";
  FeatureSetIdentifiers["WEBSITE_COMMERCE_ADVANCED_RESOLD"] = "website-commerce-advanced-resold";
  FeatureSetIdentifiers["WEBSITE_COMMERCE_ADVANCED_WITH_CALLBACKS"] = "website-commerce-advanced-callbacks";
  FeatureSetIdentifiers["WEBSITE_ENTERPRISE_TRIAL"] = "website-enterprise-trial";
  FeatureSetIdentifiers["WEBSITE_ENTERPRISE_SELECT"] = "website-enterprise-select";
  FeatureSetIdentifiers["PARKING_PAGE_REMOVED"] = "parking-page-removed";
  FeatureSetIdentifiers["WEBSITE_V5_LINKED_LOW"] = "website-v5-linked-low";
  FeatureSetIdentifiers["WEBSITE_V5_LINKED_HIGH"] = "website-v5-linked-high";
  FeatureSetIdentifiers["WEBSITE_PARKING_PAGE"] = "website-parking-page";
})(FeatureSetIdentifiers || (FeatureSetIdentifiers = {}));

var _default = FeatureSetIdentifiers;
exports["default"] = _default;
module.exports = exports.default;