import Flag from '@sqs/enums/Flag';
import { legacyV6Flags as BetaFeaturesUtils } from '@sqs/universal-flags';

import { t } from 'shared/i18n';
import evaluateJsonTemplate from 'shared/utils/evaluateJsonTemplate';

import renderCharacterCounters from 'shared/components/forms/CharacterCounter';
import FormHandler from 'shared/utils/FormHandler';
import * as CensusUtils from 'shared/utils/census/CensusUtils';

/**
 * @deprecated prefer `apps/Forms`
 * A widget used to asynchronously render forms.  Useful for cases where you
 * want to create a form where submission either happens asynchronously (rather
 * than via a direct form POST) or where you want to grab values out of a form.
 */
YUI.add('squarespace-async-form', function (Y) {

  Y.namespace('Squarespace.Widgets');

  /**
   * A widget used to asynchronously render forms.  Useful for cases where you
   * want to create a form where submission either happens asynchronously
   * (rather than via a direct form POST) or where you want to grab values out
   * of a form.
   *
   * @namespace Squarespace.Widgets
   * @class     AsyncForm
   * @extends   Squarespace.Widgets.SSWidget
   */
  var AsyncForm = Y.Squarespace.Widgets.AsyncForm = Y.Base.create(
  'AsyncForm',
  Y.Squarespace.Widgets.SSWidget,
  [],
  {
    initializer: function () {
      /**
      * Fires when the form is submitted
      * @event submission
      */
      this.publish('submission');

      this._typeGetterMap = {
        date: this._getMultiFieldVal,
        name: this._getMultiFieldVal,
        time: this._getMultiFieldVal,
        address: this._getMultiFieldVal,
        checkbox: this._getOptionFieldVal,
        likert: this._getLikertFieldVal,
        radio: this._getRadioFieldVal,
        select: this._getSelectVal,
        phone: this._getPhoneFieldVal };


      this._typeSetterMap = {
        date: this._setMultiFieldVal,
        name: this._setMultiFieldVal,
        time: this._setMultiFieldVal,
        address: this._setMultiFieldVal,
        checkbox: this._setOptionFieldVal,
        likert: this._setLikertFieldVal,
        radio: this._setRadioFieldVal,
        select: this._setSelectVal,
        phone: this._setPhoneFieldVal };


      this._defaultGetter = this._getSingleFieldVal;
      this._defaultSetter = this._setSingleFieldVal;
    },

    renderUI: function () {
      AsyncForm.superclass.renderUI.call(this);

      var formJson = this.get('form');
      var formFields = formJson.fields;

      if (Y.Lang.isString(formFields[0])) {
        formFields = Y.Array.map(formFields, Y.JSON.parse);
      }

      CensusUtils.trackFormRender(formJson.id);

      // Passed to templates-v6/system/blocks/form.block
      var templateContext = {
        showTitle: this.get('showTitle'),
        preventSubmit: this.get('preventDefaultSubmit') || this.get('preventAllSubmits'),
        hideSubmitButton: this.get('hideSubmitButton'),
        formId: formJson.id,
        formName: this.get('formName'),
        formFields: formFields,
        formSubmitButtonText: this.get('formSubmitButtonText'),
        formSubmissionMessage: formJson.parsedSubmissionMessage,
        formSubmissionHTML: formJson.submissionHTML,
        successRedirect: formJson.successRedirect,
        // parsedDisclaimerMessage comes from FormWidget.java parsing
        disclaimerMessage: formJson.parsedDisclaimerMessage,
        captchaEnabled: formJson.captchaEnabled,
        captchaTheme: formJson.captchaTheme,
        captchaAlignment: formJson.captchaAlignment };


      var contentBox = this.get('contentBox');
      var formHtml = this.evaluateTemplate(templateContext);

      contentBox.append(formHtml);

      this._formEl = contentBox.one('form');
      this._setFormData();
      renderCharacterCounters(this._formEl.getDOMNode());
    },

    _onSubmit: function asyncFormOnSubmit(e) {
      if (this.get('preventDefaultSubmit') && !this.get('preventAllSubmits')) {
        this._clearErrors();

        var handler = null;
        var validationResult;
        if (BetaFeaturesUtils.isFeatureEnabled(Flag.VANILLA_FORM_HANDLER)) {
          // Vanilla form handler
          var formEl = this._formEl.getDOMNode();
          handler = new FormHandler(Y.config.win, formEl);
          validationResult = handler.validate();
        } else {
          // Original YUI form handler
          validationResult = this._validateForm();
        }

        if (validationResult.errors.length > 0) {
          this._renderErrors(validationResult.errors);
        } else {

          var sanitizedFormData = handler ?
          handler.getSanitizedFieldValues() :
          validationResult.data;

          this.fetchValidatedFormSubmission(
          handler,
          this.get('form').id,
          // success
          Y.bind(function () {
            this.fire('submission', { data: sanitizedFormData });
          }, this),
          // failure
          Y.bind(function (data) {
            // transform the Map returned by backend form validator into an Array.
            var errorsArr = [];
            Y.Object.each(data.errors, function (message, fieldId) {
              errorsArr.push({
                fieldId: fieldId,
                message: message });

            });
            this._renderErrors(errorsArr);
          }, this));

        }
      }
      e.halt();
    },

    bindUI: function () {
      this._formEl.on('submit', this._onSubmit, this);
      this.after('formDataChange', this._setFormData, this);
    },

    /**
     * Puts in saving state
     * @method setStateSaving
     */
    setStateSaving: function () {
      var contentBox = this.get('contentBox');

      contentBox.addClass('saving');
      contentBox.one('input[type="submit"]').set('value', t("Saving\u2026"));


    },

    /**
     * Puts in editing state
     * @method setStateEditing
     */
    setStateEditing: function () {
      var contentBox = this.get('contentBox');
      var buttonText = this.get('formSubmitButtonText');

      contentBox.one('input[type="submit"]').set('value', buttonText);
      contentBox.removeClass('saving');
    },

    /**
     * @param {?object} handler null for v1
     * @param  {String} formId
     * @param  {Function} successCb
     * @param  {Function} failureCb
     */
    fetchValidatedFormSubmission: function (
    handler,
    formId,
    successCb,
    failureCb)
    {
      var sanitizedFields = handler ?
      handler.getSanitizedFieldValues() :
      this.getFormData();
      var data = JSON.stringify(sanitizedFields);
      Y.Data.post({
        url: '/api/rest/forms/validate/' + formId,
        headers: { 'Content-Type': 'application/json' },
        data: data,
        success: successCb,
        failure: failureCb });

    },

    /**
     * @deprecated in favor of FormHandler
     * @return {object} data
     */
    getFormData: function () {
      return this._validateForm().data;
    },

    /**
     *
     * @method  _renderErrors
     * @param   {Array} errors
     * @private
     */
    _renderErrors: function (errors) {
      Y.Array.each(errors, function (error) {
        this.get('contentBox').one('#' + error.fieldId).one('.title').insert(
        '<div class="field-error">' + error.message + '</div>',
        'before');

      }, this);
    },

    /**
     * Clear field
     * @method  _clearErrors
     * @private
     */
    _clearErrors: function () {
      this.get('contentBox').all('.field-error').remove(true);
    },

    /**
     * @deprecated in favor of FormHandler
     * @return  {Object} {data, errors}
     */
    _validateForm: function () {
      var data = {};
      var errors = [];

      this._formEl.all('.form-item').each(function (field) {
        var fieldId = field.getAttribute('id');
        var fieldValidation = this._getFieldData(field);

        if (!fieldValidation) {
          return;
        }

        var fieldError = fieldValidation.error;

        if (fieldError) {
          errors.push({ fieldId: fieldId, message: fieldError });
        }

        data[fieldId] = fieldValidation.data;
      }, this);

      return { data: data, errors: errors };
    },

    /**
     * @deprecated in favor of FormHandler
     * @param   {Node} field
     */
    _getFieldData: function (field) {
      var classes = field.get('className').split(' ');
      var type = null;
      var getter;

      var isSection = false;

      Y.Array.each(classes, function (_class) {

        if (Y.Object.hasKey(this._typeGetterMap, _class)) {
          type = _class;
          getter = this._typeGetterMap[type];
        } else if (_class === 'section') {
          isSection = true;
        }
      }, this);

      // don't process section dividers
      if (isSection) {
        return;
      }

      if (type === null) {
        getter = this._defaultGetter;
      }

      return getter.call(this, field);
    },

    /**
     * @deprecated in favor of FormHandler
     * @param   {Node} field
     */
    _getSingleFieldVal: function (field) {
      var control = field.one('.field-element');

      if (control) {
        var data = control.get('value');
        var error;
        var noData = !Y.Lang.isValue(data) || data === '';

        if (field.hasClass('required') && noData) {
          error = t("Required");


        }

        return { data: control.get('value'), error: error };
      }

      return null;
    },

    /**
     * @deprecated in favor of FormHandler
     */
    _getMultiFieldVal: function (field) {
      var data = [];
      var error;
      var hasOneValue = false;

      field.all('.field-element').each(function (fieldEl) {
        var fieldElData = fieldEl.get('value');

        if (Y.Lang.isValue(fieldElData) && fieldElData !== '') {
          hasOneValue = true;
        }


        data.push(fieldEl.get('value'));
      });

      if (field.hasClass('required') && !hasOneValue) {
        error = t("Required");


      }

      return { data: data, error: error };
    },

    /**
     * @deprecated in favor of FormHandler
     */
    _getOptionFieldVal: function (field) {
      var data = [];
      var error;

      field.all('input').each(function (input) {
        if (input.get('checked')) {
          data.push(input.get('value'));
        }
      }, this);

      if (field.hasClass('required') && data.length === 0) {
        error = 'Required';
      }

      return { data: data, error: error };
    },

    /**
     * @deprecated in favor of FormHandler
     */
    _getLikertFieldVal: function (field) {
      var data = {};
      var error;

      field.all('.item').each(function (questionNode) {
        var questionValue;

        questionNode.all('input').each(function (optionInput) {
          if (optionInput.get('checked')) {
            questionValue = optionInput.get('value');
          }
        });

        if (Y.Lang.isValue(questionValue)) {
          data[questionNode.getAttribute('data-question')] = questionValue;
        }
      });

      return { data: data, error: error };
    },

    /**
     * @deprecated in favor of FormHandler
     */
    _getRadioFieldVal: function (field) {
      var data;
      var error;

      field.all('input').each(function (input) {
        if (input.get('checked')) {
          data = input.get('value');
        }
      }, this);

      if (field.hasClass('required') && !Y.Lang.isValue(data)) {
        error = t("Required");


      }

      return { data: data, error: error };
    },

    /**
     * @deprecated in favor of FormHandler
     */
    _getSelectVal: function (field) {
      var data = field.one('select').get('value');
      var error;
      var noData = !Y.Lang.isValue(data) || data === '';

      if (field.hasClass('required') && noData) {
        error = t("Required");


      }

      return { data: data, error: error };
    },

    /**
     * @deprecated in favor of FormHandler
     */
    _getPhoneFieldVal: function (field) {
      var value = this._getMultiFieldVal(field);
      var data = value.data;

      if (data && data.length === 3) {
        // prepend an empty value for the country code, which
        // is optional and may not be present
        data.unshift('');
      }

      return value;
    },

    _setFormData: function () {
      var formData = this.get('formData');

      if (formData === null) {
        return;
      }

      this._formEl.all('.form-item').each(function (field) {
        var fieldData = formData[field.getAttribute('id')];

        if (fieldData) {
          var value = fieldData.value;
          var dataToSet = Y.Lang.isValue(value) ? value : fieldData.values || [];

          this._setFieldData(field, dataToSet);
        }
      }, this);
    },

    _setFieldData: function (field, fieldData) {
      var classes = field.get('className').split(' ');
      var type = null;
      var setter;

      var isSection;

      Y.Array.each(classes, function (_class) {
        if (Y.Object.hasKey(this._typeSetterMap, _class)) {
          type = _class;
          setter = this._typeSetterMap[type];
        } else if (_class === 'section') {
          isSection = true;
        }

        // don't try and set value on section dividers
        if (isSection) {
          return;
        }

        if (type === null) {
          setter = this._defaultSetter;
        }

        return setter.call(this, field, fieldData);
      }, this);
    },

    _setSingleFieldVal: function (field, fieldData) {
      var control = field.one('.field-element');

      if (control) {
        return control.set('value', fieldData);
      }
    },

    _setMultiFieldVal: function (field, fieldData) {
      var fieldEls = field.all('.field-element');

      fieldEls.each(function (fc) {
        fc.set('value', fieldData[fc.getData('title')]);
      });
    },

    _setOptionFieldVal: function (field, fieldData) {
      var inputs = field.all('input');

      inputs.each(function (input) {
        if (fieldData.indexOf(input.get('value')) !== -1) {
          input.setAttribute('checked', 'checked');
        }
      }, this);
    },

    _setLikertFieldVal: function (field, fieldData) {
      var questionNodes = field.all('.item');

      questionNodes.each(function (questionNode) {
        var question = questionNode.getAttribute('data-question');
        var questionValue = fieldData[question];

        if (!Y.Lang.isValue(questionValue) || questionValue === '') {
          return;
        }

        var optionIndex = parseInt(questionValue, 10) + 2;

        questionNode.all('input').item(optionIndex).setAttribute('checked', 'checked');
      });
    },

    _setRadioFieldVal: function (field, fieldData) {
      var inputs = field.all('input');

      inputs.each(function (input) {
        if (input.get('value') === fieldData) {
          input.setAttribute('checked', 'checked');
        }
      }, this);
    },

    _setSelectVal: function (field, fieldData) {
      field.one('select').set('value', fieldData);
    },

    _setPhoneFieldVal: function (field, fieldData) {
      // shift field data if not showing country code
      if (field.all('.field').size() === 3 && fieldData.length === 4) {
        fieldData.shift();
      }

      this._setMultiFieldVal(field, fieldData);
    },

    evaluateTemplate: function (context) {
      var formTemplate = this.get('formTemplate');

      try {
        return evaluateJsonTemplate(
        formTemplate.html,
        Y.merge({}, context, { localizedStrings: formTemplate.localizedStrings }));

      } catch (e) {
        return e.message;
      }
    } },

  {

    CSS_PREFIX: 'sqs-async-form',

    ATTRS: {
      /**
       * Form
       * @attribute form
       */
      form: {
        value: {
          fields: [] },

        validator: Y.Lang.isValue },


      /**
       * @attribute formTemplate
       */
      formTemplate: {
        value: null },


      /**
       * @attribute hideSubmitButton
       */
      hideSubmitButton: {
        value: false },


      /**
       * @attribute formSubmitButtonText
       * @default 'Add to Cart'
       */
      formSubmitButtonText: {
        value: t("Add to Cart") },


      /**
       * Form name
       * @attribute formName
       */
      formName: {
        value: t("My Form Name") },


      /**
       * Form data
       * @attribute formData
       */
      formData: {
        value: null },


      /**
       * Show title?
       * @attribute showTitle
       * @type      {Boolean}
       */
      showTitle: {
        value: true },


      preventDefaultSubmit: {
        value: true },


      preventAllSubmits: {
        value: false } } });





}, '1.0', {
  requires: [
  'base',
  'json',
  'node',
  'squarespace-ss-widget'] });