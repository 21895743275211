"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.ShippingRateType */

/* Java class defined in repository squarespace-v6 */
var ShippingRateTypes;

(function (ShippingRateTypes) {
  ShippingRateTypes["OTHER"] = 0;
  ShippingRateTypes["FLAT"] = 1;
  ShippingRateTypes["WEIGHT_RANGE"] = 3;
  ShippingRateTypes["USPS"] = 6;
  ShippingRateTypes["UPS"] = 7;
  ShippingRateTypes["FEDEX"] = 8;
  ShippingRateTypes["LOCAL_PICKUP"] = 100;
})(ShippingRateTypes || (ShippingRateTypes = {}));

var _default = ShippingRateTypes;
exports["default"] = _default;
module.exports = exports.default;