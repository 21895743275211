"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.business.components.legal.termsauditor.TermsAcceptanceRequirement */

/* Java class defined in repository squarespace-v6 */
var TermsAcceptanceRequirement;

(function (TermsAcceptanceRequirement) {
  TermsAcceptanceRequirement["NONE"] = 1;
  TermsAcceptanceRequirement["OPTIONAL"] = 2;
  TermsAcceptanceRequirement["MANDATORY"] = 3;
})(TermsAcceptanceRequirement || (TermsAcceptanceRequirement = {}));

var _default = TermsAcceptanceRequirement;
exports["default"] = _default;
module.exports = exports.default;