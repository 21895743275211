"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.PlanGenus */

/* Java class defined in repository squarespace-v6 */
var PlanGenus;

(function (PlanGenus) {
  PlanGenus["PARKING"] = -2;
  PlanGenus["STARTER"] = -1;
  PlanGenus["STANDARD"] = 1;
  PlanGenus["UNLIMITED"] = 2;
  PlanGenus["BUSINESS"] = 3;
  PlanGenus["COMMERCE_ADVANCED"] = 4;
  PlanGenus["ENTERPRISE_SELECT"] = 5;
  PlanGenus["GOOGLE_WORKSPACE_STARTER"] = 7;
  PlanGenus["GOOGLE_WORKSPACE_STANDARD"] = 8;
})(PlanGenus || (PlanGenus = {}));

var _default = PlanGenus;
exports["default"] = _default;
module.exports = exports.default;