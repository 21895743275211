"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.census.DeviceCategory */

/* Java class defined in repository squarespace-v6 */
var DeviceCategory;

(function (DeviceCategory) {
  DeviceCategory["DESKTOP"] = 1;
  DeviceCategory["MOBILE"] = 2;
  DeviceCategory["TABLET"] = 3;
  DeviceCategory["UNKNOWN"] = 4;
  DeviceCategory["ALL"] = 200;
})(DeviceCategory || (DeviceCategory = {}));

var _default = DeviceCategory;
exports["default"] = _default;
module.exports = exports.default;