import {
  getResolvedMemberLocale,
  getResolvedWebsiteLocale
} from '../../i18n/resolvedLocales';

type DataType = any;

// tslint:disable cyclomatic-complexity
export default function buildTrackInternalPayload() {
  const data: DataType = {};

  // @todo refer to stores once SQUARESPACE_CONTEXT is bridged over
  const Static: any = window.Static;
  const SQUARESPACE_CONTEXT: any = Static.SQUARESPACE_CONTEXT;


  if (SQUARESPACE_CONTEXT.website) {
    data.websiteId = SQUARESPACE_CONTEXT.website.id;
  }

  if (SQUARESPACE_CONTEXT.templateId) {
    data.templateId = SQUARESPACE_CONTEXT.templateId;
  }

  if (SQUARESPACE_CONTEXT.templateInstallation && SQUARESPACE_CONTEXT.templateInstallation.templateWebsite) {
    data.variantId = SQUARESPACE_CONTEXT.templateInstallation.templateWebsite.id;
  }

  if (SQUARESPACE_CONTEXT.authenticatedAccount && SQUARESPACE_CONTEXT.authenticatedAccount.id) {
    data.memberAccountId = SQUARESPACE_CONTEXT.authenticatedAccount.id;
  }

  const { pathname, hash } = window.location;
  data.pagePath = data.pagePath || pathname || '';

  if (hash && hash !== '') {
    data.pageHash = hash.substring(1);
  }

  data.member_locale = getResolvedMemberLocale(); // eslint-disable-line camelcase
  data.website_locale = getResolvedWebsiteLocale(); // eslint-disable-line camelcase

  return data;
}
