"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.OrderDirection */

/* Java class defined in repository cms-legacy-data */
var OrderDirection;

(function (OrderDirection) {
  OrderDirection["ASCENDING"] = 1;
  OrderDirection["DESCENDING"] = 2;
})(OrderDirection || (OrderDirection = {}));

var _default = OrderDirection;
exports["default"] = _default;
module.exports = exports.default;