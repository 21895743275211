"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.ProductType */

/* Java class defined in repository cms-legacy-data */
var ProductTypes;

(function (ProductTypes) {
  ProductTypes["PHYSICAL"] = 1;
  ProductTypes["DIGITAL"] = 2;
  ProductTypes["SERVICE"] = 3;
  ProductTypes["GIFT_CARD"] = 4;
  ProductTypes["PAYWALL"] = 5;
})(ProductTypes || (ProductTypes = {}));

var _default = ProductTypes;
exports["default"] = _default;
module.exports = exports.default;