import {
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
} from '@sqs/responsive';

/**
 * The module containing the Squarespace.SlideRendering.Layouts.LockFullCenter01
 * class.
 *
 * @module squarespace-slide-rendering-layouts-lock-full-center-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-lock-full-center-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for lock-full-center-01.
   *
   * @class LockFullCenter01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.LockFullCenter01 = Y.Base.create('LockFullCenter01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var contentNode = contentBox.querySelector('.layer-front .sqs-slide-layer-content');

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthRatio: 0.99,
          scale: 0.99,
          minFontSize: 14,
          heightBoundaryNode: contentBox.querySelector('.sqs-slide-layer'),
          heightRatio: 0.16
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          heightRatio: 0.16,
          heightBoundaryNode: contentBox.querySelector('.sqs-slide-layer'),
          widthRatio: 0.99,
          scale: 0.99,
          minFontSize: 18
        }));

        // Scroll on overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: contentBox.querySelector('.sqs-slice-group.group-content'),
          onReset: function(rootNode) {
            rootNode.classList.add('align-center');
            rootNode.classList.add('align-center-absolute');
            contentNode.classList.remove('scroll');
          },
          onRespond: function(rootNode) {
            rootNode.classList.remove('align-center');
            rootNode.classList.remove('align-center-absolute');
            contentNode.classList.add('scroll');
          }
        }));
      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
