"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.enums.EmailType */

/* Java class defined in repository squarespace-v6 */
var EmailTypes;

(function (EmailTypes) {
  EmailTypes["ABANDONED_CART"] = 1;
  EmailTypes["ACCOUNT_VERIFICATION_EMAIL"] = 2;
  EmailTypes["BATCH_DOMAIN_REGISTRATION_SUCCESSFUL_EMAIL"] = 3;
  EmailTypes["BILLING_FAILURE_EMAIL"] = 4;
  EmailTypes["BILLING_FAILURE_FINAL_EMAIL"] = 5;
  EmailTypes["BILLING_RENEWAL_REMINDER"] = 6;
  EmailTypes["BILLING_SUSPENDED"] = 7;
  EmailTypes["CHANGED_IDENTIFIER_EMAIL"] = 8;
  EmailTypes["COMMENT_EMAIL"] = 9;
  EmailTypes["COMPED_STATUS_ENDED"] = 10;
  EmailTypes["CONTRIBUTION_ARRIVED"] = 11;
  EmailTypes["CONTRIBUTION_CONFIRMED"] = 12;
  EmailTypes["CREATIVE_NEW_WEBSITE_EMAIL"] = 13;
  EmailTypes["CIRCLE_SIGNUP_CONFIRMATION_EMAIL"] = 14;
  EmailTypes["CREATIVE_WELCOME_DAY_1"] = 15;
  EmailTypes["CREATIVE_WELCOME_DAY_2"] = 16;
  EmailTypes["CREATIVE_WELCOME_DAY_3"] = 17;
  EmailTypes["DOMAIN_EXPIRATION_NOTICE"] = 18;
  EmailTypes["DOMAIN_EXPIRATION_REMINDER"] = 19;
  EmailTypes["DOMAIN_RENEWAL_FAILURE_NOTICE"] = 20;
  EmailTypes["DOMAIN_RENEWAL_REMINDER"] = 21;
  EmailTypes["DOMAIN_SUBSCRIPTION_SUCCESSFUL_EMAIL"] = 22;
  EmailTypes["EMAIL_RECOVERY"] = 24;
  EmailTypes["FORM_SUBMISSION_EMAIL"] = 25;
  EmailTypes["FORM_SUBMISSION_ERROR"] = 26;
  EmailTypes["GOOGLE_WORKSPACE_ADMIN_CREATION_FAILURE"] = 27;
  EmailTypes["GOOGLE_WORKSPACE_BILLING_FAILURE_FINAL"] = 28;
  EmailTypes["GOOGLE_WORKSPACE_BILLING_FAILURE"] = 29;
  EmailTypes["DEPRECATED_GOOGLE_WORKSPACE_FREE_INBOX_REMINDER"] = 30;
  EmailTypes["GOOGLE_WORKSPACE_INVITATION"] = 31;
  EmailTypes["GOOGLE_WORKSPACE_PASSWORD_RESET"] = 32;
  EmailTypes["GOOGLE_WORKSPACE_SUSPENDED"] = 33;
  EmailTypes["GOOGLE_WORKSPACE_TOTAL_FAILURE"] = 34;
  EmailTypes["GOOGLE_WORKSPACE_WELCOME"] = 35;
  EmailTypes["LINKED_GOOGLE_WORKSPACE_WELCOME"] = 36;
  EmailTypes["LOGO_GENERATED_EMAIL"] = 37;
  EmailTypes["MARKETING_RELATIONSHIP_VERIFICATION_EMAIL"] = 38;
  EmailTypes["MEMBER_ACCOUNT_ACTIVATION_EMAIL"] = 39;
  EmailTypes["MEMBER_INVITE_EMAIL"] = 40;
  EmailTypes["NEW_ORDER_ALERT"] = 41;
  EmailTypes["NEWSLETTER_OPT_IN"] = 42;
  EmailTypes["STORE_LIVE"] = 43;
  EmailTypes["DEPRECATED_ONBOARDING_ONE_WEEK_LEFT"] = 44;
  EmailTypes["DEPRECATED_24_HOURS_LEFT"] = 45;
  EmailTypes["DEPRECATED_TRIAL_EXPIRED"] = 46;
  EmailTypes["ONBOARDING_TRIAL_EXTENSION"] = 47;
  EmailTypes["ORDER_CONFIRMED"] = 48;
  EmailTypes["ORDER_DIGITAL_GOODS"] = 49;
  EmailTypes["ORDER_REFUNDED"] = 51;
  EmailTypes["ORDER_SHIPPED"] = 52;
  EmailTypes["PASSWORD_RESET_EMAIL"] = 53;
  EmailTypes["PLAN_CHANGE_EMAIL"] = 54;
  EmailTypes["REFERRAL_RECEIVED"] = 55;
  EmailTypes["REFERRAL_SAVED_SEARCH_MATCH"] = 56;
  EmailTypes["SECURITY_NOTIFICATION_EMAIL"] = 57;
  EmailTypes["OLD_SIGNUP_CONFIRMATION_EMAIL"] = 58;
  EmailTypes["SIGNUP_FAILED_EMAIL"] = 59;
  EmailTypes["STOCK_LEVEL_ALERT"] = 60;
  EmailTypes["DEPRECATED_SUBSCRIPTION_SUCCESSFUL_EMAIL"] = 61;
  EmailTypes["XERO_EXPORT_FAILURE_EMAIL"] = 62;
  EmailTypes["NEW_TRIAL_ONBOARDING_1"] = 63;
  EmailTypes["NEW_TRIAL_ONBOARDING_2"] = 64;
  EmailTypes["NEW_TRIAL_ONBOARDING_3"] = 65;
  EmailTypes["NEW_TRIAL_ONBOARDING_4"] = 66;
  EmailTypes["NEW_TRIAL_ONBOARDING_DAY_BEFORE"] = 67;
  EmailTypes["NEW_TRIAL_ONBOARDING_DAY_AFTER"] = 68;
  EmailTypes["TRIAL_3X_EXTENSION_CHECKIN"] = 69;
  EmailTypes["SIGNUP_CONFIRMATION_EMAIL"] = 70;
  EmailTypes["COMMERCE_COMMON_MOCK_EMAIL"] = 71;
  EmailTypes["SUBSCRIPTION_SUCCESSFUL"] = 72;
  EmailTypes["SUBSCRIPTION_WELCOME"] = 73;
  EmailTypes["EMAIL_ENDPOINT_SOURCED"] = 74;
  EmailTypes["PARKED_PAGE_ONBOARDING"] = 75;
  EmailTypes["GOOGLE_WORKSPACE_RENEWAL_REMINDER"] = 76;
  EmailTypes["GOOGLE_WORKSPACE_EXPIRATION_REMINDER"] = 77;
  EmailTypes["GOOGLE_WORKSPACE_RENEWAL_FAILURE_NOTICE"] = 78;
  EmailTypes["GOOGLE_WORKSPACE_EXPIRATION_NOTICE"] = 79;
  EmailTypes["GOOGLE_WORKSPACE_RESELLER_ACCESS_DISABLED_REMINDER"] = 80;
  EmailTypes["GOOGLE_WORKSPACE_RESELLER_ACCESS_DISABLED_FAILURE"] = 81;
  EmailTypes["GOOGLE_WORKSPACE_RESELLER_ACCESS_DISABLED_EXPIRATION_REMINDER"] = 82;
  EmailTypes["SALES_TAX_NOTIFICATION"] = 83;
  EmailTypes["BILLING_EXPIRATION_REMINDER"] = 84;
  EmailTypes["BILLING_EXPIRED_NATURALLY"] = 85;
  EmailTypes["BILLING_CANCELLATION_CONFIRMATION"] = 86;
  EmailTypes["GOOGLE_WORKSPACE_CANCELLATION_CONFIRMATION"] = 87;
  EmailTypes["BILLING_CARD_EXPIRING"] = 88;
  EmailTypes["BILLING_AUTORENEW_DISABLED_CONFIRMATION"] = 89;
  EmailTypes["DOMAIN_AUTORENEW_DISABLED_CONFIRMATION"] = 90;
  EmailTypes["GOOGLE_WORKSPACE_AUTORENEW_DISABLED_CONFIRMATION"] = 91;
  EmailTypes["DOMAIN_TRANSFER_INITIATED"] = 92;
  EmailTypes["DOMAIN_TRANSFER_SUCCESS"] = 93;
  EmailTypes["DOMAIN_TRANSFER_CANCELLED"] = 94;
  EmailTypes["DOMAIN_TRANSFER_FAILED"] = 95;
  EmailTypes["BATCH_DOMAIN_REGISTRATION_FAILURE_EMAIL"] = 96;
  EmailTypes["GOOGLE_WORKSPACE_NAME_IN_USE"] = 97;
  EmailTypes["GOOGLE_WORKSPACE_PURGE_REMINDER"] = 98;
  EmailTypes["GIFT_CARD_DELIVERY"] = 99;
  EmailTypes["BILLING_WEBSITE_CHARGEBACK_CANCELLATION"] = 100;
  EmailTypes["CUSTOMER_ACCOUNT_RESET_PASSWORD"] = 101;
  EmailTypes["GST_REMINDER"] = 102;
  EmailTypes["ADVANCED_COMMERCE_CALLBACKS"] = 103;
  EmailTypes["OLD_SHARE_REFERRAL_CODE"] = 104;
  EmailTypes["CUSTOMER_ACCOUNT_WELCOME"] = 105;
  EmailTypes["CUSTOMER_ACCOUNT_PASSWORD_UPDATED"] = 106;
  EmailTypes["DOMAIN_SSL_RENEWAL_FAILED"] = 107;
  EmailTypes["GOOGLE_WORKSPACE_CHANGE_PLAN_TO_ANNUAL"] = 108;
  EmailTypes["GIFT_CARD_CANCELED"] = 109;
  EmailTypes["COMMERCE_PAYMENT_ACCOUNT_CHANGED"] = 110;
  EmailTypes["TWO_FACTOR_UPDATED"] = 111;
  EmailTypes["DOMAIN_WITH_CNAME_TO_WWW"] = 112;
  EmailTypes["SOCIAL_ACCOUNT_CONFIRMATION"] = 113;
  EmailTypes["COMMERCE_MERCHANT_SUBSCRIBER_PAYMENT_DECLINED"] = 114;
  EmailTypes["COMMERCE_SUBSCRIBER_PAYMENT_DECLINED"] = 115;
  EmailTypes["TAX_CHANGE"] = 116;
  EmailTypes["COMMERCE_MERCHANT_SUBSCRIPTION_CANCELLED"] = 117;
  EmailTypes["REGISTRAR_EMAIL_VERIFICATION"] = 118;
  EmailTypes["COMMERCE_SUBSCRIBER_SUBSCRIPTION_CANCELLED"] = 119;
  EmailTypes["GOOGLE_WORKSPACE_TOS_SUSPENSION_30DAY_REMINDER"] = 120;
  EmailTypes["GOOGLE_WORKSPACE_TOS_SUSPENSION_15DAY_REMINDER"] = 121;
  EmailTypes["COMMERCE_MERCHANT_SUBSCRIPTION_PAYMENT_MERCHANT_ERROR"] = 122;
  EmailTypes["CAMPAIGNS_SUBSCRIPTION_SUCCESSFUL"] = 123;
  EmailTypes["CAMPAIGNS_BILLING_FAILURE_EMAIL"] = 124;
  EmailTypes["CAMPAIGNS_BILLING_FAILURE_FINAL_EMAIL"] = 125;
  EmailTypes["CAMPAIGNS_BILLING_EXPIRATION_REMINDER"] = 126;
  EmailTypes["CAMPAIGNS_BILLING_EXPIRED_NATURALLY"] = 127;
  EmailTypes["CAMPAIGNS_BILLING_RENEWAL_REMINDER"] = 128;
  EmailTypes["CAMPAIGNS_SUBSCRIPTION_SUSPENDED"] = 129;
  EmailTypes["CAMPAIGNS_AUTORENEW_DISABLED_CONFIRMATION"] = 130;
  EmailTypes["CAMPAIGNS_SUBSCRIPTION_CANCELED"] = 131;
  EmailTypes["CAMPAIGNS_PLAN_CHANGE_EMAIL"] = 132;
  EmailTypes["MEMBER_AREA_INVITATION"] = 133;
  EmailTypes["MEMBER_AREA_WELCOME"] = 134;
  EmailTypes["OPENSRS_DE_DOMAIN_DELETION"] = 135;
  EmailTypes["COUNTRY_TAX_CHANGE"] = 136;
  EmailTypes["VAT_TAX_CHANGE"] = 137;
  EmailTypes["GST_TAX_CHANGE"] = 138;
  EmailTypes["ORDER_NOT_PLACED"] = 139;
  EmailTypes["TRIAL_DATA_DELETION"] = 140;
  EmailTypes["COMMERCE_PRODUCT_RESTOCK_NOTIFICATION"] = 141;
  EmailTypes["NEW_ORDER_ALERT_POS"] = 142;
  EmailTypes["POS_ORDER_CONFIRMED"] = 143;
  EmailTypes["THIRD_PARTY_CONNECTED"] = 144;
  EmailTypes["THIRD_PARTY_DISCONNECTED"] = 145;
  EmailTypes["POS_ORDER_REFUNDED"] = 146;
  EmailTypes["COMMERCE_SUBSCRIBER_AUTH_REQUIRED"] = 147;
  EmailTypes["STRONG_AUTH_BILLING_FAILURE_EMAIL"] = 148;
  EmailTypes["MERCHANT_NEW_MEMBER_JOINED"] = 149;
  EmailTypes["BILLING_RESOLD_EXPIRATION_REMINDER"] = 150;
  EmailTypes["MEMBER_AREA_MEMBERSHIP_CANCELLED"] = 151;
  EmailTypes["MERCHANT_MEMBERSHIP_CANCELLED"] = 152;
  EmailTypes["BILLING_RESOLD_GRACE_PERIOD_REMINDER"] = 153;
  EmailTypes["STRONG_AUTH_BILLING_FAILURE_FINAL_EMAIL"] = 154;
  EmailTypes["SCHEDULING_BILLING_FAILURE"] = 155;
  EmailTypes["BATCH_DOMAIN_REGISTRATION_PAYMENT_AUTHENTICATION_EMAIL"] = 156;
  EmailTypes["SCHEDULING_SUBSCRIPTION_SUCCESSFUL"] = 157;
  EmailTypes["SCHEDULING_BILLING_FAILURE_FINAL"] = 158;
  EmailTypes["SCHEDULING_BILLING_EXPIRATION_REMINDER"] = 159;
  EmailTypes["SCHEDULING_PLAN_CHANGE"] = 160;
  EmailTypes["SCHEDULING_BILLING_EXPIRED_NATURALLY"] = 161;
  EmailTypes["SCHEDULING_SUBSCRIPTION_SUSPENDED"] = 162;
  EmailTypes["SCHEDULING_SUBSCRIPTION_CANCELLED"] = 163;
  EmailTypes["SCHEDULING_BILLING_RENEWAL_REMINDER"] = 164;
  EmailTypes["SCHEDULING_AUTORENEW_DISABLED_CONFIRMATION"] = 165;
  EmailTypes["DOMAIN_TRANSFER_LOCKED"] = 166;
  EmailTypes["DOMAIN_TRANSFER_AUTH_CODE_NEEDED"] = 167;
  EmailTypes["COMMERCE_TRANSACTIONAL_EMAIL"] = 168;
  EmailTypes["GOOGLE_WORKSPACE_SUBSCRIPTION_EXISTS_ON_DOMAIN"] = 169;
  EmailTypes["DOMAIN_TRANSFER_LOCKED_REMINDER"] = 170;
  EmailTypes["DOMAIN_TRANSFER_AUTH_CODE_NEEDED_REMINDER"] = 171;
  EmailTypes["REGISTRAR_EMAIL_AUTH_CODE"] = 172;
  EmailTypes["ACCOUNT_RECOVERY_CHANGED"] = 173;
  EmailTypes["ACCOUNT_PHONE_NUMBER_CHANGED"] = 174;
  EmailTypes["FRAUD_DETECTION_ACCOUNT_VERIFICATION"] = 175;
  EmailTypes["DOMAIN_TRANSFER_EXPIRED"] = 176;
  EmailTypes["CUSTOMER_RECIPIENT_EMAIL_NOTIFICATION"] = 177;
  EmailTypes["WHOIS_PRIVACY_VERIFICATION_EMAIL"] = 178;
  EmailTypes["BILLING_CARD_DELETED"] = 179;
  EmailTypes["DOMAIN_SUSPENDED_EMAIL"] = 180;
  EmailTypes["CONTACT_CHANGE_CONFIRMATION"] = 181;
  EmailTypes["REGISTRANT_CHANGE_APPROVAL"] = 182;
  EmailTypes["MEMBER_AREAS_SUBSCRIPTION_SUCCESSFUL"] = 184;
  EmailTypes["MEMBER_AREAS_BILLING_EXPIRED_NATURALLY"] = 185;
  EmailTypes["MEMBER_AREAS_BILLING_RENEWAL_REMINDER"] = 186;
  EmailTypes["MEMBER_AREAS_BILLING_AUTO_RENEW_DISABLED"] = 187;
  EmailTypes["MEMBER_AREAS_BILLING_CANCELLATION_CONFIRMATION"] = 188;
  EmailTypes["MEMBER_AREAS_BILLING_EXPIRATION_REMINDER"] = 189;
  EmailTypes["MEMBER_AREAS_PLAN_CHANGE"] = 190;
  EmailTypes["FORM_SUBMISSION_2020_EMAIL"] = 191;
  EmailTypes["MEMBER_AREAS_BILLING_FAILURE"] = 192;
  EmailTypes["MEMBER_AREAS_SUBSCRIPTION_SUSPENDED"] = 193;
  EmailTypes["DOMAINS_WRONGLY_EXPIRED_REACTIVATION_EMAIL"] = 194;
  EmailTypes["PASSWORDLESS_LOGIN"] = 195;
  EmailTypes["NEW_LOGIN"] = 196;
  EmailTypes["FORM_SUBMISSION_PHISHING_EMAIL"] = 197;
  EmailTypes["MERCHANT_ORDER_SYNCING_FAILED"] = 198;
  EmailTypes["ORG_INVITE"] = 199;
  EmailTypes["FIVE_DAY_EXPIRATION_REMINDER"] = 200;
  EmailTypes["THREE_DAY_POST_EXPIRATION"] = 201;
  EmailTypes["WHOIS_DATA_REMINDER"] = 202;
  EmailTypes["CONSOLIDATED_BILLING_RENEWAL_REMINDER"] = 203;
  EmailTypes["CONSOLIDATED_BILLING_EXPIRED_NATURALLY"] = 204;
  EmailTypes["CONSOLIDATED_BILLING_FAILURE_FINAL_EMAIL"] = 205;
  EmailTypes["CONSOLIDATED_NEW_SUBSCRIPTION_SUCCESSFUL"] = 206;
  EmailTypes["CONSOLIDATED_PLAN_CHANGE_EMAIL"] = 207;
  EmailTypes["CONSOLIDATED_BILLING_EXPIRATION_REMINDER"] = 208;
  EmailTypes["CONSOLIDATED_BILLING_SUSPENDED"] = 209;
  EmailTypes["ADMIN_COUPON_SERIES"] = 210;
  EmailTypes["CONSOLIDATED_BILLING_DISPUTE_OPENED"] = 211;
  EmailTypes["CONSOLIDATED_BILLING_PLAN_CHANGE_FAILED"] = 212;
  EmailTypes["MEMBER_AREAS_WEBSITE_CONTRACT_TERMINATED"] = 213;
  EmailTypes["COE_1682_INDIA_CREDIT_EMAIL"] = 214;
  EmailTypes["CONSOLIDATED_BILLING_ONE_TIME_CONTRACT_CREATED"] = 215;
  EmailTypes["CONSOLIDATED_BILLING_ONE_TIME_CONTRACT_REFUNDED"] = 216;
  EmailTypes["FORM_SUBMISSION_2021_EMAIL"] = 217;
  EmailTypes["PRODUCT_REVIEW"] = 218;
  EmailTypes["MERCHANT_NEW_PRODUCT_REVIEW_ALERT"] = 219;
  EmailTypes["FORM_SUBMISSION_SPAM_2022_EMAIL"] = 220;
  EmailTypes["LOCAL_PICKUP_READY"] = 221;
  EmailTypes["PLAN_CHANGE_SCHEDULED"] = 222;
  EmailTypes["PLAN_CHANGE_CANCELED"] = 223;
  EmailTypes["UKRAINE_BILLING_RELIEF"] = 224;
  EmailTypes["PRICE_REFRESH_NOTIFICATION"] = 225;
})(EmailTypes || (EmailTypes = {}));

var _default = EmailTypes;
exports["default"] = _default;
module.exports = exports.default;