"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.MarketingCouponExpirationType */

/* Java class defined in repository squarespace-v6 */
var MarketingCouponExpirationTypes;

(function (MarketingCouponExpirationTypes) {
  MarketingCouponExpirationTypes["TIME"] = 1;
  MarketingCouponExpirationTypes["USES"] = 2;
  MarketingCouponExpirationTypes["TIME_AND_USES"] = 3;
})(MarketingCouponExpirationTypes || (MarketingCouponExpirationTypes = {}));

var _default = MarketingCouponExpirationTypes;
exports["default"] = _default;
module.exports = exports.default;