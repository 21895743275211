/**
 * Update the counterEl with the character count and maxLength, clear errors
 *
 * @param {Node} wrapperEl
 * @param {Node} fieldEl
 * @param {Node} counterEl
 * @param {number} maxLength
 */
const setContent = (wrapperEl, fieldEl, counterEl, maxLength) => {
  const length = fieldEl.value.length;
  counterEl.innerText = `${length} / ${maxLength}`;
  wrapperEl.classList.toggle('error', length > maxLength);
};

/**
 * Render a character counter under the a field wrapped by wrapperEl and
 * listen to input changes.
 *
 * @param {Node} wrapperEl
 */
const render = (wrapperEl) => {
  const fieldEl = wrapperEl.querySelector('textarea');
  if (!fieldEl || fieldEl.dataset.characterCounter) {
    return;
  }
  fieldEl.dataset.characterCounter = true;

  let maxLength;
  maxLength = parseInt(wrapperEl.dataset.maxLength, 10);
  maxLength = isNaN(maxLength) ? 0 : Math.max(maxLength, 0);
  if (maxLength === 0) {
    return;
  }

  const counterEl = document.createElement('div');
  counterEl.style.textAlign = 'right';
  wrapperEl.appendChild(counterEl);

  setContent(wrapperEl, fieldEl, counterEl, maxLength);

  fieldEl.addEventListener('input', (e) => {
    setContent(wrapperEl, fieldEl, counterEl, maxLength);
  });
};

/**
 * Main method to render a character counter under all div.field.textarea in
 * <root>
 *
 * @param {Node} root
 */
export default function renderCharacterCounters(root) {
  // Spread will fail here in IE11 since if there are no textareas, the node
  // list will not be iterable. Array.from works though.
  Array
    .from(root.querySelectorAll('.textarea[data-max-length]'))
    .forEach(render);
}
