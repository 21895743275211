"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.CanadianProvince */

/* Java class defined in repository squarespace-v6 */
var CanadianProvinces;

(function (CanadianProvinces) {
  CanadianProvinces["ON"] = "ON";
  CanadianProvinces["QC"] = "QC";
  CanadianProvinces["NS"] = "NS";
  CanadianProvinces["NB"] = "NB";
  CanadianProvinces["MB"] = "MB";
  CanadianProvinces["BC"] = "BC";
  CanadianProvinces["PE"] = "PE";
  CanadianProvinces["SK"] = "SK";
  CanadianProvinces["AB"] = "AB";
  CanadianProvinces["NL"] = "NL";
  CanadianProvinces["NT"] = "NT";
  CanadianProvinces["YT"] = "YT";
  CanadianProvinces["NU"] = "NU";
})(CanadianProvinces || (CanadianProvinces = {}));

var _default = CanadianProvinces;
exports["default"] = _default;
module.exports = exports.default;