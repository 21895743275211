"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.CensusDataType */

/* Java class defined in repository squarespace-v6 */
var CensusDataType;

(function (CensusDataType) {
  CensusDataType["TOTAL"] = 1;
  CensusDataType["PAGE_URL"] = 2;
  CensusDataType["PAGE_TITLE"] = 3;
  CensusDataType["BROWSER"] = 4;
  CensusDataType["OS"] = 5;
  CensusDataType["COUNTRY"] = 6;
  CensusDataType["REFERRER"] = 7;
  CensusDataType["SEARCH_TERM"] = 8;
  CensusDataType["SEARCH_ENGINE"] = 9;
  CensusDataType["SUBSCRIBER"] = 10;
  CensusDataType["DEVICE"] = 11;
  CensusDataType["CATEGORY_PURCHASE"] = 30;
  CensusDataType["PRODUCT_PURCHASE"] = 31;
  CensusDataType["SITE_SEARCH"] = 32;
  CensusDataType["ACTIVE_TIME"] = 33;
})(CensusDataType || (CensusDataType = {}));

var _default = CensusDataType;
exports["default"] = _default;
module.exports = exports.default;