"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.MemberAccountGuild */

/* Java class defined in repository squarespace-v6 */
var MemberAccountGuild;

(function (MemberAccountGuild) {
  MemberAccountGuild["CIRCLE_USER"] = 1;
  MemberAccountGuild["DEVELOPER"] = 2;
  MemberAccountGuild["ENTERPRISE"] = 3;
})(MemberAccountGuild || (MemberAccountGuild = {}));

var _default = MemberAccountGuild;
exports["default"] = _default;
module.exports = exports.default;