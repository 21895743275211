"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.DomainAvailabilityStatus */

/* Java class defined in repository squarespace-v6 */
var DomainAvailability;

(function (DomainAvailability) {
  DomainAvailability["AVAILABLE"] = 1;
  DomainAvailability["TAKEN"] = 2;
  DomainAvailability["UNDETERMINED"] = 3;
})(DomainAvailability || (DomainAvailability = {}));

var _default = DomainAvailability;
exports["default"] = _default;
module.exports = exports.default;