"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.commons.business.commerce.settings.PaymentSettingsErrorType */

/* Java class defined in repository squarespace-v6 */
var PaymentSettingsErrorType;

(function (PaymentSettingsErrorType) {
  PaymentSettingsErrorType["PAYMENT_PROVIDER_NOT_SUPPORTED"] = "payment-provider-not-supported";
  PaymentSettingsErrorType["CURRENCY_REQUIRED"] = "currency-required";
  PaymentSettingsErrorType["COULD_NOT_REVOKE_ACCESS_TOKEN"] = "could-not-revoke-access-token";
  PaymentSettingsErrorType["COUNTRY_NOT_SUPPORTED"] = "country-not-supported";
  PaymentSettingsErrorType["INVALID_TOKEN"] = "invalid-token";
  PaymentSettingsErrorType["UNKNOWN"] = "unknown";
  PaymentSettingsErrorType["CURRENCY_NOT_SUPPORTED"] = "currency-not-supported";
  PaymentSettingsErrorType["COULD_NOT_CHANGE_CURRENCY"] = "could-not-change-currency";
})(PaymentSettingsErrorType || (PaymentSettingsErrorType = {}));

var _default = PaymentSettingsErrorType;
exports["default"] = _default;
module.exports = exports.default;