import {
  BoundaryRatios as ResponsiveBoundaryRatios,
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
} from '@sqs/responsive';
/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.ProfileFullLeft01 class.
 *
 * @module squarespace-slide-rendering-layouts-profile-full-left-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-profile-full-left-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for profile-full-left-01.
   *
   * @class ProfileFullLeft01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.ProfileFullLeft01 = Y.Base.create('ProfileFullLeft01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var contentNode = contentBox.querySelector('.layer-front .sqs-slide-layer-content');
        var copyNode = contentBox.querySelector('.group-copy');
        var groupLinksNode = contentBox.querySelector('.group-links');

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthRatio: 0.99,
          minFontSize: 26
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          widthRatio: 0.99,
          scale: 0.99,
          minFontSize: 18
        }));

        // Actions font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="action"] ul')),
          widthBoundaryNode: copyNode,
          minFontSize: 10,
          widthRatio: 0.99,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
          },
          onMinFontSize: function(rootNode) {
            rootNode.classList.add('stacked');
          }
        }));

        // Social Icon Wrapping
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: contentBox.querySelector('[data-slice-type="social-icons"]:not([data-content-empty="true"])'),
          widthBoundaryNode: copyNode,
          widthRatio: 0.66,
          onReset: function(rootNode, widthBoundaryNode) {
            rootNode.classList.remove('wrap');
            rootNode.parentNode.classList.remove('stacked');
            if (rootNode.querySelector('.break')) {
              rootNode.querySelector('.break').remove();
            }
          },
          onRespond: function(rootNode, widthBoundaryNode) {
            rootNode.classList.add('wrap');
            rootNode.parentNode.classList.add('stacked');
            // Social icons wrap onto two lines
            var icons = rootNode.childNodes;
            if (!rootNode.querySelector('.break')) {
              var item = new Y.Node(icons.item(Math.floor(icons.length / 2)));
              item.insert('<br class="break">', 'before');
            }
          }
        }));

        // Link group stacking
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: groupLinksNode,
          widthBoundaryNode: copyNode,
          widthRatio: 0.9,
          onReset: function(rootNode, widthBoundaryNode) {
            rootNode.classList.remove('stacked');
          },
          onRespond: function(rootNode, widthBoundaryNode) {
            rootNode.classList.add('stacked');
          }
        }));

        // Copy Overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: copyNode,
          onReset: function(rootNode) {
            rootNode.classList.add('align-center-vert');
            contentNode.parentNode.classList.remove('scroll');
          },
          onRespond: function(rootNode) {
            rootNode.classList.remove('align-center-vert');
            contentNode.parentNode.classList.add('scroll');
          }
        }));

        // Copy Overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: groupLinksNode,
          onReset: function(rootNode) {
            copyNode.classList.add('align-center-vert');
            contentNode.parentNode.classList.remove('scroll');
          },
          onRespond: function(rootNode) {
            copyNode.classList.remove('align-center-vert');
            contentNode.parentNode.classList.add('scroll');
          }
        }));
      }
    }
  );

}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
