"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.business.commerce.discount.template.DiscountTemplateType */

/* Java class defined in repository squarespace-v6 */
var DiscountTemplateType;

(function (DiscountTemplateType) {
  DiscountTemplateType["FIXED_AMOUNT"] = "FIXED_AMOUNT";
  DiscountTemplateType["FREE_SHIPPING"] = "FREE_SHIPPING";
  DiscountTemplateType["PERCENTAGE"] = "PERCENTAGE";
})(DiscountTemplateType || (DiscountTemplateType = {}));

var _default = DiscountTemplateType;
exports["default"] = _default;
module.exports = exports.default;