/**
 * The module containing the Squarespace.SlideRendering.Slices.HasScrim class.
 *
 * @module squarespace-slide-rendering-slices-has-scrim
 */
YUI.add('squarespace-slide-rendering-slices-has-scrim', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * Adds a scrim and maintains it's color and intensity.
   *
   * @class hasScrim
   * @constructor
   * @namespace Squarespace.SlideRendering.Slices.HasScrim
   * @extends Base
   */
  Y.Squarespace.SlideRendering.Slices.HasScrim = Y.Base.create('HasScrim',
    Y.Base,
    [],
    {
      initializer: function() {
        Y.Do.after(this._renderScrim, this, 'renderUI', this);
      },

      /**
       * Updates the scrim using color data from a given node.
       *
       * @method updateScrim
       * @param {Node} node
       */
      updateScrim: function(node) {
        if (!node) {
          return;
        }

        var scrimStyles = node.getData('processedColorStyles');

        if (Y.Lang.isUndefined(scrimStyles)) {
          scrimStyles = Y.Squarespace.SlideRendering.ColorProcessor.processNode(node);
        }

        this._styleScrim(scrimStyles.color, scrimStyles.opacity);
      },

      /**
       * Creates the scrim node. Invoked after the hosts's renderUI call.
       *
       * @method _renderScrim
       * @private
       */
      _renderScrim: function() {
        var contentBox = this.get('contentBox');
        this._scrim = contentBox.one('.scrim');
        if (!this._scrim) {
          this._scrim = Y.Node.create('<div class="scrim"></div>');
          contentBox.appendChild(this._scrim);
        }
      },

      /**
       * Change the background color and opacity of the scrim.
       *
       * @method _updateScrim
       * @private
       * @param {Squarespace.SlideRendering.Slices.RGB} color
       * @param {Number} opacity
       */
      _styleScrim: function(color, opacity) {
        this._scrim.setStyle('backgroundColor', color.toString());
        this._scrim.setStyle('opacity', opacity);

        this._scrim.ancestor('.sqs-slice').setStyle('backgroundColor', color.toString());
      }
    },
    {
      ATTRS: {
        /**
         * Determines if a value is dark or light.
         *
         * @attribute color
         * @type {Number}
         * @writeOnce
         */
        midPoint: {
          value: 0.6,
          writeOnce: 'initOnly'
        }
      }
    }
  );
}, '1.0', {
  requires: [
    'base',
    'squarespace-slide-rendering-color-processor',
    'squarespace-slide-rendering-slices-rgb'
  ]
});
