"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.DomainVerificationStatus */

/* Java class defined in repository squarespace-v6 */
var DomainVerificationStatus;

(function (DomainVerificationStatus) {
  DomainVerificationStatus["VERIFIED"] = "verified";
  DomainVerificationStatus["PENDING"] = "pending";
  DomainVerificationStatus["SUSPENDED"] = "suspended";
  DomainVerificationStatus["UNVERIFIED"] = "unverified";
})(DomainVerificationStatus || (DomainVerificationStatus = {}));

var _default = DomainVerificationStatus;
exports["default"] = _default;
module.exports = exports.default;