"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.getty.ImageFamily */

/* Java class defined in repository squarespace-v6 */
var ImageFamilies;

(function (ImageFamilies) {
  ImageFamilies["CREATIVE"] = "creative";
  ImageFamilies["EDITORIAL"] = "editorial";
})(ImageFamilies || (ImageFamilies = {}));

var _default = ImageFamilies;
exports["default"] = _default;
module.exports = exports.default;