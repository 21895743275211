"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.enums.AuthenticationFrameMessageType */

/* Java class defined in repository squarespace-v6 */
var AuthenticationFrameMessageType;

(function (AuthenticationFrameMessageType) {
  AuthenticationFrameMessageType["AUTHENTICATED"] = "authenticated";
  AuthenticationFrameMessageType["REDIRECT"] = "redirect";
})(AuthenticationFrameMessageType || (AuthenticationFrameMessageType = {}));

var _default = AuthenticationFrameMessageType;
exports["default"] = _default;
module.exports = exports.default;