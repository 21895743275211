"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.ApplicationSettingType */

/* Java class defined in repository squarespace-v6 */
var ApplicationSettingType;

(function (ApplicationSettingType) {
  ApplicationSettingType["STRING"] = "str";
  ApplicationSettingType["NUMERIC"] = "num";
  ApplicationSettingType["BOOLEAN"] = "bool";
})(ApplicationSettingType || (ApplicationSettingType = {}));

var _default = ApplicationSettingType;
exports["default"] = _default;
module.exports = exports.default;