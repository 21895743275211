import {
  FontSize as ResponsiveFontSize,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the Squarespace.SlideRendering.Layouts.LockFullCenter04
 * class.
 *
 * @module squarespace-slide-rendering-layouts-lock-full-center-04
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-lock-full-center-04', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for lock-full-center-04.
   *
   * @class LockFullCenter04
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.LockFullCenter04 = Y.Base.create('LockFullCenter04',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var contentNode = contentBox.querySelector('.layer-front .sqs-slide-layer-content');

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          heightRatio: 0.16,
          heightBoundaryNode: contentBox.querySelector('.sqs-slide-layer'),
          widthRaito: 0.99,
          scale: 0.99,
          minFontSize: 18
        }));

        // Scroll on overlap between top group and main content
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('.sqs-slice-group.group-content'),
          targetNode: contentBox.querySelector('.sqs-slice-group.group-top'),
          onReset: function(rootNode) {
            rootNode.classList.add('align-center');
            rootNode.classList.add('align-center-absolute');
            contentNode.classList.remove('scroll');
          },
          onRespond: function(rootNode) {
            rootNode.classList.remove('align-center');
            rootNode.classList.remove('align-center-absolute');
            contentNode.classList.add('scroll');
          }
        }));

        // Overlap between lock icon and logo slice
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('[data-slice-type="lock"]:not([data-content-empty])'),
          targetNode: contentBox.querySelector('[data-compound-type="logo"]'),
          onReset: function(rootNode, targetNode) {
            targetNode.parentNode.classList.remove('stacked');
          },
          onRespond: function(rootNode, targetNode) {
            targetNode.parentNode.classList.add('stacked');
          }
        }));
      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
