/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.LandingFullRight01 class.
 *
 * @module squarespace-slide-rendering-layouts-landing-full-right-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-landing-full-right-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for landing-full-right-01.
   *
   * @class LandingFullRight01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.LandingFullCenter01
   */
  Y.Squarespace.SlideRendering.Layouts.LandingFullRight01 = Y.Base.create('LandingFullRight01',
    Y.Squarespace.SlideRendering.Layouts.LandingFullCenter01,
    [],
    {}
  );
}, '1.0', {
  requires: [
    'base',
    'squarespace-slide-rendering-layouts-landing-full-center-01'
  ]
});