"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SocialIconStyle */

/* Java class defined in repository cms-legacy-data */
var SocialIconStyles;

(function (SocialIconStyles) {
  SocialIconStyles["ICON_ONLY"] = "icon-only";
  SocialIconStyles["ROUND"] = "round";
  SocialIconStyles["ROUND_BORDER"] = "round-border";
  SocialIconStyles["ROUND_SOLID"] = "round-solid";
  SocialIconStyles["REAL_SQUARE"] = "real-square";
  SocialIconStyles["REAL_SQUARE_BORDER"] = "real-square-border";
  SocialIconStyles["REAL_SQUARE_SOLID"] = "real-square-solid";
  SocialIconStyles["SQUARE"] = "square";
  SocialIconStyles["SQUARE_BORDER"] = "square-border";
  SocialIconStyles["SQUARE_SOLID"] = "square-solid";
})(SocialIconStyles || (SocialIconStyles = {}));

var _default = SocialIconStyles;
exports["default"] = _default;
module.exports = exports.default;