"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SocialSearchStatus */

/* Java class defined in repository squarespace-v6 */
var SocialSearchStatus;

(function (SocialSearchStatus) {
  SocialSearchStatus["UNDEFINED"] = 1;
  SocialSearchStatus["PREVIEW"] = 2;
  SocialSearchStatus["BLOCK_SAVED"] = 3;
  SocialSearchStatus["BLOCK_REMOVED"] = 4;
  SocialSearchStatus["SAVED"] = 5;
  SocialSearchStatus["REMOVED"] = 6;
})(SocialSearchStatus || (SocialSearchStatus = {}));

var _default = SocialSearchStatus;
exports["default"] = _default;
module.exports = exports.default;