"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.ReferralMatchType */

/* Java class defined in repository squarespace-v6 */
var ReferralMatchType;

(function (ReferralMatchType) {
  ReferralMatchType["WEBSITE"] = 1;
  ReferralMatchType["EMAIL_ADDRESS"] = 2;
  ReferralMatchType["FACEBOOK"] = 3;
  ReferralMatchType["TWITTER"] = 4;
  ReferralMatchType["LINKEDIN"] = 5;
  ReferralMatchType["FACEBOOK_ID"] = 6;
})(ReferralMatchType || (ReferralMatchType = {}));

var _default = ReferralMatchType;
exports["default"] = _default;
module.exports = exports.default;