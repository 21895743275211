import {
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.VideoFullCenter01 class.
 *
 * @module squarespace-slide-rendering-layouts-video-full-center-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-video-full-center-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for video-full-center-01.
   *
   * @class VideoFullCenter01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.VideoFullCenter01 = Y.Base.create('VideoFullCenter01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var copyNode = contentBox.querySelector('.group-copy');
        var logoNode = contentBox.querySelector('[data-compound-type="logo"] > *');
        var responsiveWrapperNode = contentBox.querySelector('.responsive-wrapper');

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthRatio: 0.49,
          minFontSize: 13
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          widthRatio: 0.99,
          heightRatio: 0.10,
          scale: 0.99,
          minFontSize: 18,
          heightBoundaryNode: contentBox.querySelector('.sqs-slide-layer')
        }));

        // Actions and custom form font size
        var actionsFontSizeComponent = new ResponsiveFontSize({
          rootNode: responsiveWrapperNode,
          fontSizeNodes: [
            contentBox.querySelector(('[data-compound-type="action"] ul')),
            contentBox.querySelector('[data-slice-type="custom-form"]')
          ],
          minFontSize: 10,
          widthRatio: 0.8,
          enabled: false,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
          },
          onMinFontSize: function(rootNode) {
            rootNode.classList.add('stacked');
          }
        });

        this._registerResponsiveComponent(actionsFontSizeComponent);

        // Overlap between copy and responsive wrapper
        var copyResponsiveOverlapComponent = new ResponsiveOverlapDetector({
          rootNode: copyNode,
          targetNode: responsiveWrapperNode,
          enabled: false,
          padding: 10,
          onRespond: function(rootNode, targetNode) {
            rootNode.classList.add('stacked');
            rootNode.classList.remove('align-center-vert');
            rootNode.parentNode.classList.add('scroll');
            targetNode.parentNode.classList.remove('bottom-full-width');
          }
        });

        this._registerResponsiveComponent(copyResponsiveOverlapComponent);

        // Overlap between the logo first child and the responsive wrapper
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: logoNode,
          targetNode: responsiveWrapperNode,
          padding: 60,
          onReset: function(rootNode, targetNode) {
            var targetNodeParent = targetNode.parentNode;

            targetNodeParent.classList.add('top-right');
            targetNodeParent.classList.remove('bottom-full-width');

            actionsFontSizeComponent.disable();
            copyResponsiveOverlapComponent.disable();

            if (targetNode.querySelector('[data-slice-type]:not([data-content-empty="true"])')) {
              rootNode.parentNode.classList.add('text-align-left');
            }
          },
          onRespond: function(rootNode, targetNode) {
            var targetNodeParent = targetNode.parentNode;

            targetNodeParent.classList.remove('top-right');
            targetNodeParent.classList.add('bottom-full-width');

            actionsFontSizeComponent.enable();
            copyResponsiveOverlapComponent.enable();

            rootNode.parentNode.classList.remove('text-align-left');
          }
        }));

        // Scroll on overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: copyNode,
          widthRatio: 0.9,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
            rootNode.classList.add('align-center-vert');
            rootNode.classList.add('full-width');
            rootNode.parentNode.classList.remove('scroll');
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('stacked');
            rootNode.classList.remove('align-center-vert');
            rootNode.classList.remove('full-width');
            rootNode.parentNode.classList.add('scroll');
          }
        }));
      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
