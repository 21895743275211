/**
 * The module containing the Squarespace.SlideRendering.Slices.Body class.
 *
 * @module squarespace-slide-rendering-slices-body
 */
YUI.add('squarespace-slide-rendering-slices-body', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * The slice widget for slices of type SliceType.BODY.
   *
   * @class Body
   * @constructor
   * @namespace Squarespace.SlideRendering.Slices
   * @extends Squarespace.SlideRendering.Slices.Base
   * @uses Squarespace.SlideRendering.Slices.ClientRefresh
   */
  Y.Squarespace.SlideRendering.Slices.Body = Y.Base.create('Body',
    Y.Squarespace.SlideRendering.Slices.Base,
    [Y.Squarespace.SlideRendering.Slices.ClientRefresh],
    {
      // Override
      refreshFromSliceContent: function(sliceContent) {
        var markup = sliceContent.get('body.html');
        if (markup && typeof markup !== 'string' && markup.length) {
          markup = markup.toString();
        }
        var isEmpty = !Y.Lang.isValue(markup) || markup === '';

        this.get('contentBox').setHTML(markup);
        this._updateContentEmptyAttribute(isEmpty);
      }
    },
    {
      SLICE_TYPE: Y.Squarespace.SliceType.BODY,
      CSS_PREFIX: 'sqs-slice-body'
    }
  );
}, '1.0', {
  requires: [
    'base',
    'squarespace-enum',
    'squarespace-slide-rendering-slices-base',
    'squarespace-slide-rendering-slices-client-refresh'
  ]
});
