"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.model.applenews.ChannelMappingState */

/* Java class defined in repository squarespace-v6 */
var ChannelMappingState;

(function (ChannelMappingState) {
  ChannelMappingState["CONNECTED"] = 1;
  ChannelMappingState["DISCONNECTING"] = 2;
})(ChannelMappingState || (ChannelMappingState = {}));

var _default = ChannelMappingState;
exports["default"] = _default;
module.exports = exports.default;