"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SocialService */

/* Java class defined in repository squarespace-v6 */
var SocialServices;

(function (SocialServices) {
  SocialServices["TWITTER"] = 1;
  SocialServices["FACEBOOK"] = 2;
  SocialServices["GOOGLE"] = 3;
  SocialServices["LINKEDIN"] = 4;
  SocialServices["STUMBLE"] = 5;
  SocialServices["REDDIT"] = 6;
  SocialServices["PINTEREST"] = 7;
  SocialServices["TUMBLR"] = 8;
})(SocialServices || (SocialServices = {}));

var _default = SocialServices;
exports["default"] = _default;
module.exports = exports.default;