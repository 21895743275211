"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.InvoiceProvider */

/* Java class defined in repository squarespace-v6 */
var InvoiceProvider;

(function (InvoiceProvider) {
  InvoiceProvider["STRIPE"] = 1;
  InvoiceProvider["ZUORA"] = 2;
  InvoiceProvider["BILLING_SERVICE"] = 3;
  InvoiceProvider["BILLING_SERVICE_DEBT"] = 4;
})(InvoiceProvider || (InvoiceProvider = {}));

var _default = InvoiceProvider;
exports["default"] = _default;
module.exports = exports.default;