"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SubscriptionLineItemType */

/* Java class defined in repository squarespace-v6 */
var SubscriptionLineItemType;

(function (SubscriptionLineItemType) {
  SubscriptionLineItemType["NOT_PRORATED"] = "not-prorated";
  SubscriptionLineItemType["PRORATED_REFUND"] = "prorated-refund";
  SubscriptionLineItemType["PRORATED_CHARGE"] = "prorated-charge";
  SubscriptionLineItemType["MOVE_RECEIVED"] = "move-received";
  SubscriptionLineItemType["MOVE_SENT"] = "move-sent";
  SubscriptionLineItemType["MANUAL_REFUND"] = "manual-refund";
  SubscriptionLineItemType["BUNDLE_DISCOUNT_FORFEITURE"] = "bundle-discount-forfeiture";
})(SubscriptionLineItemType || (SubscriptionLineItemType = {}));

var _default = SubscriptionLineItemType;
exports["default"] = _default;
module.exports = exports.default;