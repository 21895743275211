import {
  OverflowDetector as ResponsiveOverflowDetector,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.LockFullBottomleft01 class.
 *
 * @module squarespace-slide-rendering-layouts-lock-full-bottomleft-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-lock-full-bottomleft-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for lock-full-bottomleft-01.
   *
   * @class LockFullBottomleft01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.LockFullBottomleft01 = Y.Base.create('LockFullBottomleft01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var contentGroupNode = contentBox.querySelector('.sqs-slice-group.group-content');
        var frontLayerContentNode = contentBox.querySelector('.layer-front .sqs-slide-layer-content');

        // Overlap between the lock icon and the copy/password group
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('[data-slice-type="lock"]'),
          targetNode: contentBox.querySelector('.sqs-slice-group.group-copy-password'),
          onReset: function() {
            contentGroupNode.classList.remove('stack');
          },
          onRespond: function() {
            contentGroupNode.classList.add('stack');
          },
          padding: 200
        }));

        // Scroll on overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: contentGroupNode,
          onReset: function(rootNode) {
            rootNode.classList.add('bottom-full-width');
            frontLayerContentNode.classList.remove('scroll');
          },
          onRespond: function(rootNode) {
            rootNode.classList.remove('bottom-full-width');
            frontLayerContentNode.classList.add('scroll');
          }
        }));
      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
