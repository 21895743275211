"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.BillingTaxType */

/* Java class defined in repository squarespace-v6 */
var BillingTaxType;

(function (BillingTaxType) {
  BillingTaxType["AUSTRALIAN_GST"] = "australian-gst";
  BillingTaxType["NZ_GST"] = "nz-gst";
  BillingTaxType["US_SALES"] = "us-sales";
  BillingTaxType["VAT"] = "vat";
  BillingTaxType["VAT_PROVINCE"] = "vat_province";
  BillingTaxType["NONE"] = "none";
})(BillingTaxType || (BillingTaxType = {}));

var _default = BillingTaxType;
exports["default"] = _default;
module.exports = exports.default;