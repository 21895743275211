import {
  BoundaryRatios as ResponsiveBoundaryRatios,
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
} from '@sqs/responsive';

/**
 * The module containing the Squarespace.SlideRendering.Layouts.AudioFullLeft01
 * class.
 *
 * @module squarespace-slide-rendering-layouts-audio-full-left-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-audio-full-left-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for audio-full-left-01.
   *
   * @class AudioFullLeft01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.AudioFullLeft01 = Y.Base.create('AudioFullLeft01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var actionsGroupNode = contentBox.querySelector('.sqs-slice-group.group-links');

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthRatio: 0.98,
          minFontSize: 26
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          widthRatio: 0.98,
          minFontSize: 15
        }));

        // Actions and custom form font size
        var actionsFontSizeComponent = new ResponsiveFontSize({
          rootNode: contentBox.querySelector('.responsive-wrapper.actions'),
          widthBoundaryNode: actionsGroupNode,
          fontSizeNodes: [
            contentBox.querySelector(('[data-compound-type="action"] ul')),
            contentBox.querySelector('[data-slice-type="custom-form"]')
          ],
          minFontSize: 10,
          widthRatio: 0.9,
          enabled: false,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
          },
          onMinFontSize: function(rootNode) {
            rootNode.classList.add('stacked');
          }
        });

        this._registerResponsiveComponent(actionsFontSizeComponent);

        // Social icons wrapping
        var socialIconsWrappingComponent = new ResponsiveBoundaryRatios({
          rootNode: contentBox.querySelector('.responsive-wrapper.social'),
          widthRatio: 0.9,
          enabled: false,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('stacked');
          }
        });

        this._registerResponsiveComponent(socialIconsWrappingComponent);

        // Disable actions and custom form font size and social icons wrapping
        // when their wrapper's boundary ratio is high enough
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: actionsGroupNode,
          widthRatio: 0.9,
          onReset: function(rootNode) {
            rootNode.classList.add('inline');
            actionsFontSizeComponent.disable();
            socialIconsWrappingComponent.disable();
          },
          onRespond: function(rootNode) {
            rootNode.classList.remove('inline');
            actionsFontSizeComponent.enable();
            socialIconsWrappingComponent.enable();
          }
        }));

        // Scroll on overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: contentBox.querySelector('.sqs-slide-layer-content > .sqs-slice-group'),
          onReset: function(rootNode) {
            rootNode = new Y.Node(rootNode);

            rootNode.removeClass('stacked');
            rootNode.ancestor('.sqs-slide-layer').removeClass('scroll');
          },
          onRespond: function(rootNode) {
            rootNode = new Y.Node(rootNode);

            rootNode.addClass('stacked');
            rootNode.ancestor('.sqs-slide-layer').addClass('scroll');
          }
        }));
      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
