import {
  BoundaryRatios as ResponsiveBoundaryRatios,
  FontSize as ResponsiveFontSize,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.LandingLeftRight01 class.
 *
 * @module squarespace-slide-rendering-layouts-landing-left-right-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-landing-left-right-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for landing-left-right-01.
   *
   * @class LandingLeftRight01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.LandingLeftRight01 = Y.Base.create('LandingLeftRight01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var copyNode = contentBox.querySelector('.group-copy');
        var responsiveWrapperNodeActions = contentBox.querySelector('.responsive-wrapper.actions');
        var responsiveWrapperNodeSocial = contentBox.querySelector('.responsive-wrapper.social');

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthBoundaryNode: copyNode,
          widthRatio: 1,
          scale: 0.99,
          minFontSize: 16
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          widthBoundaryNode: copyNode,
          widthRatio: 1,
          heightRatio: 0.2,
          scale: 0.99,
          minFontSize: 20,
          heightBoundaryNode: contentBox.querySelector('.sqs-slide-layer')
        }));

        // Actions and custom form font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: responsiveWrapperNodeActions,
          fontSizeNodes: [
            contentBox.querySelector(('[data-compound-type="action"] ul')),
            contentBox.querySelector('[data-slice-type="custom-form"]')
          ],
          minFontSize: 10,
          widthRatio: 0.9,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
          },
          onMinFontSize: function(rootNode) {
            rootNode.classList.add('stacked');
          }
        }));

        // Social icons wrapping
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: responsiveWrapperNodeSocial,
          widthRatio: 0.9,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('stacked');
          }
        }));

        // Overlap between copy and logo first child
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: copyNode,
          targetNode: contentBox.querySelector('[data-compound-type="logo"] > *'),
          padding: 60,
          onReset: function(rootNode, targetNode) {
            copyNode.classList.add('align-center-vert');
          },
          onRespond: function(rootNode, targetNode) {
            copyNode.classList.remove('align-center-vert');
          }
        }));

        // Overlap between branding and social icons responsive wrapper
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('[data-compound-type="logo"]'),
          targetNode: responsiveWrapperNodeSocial,
          padding: 40,
          onReset: function(rootNode, targetNode) {
            rootNode.parentNode.classList.remove('scroll');
            targetNode.parentNode.classList.add('bottom-full-width');
            rootNode.classList.remove('stacked');
          },
          onRespond: function(rootNode, targetNode) {
            rootNode.classList.add('stacked');
            rootNode.parentNode.classList.add('scroll');
            targetNode.parentNode.classList.remove('bottom-full-width');
          }
        }));

        // Overlap between copy and social icons responsive wrapper
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: copyNode,
          targetNode: responsiveWrapperNodeSocial,
          padding: 50,
          onReset: function(rootNode, targetNode) {
            rootNode.parentNode.classList.remove('scroll');
            targetNode.parentNode.classList.add('bottom-full-width');
            rootNode.classList.remove('stacked');
          },
          onRespond: function(rootNode, targetNode) {
            rootNode.classList.add('stacked');
            rootNode.parentNode.classList.add('scroll');
            targetNode.parentNode.classList.remove('bottom-full-width');
          }
        }));
      }
    }
  );

}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
