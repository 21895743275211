/**
 * The module containing the Squarespace.SlideRendering.Slices.Factory
 * class.
 *
 * @module squarespace-slide-rendering-slices-factory
 */
YUI.add('squarespace-slide-rendering-slices-factory', function(Y) {

  var factory = Y.Squarespace.FactoryUtils.createEnumToClassFactory(
    Y.Squarespace.SliceType, Y.Squarespace.SlideRendering.Slices, 'SLICE_TYPE', 'Base');

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * A factory class for creating Squarespace.SlideRendering.Slices
   * subclasses for a given Squarespace.SliceType.
   *
   * @class Factory
   * @static
   * @namespace Squarespace.SlideRendering.Slices
   */
  Y.Squarespace.SlideRendering.Slices.Factory = factory;

}, '1.0', {
  requires: [
    'squarespace-enum',
    'squarespace-factory-utils',
    'squarespace-slide-rendering-slices-album',
    'squarespace-slide-rendering-slices-body',
    'squarespace-slide-rendering-slices-buttons',
    'squarespace-slide-rendering-slices-custom-form',
    'squarespace-slide-rendering-slices-gallery',
    'squarespace-slide-rendering-slices-heading',
    'squarespace-slide-rendering-slices-image',
    'squarespace-slide-rendering-slices-lock',
    'squarespace-slide-rendering-slices-map',
    'squarespace-slide-rendering-slices-newsletter',
    'squarespace-slide-rendering-slices-navigation',
    'squarespace-slide-rendering-slices-password',
    'squarespace-slide-rendering-slices-social-icons',
    'squarespace-slide-rendering-slices-sub-heading',
    'squarespace-slide-rendering-slices-twitter',
    'squarespace-slide-rendering-slices-video'
  ]
});