import CookieManager from '@sqs/census-cookie-manager';
import CookieCutter from '@sqs/cookie-cutter';
import PageTypesEnum from '@sqs/enums/PageTypes';
import PagePermissionTypeEnum from '@sqs/enums/PagePermissionType';
import EventType from '@sqs/enums/CensusEventType';
import WebsiteRole from '@sqs/enums/WebsiteRole';
import { getResolvedWebsiteLocale } from 'shared/i18n/resolvedLocales';
import network from '@sqs/network';
import qs from 'qs';

interface SquarespaceContext {
  itemId: undefined | string;
  item: undefined | {
    title: string;
    recordType: string;
  };
  collectionId: undefined | string;
  collection: undefined | {
    title: string;
    permissionType: undefined | PagePermissionTypeEnum;
  };
  cookieSettings: undefined | {
    isRestrictiveCookiePolicyEnabled: boolean;
    isRestrictiveCookiePolicyAbsolute: boolean;
    isCookieBannerEnabled: boolean;
  };
  website: undefined | {
    id: string;
    cloneable: boolean;
  };
  templateId: undefined | string;
  authenticatedAccount: undefined | object;
  pageType: undefined | PageTypesEnum;
  websiteRoles: undefined | object;
}

const censusCookies = [
  // CENSUS-4846:
  // remove references to old census cookies ('ss_cid', 'ss_cpvisit', 'ss_cvisit') after 2023/07/01
  'ss_cid', 'ss_cpvisit', 'ss_cvisit',
  'ss_cvr', 'ss_cvt'];

const ignoreRejectedPromise = () => Promise.resolve();

const encodeFormWww = { headers: { 'Content-type': 'application/x-www-form-urlencoded; charset=utf-8' } };

/**
 * Gets the squarespace context
 * Also useful because it allows us to bypass the type-system a bit since Window.Static always has SQUARESPACE_CONTEXT
 */
function getContext(): SquarespaceContext {
  return (window.Static as any).SQUARESPACE_CONTEXT;
}

/**
 * Unclear whether this is actually used but it seems to be a way to disable census,
 * TODO: figure out if this is actually used anywhere
 */
function getWindowDisabledCensus(): boolean | undefined {
  return (window as any).__WE_ARE_SQUARESPACE_DISABLING_CENSUS__;
}

function browserHasLocalStorage(): boolean {
  try {
    window.localStorage.setItem('test', '1');
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * @method addPagePermissionType  Sets the data.pagePermissionTypeValue field based on collection.permissionType
 * @param {Object} <data> - the data to be recorded by Census
 * @param {Object} <collection> - information about the page being displayed
 * @private
 * @return {Object} <data> - the data to be recorded by Census
 */
function addPagePermissionType(data: any, collection: any) {
  if (collection) {
    // must match pagePermissionTypeValue field name from com.squarespace.census.model.event.UserEvent for recording to
    // work with buttons and forms
    data.pagePermissionTypeValue = collection.permissionType;
  }
  return data;
}

/**
 * IMPURE - extends the 'data' object you pass in with tracking properties
 */
function addIntraPageEventFields(data: any) {
  const { itemId, item, collectionId, collection } = getContext();

  addPagePermissionType(data, collection);

  if (itemId && item) {
    data.pageTitle = item.title;
    data.pageId = itemId;
    data.contentSource = 'i';
  } else if (collectionId && collection) {
    data.pageTitle = collection.title;
    data.pageId = collectionId;
    data.contentSource = 'c';
  }

  data.pagePath = window.location.pathname;
}

/**
 * @method shouldRecord
 * @return {Boolean} whether or not to record
 */
function shouldRecord() {
  const { authenticatedAccount, pageType, websiteRoles } = getContext();

  /** there are enterprise clients that only
   * allow people to view the site after logging in. In this case, we only want to
   * record events on non config pages. The VIEWER role is given to such visitors.
   * To safeguard against recording for users having multiple roles (i.e. ADMIN + VIEWER), we assert
   * that visitors with only the VIEWER role get their events recorded.
   */
  const onlyHasViewerRole = websiteRoles &&
    Object.keys(websiteRoles).map(role => parseInt(role, 10)).includes(WebsiteRole.VIEWER) &&
    Object.keys(websiteRoles).length === 1;
  const shouldRecordForAuthenticatedAccount = !window.location.pathname.match(/\/config\/*/) &&
    onlyHasViewerRole;

  return (!authenticatedAccount || shouldRecordForAuthenticatedAccount) &&
    pageType !== PageTypesEnum.NOT_FOUND &&
    pageType !== PageTypesEnum.LOCK_SCREEN &&
    pageType !== PageTypesEnum.MEMBER_AREA_ACCESS_DENIED &&
    getWindowDisabledCensus() !== true;
}

/**
 * Cloneable websites should not collect data
 * as we don't use this information. We frame these
 * websites through the www Template store, and the
 * user tracking is captured there with the user's consent
 * on www.
 */
function websiteIsDemoSite() {
  const { website } = getContext();

  if (website?.cloneable) {
    return true;
  }

  return false;
}

/**
 * cookies are not allowed if
 *   - restrictive cookie policy is enabled
 *       AND
 *     at least one of the following is true:
 *     - restrictive cookie is absolute
 *     - cookie banner is not enabled
 *     - ss_cookieAllowed cookie is null or undefined
 *     - ss_cookieAllowed is string 'false'
 * (if ss_cookieAllowed cookie is true AND restrictive cookie policy is not absolute)
 *    - OR if the website is cloneable (meaning it is a demo website)
*/
function shouldRestrictCookies() {
  const { cookieSettings } = getContext();

  if (websiteIsDemoSite()) {
    return true;
  }

  return cookieSettings && cookieSettings.isRestrictiveCookiePolicyEnabled && (
    cookieSettings.isRestrictiveCookiePolicyAbsolute ||
    !cookieSettings.isCookieBannerEnabled ||
    !CookieCutter.get('ss_cookieAllowed') ||
    CookieCutter.get('ss_cookieAllowed') === 'false');
}

/**
 * Button Tracking Event Handler Factory
 *
 * buttonData: {
 *   "pagePath":"<string>",         // the page where the button resides, e.g. "/contact"
 *   "id":"<string>",
 *   "buttonText":"<string>",
 *   "clickthroughUrl":"<string>",
 *   "alignment":"<string>",        // "left", "center", "right", from button-editor.js
 *   "size":"<string>",             // "small", "medium", "large", from button-editor.js
 *   "newWindow":<boolean>,
 *   "context":<ButtonContext>      // BUTTON_BLOCK, POPUP_OVERLAY
 * }
 *
 * @method createButtonEventHandler
 * @param  {String} url The URL to send the request to.
 * @return {Function} A function that takes the buttonData as described above and POSTs
 */
function createButtonEventHandler(url: string) {
  return (buttonData: any) => {
    if (shouldRecord()) {
      const cvr = CookieManager.hit(shouldRestrictCookies());
      buttonData.visitorCookie = cvr;

      addIntraPageEventFields(buttonData);

      return network.post(url, buttonData, { timeout: 2500 }).catch(ignoreRejectedPromise);
    }

    return Promise.resolve();
  };
}

/**
 * @method addClientData
 * @param data
 * @private
 * @return data
 */
function addClientData(data: any) {
  data.userAgent = navigator.userAgent;
  data.clientDate = new Date().getTime();

  // screen data
  data.viewportInnerHeight = window.innerHeight;
  data.viewportInnerWidth = window.innerWidth;
  data.screenHeight = window.screen.height;
  data.screenWidth = window.screen.width;

  return data;
}

/**
 * @method addCoreSiteData
 * @param data - the data to be added to
 * @return data
 */
function addCoreSiteData(data: any) {
  const { website, templateId } = getContext();

  // augment core context data if present
  if (website) {
    data.websiteId = website.id;
  }

  if (templateId) {
    data.templateId = templateId;
  }

  data.website_locale = getResolvedWebsiteLocale(); // eslint-disable-line camelcase

  return data;
}

/**
 * @method addPageData
 * @param data
 * @private
 * @return data
 */
function addPageData(data: any) {
  data.url = window.location.pathname;

  const { itemId, item, collectionId, collection } = getContext();

  addPagePermissionType(data, collection);

  if (itemId && item) {
    data.title = item.title;
    data.itemId = itemId;
    data.recordType = item.recordType;
  } else if (collectionId && collection) {
    data.title = collection.title;
    data.collectionId = collectionId;
  }

  return data;
}

/**
 * @method getPageviewData
 * @private
 * @return data
 */
function getPageviewData() {
  return {
    localStorageSupported: browserHasLocalStorage(),
    queryString: window.location.search,
    referrer: window.document.referrer
  };
}

/**
 * We don't track page views if a pages is named /search
 * Likely to prevent inflation of that page's pageviews and because searches are tracked separately
 */
function isSearchPage() {
  return window.location.pathname === '/search';
}

/**
 * If cookies are restricted, delete existing cookies so
 * users don't freak out thinking that cookies are being used
 *
 * @method manageCookies
 */
function manageCookies() {
  if (shouldRestrictCookies()) {
    for (let i = 0; i < censusCookies.length; i++) {
      if (CookieCutter.get(censusCookies[i])) {
        CookieCutter.set(censusCookies[i], '', { path: '/', expires: (new Date(0)).toUTCString() });
      }
    }
  }
}

/**
 * @method track
 * @param  event
 * @param  data
 */
function track(event: number, data: any) {
  if (!shouldRecord()) {
    return;
  }

  if (typeof data !== 'object' || data === null) {
    data = {};
  }

  addCoreSiteData(data);
  addClientData(data);
  addPageData(data);

  const cvr = CookieManager.hit(shouldRestrictCookies());

  network.post('/api/census/RecordHit', qs.stringify({
    event,
    data: JSON.stringify(data),
    ss_cvr: cvr // eslint-disable-line camelcase
  }, { arrayFormat: 'repeat' }), encodeFormWww).catch(ignoreRejectedPromise);
}

const trackButtonClick = createButtonEventHandler('/api/census/button-click');

const trackButtonView = createButtonEventHandler('/api/census/button-render');

/**
 * Form Render Tracking
 *
 * @method trackFormRender
 * @param  {formId} The id of the form that was rendered.
 * @return {Promise} A promise to resolve
 */
function trackFormRender(formId: string) {
  if (shouldRecord()) {
    const cvr = CookieManager.hit(shouldRestrictCookies());

    const formData = {
      formId,
      visitorCookie: cvr
    };

    addIntraPageEventFields(formData);
    return network.post('/api/census/form-render', formData).catch(ignoreRejectedPromise);
  }

  return Promise.resolve();
}

/**
 * Overlay Event Tracking
 *
 * Note: This doesn't not include buttons and form submissions from overlays
 *
 * overlayEvent: {
 *   "pagePath":"<string>",      // the page where the overlay displayed, e.g. "/contact"
 *   "version":"<string>"
 *   "action":<OverlayAction>    // DISPLAY, CLOSE, DISMISS
 *   "trigger":<OverlayTrigger>  // TIME, SCROLL
 *   "layoutId":<string>
 *   "pagePermissionTypeValue":<PagePermissionType>
 * }
 *
 * @method trackOverlayEvent
 * @param overlayEvent
 */
function trackOverlayEvent(overlayEvent: any) {
  if (shouldRecord()) {
    const cvr = CookieManager.hit(shouldRestrictCookies());
    overlayEvent.visitorCookie = cvr;

    addIntraPageEventFields(overlayEvent);

    return network.post('/api/census/overlay', overlayEvent).catch(ignoreRejectedPromise);
  }
}

function trackPageview() {
  if (isSearchPage()) {
    return;
  }

  const data = getPageviewData();

  track(EventType.PAGE_VIEW, data);
}

/**
 * @method trackQuickView
 * @param contentItemId - string
 */
function trackProductQuickView(contentItemId: string) {
  if (shouldRecord()) {
    network.post(`/api/census/RecordQuickView/${contentItemId}`);
  }
}

export {
  addIntraPageEventFields,
  manageCookies,
  track,
  trackButtonClick,
  trackButtonView,
  trackFormRender,
  trackOverlayEvent,
  trackPageview,
  trackProductQuickView
};
