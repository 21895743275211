"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.ExternalServiceProviderOnlyOneAllowed */

/* Java class defined in repository squarespace-v6 */
var ExternalServiceProvidersOnlyOneAllowed;

(function (ExternalServiceProvidersOnlyOneAllowed) {
  ExternalServiceProvidersOnlyOneAllowed["CHOWNOW"] = 49;
  ExternalServiceProvidersOnlyOneAllowed["WEGLOT"] = 80;
})(ExternalServiceProvidersOnlyOneAllowed || (ExternalServiceProvidersOnlyOneAllowed = {}));

var _default = ExternalServiceProvidersOnlyOneAllowed;
exports["default"] = _default;
module.exports = exports.default;