import parseColor from '@sqs/utils/color/parseColor';

/**
 * The module containing the Squarespace.SlideRendering.Slices.RGB class.
 *
 * @module squarespace-widges-slices-rgb
 */
YUI.add('squarespace-slide-rendering-slices-rgb', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * An RGB color.
   *
   * @class RGB
   * @constructor
   * @namespace Squarespace.SlideRendering.Slices
   * @extends Base
   */
  Y.Squarespace.SlideRendering.Slices.RGB = Y.Base.create('RGB',
    Y.Base,
    [],
    {
      /**
       * Calculates the color's perceived brightness. The formula reflects
       * the luminosity function: green light contributes the most to the
       * intensity perceived by humans, and blue light the least.
       * http://en.wikipedia.org/wiki/Luminance_(relative)
       *
       * @method getBrightness
       * @returns {Number}
       */
      getBrightness: function() {
        var color = this.get('color');
        return (0.2126 * color.r) + (0.7152 * color.g) + (0.0722 * color.b);
      },

      /**
       * Adds an RGB color to the current RGB components.
       *
       * @method add
       * @param {RGB} rgb
       */
      add: function(rgb) {
        var color = this.get('color');
        var extra = rgb.get('color');

        color.r += extra.r;
        color.g += extra.g;
        color.b += extra.b;
      },

      /**
       * Darkens the color by a given percentage.
       *
       * @method darken
       * @param {Number} percentage
       */
      darken: function(percentage) {
        this.divide((100 + percentage) / 100);
      },

      /**
       * Divides the RGB components by a given factor.
       *
       * @method divide
       * @param {Number} factor
       */
      divide: function(factor) {
        var color = this.get('color');

        color.r /= factor;
        color.g /= factor;
        color.b /= factor;

        this._validate();
      },

      /**
       * Converts the current color to a string.
       *
       * @method toString
       * @returns {String}
       */
      toString: function() {
        var color = this.get('color');
        return 'rgb(' + color.r + ',' + color.g + ',' + color.b + ')';
      },

      /**
       * Makes sure that each RGB component lies within [0..255]
       * and that it's not a fraction.
       *
       * @method _validate
       * @private
       */
      _validate: function() {
        var color = this.get('color');

        Y.Object.keys(color).forEach(function(key) {
          color[key] = Math.round(Math.min(Math.max(0, color[key]), 255));
        });
      },

      /**
       * Converts a hex color to an RGB.
       *
       * @method _fromHex
       * @private
       * @param {String} hex
       * @returns {Object}
       */
      _fromHex: function(hex) {
        var rgb = parseColor(hex, 'rgb');

        return {
          r: rgb.red,
          g: rgb.green,
          b: rgb.blue
        };
      }
    },
    {
      ATTRS: {
        /**
         * The color value.
         *
         * @attribute color
         * @type Object
         */
        color: {
          value: {
            r: 0,
            g: 0,
            b: 0
          },
          setter: function(val) {
            if (Y.Lang.isString(val)) {
              if (val[0] !== '#') {
                val = '#' + val;
              }

              val = this._fromHex(val);
            }

            return val;
          }
        }
      }
    }
  );
}, '1.0', {
  requires: [
    'base'
  ]
});