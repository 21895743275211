import {
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the Squarespace.SlideRendering.Layouts.TwitterFullCenter01 class.
 * @module squarespace-slide-rendering-layouts-twitter-full-center-01
 */
YUI.add('squarespace-slide-rendering-layouts-twitter-full-center-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for twitter-full-center-01.
   *
   * @class TwitterFullCenter01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.TwitterFullCenter01 = Y.Base.create('TwitterFullCenter01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function () {
        var contentBox = this.get('contentBox').getDOMNode();
        var twitterGroupNode = contentBox.querySelector('.group-twitter');
        var responsiveWrapperNode = contentBox.querySelector('.responsive-wrapper');
        var logoGroupNode = contentBox.querySelector('.group-logo');
        var contentNode = contentBox.querySelector('.layer-front .sqs-slide-layer-content');
        var brandingNode = contentBox.querySelector('[data-compound-type="logo"]');

        var setLogoHeight = function() {
          if (responsiveWrapperNode.classList.contains('stacked') || !logoGroupNode.classList.contains('top-left')) {
            logoGroupNode.style.minHeight = null;
            responsiveWrapperNode.style.minHeight = null;
          } else {
            logoGroupNode.style.minHeight = responsiveWrapperNode.clientHeight + 'px';
            responsiveWrapperNode.style.minHeight = logoGroupNode.clientHeight + 'px';
          }
        };

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: brandingNode.querySelector('h1'),
          widthRatio: 0.4,
          minFontSize: 13,
          onReset: function() {
            setLogoHeight();
          },
          onRespond: function() {
            setLogoHeight();
          },
          onMinFontSize: function() {
            setLogoHeight();
          }
        }));

        // Actions and custom form font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: responsiveWrapperNode,
          fontSizeNodes: [
            contentBox.querySelector(('[data-compound-type="action"] ul')),
            contentBox.querySelector('[data-slice-type="custom-form"]')
          ],
          widthBoundaryNode: twitterGroupNode,
          minFontSize: 10,
          widthRatio: 0.8,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
          },
          onMinFontSize: function(rootNode) {
            rootNode.classList.add('stacked');
          }
        }));

        // Font size scaling for tweet bodies
        var tweetBodyNodes = contentBox.querySelectorAll('[data-slice-type="twitter"] .sqs-tweet .tweet-body');

        Array.prototype.forEach.call(tweetBodyNodes, function(tweetBodyNode) {
          this._registerResponsiveComponent(new ResponsiveFontSize({
            rootNode: tweetBodyNode,
            widthRatio: 1,
            heightRatio: 0.5,
            scale: 0.97,
            minFontSize: 18,
            heightBoundaryNode: twitterGroupNode,

            onReset: function(rootNode) {
            },
            onMinFontSize: function(rootNode) {
            }
          }));
        }, this);

        // Overlap between logo first child and responsive wrapper
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('[data-compound-type="logo"] > *'),
          targetNode: responsiveWrapperNode,
          onReset: function(rootNode, targetNode) {
            targetNode.classList.add('top-right');
            logoGroupNode.classList.add('top-left');
            targetNode.classList.remove('stacked');
            brandingNode.classList.remove('stacked');
            setLogoHeight();
          },
          onRespond: function(rootNode, targetNode) {
            targetNode.classList.remove('top-right');
            logoGroupNode.classList.remove('top-left');
            targetNode.classList.add('stacked');
            brandingNode.classList.add('stacked');
            setLogoHeight();
          }
        }));

        // Overlap between tweetNode and actions
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: responsiveWrapperNode,
          targetNode: twitterGroupNode,
          padding: 20,
          onReset: function(rootNode, targetNode) {
            rootNode.classList.add('top-right');
            logoGroupNode.classList.add('top-left');
            rootNode.classList.remove('stacked');
            brandingNode.classList.remove('stacked');
            twitterGroupNode.classList.remove('stacked');
            contentNode.classList.remove('scroll');
            setLogoHeight();
          },
          onRespond: function(rootNode, targetNode) {
            rootNode.classList.remove('top-right');
            logoGroupNode.classList.remove('top-left');
            rootNode.classList.add('stacked');
            brandingNode.classList.add('stacked');
            twitterGroupNode.classList.add('stacked');
            contentNode.classList.add('scroll');
            setLogoHeight();
          }
        }));

        // Scroll on Twitter node overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: twitterGroupNode,
          onReset: function() {
            twitterGroupNode.classList.remove('stacked');
            contentNode.classList.remove('scroll');
          },
          onRespond: function() {
            twitterGroupNode.classList.add('stacked');
            contentNode.classList.add('scroll');
          }
        }));

        // Scroll on overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: responsiveWrapperNode,
          onReset: function() {
            twitterGroupNode.classList.remove('stacked');
            contentNode.classList.remove('scroll');
          },
          onRespond: function() {
            twitterGroupNode.classList.add('stacked');
            contentNode.classList.add('scroll');
          }
        }));
      }
    }
  );

}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
