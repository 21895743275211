"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.BlockForeignKeyFieldNames */

/* Java class defined in repository squarespace-v6 */
var BlockForeignKeyFieldNames;

(function (BlockForeignKeyFieldNames) {
  BlockForeignKeyFieldNames["GALLERY_BLOCK_TRANSIENT_GALLERY_ID"] = "transientGalleryId";
  BlockForeignKeyFieldNames["ALBUM_BLOCK_TRANSIENT_COLLECTION_ID"] = "transientCollectionId";
  BlockForeignKeyFieldNames["FORM_BLOCK_FORM_ID"] = "formId";
  BlockForeignKeyFieldNames["IMAGE_BLOCK_IMAGE_ID"] = "imageId";
  BlockForeignKeyFieldNames["TWITTER_BLOCK_SEARCH_COLLECTION_ID"] = "socialCollectionId";
  BlockForeignKeyFieldNames["CHART_BLOCK_DATA_TABLE_ID"] = "dataTableId";
})(BlockForeignKeyFieldNames || (BlockForeignKeyFieldNames = {}));

var _default = BlockForeignKeyFieldNames;
exports["default"] = _default;
module.exports = exports.default;