import platform from 'platform';
import network from '@sqs/network';

export default class SvgCompatibility {

  static SVG_NAMESPACE_URI = 'http://www.w3.org/2000/svg';
  static SVG_NAMESPACE_QUALIFIED_NAME = 'g';
  static SVG_USE_SELECTOR = 'use';

  /**
   * map of url to spritesheet promise
   */
  static spritesheetPromises = {};

  /**
   * @return {Boolean}
   */
  static isNeeded = () => platform.name === 'IE';

  static logError = ({ message }) => {
    if (typeof console !== 'undefined') {
      console.error(`[SvgCompatibility] ${message}`);
    }
  };

  /**
   * @param {Element} root
   * @return {SVGUseElement[]}
   */
  static getEls = (root = document.body) =>
    [ ...(root.querySelectorAll(SvgCompatibility.SVG_USE_SELECTOR)) ];

  /**
   * @param {Element} root
   */
  static parse = (root = document.body) => {
    if (!SvgCompatibility.isNeeded()) {
      return;
    }
    SvgCompatibility.getEls(root).forEach((el) => {
      const { url, id } = SvgCompatibility.elToUrlId(el);
      if (!url || !id) {
        if (__DEV__ && typeof console !== 'undefined') {
          console.warn('SVG has incomplete xlink:href', el);
        }
        return;
      }

      SvgCompatibility
        .getSpritesheet(url)
        .then((spritesheet) => {
          if (__DEV__ && typeof console !== 'undefined') {
            console.log(`[SvgCompatibility] swapping ${id} with icon in ${url}`);
          }
          const icon = SvgCompatibility.getIconFromSpritesheet(id, spritesheet);
          SvgCompatibility.swapElWithIcon(el, icon);
        })
        .catch(SvgCompatibility.logError);
    });
  };

  /**
   * @param {SVGUseElement} <use> el in an <svg>
   * @return {Object}
   */
  static elToUrlId = (el) => {
    const parts = el.getAttribute('xlink:href').split('#');
    return { url: parts[0], id: parts[1] };
  };

  /**
   * @param {string} url
   * @return {Promise} cached promise resolves with a <div>optional <svg></div>
   */
  static getSpritesheet = (url) =>
    SvgCompatibility.spritesheetPromises[url] ?
      SvgCompatibility.spritesheetPromises[url] :
      SvgCompatibility.loadSpritesheet(url);

  /**
   * @param {string} url
   * @return {Promise} resolved with <div /> maybe containing and <svg />
   */
  static loadSpritesheet = (url) => {
    if (__DEV__ && typeof console !== 'undefined') {
      console.log(`[SvgCompatibility] loading ${url}`);
    }
    SvgCompatibility.spritesheetPromises[url] = network.get(url)
      .then(({ data }) => {
        const spritesheetFragment = document.createElement('div');
        spritesheetFragment.innerHTML = data;
        return spritesheetFragment;
      })
      .catch((error) => document.createElement('div')); // empty spritesheet
    return SvgCompatibility.spritesheetPromises[url];
  };

  /**
   * @param {String} id
   * @param {Element} spritesheet <svg> with <symbol>s
   * @return {?Element} a CLONE of child of an <svg>, <symbol>
   */
  static getIconFromSpritesheet = (id, spritesheet) => {
    if (!id || !spritesheet) {
      throw new Error('id and spritesheet are required');
    }
    const icon = spritesheet.querySelector(`#${id}`);
    if (!icon) {
      throw new Error(`Could not find icon "${id}"`);
    }
    return icon.cloneNode(true);
  };

  /**
   * @param {SVGUseElement} el <use> in an <svg> to fix
   * @param {Element} icon cloned icon spritesheet
   */
  static swapElWithIcon = (el, icon) => {
    if (!el || !el.parentNode || !icon || !icon.childNodes) {
      throw new Error('Non-orphan el and non-empty icon are required');
    }

    // Probably a <g>
    // @type {SVGElement}
    const replacement = document.createElementNS(
      SvgCompatibility.SVG_NAMESPACE_URI,
      SvgCompatibility.SVG_NAMESPACE_QUALIFIED_NAME
    );
    // <symbol> does not have children and is not an html element; use
    // childNodes to iterate and copy everything into the new NS
    [ ...(icon.childNodes) ].forEach((childNode) =>
      replacement.appendChild(childNode));

    replacement.setAttribute('class', el.getAttribute('class'));
    el.parentNode.replaceChild(replacement, el);
  };
}
