"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SocialIconSize */

/* Java class defined in repository cms-legacy-data */
var SocialIconSizes;

(function (SocialIconSizes) {
  SocialIconSizes["XSMALL"] = "extra-small";
  SocialIconSizes["SMALL"] = "small";
  SocialIconSizes["DEFAULT"] = "default";
  SocialIconSizes["LARGE"] = "large";
  SocialIconSizes["XLARGE"] = "extra-large";
})(SocialIconSizes || (SocialIconSizes = {}));

var _default = SocialIconSizes;
exports["default"] = _default;
module.exports = exports.default;