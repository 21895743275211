"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.getty.SortOrder */

/* Java class defined in repository squarespace-v6 */
var SortOrders;

(function (SortOrders) {
  SortOrders["BEST_MATCH"] = "best_match";
  SortOrders["MOST_POPULAR"] = "most_popular";
  SortOrders["NEWEST"] = "newest";
})(SortOrders || (SortOrders = {}));

var _default = SortOrders;
exports["default"] = _default;
module.exports = exports.default;