"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.domains.CertificateStatus */

/* Java class defined in repository squarespace-v6 */
var CertificateStatus;

(function (CertificateStatus) {
  CertificateStatus["ERROR"] = "ERROR";
  CertificateStatus["UNKNOWN"] = "UNKNOWN";
  CertificateStatus["NOT_FOUND"] = "NOT_FOUND";
  CertificateStatus["FAILED_DNS"] = "FAILED_DNS";
  CertificateStatus["FAILED"] = "FAILED";
  CertificateStatus["PROCESSING"] = "PROCESSING";
  CertificateStatus["ISSUED"] = "ISSUED";
})(CertificateStatus || (CertificateStatus = {}));

var _default = CertificateStatus;
exports["default"] = _default;
module.exports = exports.default;