"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.util.search.aggregations.AggregationGroupSortByTypes */

/* Java class defined in repository cms-legacy-data */
var AggregationGroupSortByTypes;

(function (AggregationGroupSortByTypes) {
  AggregationGroupSortByTypes["NAME"] = "name";
  AggregationGroupSortByTypes["FIRSTNAME"] = "firstName";
  AggregationGroupSortByTypes["LASTNAME"] = "lastName";
  AggregationGroupSortByTypes["DATE"] = "date";
  AggregationGroupSortByTypes["COUNT"] = "count";
  AggregationGroupSortByTypes["ACTIVITY"] = "activity";
})(AggregationGroupSortByTypes || (AggregationGroupSortByTypes = {}));

var _default = AggregationGroupSortByTypes;
exports["default"] = _default;
module.exports = exports.default;