"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.OrderPaymentState */

/* Java class defined in repository squarespace-v6 */
var OrderPaymentStates;

(function (OrderPaymentStates) {
  OrderPaymentStates["NOT_CHARGED"] = 1;
  OrderPaymentStates["AUTHORIZED"] = 2;
  OrderPaymentStates["PAID"] = 3;
  OrderPaymentStates["REFUNDED"] = 4;
})(OrderPaymentStates || (OrderPaymentStates = {}));

var _default = OrderPaymentStates;
exports["default"] = _default;
module.exports = exports.default;