import throttle from 'lodash/throttle';
import { t } from 'shared/i18n';
import PopupOverlayStyle from '@sqs/enums/PopupOverlayStyle';
import OverlayAction from '@sqs/enums/OverlayAction';
import OverlayTrigger from '@sqs/enums/OverlayTrigger';
import * as focus from '@sqs/utils/focus';
import WebsiteOverlaysUtils from 'shared/utils/WebsiteOverlaysUtils';
import * as LocalStorageUtils from 'shared/utils/LocalStorageUtils';
import { PopupOverlayLocalStorage } from '@sqs/websites-constants';
import * as CensusUtils from 'shared/utils/census/CensusUtils';
import * as GlobalSentry from 'shared/utils/error-reporter/global-sentry';
// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/popup-overlay.css';

var captureException = GlobalSentry.captureException;

// The max width constant must match the one in popup-overlay.less
var NON_MODAL_POPUP_MAX_WIDTH = 600;
/**
 * @module squarespace-popup-overlay
 */
YUI.add('squarespace-popup-overlay', function (Y) {

  /**
   * Displays a popup overlay when
   * Design > Popup Overlay
   * is enabled.
   *
   * @class PopupOverlay
   * @namespace Squarespace.Widgets
   * @extends SSWidget
   * @contructor
   */

  var nonModalMediaQueryList = Y.config.win.matchMedia('(max-width: ' + NON_MODAL_POPUP_MAX_WIDTH + 'px)');

  var LOGGED_IN = window.SQUARESPACE_LOGIN && window.SQUARESPACE_LOGIN.isLoggedIn();

  Y.namespace('Squarespace.Widgets');

  var PopupOverlay =
  Y.Squarespace.Widgets.PopupOverlay =
  Y.Base.create('PopupOverlay', Y.Squarespace.Widgets.SSWidget, [], {

    initializer: function () {
      this.popupSettings = Y.config.win.Static.SQUARESPACE_CONTEXT.websiteSettings.popupOverlaySettings || {};
      this.isHidden = true;
      this.isSetup = false;
      this._cb = this.get('contentBox');

      this.documentHeight = this.getDocumentHeight();
      this.viewportHeight = Y.config.win.innerHeight;


      // For an unclear reason, when using /config on a device with a tablet-sized screen,
      // calling `getDocumentHeight` above causes the body element to shift over,
      // pushing the /config sidebar off-screen rather than the intended effect of keeping the
      // iframe out of view until the user clicks the Edit button.
      //
      // Fixing the scrollLeft manually here and in frame-base.js fixes the issue.
      // See IA-1494 for details
      document.body.scrollLeft = 0;

      this.publish('closed', {
        emitFacade: true });


      Y.Global.publish('popupOverlay:showing');

      this.loadPopupJSON();

      this.showEvent = Y.Global.on('popupOverlay:requestShow', this.show, this);
      this.hideEvent = Y.Global.on('popupOverlay:hide', this.hide, this);
      this.formSubmitEvent = Y.Global.on('form:submitSuccess', this._markSubmitted, this);
      this._onMediaQueryChange = this._onMediaQueryChange.bind(this);
    },

    destructor: function () {
      this.showEvent.detach();
      this.showEvent = null;

      this.hideEvent.detach();
      this.hideEvent = null;

      this.formSubmitEvent.detach();
      this.formSubmitEvent = null;

      this.popupContainerNode.remove();
    },

    getDocumentHeight: function () {
      var doc = Y.config.win.document;
      var body = doc.body;
      var html = doc.documentElement;

      return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    },

    // override
    render: function () {
      this.popupContainerNode = Y.Node.create('<div></div>');
      Y.one('body').append(this.popupContainerNode);

      PopupOverlay.superclass.render.call(this, this.popupContainerNode);
    },

    loadPopupJSON: function () {
      var location = Y.config.win.location;
      var currentUrl = location.pathname + location.hash;
      var getParams = 'currentUrl=' + encodeURIComponent(currentUrl);

      if (Y.Squarespace.Utils.isInDamaskFrame()) {
        getParams += '&forceVisibility=true';
      }

      var xhr = new XMLHttpRequest();
      xhr.open('GET', '/api/popup-overlay/render?' + getParams);

      xhr.onload = function () {
        var response = null;
        try {
          if (xhr.status === 200) {
            response = JSON.parse(xhr.response);
          } else {
            throw new Error('Could not fetch popup overlay JSON');
          }
        } catch (error) {
          captureException(error);
          response = null;
        }

        if (!response || !response.shouldDisplayOnPage) {
          Y.Squarespace.Singletons.PopupOverlay.destroy();
          Y.Squarespace.Singletons.PopupOverlay = null;
          return;
        }

        var stored = LocalStorageUtils.getLocalStorageData(PopupOverlayLocalStorage.KEY) || {};
        stored[PopupOverlayLocalStorage.VERSION] = this.popupSettings.version;
        LocalStorageUtils.saveToLocalStorage(PopupOverlayLocalStorage.KEY, stored);

        if (LOGGED_IN) {
          this._loggedInResponse(response.renderedHtml);
        } else {
          this._loggedOutResponse(response.renderedHtml);
        }
      }.bind(this);

      xhr.send();
    },

    setupPopupOverlay: function (response) {
      if (this.isSetup || !response) {
        return;
      }

      this._cb.set('innerHTML', response);

      var slideNode = this._cb.one('.sqs-slide-container .sqs-slide');
      var closeButton = slideNode.one('.sqs-popup-overlay-close');
      closeButton.setAttribute('aria-label', t("Close"));




      // Don't bind close triggers when logged in
      if (!LOGGED_IN) {
        closeButton.once('click', this._closePopup.bind(this));
        closeButton.on('keydown', function (e) {
          var enterPressed = e.keyCode === 13;
          var spacePressed = e.keyCode === 32;
          if (enterPressed || spacePressed) {
            this._closePopup(e);
          }
        }.bind(this));
        slideNode.one('.layer-back').once('click', this._closePopup, this);
        Y.Squarespace.EscManager.disable();
        Y.one(Y.config.win.document.documentElement).on('keyup', this._onKeyUp, this);
      }

      var layoutId = slideNode.getAttribute('data-slide-layout-id');
      this.popupSettings.layout = layoutId;

      Y.Squarespace.SlideRendering.Layouts.Factory.create(layoutId, {
        boundingBox: slideNode,
        render: true });


      this.isSetup = true;
    },

    scrollHandler: function (event, xhrresponse, scrollHandler, resizeHandler) {
      var doc = Y.config.win.document;

      var scrollTop = doc.defaultView && doc.defaultView.scrollY ||
      doc.documentElement && doc.documentElement.scrollTop;

      var availableScroll = this.documentHeight - this.viewportHeight;
      var scrolledPercentage;
      if (availableScroll > 0) {
        scrolledPercentage = scrollTop / availableScroll;
      } else {
        scrolledPercentage = 1;
      }

      if (scrolledPercentage >= this.popupSettings.scrollPercentage / 100) {
        var winNode = Y.one(Y.config.win);
        winNode.detach('scroll', scrollHandler);
        winNode.detach('resize', resizeHandler);
        clearTimeout(this.popupTimer);
        this.setupPopupOverlay(xhrresponse);
        this.triggeredBy = OverlayTrigger.SCROLL;
        this.show();
      }
    },

    resizeHandler: function () {
      this.viewportHeight = Y.config.win.innerHeight;
    },

    _loggedOutResponse: function (xhrResponse) {
      var throttledScrollHandler = throttle(this.scrollHandler, 100);
      var throttledResizeHandler = throttle(this.resizeHandler, 100);
      var winNode = Y.one(Y.config.win);

      if (this.popupSettings.showOnTimer) {
        this.popupTimer = setTimeout(function () {
          this.setupPopupOverlay(xhrResponse);

          winNode.detach('scroll', throttledScrollHandler);
          winNode.detach('resize', throttledResizeHandler);

          this.triggeredBy = OverlayTrigger.TIME;
          this.show();
        }.bind(this), this.popupSettings.timerDelay);
      }

      if (this.popupSettings.showOnScroll) {
        if (this.documentHeight === this.viewportHeight && !this.popupSettings.showOnTimer) {
          this.setupPopupOverlay(xhrResponse);
          this.triggeredBy = OverlayTrigger.SCROLL;
          this.show();
          return;
        }

        winNode.on('scroll', throttledScrollHandler, this, xhrResponse, throttledScrollHandler, throttledResizeHandler);
        winNode.on('resize', throttledResizeHandler, this);
      }
    },

    _loggedInResponse: function (xhrResponse) {
      this.setupResponse = xhrResponse;

      if (!this.isHidden) {
        this.setupPopupOverlay(xhrResponse);
        this.show();
      }
    },

    _onKeyUp: function (e) {
      var escPressed = e.keyCode === 27;
      if (escPressed) {
        e.stopPropagation();
        this._closePopup(e);
      }
    },

    _closePopup: function (e) {
      // Avoid <a>
      if (e) {
        e.preventDefault();
      }

      if (!LOGGED_IN) {
        var action = e.currentTarget.hasClass('sqs-popup-overlay-close') ? t("CLOSE") : t("DISMISS");




        this._trackEvent(OverlayAction[action]);
      }

      this.hide();
      this._recordClose();
      Y.Squarespace.EscManager.enable();
      Y.one(Y.config.win.document.documentElement).detach('keyup', this._onKeyUp);

      if (this._resizeEvent) {
        this._resizeEvent.detach();
      }

      this.fire('closed');
    },

    /**
     * @method  _recordClose
     * @private
     */
    _recordClose: function () {
      var stored = LocalStorageUtils.getLocalStorageData(PopupOverlayLocalStorage.KEY);

      if (stored) {
        stored[PopupOverlayLocalStorage.CLOSED_DATE] = Date.now();
        LocalStorageUtils.saveToLocalStorage(PopupOverlayLocalStorage.KEY, stored);
      }
    },

    _markSubmitted: function () {
      var stored = LocalStorageUtils.getLocalStorageData(PopupOverlayLocalStorage.KEY);

      if (stored) {
        stored[PopupOverlayLocalStorage.SUBMITTED] = true;
        LocalStorageUtils.saveToLocalStorage(PopupOverlayLocalStorage.KEY, stored);
      }
    },

    _trackEvent: function (action) {
      var buttons = [];
      var formId = null;
      var slideNode = Y.one('div.sqs-slide[data-slide-type="popup-overlay"]');

      if (slideNode.one('.sqs-slice-buttons')) {
        slideNode.all('.sqs-slice-buttons li a').each(function (b) {
          buttons.push({
            buttonText: b.get('innerText'),
            clickthroughUrl: b.getAttribute('href') });

        });
      } else if (slideNode.one('.sqs-slice-newsletter')) {
        buttons.push({
          buttonText: slideNode.one('.newsletter-form button').get('value'),
          clickthroughUrl: null });

        formId = slideNode.one('.newsletter-form').getAttribute('data-form-id');
      } else {
        buttons = null;
      }

      CensusUtils.trackOverlayEvent({
        pagePath: Y.config.win.location.pathname,
        version: this.popupSettings.version.toString(),
        action: action,
        trigger: this.triggeredBy,
        layoutId: this.popupSettings.layout,
        buttons: buttons,
        formId: formId });

    },

    hide: function () {
      this.isHidden = true;
      var slide = this.get('boundingBox');
      slide.removeClass('visible');
      slide.setStyle('display', 'none');

      if (this.revertFocusContainment) {
        this.revertFocusContainment();
        this.revertFocusContainment = null;
      }

      if (this.previouslyFocusedElement) {
        this.previouslyFocusedElement.focus();
      } else {
        // If no element was focused before popup opened
        // (a legitimate case since popup may open on timeout,
        // without user actions), the only element to focus on
        // which is guaranteed to not cause a scroll position change
        // is the body.
        // Note: document.activeElement may be set to null or <body>
        // before any element is explicitly focused, per documentation:
        // https://developer.mozilla.org/en-US/docs/Web/API/DocumentOrShadowRoot/activeElement
        // In case it's set to <body>, then the condition will be truthy,
        // but the result will be the same: <body> receiving focus.
        Y.one('body').focus();
      }

      nonModalMediaQueryList.removeListener(this._onMediaQueryChange);
    },

    show: function () {
      this.isHidden = false;

      if (!this.isSetup && this.setupResponse) {
        this.setupPopupOverlay(this.setupResponse);

        if (!this.isSetup) {
          return;
        }
      }

      var slide = this.get('boundingBox');
      // slide.once('transitionend', function(evt) {
      //   evt.currentTarget.setStyle('display', 'block');
      // }, this);
      slide.addClass('visible');
      slide.setStyle('display', 'block');

      if (!LOGGED_IN) {
        this._trackEvent(OverlayAction.DISPLAY);
      }

      Y.Global.fire('popupOverlay:showing');

      if (!LOGGED_IN && Y.Squarespace.Singletons.WebsiteOverlaysManager) {
        Y.Squarespace.Singletons.WebsiteOverlaysManager.syncPreviewBar();
      }

      // We have to manually store the previously focused element
      // (to return focus to it after pop-up is dismissed)
      // because on small screens where the pop-up is non-modal,
      // we don't invoke the containFocus() function when the pop-up opens.
      this.previouslyFocusedElement = Y.one(Y.config.doc.activeElement);

      // We want to move focus to the pop-up, regardless of whether
      // it's modal or non-modal (to help screen reader users realize
      // that new important information is available on the page),
      // but containFocus() which could handle setting focus is only invoked
      // in the modal case.
      // So setting focus needs to also be handled manually.
      var firstFocusableElement = slide.one(focus.focusableSelector);
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }

      this._onShowOrMediaQueryChange(!nonModalMediaQueryList.matches);

      // Per documentation, MediaQueryList.addListener() is a legacy method,
      // and addEventListener('change') should be used instead.
      // However, addEventListener doesn't work in Safari, so the legacy method
      // needs to be used for compatibility.
      nonModalMediaQueryList.addListener(this._onMediaQueryChange);
    },

    _onMediaQueryChange: function (e) {
      this._onShowOrMediaQueryChange(!e.matches);
    },

    _onShowOrMediaQueryChange: function (isModal) {
      var slide = this.get('boundingBox');
      // Slide is given a role of 'dialog' or 'region' (depending
      // on whether it's modal), and for both of these roles a name
      // needs to be provided.
      // The best name is the header, and the header in question
      // always has the same id.
      // It seems to be safe to use this id without the risk of having
      // id clashes on the page and thus the region being labelled
      // by an unrelated element. In addition to the popup, the id
      // in question is used in lock screen and cover pages (unavailable in 7.1).
      // (by searching for <squarespace:slice type="heading" />)
      slide.setAttribute('aria-labelledby', 'sqs-slash-page-header');
      if (isModal) {
        slide.setAttribute('role', 'dialog');
        this.revertFocusContainment = focus.containFocus({
          container: this.get('boundingBox').getDOMNode(),
          // The library default value for `root` is `document.body`,
          // which does not work correctly when we're in config
          // (`document.body` being the containing document's body).
          root: Y.one('body').getDOMNode(),
          // We manage focus in this class instead of delegating it to
          // containFocus() because containFocus() is not always called
          // when pop-up is displayed. It's not called when the pop-up
          // is non-modal.
          setFocusOnContain: false,
          restoreFocusOnRevert: false });

      } else {
        slide.setAttribute('role', 'region');
        // Focus could previously be contained if we arrived here
        // by making the viewport smaller. Focus needs to be released.
        if (this.revertFocusContainment) {
          this.revertFocusContainment();
          this.revertFocusContainment = null;
        }
      }
    } },

  {
    CSS_PREFIX: 'sqs-popup-overlay',
    ATTRS: {
      style: {} } });




  // Initializer
  Y.config.win.Squarespace.onInitialize(Y, function () {
    var popupSettings = Y.config.win.Static.SQUARESPACE_CONTEXT.websiteSettings.popupOverlaySettings;
    var popupOverlayStyle = PopupOverlayStyle.NONE;
    if (popupSettings && popupSettings.style) {
      popupOverlayStyle = popupSettings.style;
    }

    if (!Y.Squarespace.Singletons.PopupOverlay) {
      Y.Squarespace.Singletons.PopupOverlay = new PopupOverlay({
        style: popupOverlayStyle });


      var shouldShowPopupOverlay = WebsiteOverlaysUtils.shouldShowPopupOverlay({
        context: {
          pageType: Y.config.win.Static.SQUARESPACE_CONTEXT.pageType,
          collection: Y.config.win.Static.SQUARESPACE_CONTEXT.collection },

        settings: popupSettings,
        isMobile: Y.UA.mobile,
        urlSearch: window.location.search });


      if (LOGGED_IN || shouldShowPopupOverlay) {
        Y.Squarespace.Singletons.PopupOverlay.render();
      }
    }

  });

  // Destructor
  Y.config.win.Squarespace.onDestroy(Y, function () {

    if (Y.Squarespace.Singletons.PopupOverlay) {
      Y.Squarespace.Singletons.PopupOverlay.destroy();
    }

  });


}, '1.0', {
  requires: [
  'base',
  'json',
  'node',
  'squarespace-escmanager',
  'squarespace-util',
  'squarespace-slide-rendering-layouts-factory',
  'squarespace-ss-widget'] });