"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.MediaProcessingState */

/* Java class defined in repository cms-legacy-data */
var MediaProcessingState;

(function (MediaProcessingState) {
  MediaProcessingState["QUEUED"] = 1;
  MediaProcessingState["PROCESSING"] = 2;
  MediaProcessingState["READY"] = 3;
  MediaProcessingState["ERROR"] = 4;
  MediaProcessingState["DOWNLOAD_ERROR"] = 5;
  MediaProcessingState["ERROR_TOO_LARGE"] = 6;
  MediaProcessingState["ERROR_FILE_NOT_FOUND"] = 7;
  MediaProcessingState["ERROR_UNSUPPORTED_IMAGE_TYPE"] = 8;
  MediaProcessingState["ERROR_UNSUPPORTED_AUDIO_TYPE"] = 9;
})(MediaProcessingState || (MediaProcessingState = {}));

var _default = MediaProcessingState;
exports["default"] = _default;
module.exports = exports.default;