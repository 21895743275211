"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.TweakType */

/* Java class defined in repository cms-legacy-data */
var TweakTypes;

(function (TweakTypes) {
  TweakTypes["COLOR"] = 1;
  TweakTypes["HUE"] = 2;
  TweakTypes["FONT"] = 3;
  TweakTypes["IMAGE"] = 4;
  TweakTypes["VALUE"] = 5;
  TweakTypes["MARGIN"] = 6;
  TweakTypes["PADDING"] = 7;
  TweakTypes["DROPDOWN"] = 8;
  TweakTypes["CHECKBOX"] = 9;
  TweakTypes["COLORPALETTE"] = 10;
})(TweakTypes || (TweakTypes = {}));

var _default = TweakTypes;
exports["default"] = _default;
module.exports = exports.default;