"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.CurrencySymbol */

/* Java class defined in repository squarespace-v6 */
var CurrencySymbol;

(function (CurrencySymbol) {
  CurrencySymbol["USD"] = "$";
  CurrencySymbol["CAD"] = "$";
  CurrencySymbol["GBP"] = "\xA3";
  CurrencySymbol["AUD"] = "$";
  CurrencySymbol["EUR"] = "\u20AC";
  CurrencySymbol["CHF"] = "CHF";
  CurrencySymbol["NOK"] = "kr";
  CurrencySymbol["SEK"] = "kr";
  CurrencySymbol["DKK"] = "kr";
  CurrencySymbol["NZD"] = "$";
  CurrencySymbol["SGD"] = "S$";
  CurrencySymbol["MXN"] = "$";
  CurrencySymbol["HKD"] = "HK$";
  CurrencySymbol["CZK"] = "K\u010D";
  CurrencySymbol["ILS"] = "\u20AA";
  CurrencySymbol["MYR"] = "RM";
  CurrencySymbol["RUB"] = "\u0440.";
  CurrencySymbol["PHP"] = "Php";
  CurrencySymbol["PLN"] = "z\u0142";
  CurrencySymbol["THB"] = "\u0E3F";
  CurrencySymbol["BRL"] = "R$";
  CurrencySymbol["ARS"] = "$";
  CurrencySymbol["COP"] = "COL$";
  CurrencySymbol["IDR"] = "Rp";
  CurrencySymbol["INR"] = "\u20B9";
  CurrencySymbol["JPY"] = "\xA5";
  CurrencySymbol["ZAR"] = "R";
})(CurrencySymbol || (CurrencySymbol = {}));

var _default = CurrencySymbol;
exports["default"] = _default;
module.exports = exports.default;