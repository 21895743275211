"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.PlanViolationError */

/* Java class defined in repository squarespace-v6 */
var PlanViolationErrors;

(function (PlanViolationErrors) {
  PlanViolationErrors["PAGE_LIMIT_EXCEEDED"] = 1;
  PlanViolationErrors["STORAGE_LIMIT_EXCEEDED"] = 2;
})(PlanViolationErrors || (PlanViolationErrors = {}));

var _default = PlanViolationErrors;
exports["default"] = _default;
module.exports = exports.default;