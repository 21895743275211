"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.AnnouncementBarStyle */

/* Java class defined in repository squarespace-v6 */
var AnnouncementBarStyle;

(function (AnnouncementBarStyle) {
  AnnouncementBarStyle["NONE"] = 1;
  AnnouncementBarStyle["CUSTOM"] = 2;
})(AnnouncementBarStyle || (AnnouncementBarStyle = {}));

var _default = AnnouncementBarStyle;
exports["default"] = _default;
module.exports = exports.default;