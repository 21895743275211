"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.DomainProviderId */

/* Java class defined in repository squarespace-v6 */
var DomainProviderId;

(function (DomainProviderId) {
  DomainProviderId["UNKNOWN"] = 1;
  DomainProviderId["GO_DADDY"] = 2;
  DomainProviderId["GOOGLE"] = 3;
  DomainProviderId["OPEN_SRS"] = 4;
  DomainProviderId["NAMECHEAP"] = 5;
  DomainProviderId["ENOM"] = 6;
  DomainProviderId["NETWORK_SOLUTIONS"] = 7;
  DomainProviderId["DREAMHOST"] = 8;
  DomainProviderId["BLUEHOST"] = 9;
  DomainProviderId["I_WANT_MY_NAME"] = 10;
  DomainProviderId["NAME_COM"] = 11;
  DomainProviderId["HOVER"] = 12;
  DomainProviderId["DOMAINS101"] = 13;
  DomainProviderId["ONE_AND_ONE"] = 14;
  DomainProviderId["DOTSTER"] = 15;
  DomainProviderId["DYN_AND_NETTICA"] = 16;
  DomainProviderId["DYNADOT"] = 17;
  DomainProviderId["EASY_DNS"] = 18;
  DomainProviderId["REGISTER_COM"] = 19;
  DomainProviderId["WORDPRESS"] = 20;
  DomainProviderId["YAHOO"] = 21;
})(DomainProviderId || (DomainProviderId = {}));

var _default = DomainProviderId;
exports["default"] = _default;
module.exports = exports.default;