"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.ButtonContext */

/* Java class defined in repository squarespace-v6 */
var ButtonContext;

(function (ButtonContext) {
  ButtonContext["BUTTON_BLOCK"] = 1;
  ButtonContext["POPUP_OVERLAY"] = 2;
})(ButtonContext || (ButtonContext = {}));

var _default = ButtonContext;
exports["default"] = _default;
module.exports = exports.default;