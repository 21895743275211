import TweetGallery from '@sqs/site-rendering/TweetGallery';

/**
 * The module containing the Squarespace.SlideRendering.Slices.Twitter class.
 *
 * @module squarespace-slide-rendering-slices-twitter
 */
YUI.add('squarespace-slide-rendering-slices-twitter', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * The slice widget for slices of type SliceType.TWITTER.
   *
   * @class Twitter
   * @constructor
   * @namespace Squarespace.SlideRendering.Slices
   * @extends Squarespace.SlideRendering.Slices.Base
   */
  Y.Squarespace.SlideRendering.Slices.Twitter = Y.Base.create('Twitter',
    Y.Squarespace.SlideRendering.Slices.Base,
    [],
    {
      initializer: function() {
        var boundingBox = this.get('boundingBox');
        var tweetNodes = boundingBox.all('.sqs-tweet');

        if (tweetNodes.size() > 0) {
          /*
           * Twitter's body HTML does not include a target for links it contains.
           * Find all anchor tags within all tweet body content and add "target='_blank'".
           * No user option setting for this.
           */
          tweetNodes.each(function(node) {
            node.all('.tweet-body a').each(function(anchor) {
              anchor.setAttribute('target', '_blank');
            });
          });

          var container = boundingBox.one('.sqs-tweets-container').getDOMNode();
          this._tweetGallery = new TweetGallery(container, 'sqs-tweet', Y.config.win);
        }
      },

      destructor: function() {
        if (this._tweetGallery) {
          this._tweetGallery.destroy();
        }
      }
    },
    {
      SLICE_TYPE: Y.Squarespace.SliceType.TWITTER,
      CSS_PREFIX: 'sqs-slice-twitter',
      ATTRS: {
      }
    }
  );
}, '1.0', {
  requires: [
    'base',
    'squarespace-enum',
    'squarespace-slide-rendering-slices-base',
    'squarespace-util'
  ]
});
