/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.ProfileFullTop01 class.
 *
 * @module squarespace-slide-rendering-layouts-profile-full-top-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-profile-full-top-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for profile-full-top-01.
   *
   * @class ProfileFullTop01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.ProfileFullTop01 = Y.Base.create('ProfileFullTop01',
    Y.Squarespace.SlideRendering.Layouts.ProfileFullBottom01,
    [],
    {}
  );
}, '1.0', {
  requires: [
    'base',
    'squarespace-slide-rendering-layouts-profile-full-bottom-01'
  ]
});