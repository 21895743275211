/**
 * The module containing the Squarespace.SlideRendering.ColorProcessor utility.
 *
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-color-processor', function(Y) {

  Y.namespace('Squarespace.SlideRendering');

  var DEFAULT_AUTO_COLOR = '222222';

  Y.Squarespace.SlideRendering.ColorProcessor = {
    /**
     * Takes a given node with the server-produced data-average-color attribute
     * and returns an object containing the calculated color and opacity values.
     *
     * @method processNode
     * @param node {Node} The node to be processed.
     * @return {Object} The object containing the 'color' and 'opacity' values.
     */
    processNode: function(node) {
      var raw = node.getAttribute('data-average-color');
      var json;

      // If we cannot find color data on the node, chances are the image is
      // still processing. We will use a default color for now.
      if (raw) {
        json = Y.JSON.parse(raw);
      } else {
        json = {
          topLeftAverage: DEFAULT_AUTO_COLOR,
          topRightAverage: DEFAULT_AUTO_COLOR,
          bottomLeftAverage: DEFAULT_AUTO_COLOR,
          bottomRightAverage: DEFAULT_AUTO_COLOR,
          centerAverage: DEFAULT_AUTO_COLOR,
          suggestedBgColor: DEFAULT_AUTO_COLOR
        };
      }

      // suggestedBgColor is the color value of the
      // top left pixel. Discard it.
      delete json.suggestedBgColor;

      var colorData = this._processColors(json);

      var processedColorStyles = this._calculateStyles(
        colorData.averageColor, colorData.distribution);

      node.setData('processedColorStyles', processedColorStyles);

      return processedColorStyles;
    },

    /**
     * Calculates the brightness distribution and the average color
     * for a list of hex colors.
     *
     * @method _processColors
     * @param colorJson {Object} The json from the data-average-color
     *     attribute.
     * @returns {Object} The object of color data.
     * @private
     */
    _processColors: function(colorJson) {
      var n = 0;
      var sum = 0;
      var sumSqr = 0;

      var averageColor = new Y.Squarespace.SlideRendering.Slices.RGB({
        color: {
          r: 0,
          g: 0,
          b: 0
        }
      });

      var brightness;
      var color;

      Y.Object.each(colorJson, function(hex) {
        color = new Y.Squarespace.SlideRendering.Slices.RGB({
          color: hex
        });

        brightness = color.getBrightness();

        n++;
        sum += brightness;
        sumSqr += Math.pow(brightness, 2);

        averageColor.add(color);
      });

      // Normalize distribution between [0..1]
      var variance = (sumSqr - (Math.pow(sum, 2) / n)) / n;
      var distribution = Math.sqrt(variance) / (255 / 2);

      averageColor.divide(n);

      return {
        distribution: distribution,
        averageColor: averageColor
      };
    },

    /**
     * Calculates the node color and opacity.
     *
     * @method _calculateStyles
     * @param averageColor {Squarespace.SlideRendering.Slices.RGB} The average
     *     color RGB.
     * @param distribution {Number} The distribution.
     * @returns {Object} Object with opacity and color values.
     * @private
     */
    _calculateStyles: function(averageColor, distribution) {
      var midPoint = 0.6 * 255;
      var brightness = averageColor.getBrightness();

      var opacity = (distribution > 0.4) ? 0.5 : 0.3;
      var darkenFactor = (brightness >= midPoint) ? 30 : 20;

      averageColor.darken(darkenFactor);

      return {
        opacity: opacity,
        color: averageColor
      };
    }
  };

}, '1.0', {
  requires: [
    'json',
    'squarespace-slide-rendering-slices-rgb'
  ]
});