"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SubscriptionEventRecordType */

/* Java class defined in repository squarespace-v6 */
var SubscriptionEventRecordTypes;

(function (SubscriptionEventRecordTypes) {
  SubscriptionEventRecordTypes["PURCHASE"] = "PURCHASE";
  SubscriptionEventRecordTypes["CANCEL"] = "CANCEL";
  SubscriptionEventRecordTypes["MOVE"] = "MOVE";
  SubscriptionEventRecordTypes["RESUBSCRIBE"] = "RESUBSCRIBE";
  SubscriptionEventRecordTypes["UPDATE"] = "UPDATE";
  SubscriptionEventRecordTypes["RENEW"] = "RENEW";
  SubscriptionEventRecordTypes["ADMIN_RETROACTIVE_REFUND"] = "ADMIN_RETROACTIVE_REFUND";
})(SubscriptionEventRecordTypes || (SubscriptionEventRecordTypes = {}));

var _default = SubscriptionEventRecordTypes;
exports["default"] = _default;
module.exports = exports.default;