/**
 * The module containing the Squarespace.SlideRendering.Slices.Lock class.
 *
 * @module squarespace-slide-rendering-slices-lock
 */
YUI.add('squarespace-slide-rendering-slices-lock', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * The slice widget for slices of type SliceType.LOCK.
   *
   * @class Lock
   * @constructor
   * @namespace Squarespace.SlideRendering.Slices
   * @extends Squarespace.SlideRendering.Slices.Base
   */
  Y.Squarespace.SlideRendering.Slices.Lock = Y.Base.create('Lock',
    Y.Squarespace.SlideRendering.Slices.Base,
    [],
    {},
    {
      SLICE_TYPE: Y.Squarespace.SliceType.LOCK,
      CSS_PREFIX: 'sqs-slice-lock'
    }
  );
}, '1.0', {
  requires: [
    'base',
    'squarespace-enum',
    'squarespace-slide-rendering-slices-base'
  ]
});