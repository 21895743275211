/**
 * A Squarespace-speciific, augmented implementation of Y.ModelSync.REST
 *
 * Currently this alternative mixin provides the following additional features:
 *
 *   * Replays requests that fail due to invalid crumbs
 *
 * @module squarespace-model-sync-rest
 */
YUI.add('squarespace-model-sync-rest', function(Y) {
  function ModelSync() {
    this._ioConfigCache = {};
  }

  ModelSync.prototype = {
    sync: function(actions, options, callback) {
      // The default request timeout is 30 seconds, unless
      // another is provided. This is far too low for our application, but
      // the default is a static value on ModelSync.REST...it would be
      // weird to change that everywhere.
      if (!Y.Lang.isValue(options.timeout)) {
        options.timeout = 10000000;
      }

      // SWAP THE TWO LINES TO TEST LATENCY
      // Y.later(5000, this, Y.ModelSync.REST.prototype.sync, arguments);
      Y.ModelSync.REST.prototype.sync.apply(this, arguments);

    },

    /**
     * Store a Y.IO configuration object in the cache
     *
     * @param {Y.IO request} A Y.IO request
     * @param {Y.IO.config} A configuration object for Y.IO
     */
    _storeRequest: function(ioRequest, config) {
      this._ioConfigCache[ioRequest.id] = Y.merge(config, {});
    },

    /**
     * Retrive _and evict_ the request from the request config cache by transaction ID
     *
     * @param {String} A Y.IO transaction ID string
     * @return The Y.IO configuration for the requested transaction
     */
    _retrieveAndEvict: function(txId) {
      var result = this._ioConfigCache[txId];
      this._ioConfigCache[txId] = null;

      return result;
    },

    /**
     * @param {Object} A response object
     * @return true if the response is a crumb check failure, false otherwise
     */
    _isCrumbFailure: function(response) {
      return response && response.crumbFail && response.crumb;
    },

    /**
     * @override
     */
    _sendSyncIORequest: function(config) {
      // Cache the Y.io request configuration by transaction ID
      var ioRequest = Y.ModelSync.REST.prototype._sendSyncIORequest.apply(this, arguments);
      this._storeRequest(ioRequest, config);
      return ioRequest;
    },


    /**
     * @override
     */
    _onSyncIOEnd: function(txId, details) {
      this._retrieveAndEvict(txId);
      Y.ModelSync.REST.prototype._onSyncIOEnd.apply(this, arguments);
    },

    /**
     * We must override the success handler, not the failure handler because
     * invalid crumb exceptions still return a 200 OK status, not 400-something.
     *
     * @override
     */
    _onSyncIOSuccess: function(txId, res, details) {
      var ioConfig = this._retrieveAndEvict(txId);
      var parsedResponse = {};
      var responseText = res.responseText;

      // If the response from the server is empty, we need to support that.
      // Also, due to a weird server-side bug, many things return a single space
      // character instead of an empty body.
      if (Y.Lang.isValue(responseText) && responseText.trim()) {
        parsedResponse = Y.JSON.parse(responseText);
      }

      // Inspect the response, and if it is a crumb failure, retry after udpating
      // the crumb Environment variable
      if (this._isCrumbFailure(parsedResponse)) {
        var newCrumb = parsedResponse.crumb;

        // oh god...
        Y.ModelSync.REST.CSRF_TOKEN = newCrumb;

        // Even though ModelSync.REST does caches this value on module initialization,
        // it's good to globally update it for other components.
        Y.Env.CSRF_TOKEN = newCrumb;

        ioConfig.headers['X-CSRF-Token'] = newCrumb;
        this._sendSyncIORequest(ioConfig);
      } else {

        // Use default behavior if it is not
        Y.ModelSync.REST.prototype._onSyncIOSuccess.apply(this, arguments);
      }
    },

    /**
     * We override the failure handler to add the "responseJSON" object
     * to the error object.
     *
     * @override
     */
    _onSyncIOFailure: function (txId, res, details) {
      var callback = details.callback;

      if (callback) {
        var parsedResponse = {};
        var responseText = res.responseText;

        if (Y.Lang.isValue(responseText) && responseText.trim()) {
          parsedResponse = Y.JSON.parse(responseText);
        }

        callback({
          code: res.status,
          msg: res.statusText,
          responseJSON: parsedResponse
        }, res);
      }
    }
  };

  Y.mix(ModelSync, Y.ModelSync.REST, false, null, 1);

  Y.namespace('Squarespace.ModelSync').REST = ModelSync;

}, '1.0', {
  requires: [
    'model-sync-rest'
  ]
});
