import {
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.ProfileLeftLeft01 class.
 *
 * @module squarespace-slide-rendering-layouts-profile-left-left-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-profile-left-left-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for profile-left-left-01.
   *
   * @class ProfileLeftLeft01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.ProfileLeftLeft01 = Y.Base.create('ProfileLeftLeft01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthRatio: 1,
          scale: 0.99,
          minFontSize: 30,
          widthBoundaryNode: contentBox.querySelector('.sqs-slice-heading-content'),
          heightBoundaryNode: contentBox.querySelector('.sqs-slide-layer'),
          heightRatio: 0.3,
          onRespond: (function() {
            Y.later(0, this, function() {
              this._syncSliceWidgets();
            });
          }).bind(this)
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          widthRatio: 1,
          scale: 0.99,
          minFontSize: 20,
          widthBoundaryNode: contentBox.querySelector('.sqs-slice-heading-content'),
          heightBoundaryNode: contentBox.querySelector('.sqs-slide-layer'),
          onRespond: (function() {
            Y.later(0, this, function() {
              this._syncSliceWidgets();
            });
          }).bind(this)
        }));

        // Actions and custom form font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector('.responsive-wrapper'),
          fontSizeNodes: [
            contentBox.querySelector(('[data-compound-type="action"] ul')),
            contentBox.querySelector('[data-slice-type="custom-form"]')
          ],
          minFontSize: 12,
          widthRatio: 0.9,
          scale: 0.9,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
          },
          onMinFontSize: function(rootNode) {
            rootNode.classList.add('stacked');
          },
          onRespond: (function() {
            Y.later(0, this, function() {
              this._syncSliceWidgets();
            });
          }).bind(this)
        }));

        // Copy and Link Group overlap detector
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('.group-copy'),
          targetNode: contentBox.querySelector('.sqs-slice-group.group-links'),
          padding: 0,
          onReset: function(rootNode, targetNode) {
            targetNode.classList.add('bottom-full-width');
          },
          onRespond: function(rootNode, targetNode) {
            targetNode.classList.remove('bottom-full-width');
          }
        }));

        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: contentBox.querySelector('.sqs-slice-group.group-links'),
          onReset: function(rootNode) {
            rootNode = new Y.Node(rootNode);
            rootNode.ancestor('.sqs-slide-layer-content').removeClass('scroll');
          },
          onRespond: function(rootNode) {
            rootNode = new Y.Node(rootNode);
            rootNode.ancestor('.sqs-slide-layer-content').addClass('scroll');
          }
        }));
      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
