"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.FormType */

/* Java class defined in repository cms-legacy-data */
var FormType;

(function (FormType) {
  FormType["FORM_BLOCK"] = 1;
  FormType["PRODUCT"] = 2;
  FormType["DONATION"] = 3;
  FormType["SLIDE"] = 4;
  FormType["NEWSLETTER_BLOCK"] = 5;
  FormType["CHECKOUT_PAGE"] = 6;
})(FormType || (FormType = {}));

var _default = FormType;
exports["default"] = _default;
module.exports = exports.default;