"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.WorkflowState */

/* Java class defined in repository cms-legacy-data */
var WorkflowStates;

(function (WorkflowStates) {
  WorkflowStates["PUBLISHED"] = 1;
  WorkflowStates["SCHEDULED"] = 2;
  WorkflowStates["NEEDS_REVIEW"] = 3;
  WorkflowStates["DRAFT"] = 4;
  WorkflowStates["PRIVATE"] = 5;
  WorkflowStates["DELETED"] = 6;
})(WorkflowStates || (WorkflowStates = {}));

var _default = WorkflowStates;
exports["default"] = _default;
module.exports = exports.default;