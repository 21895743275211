"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.CommerceCouponType */

/* Java class defined in repository squarespace-v6 */
var CommerceCouponType;

(function (CommerceCouponType) {
  CommerceCouponType["SINGLE_PAYWALL_PRODUCT"] = 7;
  CommerceCouponType["CATEGORY_IDS"] = 6;
  CommerceCouponType["THIRD_PARTY"] = 5;
  CommerceCouponType["SINGLE_PRODUCT"] = 4;
  CommerceCouponType["CATEGORIES"] = 3;
  CommerceCouponType["ORDERS_OVER"] = 2;
  CommerceCouponType["ALL_ORDERS"] = 1;
})(CommerceCouponType || (CommerceCouponType = {}));

var _default = CommerceCouponType;
exports["default"] = _default;
module.exports = exports.default;