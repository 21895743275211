"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.googleapps.GoogleAppsPlanType */

/* Java class defined in repository squarespace-v6 */
var GoogleAppsPlanType;

(function (GoogleAppsPlanType) {
  GoogleAppsPlanType["FLEXIBLE"] = 1;
  GoogleAppsPlanType["TRIAL"] = 2;
  GoogleAppsPlanType["ANNUAL"] = 3;
})(GoogleAppsPlanType || (GoogleAppsPlanType = {}));

var _default = GoogleAppsPlanType;
exports["default"] = _default;
module.exports = exports.default;