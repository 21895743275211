"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.CensusDataMetric */

/* Java class defined in repository squarespace-v6 */
var CensusDataMetric;

(function (CensusDataMetric) {
  CensusDataMetric["VIEWS"] = 1;
  CensusDataMetric["VISITS"] = 2;
  CensusDataMetric["VISITORS"] = 3;
  CensusDataMetric["COUNT"] = 4;
  CensusDataMetric["SUBS"] = 5;
  CensusDataMetric["BOTS"] = 6;
  CensusDataMetric["REVENUE"] = 7;
  CensusDataMetric["QUANTITY"] = 8;
  CensusDataMetric["QUERIES"] = 9;
  CensusDataMetric["OS"] = 10;
  CensusDataMetric["BROWSER"] = 11;
  CensusDataMetric["DEVICE_TYPE"] = 12;
  CensusDataMetric["VERSIONS"] = 13;
})(CensusDataMetric || (CensusDataMetric = {}));

var _default = CensusDataMetric;
exports["default"] = _default;
module.exports = exports.default;