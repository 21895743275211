"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.DomainNameStatus */

/* Java class defined in repository squarespace-v6 */
var DomainNameStatus;

(function (DomainNameStatus) {
  DomainNameStatus["IN_USE"] = 1;
  DomainNameStatus["INVALID_SYNTAX"] = 2;
  DomainNameStatus["INVALID_NAME"] = 3;
  DomainNameStatus["VALID"] = 4;
})(DomainNameStatus || (DomainNameStatus = {}));

var _default = DomainNameStatus;
exports["default"] = _default;
module.exports = exports.default;