"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.CollectionOrdering */

/* Java class defined in repository cms-legacy-data */
var CollectionOrdering;

(function (CollectionOrdering) {
  CollectionOrdering["USER"] = 1;
  CollectionOrdering["CHRONOLOGICAL"] = 2;
  CollectionOrdering["CUSTOM"] = 3;
  CollectionOrdering["CALENDAR"] = 4;
  CollectionOrdering["MUSIC_ALBUM"] = 5;
  CollectionOrdering["KNOWLEDGE_BASE"] = 6;
})(CollectionOrdering || (CollectionOrdering = {}));

var _default = CollectionOrdering;
exports["default"] = _default;
module.exports = exports.default;