import {
  FontSize as ResponsiveFontSize,
  BoundaryRatios as ResponsiveBoundaryRatios,
  OverflowDetector as ResponsiveOverflowDetector,
} from '@sqs/responsive';

/**
 * The module containing the
 * Squarespace.SlideRendering.Layouts.ProfileCenterCenter01 class.
 *
 * @module squarespace-slide-rendering-layouts-profile-center-center-01
 * @author dbarber
 */
YUI.add('squarespace-slide-rendering-layouts-profile-center-center-01', function(Y) {

  var toDOMNode = function(yuiNode) {
    if (Y.instanceOf(yuiNode, Y.Node)) {
      return yuiNode.getDOMNode();
    }

    return null;
  };

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for profile-center-center-01.
   *
   * @class ProfileCenterCenter01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.ProfileCenterCenter01 = Y.Base.create('ProfileCenterCenter01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox');
        var linksGroupNode = contentBox.one('.sqs-slice-group.group-links');

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: toDOMNode(contentBox.one('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          minFontSize: 26,
          widthRatio: 0.9
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: toDOMNode(contentBox.one('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          minFontSize: 18,
          widthRatio: 0.9,
          widthBoundaryNode: toDOMNode(contentBox.one('.group-copy'))
        }));

        // Actions and custom form font size
        var actionsFontSizeComponent = new ResponsiveFontSize({
          rootNode: toDOMNode(contentBox.one('.responsive-wrapper')),
          fontSizeNodes: [
            toDOMNode(contentBox.one('[data-compound-type="action"] ul')),
            toDOMNode(contentBox.one('[data-slice-type="custom-form"]:not([data-content-empty])'))
          ],
          widthBoundaryNode: linksGroupNode,
          widthRatio: 0.9,
          minFontSize: 10,
          enabled: true,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
          },
          onMinFontSize: function(rootNode) {
            rootNode.classList.add('stacked');
          }
        });

        this._registerResponsiveComponent(actionsFontSizeComponent);

        // Social icons wrapping
        var socialIconsWrappingComponent = new ResponsiveBoundaryRatios({
          rootNode: toDOMNode(contentBox.one('[data-slice-type="social-icons"]')),
          widthRatio: 0.9,
          enabled: false,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('stacked');
          }
        });

        this._registerResponsiveComponent(socialIconsWrappingComponent);

        // Boundary ratios for inline link group
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: toDOMNode(contentBox.one('.responsive-wrapper')),
          widthRatio: 0.9,
          onReset: function(rootNode) {
            rootNode.classList.remove('stacked');
            rootNode.querySelector('.group-actions').style.whiteSpace = 'nowrap';
            socialIconsWrappingComponent.disable();
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('stacked');
            rootNode.querySelector('.group-actions').style.whiteSpace = 'normal';
            socialIconsWrappingComponent.enable();
          }
        }));

        // Scroll on overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: toDOMNode(contentBox.one('.sqs-slice-group.group-content')),
          onReset: function(rootNode) {
            rootNode.classList.add('align-center-vert');
            contentBox.one('.sqs-slide-layer').removeClass('scroll');
          },
          onRespond: function(rootNode) {
            rootNode.classList.remove('align-center-vert');
            contentBox.one('.sqs-slide-layer').addClass('scroll');
          }
        }));
      }
    }
  );
}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
