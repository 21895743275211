"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.CommentState */

/* Java class defined in repository cms-legacy-data */
var CommentStates;

(function (CommentStates) {
  CommentStates["ENABLED"] = 1;
  CommentStates["DISABLED"] = 2;
})(CommentStates || (CommentStates = {}));

var _default = CommentStates;
exports["default"] = _default;
module.exports = exports.default;