"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.SocialIconAlignment */

/* Java class defined in repository cms-legacy-data */
var SocialIconAlignments;

(function (SocialIconAlignments) {
  SocialIconAlignments["LEFT"] = "left";
  SocialIconAlignments["RIGHT"] = "right";
  SocialIconAlignments["CENTER"] = "center";
})(SocialIconAlignments || (SocialIconAlignments = {}));

var _default = SocialIconAlignments;
exports["default"] = _default;
module.exports = exports.default;