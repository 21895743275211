"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.FormBackendConnectionState */

/* Java class defined in repository cms-legacy-data */
var FormBackendConnectionState;

(function (FormBackendConnectionState) {
  FormBackendConnectionState["CONNECTED"] = 1;
  FormBackendConnectionState["DISCONNECTED"] = 2;
  FormBackendConnectionState["PENDING"] = 3;
})(FormBackendConnectionState || (FormBackendConnectionState = {}));

var _default = FormBackendConnectionState;
exports["default"] = _default;
module.exports = exports.default;