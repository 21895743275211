/**
 * The module containing the Squarespace.Widgets.Slices.Navigation class.
 *
 * @module squarespace-slide-rendering-slices-navigation
 */
YUI.add('squarespace-slide-rendering-slices-navigation', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * The slice widget for slices of type SliceType.NAVIGATION.
   *
   * @class Navigation
   * @constructor
   * @namespace Squarespace.SlideRendering.Slices
   * @extends Squarespace.SlideRendering.Slices.Base
   * @uses Squarespace.SlideRendering.Slices.ClientRefresh
   */
  Y.Squarespace.SlideRendering.Slices.Navigation = Y.Base.create('Navigation',
    Y.Squarespace.SlideRendering.Slices.Base,
    [Y.Squarespace.SlideRendering.Slices.ClientRefresh],
    {
      // Override
      refreshFromSliceContent: function(sliceContent) {
        var newHTML = '';
        var isEmpty = true;

        sliceContent.get('links').each(function(link) {
          var text = link.get('text');

          if (Y.Lang.isValue(text) && text !== '') {
            isEmpty = false;

            var clickthroughUrl = link.get('clickthroughUrl');

            newHTML += Y.Lang.sub('<li><a href="{href}"{target}>{text}</a></li>', {
              href: clickthroughUrl.url,
              targetNode: clickthroughUrl.newWindow ? ' targetNode="_blank"' : '',
              text: Y.Squarespace.Escaping.escapeForHtml(text)
            });
          }
        });

        this.get('contentBox').one('ul').setHTML(newHTML);
        this._updateContentEmptyAttribute(isEmpty);
      }
    },
    {
      SLICE_TYPE: Y.Squarespace.SliceType.NAVIGATION,
      CSS_PREFIX: 'sqs-slice-navigation'
    }
  );
}, '1.0', {
  requires: [
    'base',
    'squarespace-enum',
    'squarespace-escaping-utils',
    'squarespace-slide-rendering-slices-base',
    'squarespace-slide-rendering-slices-client-refresh'
  ]
});