/**
 *  Events tracking API Key Management
 */
enum PublicApiEvent {
  API_KEY_CREATION_MODAL_COPY = 'api_key_creation_modal_copy',
  API_KEY_CREATION_MODAL_CANCEL = 'api_key_creation_modal_cancel',
  API_KEY_CREATION_MODAL_NEXT = 'api_key_creation_modal_next',
  API_KEY_CREATION_MODAL_COMPLETE = 'api_key_creation_modal_complete',

  API_KEYS_PANEL_CREATE_KEY = 'api_keys_panel_create_key',
  API_KEYS_PANEL_LEARN_MORE = 'api_keys_panel_learn_more',
  API_KEYS_PANEL_TERMS_OF_USE = 'api_keys_panel_terms_of_use',
  API_KEYS_PANEL_DELETE_KEY = 'api_keys_panel_delete_key',
  API_KEYS_PANEL_DELETE_KEY_CONFIRM = 'api_keys_panel_delete_key_confirm'
}

export default PublicApiEvent;
