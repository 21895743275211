"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.OrderState */

/* Java class defined in repository squarespace-v6 */
var OrderStates;

(function (OrderStates) {
  OrderStates["PENDING"] = 1;
  OrderStates["FULFILLED"] = 2;
  OrderStates["REFUNDED"] = 3;
  OrderStates["CANCELLED"] = 4;
})(OrderStates || (OrderStates = {}));

var _default = OrderStates;
exports["default"] = _default;
module.exports = exports.default;