"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.OverlayAction */

/* Java class defined in repository squarespace-v6 */
var OverlayAction;

(function (OverlayAction) {
  OverlayAction["DISPLAY"] = 1;
  OverlayAction["CLOSE"] = 2;
  OverlayAction["DISMISS"] = 3;
})(OverlayAction || (OverlayAction = {}));

var _default = OverlayAction;
exports["default"] = _default;
module.exports = exports.default;