"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.CardBrand */

/* Java class defined in repository squarespace-v6 */
var CardBrands;

(function (CardBrands) {
  CardBrands["VISA"] = "visa";
  CardBrands["MASTERCARD"] = "mastercard";
  CardBrands["DISCOVER"] = "discover";
  CardBrands["AMERICAN_EXPRESS"] = "amex";
  CardBrands["JCB"] = "jcb";
  CardBrands["DINERS_CLUB"] = "dinersclub";
  CardBrands["OTHER"] = "other";
})(CardBrands || (CardBrands = {}));

var _default = CardBrands;
exports["default"] = _default;
module.exports = exports.default;