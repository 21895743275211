"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.TaxCategoryType */

/* Java class defined in repository squarespace-v6 */
var TaxCategoryTypes;

(function (TaxCategoryTypes) {
  TaxCategoryTypes["CUSTOM"] = 1;
  TaxCategoryTypes["GENERAL"] = 2;
  TaxCategoryTypes["EXEMPT"] = 10;
  TaxCategoryTypes["CLOTHING"] = 11;
  TaxCategoryTypes["FOOD_GROCERIES"] = 12;
  TaxCategoryTypes["NON_PRESCRIPTION_DRUGS"] = 13;
  TaxCategoryTypes["PRESCRIPTION_DRUGS"] = 14;
  TaxCategoryTypes["DIGITAL_GOODS"] = 15;
  TaxCategoryTypes["SPECIAL_DIGITAL_PRODUCTS"] = 16;
})(TaxCategoryTypes || (TaxCategoryTypes = {}));

var _default = TaxCategoryTypes;
exports["default"] = _default;
module.exports = exports.default;