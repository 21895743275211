import {
  BoundaryRatios as ResponsiveBoundaryRatios,
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the Squarespace.SlideRendering.Layouts.LandingFullCenter02 class.
 * @module squarespace-slide-rendering-layouts-landing-full-center-02
 */
YUI.add('squarespace-slide-rendering-layouts-landing-full-center-02', function(Y) {

  var getRegion = function(node) {
    return (new Y.Node(node)).get('region');
  };

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for landing-full-center-02.
   *
   * @class LandingFullCenter02
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.LandingFullCenter02 = Y.Base.create('LandingFullCenter02',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // override
      _initResponsiveComponents: function() {
        var contentBox = this.get('contentBox').getDOMNode();
        var content = contentBox.querySelector('.layer-front .sqs-slide-layer-content');
        var copyNode = contentBox.querySelector('.group-copy');
        var bodySliceNode = copyNode.querySelector('[data-slice-type="body"]');
        var responsiveNode = contentBox.querySelector('.responsive-wrapper');
        var brandingNode = contentBox.querySelector('[data-compound-type="logo"]');
        var linksGroupNode = contentBox.querySelector('.group-links');

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-compound-type="logo"][data-slice-type="heading"] h1')),
          widthRatio: 0.99,
          minFontSize: 13
        }));

        // Heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: contentBox.querySelector(('[data-slice-type="heading"]:not([data-compound-type]) h1')),
          widthRatio: 1,
          heightRatio: 0.25,
          scale: 0.99,
          minFontSize: 18,
          heightBoundaryNode: content
        }));

        // Social Icons boundary detection
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: contentBox.querySelector('[data-slice-type="social-icons"]:not([data-content-empty="true"])'),
          widthBoundaryNode: content,
          widthRatio: 0.8,
          onReset: function(rootNode) {
            responsiveNode.classList.remove('stacked');
            if (rootNode.querySelector('.break')) {
              rootNode.querySelector('.break').remove();
            }
          },
          onRespond: function(rootNode) {
            responsiveNode.classList.add('stacked');
            // Social icons wrap onto two lines
            var icons = rootNode.childNodes;
            if (!rootNode.querySelector('.break')) {
              var item = new Y.Node(icons.item(Math.floor(icons.length / 2)));
              item.insert('<br class="break">', 'before');
            }
          }
        }));

        // Link-group boundary detection
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: linksGroupNode,
          widthBoundaryNode: content,
          widthRatio: 0.8,
          onReset: function(rootNode) {
            responsiveNode.classList.remove('stacked');
          },
          onRespond: function(rootNode) {
            responsiveNode.classList.add('stacked');
          }
        }));

        // Navigation boundary detection
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: contentBox.querySelector('[data-slice-type="navigation"]'),
          widthBoundaryNode: content,
          widthRatio: 0.8,
          onReset: function(rootNode) {
            responsiveNode.classList.remove('stacked');
          },
          onRespond: function(rootNode) {
            responsiveNode.classList.add('stacked');
          }
        }));

        // Actions boundary detection
        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: linksGroupNode,
          widthBoundaryNode: content,
          widthRatio: 0.8,
          onReset: function(rootNode) {
            responsiveNode.classList.remove('stacked');
            linksGroupNode.classList.add('top-right');
            brandingNode.classList.remove('aligned');
            linksGroupNode.classList.remove('bottom-full-width');
          },
          onRespond: function(rootNode) {
            responsiveNode.classList.add('stacked');
            linksGroupNode.classList.remove('top-right');
            brandingNode.classList.add('aligned');
            linksGroupNode.classList.add('bottom-full-width');
          }
        }));

        // Logo and Actions overlap detection
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('[data-compound-type="logo"] > *'),
          targetNode: linksGroupNode,
          padding: 60,
          onReset: function(rootNode, targetNode) {
            targetNode.classList.add('top-right');
            // Since sibling selectors are not possible in this use-case,
            // a class ('aligned') to explicitly allow the branding element to
            // obey the alignment tweak is necessary. This class should only be
            // removed if the group-links element has populated slices.
            if (targetNode.querySelector('.sqs-slice:not([data-content-empty="true"])')) {
              brandingNode.classList.remove('aligned');
            } else {
              brandingNode.classList.add('aligned');
            }
            targetNode.classList.remove('bottom-full-width');

            // Logo and Actions vertical alignment
            var logo = rootNode.parentNode;
            logo.style.marginTop = null;
            logo.style.marginBottom = null;

            targetNode.style.marginTop = null;
            targetNode.style.marginBottom = null;

            var dif = Math.floor((getRegion(logo).height - getRegion(targetNode).height) / 2);
            var adjustedElement = dif > 0 ? targetNode : logo;
            adjustedElement.style.marginTop = Math.abs(dif) + 'px';
            adjustedElement.style.marginBottom = Math.abs(dif) + 'px';
          },
          onRespond: function(rootNode, targetNode) {
            targetNode.classList.remove('top-right');
            brandingNode.classList.add('aligned');
            targetNode.classList.add('bottom-full-width');

            // Logo and Actions vertical alignment
            var logo = rootNode.parentNode;

            logo.style.marginTop = null;
            logo.style.marginBottom = null;

            targetNode.style.marginTop = null;
            targetNode.style.marginBottom = null;

          }
        }));

        // Link group and copy overlap detection
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: copyNode,
          targetNode: linksGroupNode,
          padding: 60,
          onReset: function(rootNode, targetNode) {
            rootNode.classList.remove('stacked');
            rootNode.classList.add('align-center-vert');
            rootNode.classList.add('restrict-width-1000');
            targetNode.classList.add('top-right');
            brandingNode.classList.remove('aligned');
            targetNode.classList.remove('bottom-full-width');
            targetNode.parentNode.classList.remove('scroll');
          },
          onRespond: function(rootNode, targetNode) {
            rootNode.classList.add('stacked');
            rootNode.classList.remove('align-center-vert');
            rootNode.classList.remove('restrict-width-1000');
            targetNode.classList.remove('top-right');
            brandingNode.classList.add('aligned');
            targetNode.classList.add('bottom-full-width');
            targetNode.parentNode.classList.add('scroll');
          }
        }));

        // Copy overflow detection
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: copyNode,
          onReset: function(rootNode) {
            rootNode.classList.add('align-center-vert');
            rootNode.parentNode.classList.remove('scroll');
          },
          onRespond: function(rootNode) {
            rootNode.classList.remove('align-center-vert');
            rootNode.parentNode.classList.add('scroll');
          }
        }));

        // Copy overflow detection
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: bodySliceNode,
          onReset: function(rootNode) {
            copyNode.classList.add('align-center-vert');
            copyNode.parentNode.classList.remove('scroll');
          },
          onRespond: function(rootNode) {
            copyNode.classList.remove('align-center-vert');
            copyNode.parentNode.classList.add('scroll');
          }
        }));

        // Link group overflow detection
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: linksGroupNode,
          padding: 10,
          onReset: function(rootNode) {
            copyNode.classList.add('align-center-vert');
            rootNode.parentNode.classList.remove('scroll');
          },
          onRespond: function(rootNode) {
            copyNode.classList.remove('align-center-vert');
            rootNode.parentNode.classList.add('scroll');
          }
        }));
      }
    }
  );

}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
