"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* File generated by JavaScriptEnumProcessor */

/* Derived from Java class com.squarespace.v6.utils.enums.VideoFilterOption */

/* Java class defined in repository cms-legacy-data */
var VideoFilterOption;

(function (VideoFilterOption) {
  VideoFilterOption["NONE"] = 1;
  VideoFilterOption["BLUR"] = 2;
  VideoFilterOption["BRIGHTNESS"] = 3;
  VideoFilterOption["CONTRAST"] = 4;
  VideoFilterOption["INVERT"] = 5;
  VideoFilterOption["OPACITY"] = 6;
  VideoFilterOption["SATURATE"] = 7;
  VideoFilterOption["SEPIA"] = 8;
})(VideoFilterOption || (VideoFilterOption = {}));

var _default = VideoFilterOption;
exports["default"] = _default;
module.exports = exports.default;