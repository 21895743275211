/**
 * The module containing the Squarespace.SlideRendering.Slices.Heading class.
 *
 * @module squarespace-slide-rendering-slices-heading
 */
YUI.add('squarespace-slide-rendering-slices-heading', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Slices');

  /**
   * The slice widget for slices of type SliceType.HEADING.
   *
   * @class Heading
   * @constructor
   * @namespace Squarespace.SlideRendering.Slices
   * @extends Squarespace.SlideRendering.Slices.Base
   * @uses Squarespace.SlideRendering.Slices.ClientRefresh
   */
  Y.Squarespace.SlideRendering.Slices.Heading = Y.Base.create('Heading',
    Y.Squarespace.SlideRendering.Slices.Base,
    [Y.Squarespace.SlideRendering.Slices.ClientRefresh],
    {
      // Override
      refreshFromSliceContent: function(sliceContent) {
        var text = sliceContent.get('text');
        var isEmpty = !Y.Lang.isValue(text) || text === '';
        var escapedText = Y.Squarespace.Escaping.escapeForHtml(text);
        this.get('contentBox').one('h1').setHTML(escapedText);
        this._updateContentEmptyAttribute(isEmpty);
      }
    },
    {
      SLICE_TYPE: Y.Squarespace.SliceType.HEADING,
      CSS_PREFIX: 'sqs-slice-heading'
    }
  );
}, '1.0', {
  requires: [
    'base',
    'squarespace-enum',
    'squarespace-escaping-utils',
    'squarespace-slide-rendering-slices-base',
    'squarespace-slide-rendering-slices-client-refresh'
  ]
});