import {
  BoundaryRatios as ResponsiveBoundaryRatios,
  FontSize as ResponsiveFontSize,
  OverflowDetector as ResponsiveOverflowDetector,
  OverlapDetector as ResponsiveOverlapDetector,
} from '@sqs/responsive';

/**
 * The module containing the Squarespace.SlideRendering.Layouts.TwitterFullLeft01 class.
 * @module squarespace-slide-rendering-layouts-twitter-full-left-01
 */
YUI.add('squarespace-slide-rendering-layouts-twitter-full-left-01', function(Y) {

  Y.namespace('Squarespace.SlideRendering.Layouts');

  /**
   * The layout widget for twitter-full-left-01.
   *
   * @class TwitterFullLeft01
   * @constructor
   * @namespace Squarespace.SlideRendering.Layouts
   * @extends Squarespace.SlideRendering.Layouts.Base
   */
  Y.Squarespace.SlideRendering.Layouts.TwitterFullLeft01 = Y.Base.create('TwitterFullLeft01',
    Y.Squarespace.SlideRendering.Layouts.Base,
    [],
    {
      // Override
      _initResponsiveComponents: function () {
        var contentBox = this.get('contentBox').getDOMNode();
        var twitterGroupNode = contentBox.querySelector('.group-twitter');
        var logoSocialActionsNode = contentBox.querySelector('.group-logo-social-actions');
        var responsiveWrapperNode = contentBox.querySelector('.responsive-wrapper');
        var contentNode = contentBox.querySelector('.layer-front .sqs-slide-layer-content');
        var brandingNode = contentBox.querySelector('[data-compound-type="logo"]');

        var setLogoHeight = function() {
          brandingNode.style.minHeight = responsiveWrapperNode.clientHeight + 'px';
          responsiveWrapperNode.style.minHeight = brandingNode.clientHeight + 'px';
        };

        // Logo heading font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: brandingNode.querySelector('h1'),
          widthBoundaryNode: contentNode,
          widthRatio: 0.8,
          minFontSize: 13,
          onReset: function(rootNode, widthBoundaryNode) {
            setLogoHeight();
          },
          onRespond: function(rootNode, widthBoundaryNode) {
            setLogoHeight();
          },
          onMinFontSize: function(rootNode, widthBoundaryNode) {
            setLogoHeight();
          }
        }));

        // Actions and custom form font size
        this._registerResponsiveComponent(new ResponsiveFontSize({
          rootNode: responsiveWrapperNode,
          fontSizeNodes: [
            contentBox.querySelector(('[data-compound-type="action"] ul')),
            contentBox.querySelector('[data-slice-type="custom-form"]')
          ],
          minFontSize: 12,
          widthRatio: 0.99,
          onReset: function(rootNode) {

            logoSocialActionsNode.classList.add('right-left');
            rootNode.classList.remove('stacked');
          },
          onRespond: function(rootNode) {
          },
          onMinFontSize: function(rootNode) {

            logoSocialActionsNode.classList.remove('right-left');
            rootNode.classList.add('stacked');
          }
        }));

        // Font size scaling for tweet bodies
        var tweetBodyNodes = contentBox.querySelectorAll('[data-slice-type="twitter"] .tweet-body');

        Array.prototype.forEach.call(tweetBodyNodes, Y.bind(function(tweetBodyNode) {
          this._registerResponsiveComponent(new ResponsiveFontSize({
            rootNode: tweetBodyNode,
            widthRatio: 1,
            heightRatio: 0.55,
            scale: 0.97,
            minFontSize: 18,
            heightBoundaryNode: twitterGroupNode
          }));
        }, this));

        // Overlap between logo first child and responsive wrapper last child
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('[data-compound-type="logo"] > *'),
          targetNode: responsiveWrapperNode.querySelector('.sqs-slice:last-child'),
          padding: 40,
          onReset: function(rootNode, targetNode) {
            logoSocialActionsNode.classList.add('right-left');
          },
          onRespond: function(rootNode, targetNode) {
            logoSocialActionsNode.classList.remove('right-left');
          }
        }));

        // Overlap between logo first child and responsive wrapper first child
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('[data-compound-type="logo"] > *'),
          targetNode: responsiveWrapperNode.querySelector('.sqs-slice:first-child'),
          padding: 40,
          onReset: function(rootNode, targetNode) {
            logoSocialActionsNode.classList.add('right-left');
          },
          onRespond: function(rootNode, targetNode) {
            logoSocialActionsNode.classList.remove('right-left');
          }
        }));

        this._registerResponsiveComponent(new ResponsiveBoundaryRatios({
          rootNode: responsiveWrapperNode,
          widthRatio: 0.95,
          onReset: function(rootNode) {

            rootNode.classList.remove('stacked');
            setLogoHeight();
          },
          onRespond: function(rootNode) {
            rootNode.classList.add('stacked');
          }
        }));

        // Overlap between tweetNode and actions
        this._registerResponsiveComponent(new ResponsiveOverlapDetector({
          rootNode: contentBox.querySelector('.group-logo-social-actions'),
          targetNode: twitterGroupNode,
          padding: 40,
          onReset: function(rootNode, targetNode) {
            rootNode.classList.add('bottom-full-width');
            twitterGroupNode.classList.remove('stacked');
            contentNode.classList.remove('scroll');
            setLogoHeight();
          },
          onRespond: function(rootNode, targetNode) {
            rootNode.classList.remove('bottom-full-width');
            twitterGroupNode.classList.add('stacked');
            contentNode.classList.add('scroll');
          }
        }));

        // Scroll on overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: twitterGroupNode,
          padding: 20,
          onReset: function() {
            twitterGroupNode.classList.remove('stacked');
            contentNode.classList.remove('scroll');
          },
          onRespond: function() {
            twitterGroupNode.classList.add('stacked');
            contentNode.classList.add('scroll');
          }
        }));

        // Scroll on overflow
        this._registerResponsiveComponent(new ResponsiveOverflowDetector({
          rootNode: responsiveWrapperNode,
          padding: 20,
          onReset: function() {
            twitterGroupNode.classList.remove('stacked');
            contentNode.classList.remove('scroll');
          },
          onRespond: function() {
            twitterGroupNode.classList.add('stacked');
            contentNode.classList.add('scroll');
          }
        }));
      }
    }
  );

}, '1.0', {
  requires: [
    'squarespace-slide-rendering-layouts-base'
  ]
});
