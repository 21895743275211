/**
 * The module containing the Squarespace.FactoryUtils class.
 *
 * @module squarespace-factory-utils
 * @author dbarber
 */
YUI.add('squarespace-factory-utils', function(Y) {

  Y.namespace('Squarespace');

  /**
   * A class of utility functions related to factories used throughout the
   * system.
   *
   * @class FactoryUtils
   * @static
   * @namespace Squarespace
   */
  Y.Squarespace.FactoryUtils = {

    /**
     * Creates a factory that creates classes from a given namespace by mapping
     * a given enum value to a class with a matching static property.
     *
     * @method createEnumToClassFactory
     * @param _enum {Object} The enum to map from.
     * @param classNamespace {Object} The namespace that the classes being
     *     mapped to exist at.
     * @param propertyName {String} The name of the static property on the
     *     classes at that namespace that hold the enum value.
     * @param defaultClassName {String} An optional class name to be used for
     *     values of enums that do not point to a specific class. If this
     *     parameter is null, those enums simply won't be mapped.
     */
    createEnumToClassFactory: function(_enum, classNamespace, propertyName, defaultClassName) {
      var CTOR_MAP = {};
      var enumValues = Y.Object.values(_enum);

      Y.Array.reduce(enumValues, CTOR_MAP, function(currMap, enumValue) {
        var ctor;
        var hasCtor = Y.Object.some(classNamespace, function(clazz) {
          if (clazz[propertyName] === enumValue) {
            ctor = clazz;
            return true;
          }
        });

        if (hasCtor) {
          currMap[enumValue] = ctor;
        } else if (Y.Lang.isValue(defaultClassName)) {
          currMap[enumValue] = classNamespace[defaultClassName];
        }

        return currMap;
      });

      return {
        create: function(enumValue, config) {
          var ctor = CTOR_MAP[enumValue];

          if (!Y.Lang.isValue(ctor)) {
            throw new Error('FactoryUtils: Could not find ctor for enumValue ' +
                  enumValue + '.');
          }

          return new ctor(config || {});
        }
      };
    }
  };
}, '1.0', {
  requires: []
});